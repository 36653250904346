import OasisF21 from "assets/images/oasis/FemaleOutfit21.png";
const ANANTAProductList =
    [
        {
            id: 0,
            src: OasisF21,
            product_path: "arie102_0",
            product_counter_part: "arie107_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "ANANTA",
            avatar: "v12",
            shoes: 'arie102107'
        },
        {
            id: 1,
            src: OasisF21,
            product_path: "arie107_0",
            product_counter_part: "arie102_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "ANANTA",
            avatar: "v12",
            shoes: 'arie102107'
        },
    ];

export default ANANTAProductList;