import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from 'react-router-dom';
import get from "lodash/get";
import IframeSection from "containers/dummyProduct/IframeSection";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from 'lodash/isEmpty';
import Button from "components/Button";
import Logo from "../../assets/icons/Logo.png";
import "./AdidasProducts.scss";
import Login from "containers/login/Login.js";
import { actionsCreator } from "redux/actions/actionsCreator.js";
import LoginAction from "containers/babylon/LoginAction.js";
import { abfrlProductList, adidasProductList, ANANTAProductList, beingHumanProductList, beingHumanProductListQA, beingHumansProductList, burgerBaeProductList, cavaProductList, ccclubProductList, cluster1ProductList, cluster2ProductList, enamorProductList, fashorProductList, fefDemoProductList, fefProductList, forevernewProductList, iffProductList, lacosteDemoList, missChaseList,indianTerrainList, nicobarFastProductList, nicobarProductList, rareismProductList, rarerabbitDemoList, rareRabbitProductList, SANProductList, superKicksProductList, testProductList, virgioProductList } from "configs/productList";
import thessProductList from "configs/productList/Thess";
import pumaProductList from "configs/productList/Puma";

// export const ccclubProductList = [
//   {
//     id: 1,
//     src: NicobarDress,
//     product_path: "loverteetop_0",
//     product_counter_part: "loveislove_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 11,
//     src: NicobarDress,
//     product_path: "loveislove_0",
//     product_counter_part: "loverteetop_0",
//     garment_type: "bottom",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 2,
//     src: NicobarDress,
//     product_path: "rsbltop_0",
//     product_counter_part: "rsblbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 22,
//     src: NicobarDress,
//     product_path: "rsblbottom_0",
//     product_counter_part: "rsbltop_0",
//     garment_type: "bottom",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 3,
//     src: NicobarDress,
//     product_path: "stttop_0",
//     product_counter_part: "sttbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 4,
//     src: NicobarDress,
//     product_path: "ltccctop_0",
//     product_counter_part: "ltcccbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 5,
//     src: NicobarDress,
//     product_path: "sttop_0",
//     product_counter_part: "stbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 5,
//     src: NicobarDress,
//     product_path: "sttop_0",
//     product_counter_part: "stbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 6,
//     src: NicobarDress,
//     product_path: "bptop_0",
//     product_counter_part: "bpshorts_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 7,
//     src: NicobarDress,
//     product_path: "bptop_1",
//     product_counter_part: "bpshorts_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 8,
//     src: NicobarDress,
//     product_path: "sftop_0",
//     product_counter_part: "sfbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 9,
//     src: NicobarDress,
//     product_path: "aidtop_0",
//     product_counter_part: "aidbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 10,
//     src: NicobarDress,
//     product_path: "mcbhtop_0",
//     product_counter_part: "mcbhbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 11,
//     src: NicobarDress,
//     product_path: "meltdowntop_0",
//     product_counter_part: "meltdownbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 12,
//     src: NicobarDress,
//     product_path: "hctop_0",
//     product_counter_part: "hcbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 13,
//     src: NicobarDress,
//     product_path: "fwtop_0",
//     product_counter_part: "fwsitbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 14,
//     src: NicobarDress,
//     product_path: "tirtop_0",
//     product_counter_part: "tirbottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 15,
//     src: NicobarDress,
//     product_path: "o14top_0",
//     product_counter_part: "o14bottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 16,
//     src: NicobarDress,
//     product_path: "bswye_0",
//     product_counter_part: "bswyebottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
//   {
//     id: 17,
//     src: NicobarDress,
//     product_path: "hliy_0",
//     product_counter_part: "hliybottom_0",
//     garment_type: "top",
//     gender: "male",
//     productPath: "/abfrl/idrisTshirt.glb",
//     environmentImage: "",
//     // changeableItemAlbedoColor: "#8E5858",
//     // changeableItemEmissiveColor: "#F2A9A9",
//     name: "Nicobar Dress",
//     labels: "WA_D607",
//     description:
//       "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
//     bullet_description: [
//       "Regular fit",
//       "100% cotton",
//       "Half Sleeves",
//       "Ocassion: Casual",
//       "Neck: Polo Neck",
//       "Pattern: Stripe",
//       "Color: White",
//       "Collection: LY True Casual"
//     ],
//     price: "$210",
//     brand: "ccclub",
//     avatar: "v8",
//     shoes: 'v8shoes',
//     productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
//     productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
//     productCounterPartSizeTags: [],
//     productCounterPartSizeValues: []
//   },
// ];

const mapStateToProps = ({ avatarReducer = {} }) => ({
    isAnonymous: get(avatarReducer, 'isAnonymous', true),
    oldUserId: get(avatarReducer, 'oldUserId', ''),
    loadedScene: get(avatarReducer, 'loadedScene'),
});
const AdidasProducts = (props) => {
    const { isAnonymous = true, loadedScene } = useSelector(
        mapStateToProps
    );
    const dispatch = useDispatch();
    const [productList, setProductList] = useState([]);
    const history = useHistory();
    const [selectedProduct, setSelectedProduct] = useState({});
    const [showLogin, setShowLogin] = useState(true);
    const selectProduct = product => {
        loadedScene && loadedScene.dispose();
        dispatch(actionsCreator.SET_LOADED_SCENE(null));
        history.push(`${location.pathname}?productId=${product.id}`);
        // history.push()
        setSelectedProduct(product);
    };
    const location = useLocation();
    useEffect(() => {
        setShowLogin(isAnonymous && !props.bypassLogin);
    }, [isAnonymous, props.bypassLogin])

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            const { search } = location;
            const query = new URLSearchParams(search);
            const productId = query.get('productId');
            if (!productId) {
                setSelectedProduct({});
            }
            return unlisten;
        });
    }, [])


    useEffect(() => {
        const { search } = location;
        const query = new URLSearchParams(search);
        let productList = adidasProductList;
        const productId = query.get('productId');
        if (props.showAll) {
            productList = [...adidasProductList, ...abfrlProductList, ...cluster1ProductList].sort((a, b) => {
                if (a.gender === 'female') {
                    return -1;
                };
                return 1;
            }).map((p, index) => {
                return { ...p, id: index }
            });
        } else if (location.pathname.includes('abfrlm') || location.pathname.includes('abfrlf')) {
            const gender = location.pathname.includes('abfrlm') ? 'male' : 'female'
            productList = abfrlProductList.filter(product => product.gender === gender);
        } else if (location.pathname.includes('abfrl')) {
            productList = abfrlProductList;
            //setBabylonViewer(false);
        } else if (location.pathname.includes('oasis')) {
            productList = cluster1ProductList;
        } else if (location.pathname.includes('valentino')) {
            productList = cluster2ProductList;
        } else if (location.pathname.includes('SAN')) {
            productList = SANProductList;
        }
        else if (location.pathname.includes('ANANTA')) {
            productList = ANANTAProductList;
        }
        else if (location.pathname.includes('thess')) {
            productList = thessProductList;
        }
        else if (location.pathname.includes('nicobar')) {
            productList = nicobarProductList;
        }
        else if (location.pathname.includes('ccclub')) {
            productList = ccclubProductList;
        }
        else if (location.pathname.includes('cava')) {
            productList = cavaProductList;
        }
        else if (location.pathname.includes("test")) {
            productList = testProductList;
        }
        else if (location.pathname.includes("beinghumans")) {
            productList = beingHumansProductList;
        }
        else if (location.pathname.includes("beinghuman")) {
            productList = beingHumanProductListQA;
        } else if (location.pathname.includes("BHuman")) {
            productList = beingHumanProductList;
        } else if (location.pathname.includes("burgerbae")) {
            productList = burgerBaeProductList;
        } else if (location.pathname.includes("enamor")) {
            productList = enamorProductList;
        } else if (location.pathname.includes("rarerabbit-demo")) {
            productList = rarerabbitDemoList;
        } else if (location.pathname.includes("rarerabbit")) {
            productList = rareRabbitProductList;
        } else if (location.pathname.includes("rarerism")) {
            productList = rareismProductList;
        }
        else if (location.pathname.includes("lacoste-demo")) {
            productList = lacosteDemoList;
        } else if (location.pathname.includes("dopplr-demo")) {
            productList = lacosteDemoList;
        } else if (location.pathname.includes("fashor")) {
            productList = fashorProductList;
        } else if (location.pathname.includes("superkicks")) {
            productList = superKicksProductList;
        } else if (location.pathname.includes("fef-demo")) {
            productList = fefDemoProductList;
        } else if (location.pathname.includes("fef")) {
            productList = fefProductList;
        }
        if (location.pathname.includes('puma-female')) {
            productList = pumaProductList.filter(product => product.gender === 'female');
        } else if (location.pathname.includes('puma')) {
            productList = pumaProductList;
        }
        if (location.pathname.includes('nicobar-test')) {
            productList = nicobarFastProductList;
        }
        if (location.pathname.includes('iff')) {
            productList = iffProductList;
        }
        if (location.pathname.includes('virgio')) {
            productList = virgioProductList;
        }
        if (location.pathname.includes('forevernew')) {
            productList = forevernewProductList;
        }
        if (location.pathname.includes("misschase")) {
            productList = missChaseList;
        }
        if (location.pathname.includes("indianterrain")) {
            productList = indianTerrainList;
        }
        setProductList(productList);
        if (productId) {
            let selectedProduct = productList.find(product => product.id.toString() === productId.toString());
            selectedProduct && setSelectedProduct(selectedProduct);
        }
    }, [])

    // const onLoad = () => {
    //   const frame = document.getElementById("myFrame");
    //   frame.contentWindow.postMessage(
    //     { message: "getAppData", value: selectedProduct },
    //     `${window.location.origin}/dopplr-avatar`
    //   );
    // }
    const onLoginSuccess = (user) => {
        setShowLogin(false);
    }

    if (showLogin) {
        return <Login onSuccess={onLoginSuccess} />
    }

    const isProductSelected = !isEmpty(selectedProduct);
    return (
        <div className="AdidasProducts">
            <div className="Header">
                <div style={{ height: '45px' }}>
                    <img src={Logo} alt="Dopplr" onClick={() => history.push(`${location.pathname}`)} />
                </div>
                <LoginAction className="Dopplr_LoginAction" />
            </div>
            <div className="PageWrapper">
                {isProductSelected ?
                    <div className="SelectedProduct">
                        <div className="SelectedProduct__Image">
                            {(selectedProduct?.src || selectedProduct.productImage) && (
                                <img src={selectedProduct.productImage || selectedProduct.src} alt={get(selectedProduct, 'name', '')} />
                            )}
                        </div>
                        <div className="SelectedProduct__Description">
                            <div className="SelectedProduct__Name">
                                {get(selectedProduct, "name", "") || selectedProduct.productTitle || ""}
                            </div>
                            <div className="SelectedProduct__PriceContainer">
                                <div className="SelectedProduct__Labels">
                                    {get(selectedProduct, "labels", "")}
                                </div>
                                <div className="SelectedProduct__Price">
                                    {get(selectedProduct, "price", "")}
                                </div>
                            </div>
                            <div className="SelectedProduct__Action">
                                <Button
                                    type="black-white"
                                    className="CreateAvatar"
                                    text="Add to Cart"
                                />
                                <Button
                                    type="white-black"
                                    className="CreateAvatar"
                                    text="Buy Now"
                                />
                            </div>
                            {selectedProduct?.description && <div className="SelectedProduct__Description">
                                <div className="SelectedProduct__Label">Description</div>
                                <div className="SelectedProduct__ShortDescription">
                                    {get(selectedProduct, "description", "")}
                                </div>
                                <ul className="SelectedProduct__BulletDescription">
                                    {get(selectedProduct, "bullet_description", []).map((desc, i) => {
                                        return <li key={i}>{desc}</li>;
                                    })}
                                </ul>
                            </div>}
                        </div>
                    </div> :
                    <div className="ProductList">
                        {productList.map((product, i) => {
                            const isSelected = selectedProduct && selectedProduct.id === product.id;
                            return (
                                <div
                                    key={i}
                                    onClick={() => selectProduct(product)}
                                    className={`ProductCard`}
                                >
                                    <div
                                        className={`ProductCard__Image ${isSelected ? "selected" : "overlay"
                                            }`}
                                    >
                                        <img src={product.productImage || product.src} alt={product.product_path} />
                                    </div>
                                    <div
                                        className={`ProductCard__Description ${isSelected ? "selectedText" : ""
                                            }`}
                                    >
                                        {/* {product.name} */}
                                    </div>
                                </div>
                            );
                        })}
                    </div>}
            </div>
            {isProductSelected && <IframeSection productList={productList} selectedProduct={selectedProduct} />}
        </div>
    );
};

export default AdidasProducts;
