import SaveTheTreesMen from 'assets/images/ccclub/Save_the_trees_men.jpg';
import ShakyMen from 'assets/images/ccclub/SHAKY_TEE_MEN.jpg';
import BePoliteMen1 from 'assets/images/ccclub/Be_Polite_Man.jpg';
import BePoliteMen2 from 'assets/images/ccclub/Be_Polite_Man1.jpg';
import BSWYEMen from 'assets/images/ccclub/BE_SURE_OF_WHAT_YOU_EATING_MEN.jpg';
import OceanMen from 'assets/images/ccclub/OCEAN_MEN.jpg';
import HalioyMen from 'assets/images/ccclub/Have_a_look_inside_of_you_men.jpg';
import SaveTheTreesWomen from 'assets/images/ccclub/Save_the_trees_women.jpg';
import ShakyWomen from 'assets/images/ccclub/Shaky_Tee_women.jpg';
import BePoliteWomen from 'assets/images/ccclub/Be_Polite_women.jpg';
import BePoliteWomen1 from 'assets/images/ccclub/Be_Polite_women1.jpg';
import BSWYEWomen from 'assets/images/ccclub/Be_Sure_of_What_You_Eating_women.jpg';
import OceanWomen from 'assets/images/ccclub/OCEAN_WOMEN.jpg';
import HalioyWomen from 'assets/images/ccclub/Have_a_look_inside_of_you_women.jpg';

const ccclubProductList = [
    {
        id: 0,
        src: SaveTheTreesMen,
        product_path: "stttop1_0",
        product_counter_part: "sttbottom1_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 1,
        src: ShakyMen,
        product_path: "shttop1_0",
        product_counter_part: "shtbottom1_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 2,
        src: BePoliteMen1,
        product_path: "bptop1_0",
        product_counter_part: "bpbottom1_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 3,
        src: BePoliteMen2,
        product_path: "bptop1_1",
        product_counter_part: "bpbottom1_1",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 4,
        src: BSWYEMen,
        product_path: "bsof1_0",
        product_counter_part: "bsofbottom1_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 5,
        src: OceanMen,
        product_path: "oceant1_0",
        product_counter_part: "oceanbottom1_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 6,
        src: SaveTheTreesWomen,
        product_path: "stttop2_0",
        product_counter_part: "sttbottom2_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 7,
        src: ShakyWomen,
        product_path: "shttop2_0",
        product_counter_part: "shtbottom2_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 8,
        src: BePoliteWomen,
        product_path: "bptop2_0",
        product_counter_part: "bpbottom2_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 9,
        src: BePoliteWomen1,
        product_path: "bptop2_1",
        product_counter_part: "bpbottom2_1",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 10,
        src: BSWYEWomen,
        product_path: "bsof2top_0",
        product_counter_part: "bsofbottom2_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 11,
        src: OceanWomen,
        product_path: "oceant2_0",
        product_counter_part: "oceanbottom2_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 12,
        src: HalioyWomen,
        product_path: "halioytop_0",
        product_counter_part: "halioybottom_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CCC Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 13,
        src: HalioyMen,
        product_path: "halioytop2_0",
        product_counter_part: "halioybottom2_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CCC Tshirts",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
];

export default ccclubProductList;