import EVE from 'assets/images/virgio/EVE.jpg';
const virgioProductList = [
    {
        id: 1,
        src: EVE,
        product_path: "eve_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Eve",
        labels: "WA_D607",
        description:
            "Breathable Cotton Tiered Dress With Pockets",
        bullet_description: [
            "Cinched Waist",
            "Sleeveless Midi Dress With Pockets",
            "100% cotton",
            "V-Neck",
        ],
        price: "$210",
        brand: "virgio",
        avatar: "v18",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL"],
        productSizeValues: [[81, 66], [86, 71], [91, 76], [97, 81], [102, 86]],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: [],
        fabric: {
            top: "100% Cotton",
        }
    },
];

export default virgioProductList;