import { Animation } from "@babylonjs/core/Animations/animation";
import { CubicEase, EasingFunction } from "@babylonjs/core/Animations/easing";
import { ArcRotateCamera, Vector3, HemisphericLight, DirectionalLight, PointLight, Color3 } from "@babylonjs/core";
import { isIpad, isTouchFriendly } from "utils";
import { isShoeProduct } from "./meshUtils";
import { LIGHT_TYPES } from "configs/RendererConfig";

export const createAnimation = ({ property, from, to }) => {
    const FRAMES_PER_SECOND = 60;
    const ease = new CubicEase();
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

    const animation = Animation.CreateAnimation(
        property,
        Animation.ANIMATIONTYPE_FLOAT,
        FRAMES_PER_SECOND,
        ease
    );
    animation.setKeys([
        {
            frame: 0,
            value: from,
        },
        {
            frame: 100,
            value: to,
        },
    ]);

    return animation;
}

export const moveActiveCamera = (scene, config, speedRatio, product, onAnimationStart = () => { }, onAnimationEnd = () => { }) => {
    const { target, ...properties } = config;
    const arcCamera = scene.activeCamera;
    const SPEED_RATIO = speedRatio || 4;
    const LOOP_MODE = false;
    const FROM_FRAME = 0;
    const TO_FRAME = 100;
    let collisionSet = [];
    if (isShoeProduct(product)) {
        scene.meshes.forEach(mesh => {
            if (mesh.checkCollisions) {
                collisionSet.push(mesh);
                mesh.checkCollisions = false;
            }
        })
    }
    onAnimationStart();
    if (arcCamera) {
        Object.keys(properties).forEach(property => {
            if (arcCamera[property]) {
                let animationObj = {
                    property,
                    from: arcCamera[property],
                    to: config[property]
                };
                if (property === 'alpha') {
                    animationObj['from'] = ((arcCamera.alpha % 6.28) + 6.28) % 6.28;
                }
                arcCamera.animations.push(createAnimation(animationObj));
            }
        });
        if (target) {
            if ("x" in target) {
                arcCamera.animations.push(createAnimation({
                    property: "target.x",
                    from: arcCamera.target.x,
                    to: target.x,
                }))
            }
            if ("y" in target) {
                arcCamera.animations.push(createAnimation({
                    property: "target.y",
                    from: arcCamera.target.y,
                    to: target.y,
                }))
            }
            if ("z" in target) {
                arcCamera.animations.push(createAnimation({
                    property: "target.z",
                    from: arcCamera.target.z,
                    to: target.z,
                }))
            }
        }
        scene.beginAnimation(arcCamera, FROM_FRAME, TO_FRAME, LOOP_MODE, SPEED_RATIO, () => {
            collisionSet.forEach(mesh => mesh.checkCollisions = true);
            onAnimationEnd();
        });
    }
};

export const createArcRotateCamera = (scene, target) => {
    let canvas = scene.getEngine().getRenderingCanvas();
    let defaultX = 0.018261507153511047, defaultY = 0.8, defaultZ = 0.01276637613773346;
    const x = target?.x || defaultX;
    const y = target?.y || defaultY;
    const z = target?.z || defaultZ;
    const camera = new ArcRotateCamera(
        "camera",
        Math.PI / 2,
        -Math.PI / 2,
        4,
        new Vector3(x, y, z),
        scene
    );
    camera.attachControl(canvas, true, false);
    camera.zoomStopsAnimation = true;
    const verticalAngleLimitBottom = 90; // [90 degrees, so that camera doesn't go below the dome]
    // const verticalAngleLimitTop = 20    // [20 degrees, so that camera doesn't go inside the body]

    // Set some basic camera settings
    camera.minZ = -10; // clip at 1 meter
    camera.maxZ = 100;
    if (!(isTouchFriendly && isIpad)) {
        camera.zoomToMouseLocation = true;
        camera.wheelDeltaPercentage = 0.05;
    }
    camera.multiTouchPanAndZoom = true;
    camera.multiTouchPanning = false;
    camera.panningSensibility = 0; // how fast do you pan, set to 0 if you don't want to allow panning

    // camera.panningAxis = new Vector3(1, 0, 1) // pan along ground
    // camera.panningOriginTarget = Vector3.Zero() // where does the panning distance limit originate from
    // camera.panningDistanceLimit = 100 // how far can you pan from the origin

    // camera.allowUpsideDown = false; // don't allow zooming inverted
    camera.lowerRadiusLimit = 0.0; // how close can you zoom
    camera.upperRadiusLimit = 2.75; // how far out can you zoom
    // camera.lowerBetaLimit = 0.9; // how high can you move the camera
    camera.upperBetaLimit = verticalAngleLimitBottom * Math.PI / 180; // how low down can you move the camera
    camera.inertia = 0.9;
    camera.wheelPrecision = 150;
    camera.pinchPrecision = 150;
    camera.angularSensibilityX = 2e3;
    camera.angularSensibilityY = 2e3;
    camera.setTarget(new Vector3(x, y, z));
    camera.setPosition(new Vector3(1.2, 0.95, 2.5));
    // camera.upperBetaLimit = (verticalAngleLimitBottom) * Math.PI / 180;
    // arcCamera.lowerBetaLimit = (verticalAngleLimitTop) * Math.PI / 180;

    camera.checkCollisions = true; // make the camera collide with meshes
    const radiusOnAllAxes = 0.2;
    camera.collisionRadius = new Vector3(radiusOnAllAxes, radiusOnAllAxes, radiusOnAllAxes); // how close can the camera go to collision mesh (bodyMesh)

    // camera.wheelPrecision = 80;
    // camera.pinchPrecision = 80;
    return camera;
};

export const createLight = (scene, type, name, position, direction, intensity, color) => {
    let light;
    switch (type) {
        case "hemispheric":
            light = new HemisphericLight(name, direction, scene);
            break;
        case "directional":
            light = new DirectionalLight(name, direction, scene);
            break;
        case "point":
            light = new PointLight(name, position, scene);
            break;
        default:
            break;
    }
    if (light) {
        light.intensity = intensity || 1.0;
        if (color) {
            light.diffuse = color;
        }
    }
    return light;
};

export const setLightProperties = (light, settings) => {
    if (light && settings) {
        light.intensity = settings.intensity;
        light.position = new Vector3(settings.position.x, settings.position.y, settings.position.z);
        if (settings.direction) {
            light.direction = new Vector3(settings.direction.x, settings.direction.y, settings.direction.z);
        }
        if (settings.diffuseColor) {
            light.diffuse = Color3.FromHexString(settings.diffuseColor);
            if (light.name === LIGHT_TYPES.AMBIENT_LIGHT) {
                light.specular = Color3.FromHexString(settings.diffuseColor);
                light.groundColor = Color3.FromHexString(settings.diffuseColor);
            }
        }
    }
};

export const disableLights = (lights) => {
    lights.forEach(light => light && light.setEnabled(false));
};

export const enableLights = (lights) => {
    lights.forEach(light => light && light.setEnabled(true));
};

export const getLightObject = (scene) => {
    let lights = {};
    Object.values(LIGHT_TYPES).forEach(light => {
      lights[light] = scene.getLightByName(light);
    });
    return lights;
}