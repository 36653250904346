import React, { useEffect, useState } from 'react';
import { useDeviceOrientation } from './useDeviceOrientation';
import OrientationSwitcher from './OrientationSwitcher';
import AllowFrame from './AllowFrame';
import AllowFrameTF from './AllowFrameTF';
import HalfCard from 'components/HalfCard';
import Button from 'components/Button';
import { isTouchFriendly } from 'utils';
import FloatingButtonBar from 'components/FloatingButtonBar';
import { SELFIE_STATUS } from 'utils/constants';
import { CameraIcon } from '@heroicons/react/solid';
import "./OrientationInfo.scss";

const MESSAGES = {
  [SELFIE_STATUS.VIDEO_PERMISSION_DENIED]: {
    title: "Please allow camera access to take your selfie",
    text: "Click the camera blocked icon in your browser's address bar to allow camera access"
  },
  [SELFIE_STATUS.ORIENTATION_PERMISSION_DENIED]: {
    title: "",
    text: 'Please allow browser to use orientation',
  },
  default: {
    title: "",
    text: 'Please allow browser to use orientation',
  }
}
const OrientationInfo = ({ onFlagChange, setShowOrientationPermission, showOrientationPermission, onClose, status, getUserMedia }) => {
  const [gyroValue, setGyroValue] = useState(180);
  const { orientation, requestAccess, revokeAccess } = useDeviceOrientation();
  const gyroscopePointerPosition = (value) => {
    const result = (value * 360) / 180;
    let position = result;

    if (result < 0) {
      position = 0;
    }

    if (result > 360) {
      position = 360;
      return position;
    }
    return position;
  }
  const getGyroValue = (orientation) => {
    const gyroValue = orientation ? gyroscopePointerPosition(orientation.beta) : null;
    setGyroValue(gyroValue);
  }
  const isGryoRange = (value) => {
    if (!value) {
      return null;
    }
    if (value < 220 && value > 145) {
      onFlagChange(true)
      return true;
    } else {
      onFlagChange(false)
      return true;
    }
  }

  const onToggle = async () => {
    try {
      if (showOrientationPermission) {
        if (isTouchFriendly) {
          const response = await requestAccess();
        } else {
          onFlagChange(true)
        }
        setShowOrientationPermission(false);
      } else {
        await revokeAccess();
      }
    } catch (e) {
      setShowOrientationPermission(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getGyroValue(orientation);
  }, [orientation, getGyroValue]);

  const message = MESSAGES[status] || MESSAGES['default'];
  console.log(showOrientationPermission);
  return (
    <>
      {!showOrientationPermission && isGryoRange(gyroValue) && <AllowFrame
        info={false}
        isLastPhoto={true}
        gyroscopePosition={gyroValue} å
      />}
      {/* <OrientationSwitcher
          onToggle={onToggle}
          labelOff="Show orientation angles"
          labelOn="Hide orientation angles"
        /> */}
      {showOrientationPermission && <div className='Dopplr_PermissionInfo'>
        {status === SELFIE_STATUS.VIDEO_PERMISSION_DENIED ?
          <CameraIcon width={150} height={150} fill="#333333" />
          : <CameraIcon width={150} height={150} fill="#333333" />}
        <div className='Dopplr_PermissionInfo__Container'>
          <h1>{message?.title}</h1>
          <div className="Dopplr_PermissionInfo__Text">
            {message?.text}
          </div>
          {status === SELFIE_STATUS.ORIENTATION_PERMISSION_DENIED &&
            <div style={{ width: 200 }}>
              <Button type="black-white" onClick={onToggle}>
                Use Orientation
              </Button>
            </div>}
        </div>
      </div>}
    </>
  );
};

export default OrientationInfo;

