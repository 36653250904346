import React, { useState, useEffect, useRef } from 'react';
import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import { ArcRotateCamera, Vector3, SceneLoader, FilesInput, DepthPeelingRenderer } from "@babylonjs/core";
import { Animation } from "@babylonjs/core/Animations/animation";
import { CubicEase, EasingFunction } from "@babylonjs/core/Animations/easing";
import { Color3, ShadowGenerator } from "@babylonjs/core";
import "@babylonjs/loaders/OBJ";
import { proxyAPI } from "api";
import * as dat from 'dat.gui';

import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import SceneComponent from "containers/babylon/SceneComponent";
import { RENDERER_CONFIG, MEASUREMENTS, CLOTHES, BODY_IDENTIFIER, CUP_IDENTIFIER, SHAPE_IDENTIFIER, POSITION_IDENTIFIER, tempPropertyChange, LIGHT_TYPES, LIGHT_SETTING_TYPES } from "../../configs/RendererConfig.js";
import { ASSETS_KEYLIST, BRANDS } from "utils/constants";
import { isTouchFriendly, isIpad, isIos } from "utils";
import { changeHairProperties } from 'utils/babylonUtils.js';
import { adidasProductList, abfrlProductList, cluster1ProductList, cluster2ProductList, SANProductList, ANANTAProductList, abfrlV2ProductList, thessProductList, pumaProductList, nicobarProductList, ccclubProductList, cavaProductList, virgioProductList, forevernewProductList, beyondExtremesProductList, testProductList, beingHumansProductList, beingHumanProductListQA, rareRabbitProductList, rareismProductList, fashorProductList, superKicksProductList, fefProductList, burgerBaeProductList, enamorProductList, missChaseList, indianTerrainList } from "configs/productList";
import { skyboxConfig } from 'configs/SkyboxConfig.js';
import NoSkybox from "assets/icons/none.svg";
import SkyBoxWhite from "assets/icons/skyboxIconWhite.png";
import SkyBoxBlack from "assets/icons/skyboxIconBlack.png";
import ShadowImage from "assets/images/Shadow1.png";
import "./Sandbox.scss";
import snapHeadToBodyNewPose from '../babylon/avatarLogic/SnappingLogic.js';
import Loader from 'components/Loader/Loader.js';
import Select from './Select';
import { Radio } from '@mui/material';
import { createLight, disableLights, enableLights, getLightObject, setLightProperties } from 'utils/cameraUtils.js';

const productList = [...adidasProductList, ...abfrlProductList, ...abfrlV2ProductList, ...cluster1ProductList, ...cluster2ProductList, ...SANProductList, ...ANANTAProductList, ...thessProductList, ...pumaProductList, ...nicobarProductList, ...ccclubProductList, ...cavaProductList, ...beyondExtremesProductList, ...virgioProductList, ...forevernewProductList, ...testProductList, ...beingHumansProductList, ...beingHumanProductListQA, ...rareRabbitProductList, ...rareismProductList, ...fashorProductList, ...superKicksProductList, ...fefProductList, ...burgerBaeProductList, ...enamorProductList, ...missChaseList, ...indianTerrainList]

const faceVertices = [2477, 3284, 3287, 3288, 3289, 3292, 3293, 3295, 3297, 3301, 11826, 11827, 11828, 11831, 11832, 11819, 11892, 11891, 11893, 11890, 11889, 11888, 12366, 11641, 11640, 11639, 11638, 11637, 11636, 11635, 11571, 11570, 11569, 11562, 11561, 11560, 3299, 2415, 2468, 2464, 2465, 2466, 2471, 2473, 2474]
const bodyVertices = [6297, 6298, 6299, 6301, 6300, 6317, 6315, 6314, 6312, 6311, 6262, 6263, 6264, 6265, 6266, 6267, 6268, 6269, 6270, 6271, 6272, 6273, 6275, 6275, 6276, 6277, 6278, 6279, 6280, 6281, 6282, 6283, 6284, 6285, 6286, 6287, 6288, 6289, 6290, 6291, 6292, 6293, 6294, 6295, 6296]
const bodyVerticesPlus1 = [6290, 6289, 6288, 6313, 6287, 6314, 6286, 6315, 6285, 6316, 6284, 6317, 6318, 6283, 6319, 6282, 6281, 6280, 6320, 6279, 6321, 6278, 6277, 6303, 6302, 6304, 6301, 6305, 6300, 6299, 6298, 6306, 6297, 6307, 6296, 6308, 6309, 6295, 6310, 6294, 6311, 6293, 6292, 6312, 6291];
const bodySizeOptions = [{ label: 'medium', value: 'medium' }, { label: 'small', value: 'small' }, { label: 'large', value: 'large' }, { label: 'Extra small', value: 'extrasmall' }];



const Sandbox = (props) => {
  const [sceneLoading, setSceneLoading] = useState(true);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [productOptions, setProductOptions] = useState([]);
  const [brandOption, setBrandOption] = useState(null);

  const [bodyOptions, setBodyOptions] = useState(null);
  const [cupOptions, setCupOptions] = useState(null);
  const [shapeOptions, setShapeOptions] = useState(null);
  const [positionOptions, setPositionOptions] = useState(null);

  const [brandInput, setBrandInput] = useState('');
  const [productInput, setProductInput] = useState('');
  const [bodyTypeInput, setBodyTypeInput] = useState('');

  const [cupTypeInput, setCupTypeInput] = useState('');
  const [positionTypeInput, setPositionTypeInput] = useState('');
  const [shapeTypeInput, setShapeTypeInput] = useState('');

  const [selectedProductOption, setSelectedProductOption] = useState(null);
  const [selectedBodyTypeOption, setSelectedBodyTypeOption] = useState(null);
  const [selectedCupTypeOption, setSelectedCupTypeOption] = useState(null);
  const [selectedPositionTypeOption, setSelectedPositionTypeOption] = useState(null);
  const [selectedShapeTypeOption, setSelectedShapeTypeOption] = useState(null);
  const [selectedBodySizeOption, setSelectedBodySizeOption] = useState(bodySizeOptions[0]);
  const [assetsObject, setAssetsObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showEnvironments, toggleEnvironment] = useState(false);
  const [showLightSettings, toggleLightSettings] = useState(props.uploadMode);
  const [skyboxList, setSkyboxList] = useState([]);
  const [selectedSkybox, setSelectedSkybox] = useState({});
  const [sceneObj, setSceneObj] = useState({});
  const [loadedGUI, setloadedGUI] = useState(null);
  const [showVariations, setShowVariations] = useState(false);
  const sceneRef = useRef();
  const [isInnerWear, setIsInnerWear] = useState(false);
  const uploadRef = useRef();
  sceneRef.current = sceneObj;
  const showVariationAction = Object.keys(selectedProductOption?.variations || {}).length > 0;
  let bodyNeckPosition = null;

  useEffect(() => {
    // setBrandOption(BRANDS[0]);
    // const brand = get(BRANDS, '0.value', '');
    // fetchProducts(brand, "");
    const bodyOptions = Object.values(BODY_IDENTIFIER).map(shape => {
      return { label: shape, value: shape }
    });
    setBodyOptions(bodyOptions);

    const cupOptions = Object.values(CUP_IDENTIFIER).map(shape => {
      return { label: shape, value: shape }
    });
    setCupOptions(cupOptions);

    const shapeOptions = Object.values(SHAPE_IDENTIFIER).map(shape => {
      return { label: shape, value: shape }
    });
    setShapeOptions(shapeOptions);

    const positionOptions = Object.values(POSITION_IDENTIFIER).map(shape => {
      return { label: shape, value: shape }
    });
    setPositionOptions(positionOptions);
  }, [])

  useEffect(() => {
    if ((!isEmpty(selectedBodyTypeOption) && !isEmpty(selectedProductOption) && !isEmpty(selectedBodySizeOption))
      || (!isEmpty(selectedCupTypeOption) && !isEmpty(selectedProductOption) && !isEmpty(selectedPositionTypeOption) && !isEmpty(selectedShapeTypeOption) && !isEmpty(selectedBodySizeOption))
    ) {
      fetchAssets();
    }
  }, [selectedBodyTypeOption, selectedProductOption, selectedBodySizeOption, selectedCupTypeOption, selectedPositionTypeOption, selectedShapeTypeOption]);

  useEffect(() => {
    if (!isEmpty(selectedSkybox) && (Object.keys(sceneObj).length === 3 || props.uploadMode) && !isEmpty(sceneObj)) {
      Object.keys(assetsObject).map(assetKey => {
        if (sceneObj[assetKey]) {
          createEnvironment(sceneObj[assetKey], selectedSkybox);
        }
      })
    }
  }, [selectedSkybox, sceneObj]);

  useEffect(() => {
    if (showVariations) {
      Object.values(sceneObj).forEach(scene => {
        enableVariations(scene);
      });
    } else {
      Object.values(sceneObj).forEach(scene => {
        disableVariations(scene);
      });
    }
  }, [showVariations]);

  useEffect(() => {
    if (!isEmpty(sceneObj)) {
      Object.values(sceneObj).forEach(scene => {
        scene.getEngine().resize();
      });
      const assets = assetsObject && Object.keys(assetsObject);
      const index = assets.find(asset => asset.includes(selectedBodySizeOption.value));
      if (showLightSettings) {
        sceneObj[index].debugLayer.show();
        // createGUI(sceneObj[index])
      } else {
        sceneObj[index].debugLayer.hide();
        const uploadedMesh = sceneObj[index].getMeshByName('uploadedAsset');
        if (uploadedMesh) {
          uploadedMesh.dispose();
        }
      }
    }
  }, [showLightSettings])


  const enableVariations = (scene) => {
    const { variations: { glowConfig = {}, detachConfig = {} } = {} } = selectedProductOption;
    if (!isEmpty(glowConfig)) {
      // const gl = new BABYLON.GlowLayer("glow", scene);
      // gl.intensity = 2.5;
      // gl.addIncludedOnlyMesh('Cloth_primitive34');
      const { materialConfig = {} } = glowConfig;
      scene.meshes.forEach(mesh => {
        const materialName = mesh?.material?.name || '';
        const key = Object.keys(materialConfig).find(materialKey => materialName.includes(materialKey));
        if (key) {
          const { emissiveColor = '' } = materialConfig[key];
          let clonedMesh = mesh.clone();
          clonedMesh.name = `glow_variations_${mesh.name}`;
          let clonedMaterial = mesh?.material?.clone();
          clonedMaterial.name = `glow_variations_${materialName}`;
          if (emissiveColor) {
            clonedMaterial.emissiveColor = BABYLON.Color3.FromHexString(emissiveColor);
          }
          clonedMesh.material = clonedMaterial;
        }
      });
      // gl.customEmissiveColorSelector = function (mesh, subMesh, material, result) {
      //   if (mesh?.material?.name && mesh?.material?.name.includes('FABRIC 1 Copy 1_FRONT_')) {
      //     console.log("hereee", mesh?.name)
      //     result.set(1, 1, 1, 1);
      //   } else {
      //     result.set(0, 0, 0, 0);
      //   }
      // };
      let ambientLight = scene.getLightByName("ambientLight");
      let pointLight1 = scene.getLightByName("pointLight1");
      let pointLight2 = scene.getLightByName("pointLight2");
      ambientLight?.setEnabled();
    }
    if (!isEmpty(detachConfig)) {
      const keyList = Object.keys(detachConfig) || [];
      keyList.forEach(key => {
        scene.meshes.forEach(mesh => {
          const meshName = mesh?.name;
          if (meshName && meshName.includes(key)) {
            const detachMaterialList = detachConfig[key] || [];
            mesh.getChildMeshes(false, subMesh => {
              const subMaterial = subMesh?.material;
              if (subMaterial) {
                const disableMaterial = detachMaterialList.findIndex(detachMaterialPrefix => subMaterial?.name?.includes(detachMaterialPrefix)) > -1;
                disableMaterial && subMesh.setEnabled(false);
              }
            });
          }
        })
      })
    }
  };

  const disableVariations = (scene) => {
    const { variations: { glowConfig = {}, detachConfig = {} } = {} } = selectedProductOption;
    if (!isEmpty(glowConfig)) {
      // const gl = scene.getGlowLayerByName('glow');
      let ambientLight = scene.getLightByName("ambientLight");
      // if (gl) {
      //   gl.dispose();
      // }
      const materialsToDispose = scene.materials.filter(material => {
        return material && material.name && material.name.includes("glow_variations_");
      });
      const meshesToRemove = scene.meshes.filter(mesh => {
        return mesh.name.includes('glow_variations_');
      });
      [...meshesToRemove, ...materialsToDispose].forEach(item => item.dispose());
      if (ambientLight) {
        ambientLight?.setEnabled(true);
      }
    }
    if (!isEmpty(detachConfig)) {
      const keyList = Object.keys(detachConfig) || [];
      keyList.forEach(key => {
        scene.meshes.forEach(mesh => {
          const meshName = mesh?.name;
          if (meshName && meshName.includes(key)) {
            const detachMaterialList = detachConfig[key] || [];
            mesh.getChildMeshes(false, subMesh => {
              const subMaterial = subMesh?.material;
              if (subMaterial) {
                const enableMaterial = detachMaterialList.findIndex(detachMaterialPrefix => subMaterial?.name?.includes(detachMaterialPrefix)) > -1;
                enableMaterial && subMesh.setEnabled(true);
              }
            });
          }
        })
      });
    }

  };

  const fetchProducts = (brand, product) => {
    const products = productList.filter(product => product.brand === brand).map((product) => {
      const { id, product_path, product_counter_part, shoes, brand } = product;
      let label = brand === "superkicks" ? `${shoes} ${product_path}` : `${id} ${product_path}`;
      return { ...product, label, value: product.product_path }
    });
    setProductOptions(products);
  }

  const fetchAssets = async () => {
    try {
      //api call to get all the assets based on selectedProductOption and brand
      setLoading(true)
      loadedGUI && loadedGUI.destroy();
      setloadedGUI(null);
      const { gender, product_path, product_counter_part, brand, avatar, shoes } = selectedProductOption;
      let bodyType = selectedBodyTypeOption?.value
      let cupType = selectedCupTypeOption?.value
      let shapeType = selectedShapeTypeOption?.value
      let positionType = selectedPositionTypeOption?.value

      if (gender === 'female' && bodyType === 'triangle') {
        bodyType = 'apple';
      }
      const clothPayload = {
        gender,
        product_path,
        product_counter_part,
        brand,
        avatar,
        shoes,
        bodyType,
        cupType,
        shapeType,
        positionType,
      };
      if (props.uploadMode) {
        clothPayload['bodySize'] = selectedBodySizeOption.value;
      }
      const clothUrlResponse = await proxyAPI.getAllAssets(clothPayload);
      setAssetsObject(clothUrlResponse.data);
      setLoading(false)
    } catch (e) {
      console.log(e);
    };
  }

  const ImportAvatarModel = async (scene, assetKey) => {
    const assetsObj = assetsObject[assetKey]
    const key = Object.keys(assetsObj || {}).find(key => assetsObj && assetsObj[key] && key === 'bodyUrl');
    //console.time("BodyDownload" + "");
    let rootUrl = '';
    if (assetKey) {
      rootUrl = assetsObj[key];
    }
    // let rootUrl = get(avatarAssets, "avatarUrl", "");
    try {
      const result = await SceneLoader.ImportMeshAsync(
        null,
        `${rootUrl}`,
        // "undiesBody.glb",
        "",
        scene,
        function (evt) {
          // onProgress
          let loadedPercent = 0;
          if (evt.lengthComputable) {
            loadedPercent = ((evt.loaded * 100) / evt.total).toFixed();
          } else {
            // TOFIX:: get the file size from config
            //get the size of file to calculate loaded percent
            let dlCount = evt.loaded / 1790368;
            loadedPercent = Math.floor(dlCount * 100.0);
          }
        });
      // Result has meshes, particleSystems, skeletons, animationGroups and transformNodes
      const onBodyDownload = function (
        meshes,
        skeletons,
        animationGroups,
        scene,
      ) {

        meshes[0].name = `avatarBody_${selectedProductOption.avatar}`;
        // meshes[1].name = `body`;
        meshes.forEach(m => {
          // m.alwaysSelectAsActiveMesh = true;
          if (m.name === "hair_primitive1" && m.material) {
            m.material.roughness = 1
          }
          m.renderingGroupId = 1;
        });
        console.log("body download completed");
        //console.timeEnd("BodyDownload");
        // meshes.forEach(mesh => console.log(mesh.name.toString()))
        const bodyMesh = meshes.find(mesh => mesh.name === 'body.001_primitive1');

        bodyMesh.name = `bodyMesh_${selectedProductOption.gender}_${selectedProductOption.avatar}`;

      };

      onBodyDownload(
        result.meshes,
        result.skeletons,
        result.animationGroups,
        scene,
      );
    } catch (error) {
      console.error("error occured in importing body", error)
    };

  };

  const ImportBodyModel = async (scene, assetKey) => {
    const assetsObj = assetsObject[assetKey]
    const key = Object.keys(assetsObj || {}).find(key => assetsObj && assetsObj[key] && key === 'bodyUrl');
    console.time("BodyDownload" + "");
    let rootUrl = '';
    if (assetKey) {
      rootUrl = assetsObj[key];
    }
    // let rootUrl = get(avatarAssets, "avatarUrl", "");
    try {
      const result = await SceneLoader.ImportMeshAsync(
        null,
        `${rootUrl}`,
        // "undiesBody.glb",
        "",
        scene,
        function (evt) {
          // onProgress
          let loadedPercent = 0;
          if (evt.lengthComputable) {
            loadedPercent = ((evt.loaded * 100) / evt.total).toFixed();
          } else {
            // TOFIX:: get the file size from config
            //get the size of file to calculate loaded percent
            let dlCount = evt.loaded / 1790368;
            loadedPercent = Math.floor(dlCount * 100.0);
          }
        });
      // Result has meshes, particleSystems, skeletons, animationGroups and transformNodes
      const onBodyDownloaded = function (
        meshes,
        skeletons,
        animationGroups,
        scene,
      ) {
        meshes[0].name = `avatarBody_${selectedProductOption.avatar}`;
        meshes[1].name = `body`;
        meshes.forEach(m => {
          m.alwaysSelectAsActiveMesh = true;

        });
        console.log("body download completed");
        console.timeEnd("BodyDownload");

        var headMaterial = null;//scene.getMaterialByName(RENDERER_CONFIG.HEAD_MATERIAL_NAME);
        if (selectedBodySizeOption.value == 'large' && selectedProductOption.brand === 'rarerabbit') {
          headMaterial = scene
            .getMaterialByName(RENDERER_CONFIG.DRACO_HEAD_MATERIAL_NAME)
        } else {
          headMaterial = scene
            .getMaterialByName(RENDERER_CONFIG.HEAD_MATERIAL_NAME)
        }
        if (headMaterial) {
          var clonedMaterial = headMaterial.clone();
          clonedMaterial.metallic = 0;
          clonedMaterial.roughness = 1;
          // clonedMaterial.forceIrradianceInFragment = true;
          clonedMaterial.environmentIntensity = 0.8;
          // clonedMaterial.reflectionTexture = hdrTexture;
          clonedMaterial.cameraExposure = 1;
          clonedMaterial.cameraContrast = 1.5;
          // clonedMaterial.disableLighting = true;
          // clonedMaterial.albedoColor = new Color3(0.95, 0.95, 0.95);
          meshes.forEach(mesh => {
            if (selectedProductOption.avatar == 'v14' || selectedProductOption.avatar == 'v18') {
              if (mesh.material != null && mesh.material.bumpTexture != null) {
                clonedMaterial.environmentIntensity = 1;
                clonedMaterial.bumpTexture = mesh.material.bumpTexture;
                clonedMaterial.bumpTexture.level = 0.68;
                clonedMaterial.roughness = 0.80;
                clonedMaterial.indexOfRefraction = 1.4500;
                // clonedMaterial.clearCoat.isEnabled = true;
                // clonedMaterial.clearCoat.intensity=0.6400;
                // clonedMaterial.clearCoat.roughness=0.4500;
                // clonedMaterial.clearCoat.indexOfRefraction=1.5000;
                // clonedMaterial.sheen.isEnabled = true;
                // clonedMaterial.sheen.intensity = 0.1600;
                // clonedMaterial.sheen.roughness= 0.1400;

                headMaterial.roughness = 0.68;
                headMaterial.indexOfRefraction = 1.4500;
                headMaterial.roughness = 0.80;
                // headMaterial.clearCoat.isEnabled = true;
                // headMaterial.clearCoat.intensity=0.1600;
                // headMaterial.clearCoat.roughness=0.5300;
                // headMaterial.clearCoat.indexOfRefraction=1.5000;
                // headMaterial.sheen.isEnabled = true;
                // headMaterial.sheen.intensity = 0.0500;
                // headMaterial.sheen.roughness= 0.6200;
              }
            }
            if (selectedProductOption.avatar == 'v15' || selectedProductOption.avatar === 'v16' || selectedProductOption.avatar == 'v19' || selectedProductOption.avatar == 'v20' || selectedProductOption.avatar == 'v21' || selectedProductOption.avatar == 'v23') {
              if (mesh.material != null && mesh.material.bumpTexture != null) {
                clonedMaterial.environmentIntensity = 1;
                clonedMaterial.bumpTexture = mesh.material.bumpTexture;
                clonedMaterial.bumpTexture.level = 1.5;
                clonedMaterial.roughness = 0.70;
                clonedMaterial.indexOfRefraction = 1.500;
                // clonedMaterial.clearCoat.isEnabled = true;
                // clonedMaterial.clearCoat.intensity=0.6400;
                // clonedMaterial.clearCoat.roughness=0.4500;
                // clonedMaterial.clearCoat.indexOfRefraction=1.5000;
                // clonedMaterial.sheen.isEnabled = true;
                // clonedMaterial.sheen.intensity = 0.1600;
                // clonedMaterial.sheen.roughness= 0.1400;

                headMaterial.roughness = 0.70;
                headMaterial.indexOfRefraction = 1.4500;
                // headMaterial.clearCoat.isEnabled = true;
                // headMaterial.clearCoat.intensity=0.1600;
                // headMaterial.clearCoat.roughness=0.5300;
                // headMaterial.clearCoat.indexOfRefraction=1.5000;
                // headMaterial.sheen.isEnabled = true;
                // headMaterial.sheen.intensity = 0.0500;
                // headMaterial.sheen.roughness= 0.6200;
              }
            }
            if (mesh.name == "nail") {
              mesh.material.albedoColor = new BABYLON.Color3(230.0 / 255.0, 174.0 / 255.0, 174.0 / 255).toLinearSpace();
            } else {
              mesh.material = clonedMaterial;
            }

            //Change rendering group to not conflict with the skybox
            mesh.renderingGroupId = 1;
          });
        }
        // meshes.forEach(mesh => console.log(mesh.name.toString()))
        const bodyMesh = meshes.find(mesh => mesh.name === 'body');
        bodyMesh.name = `bodyMesh_${selectedProductOption.gender}_${selectedProductOption.avatar}`;
        if (bodyMesh) {
          // snapHeadtoBody(bodyMesh, scene);
          bodyNeckPosition = snapHeadToBodyNewPose(bodyMesh, scene, selectedProductOption);
        }
      };

      onBodyDownloaded(
        result.meshes,
        result.skeletons,
        result.animationGroups,
        scene,
      );
    } catch (error) {
      console.error("error occured in importing body", error)
    };

  };

  const clipHair = (scene) => {
    let meshToClip = scene.getMeshByName(`hair_${selectedProductOption.avatar}`);
    const { gender } = selectedProductOption;
    let plane1 = new BABYLON.Plane(0, -1, 1, 1.54)
    let plane2 = new BABYLON.Plane(0, -1, -1, 1.5)
    if (gender === 'female') {
      plane1 = new BABYLON.Plane(0, -1, 1, 1.49)
      plane2 = new BABYLON.Plane(0, -1, -0.9, 1.34)
    }
    if (meshToClip) {
      // meshToClip.material.backFaceCulling = false
      let meshToClipClone = meshToClip.clone()

      meshToClip.onBeforeRenderObservable.add(function () {
        scene.clipPlane3 = plane1
      })
      meshToClip.onAfterRenderObservable.add(function () {
        scene.clipPlane3 = null
      })
      meshToClipClone.onBeforeRenderObservable.add(function () {
        scene.clipPlane4 = plane2
      })
      meshToClipClone.onAfterRenderObservable.add(function () {
        scene.clipPlane4 = null
      })
    }
  }

  const onHeadDownloaded = function (
    meshes,
    skeletons,
    animationGroups,
    scene,
    arcCamera,
    hdrTexture
  ) {
    console.log("head download completed");
    let headMaterialClone = null;
    if (selectedBodySizeOption.value == 'large' && selectedProductOption.brand === 'rarerabbit') {
      headMaterialClone = scene
        .getMaterialByName(RENDERER_CONFIG.DRACO_HEAD_MATERIAL_NAME)
        .clone();
    } else {
      headMaterialClone = scene
        .getMaterialByName(RENDERER_CONFIG.HEAD_MATERIAL_NAME)
        .clone();
    }
    headMaterialClone.name = "headMaterialClone";
    headMaterialClone.metallic = 0;
    headMaterialClone.roughness = 1;
    headMaterialClone.environmentIntensity = 0.8;
    // meshes[1].material = headMaterialClone;
    meshes.forEach(mesh => {
      mesh.renderingGroupId = 1;
      // if(mesh.material!=null)
      // mesh.material.wireframe = true;
    });
    // clipHair(scene);
  };

  const ImportHeadModel = async (scene, assetKey) => {
    try {
      let avatarFaceUrl = selectedProductOption && selectedProductOption.gender === 'male' ? `/${RENDERER_CONFIG.ASSETVIEWER_HEAD_MODEL_FILE_NAME}` : `/${RENDERER_CONFIG.ASSETVIEWER_HEAD_MODEL_FEMALE}`;
      if (selectedBodySizeOption.value == 'large' && selectedProductOption.brand === 'rarerabbit') {
        avatarFaceUrl = `/${RENDERER_CONFIG.ASSETVIEWER_HEAD_MODEL_MALE_LARGE}`
      }
      const result = await SceneLoader.ImportMeshAsync(
        "",
        avatarFaceUrl,
        "",
        scene,
        function (evt) {
          // onProgress
          let loadedPercent = 0;
          if (evt.lengthComputable) {
            loadedPercent = ((evt.loaded * 100) / evt.total).toFixed();
          } else {
            // TOFIX:: get the file size from config
            //get the size of file to calculate loaded percent
            let dlCount = evt.loaded / 789312;
            loadedPercent = Math.floor(dlCount * 100.0);
          }
        }
      );
      result.meshes[0].name = `head_${selectedProductOption.gender}_${selectedProductOption.avatar}`;
      const faceMesh = scene.getMeshByName("mesh");
      const hairMesh = scene.getMeshByName("haircut_generated");
      if (faceMesh) {
        faceMesh.name = `face_${selectedProductOption.gender}_${selectedProductOption.avatar}`;
      }
      if (hairMesh) {
        hairMesh.name = `hair_${selectedProductOption.gender}_${selectedProductOption.avatar}`;
        changeHairProperties(scene, hairMesh, "#281806", selectedProductOption.gender);
      }

      onHeadDownloaded(
        result.meshes,
        result.skeletons,
        result.animationGroups,
        scene
      )
      await ImportBodyModel(scene, assetKey);
    } catch (error) {
      console.error("error occured in head import", error)
    }
  };

  const ImportClothModel = async (scene, assetKey) => {
    const assetsObj = assetsObject[assetKey]
    const assetsKeys = Object.keys(assetsObj || {}).filter(key => assetsObj && assetsObj[key] && key !== 'bodyUrl');
    if (assetsKeys && assetsKeys.length > 0) {
      try {
        const assetsList = assetsKeys.map(key => assetsObj[key]);
        // const promiseList = assetsList.map(assetUrl => {
        //   return SceneLoader.ImportMeshAsync("", "", assetUrl, scene);
        // });
        let assetResults = [];
        for (let assetUrl of assetsList) {
          try {
            const result = await SceneLoader.ImportMeshAsync("", "", assetUrl, scene);
            assetResults.push(result);
          } catch (e) {
            console.log("Error in importing", e);
          }
        }
        // assetResults = await Promise.all(promiseList);
        assetResults.forEach((result, index) => {
          const meshes = result.meshes;
          const assetKey = assetsKeys[index];
          const meshName = assetKey === 'shoeUrl' ? `shoes_${selectedProductOption.shoes ? `${selectedProductOption.shoes}_` : ''}${selectedProductOption.avatar}` : `clothAsset_${selectedProductOption[ASSETS_KEYLIST[assetsKeys[index]]]}_${selectedProductOption.avatar}`;
          meshes[0].name = meshName;
          if (selectedProductOption.brand != 'puma' && meshName.includes("shoes")) {
            const leftLogoMesh = scene.getMeshByName("Retopo_puma .018");
            if (leftLogoMesh != null) {
              leftLogoMesh.setEnabled(false);
            }
            const rightLogoMesh = scene.getMeshByName("Retopo_puma .017")
            if (rightLogoMesh != null) {
              rightLogoMesh.setEnabled(false);
            }
          }

          const { sharpMaterialsList } = selectedProductOption;
          const tempProperty = tempPropertyChange[selectedProductOption.brand]?.[selectedProductOption[ASSETS_KEYLIST[assetsKeys[index]]]];
          meshes.forEach(mesh => {
            if (mesh.material) {
              if (sharpMaterialsList && sharpMaterialsList.includes(mesh.material.name)) {
                // These materials are included in sharpMaterialsList, so no change
              }
              else {
                mesh.material.transparencyMode = null; // Not Defined -> null, Opaque -> 0, Alpha Test -> 1, Alpha Blend -> 2
                mesh.material.hasAlpha = true;
                mesh.material.useAlphaFromAlbedoTexture = true;
              }
              // Enhancing bumptextures for clothes
              if (mesh.material.bumpTexture != null && !(selectedProductOption.brand === "superkicks" && selectedProductOption.shoes === '20')) {
                mesh.material.bumpTexture.level = 1.5;
              }
              if (tempProperty) {
                mesh.material.roughness = 0.89;
                mesh.material.sheen.isEnabled = true;
                mesh.material.sheen.intensity = 0.0300;
              }
            }

            mesh.renderingGroupId = 1;
          });
          // if (assetKey === 'clothUrl' || assetKey === 'bottomUrl' ) {
          // scene.materials.forEach((material) => {
          //   if (material != null && material.name !== "70022460 Plain Weave SUB_FRONT_2830" && material.bumpTexture != null)
          //     material.bumpTexture.level = 1.5;
          // });
        });
      } catch (error) {
        console.error("error occured in cloth import", error);
      }
    }
    if (selectedProductOption.brand === "enamor") {
      try {
        await ImportAvatarModel(scene, assetKey);
      } catch (error) {
        console.log("error occured avatar model", error)
      }
    } else {
      await ImportHeadModel(scene, assetKey);
    }
  }

  const createArcRotateCamera = scene => {
    let canvas = scene.getEngine().getRenderingCanvas();
    const camera = new ArcRotateCamera(
      "camera",
      Math.PI / 2,
      -Math.PI / 2,
      3,
      new Vector3(0, 0, 0),
      scene
    );
    camera.attachControl(canvas, true);

    // Set some basic camera settings
    camera.minZ = -10; // clip at 1 meter
    camera.maxZ = 100;
    if (!(isTouchFriendly && isIpad)) {
      camera.zoomToMouseLocation = true;
      camera.wheelDeltaPercentage = 0.05;
    }
    camera.panningSensibility = 0; // how fast do you pan, set to 0 if you don't want to allow panning

    // camera.panningAxis = new Vector3(1, 0, 1) // pan along ground
    // camera.panningOriginTarget = Vector3.Zero() // where does the panning distance limit originate from
    // camera.panningDistanceLimit = 100 // how far can you pan from the origin

    camera.allowUpsideDown = false; // don't allow zooming inverted
    camera.lowerRadiusLimit = 0.3; // how close can you zoom
    camera.upperRadiusLimit = 3.0; // how far out can you zoom
    // camera.lowerBetaLimit = 0.9; // how high can you move the camera
    // camera.upperBetaLimit = Math.PI / 2.5; // how low down can you move the camera

    camera.wheelPrecision = 80;
    camera.pinchPrecision = 80;

    return camera;
  };
  const configureDomeMeshes = (meshes, domeSettings) => {
    const {
      domeMaterialAlbedoColor,
      floorMaterialAlbedoColor,
      columnMaterialAlbedoColor,
      columnMaterialEmissiveColor,
    } = domeSettings;
    const changeableItemMaterialName = selectedProductOption.changeableItemMaterialName;
    const changeableItemAlbedoColor = selectedProductOption.changeableItemAlbedoColor;
    const changeableItemEmissiveColor = selectedProductOption.changeableItemEmissiveColor;
    meshes.forEach((mesh) => {
      mesh.isPickable = false;
      if (mesh.name.includes("shrub")) {
        mesh.renderingGroupId = 1;
      }
      if (selectedProductOption.brand != 'cava' && (mesh.name.includes("CAVA Logo (Small)") || mesh.name.includes("CCAVA Logo (Small).001"))) {
        mesh.dispose();
      }
      if (mesh.name == "G-__555564_primitive0") {
        mesh.dispose();
      }
      if (mesh.name === "pSphere2") {
        mesh.material.unlit = true;
      }
      if (mesh.name == "G-__555564.001" || mesh.name == "4 pain door#1.001_primitive1" || mesh.name == "4 pain window#3.004_primitive0") {
        mesh.material.albedoColor = new BABYLON.Color3(1, 1, 1).toLinearSpace();
      }
      if (mesh.name.includes("dome") || mesh.name.includes("Sphere")) {
        mesh.material.albedoColor = BABYLON.Color3.FromHexString(domeMaterialAlbedoColor);
      } else if (mesh.name.includes("floor") || mesh.name.includes("Floor")) {
        mesh.material.albedoColor = BABYLON.Color3.FromHexString(floorMaterialAlbedoColor);
      } else if (mesh.name.includes("column hall.002")) {
        mesh.material.albedoColor = BABYLON.Color3.FromHexString(columnMaterialAlbedoColor);
        mesh.material.emissiveColor = BABYLON.Color3.FromHexString(columnMaterialEmissiveColor);
      } else if (mesh.name.includes("Sketchup.008")) {
        mesh.visibility = 0.35;
      }
      // Additional mesh-specific configurations
      if (mesh.material && mesh.material.name.includes("Orangery_Glass")) {
        mesh.material.alpha = 0.36;
        mesh.material.transparencyMode = null;
        mesh.material.alphaMode = BABYLON.Engine.ALPHA_ADD;
      }
      if (changeableItemMaterialName && changeableItemAlbedoColor && changeableItemEmissiveColor && mesh.material && mesh.material.name === changeableItemMaterialName) {
        mesh.material.albedoColor = BABYLON.Color3.FromHexString(changeableItemAlbedoColor);
        mesh.material.emissiveColor = BABYLON.Color3.FromHexString(changeableItemEmissiveColor);
      }
    });
  };

  const createLightSetup = (scene) => {
    const { AMBIENT_LIGHT, KEY_LIGHT, FILL_LIGHT, BACK_LIGHT, POINT_LIGHT_1, POINT_LIGHT_2, POINT_LIGHT_3, POINT_LIGHT_4 } = LIGHT_TYPES;
    const lights = {
      [AMBIENT_LIGHT]: createLight(scene, "hemispheric", AMBIENT_LIGHT, null, new BABYLON.Vector3(0, 1, 0), 1.0),
      [KEY_LIGHT]: createLight(scene, "directional", KEY_LIGHT, null, new BABYLON.Vector3(-1, 0, -1), 1.5),
      [FILL_LIGHT]: createLight(scene, "directional", FILL_LIGHT, null, new BABYLON.Vector3(1, 0, -1), 1.0),
      [BACK_LIGHT]: createLight(scene, "directional", BACK_LIGHT, null, new BABYLON.Vector3(0, 0, 1), 1.5),
      pointLights: [
        createLight(scene, "point", POINT_LIGHT_1, new BABYLON.Vector3(-0.5, 2.0, 1.5), null, 12.0, new BABYLON.Color3(255 / 255, 250 / 255, 218 / 255)),
        createLight(scene, "point", POINT_LIGHT_2, new BABYLON.Vector3(0.5, 1.5, 0.75), null, 3.0, new BABYLON.Color3(255 / 255, 250 / 255, 218 / 255)),
        createLight(scene, "point", POINT_LIGHT_3, new BABYLON.Vector3(0, 1.5, -1), null, 8.0, new BABYLON.Color3(255 / 255, 250 / 255, 218 / 255)),
        createLight(scene, "point", POINT_LIGHT_4, new BABYLON.Vector3(-0.5, 0.5, 1.5), null, 5.0, new BABYLON.Color3(255 / 255, 250 / 255, 218 / 255)),
      ]
    };

    // Disable the ambient light initially
    lights.ambientLight.setEnabled(false);

    return lights; // Return lights for further use
  };

  const createGroundForShadow = (scene) => {
    if (scene.getMeshByName("ground")) { return }
    try {
      const ground = BABYLON.MeshBuilder.CreateGround("ground", { height: 1, width: 1, subdivisions: 4 });
      ground.position = new BABYLON.Vector3(0, 0, 0)
      ground.scaling.x = 0.55;
      ground.scaling.z = 0.55;

      const groundMat = new BABYLON.StandardMaterial("groundMat", scene);
      groundMat.diffuseTexture = new BABYLON.Texture(ShadowImage, scene);
      groundMat.roughness = 1.0
      groundMat.diffuseTexture.hasAlpha = true;
      groundMat.useAlphaFromDiffuseTexture = true;
      groundMat.backFaceCulling = true;
      groundMat.alpha = 0.8;
      groundMat.specularColor = new BABYLON.Color3(0, 0, 0);
      ground.material = groundMat;
    } catch (e) {
      console.log(e);
    }
  }

  const generateShadows = (scene, shadowType, lightTypeUsedForShadow) => {
    // if shadowType is static, we use a simple shadow image below the avatar
    // if shadowType is dynamic, we create the shadows in realtime on dome floor

    if (shadowType === "static" || lightTypeUsedForShadow === null) {
      if (showLightSettings) { createGroundForShadow(scene) }
      return
    }
    const ground = scene.getMeshByName("ground")
    if (ground) { ground.dispose() }

    // This is where we generate shadow and make the dome receive it
    let shadowList = [];
    scene.meshes.forEach((mesh) => {
      if (mesh.name === "pSphere2" || mesh.name === "BG_02_Geo" || mesh.name === "floor" || mesh.name === "Floor" || mesh.name === "dome" || mesh.name.includes("Sketchup.008") || mesh.name.includes('Sphere')) {
        mesh.receiveShadows = true;
      }
      else if (mesh._geometry === null || mesh.name === "hdrSkyBox" || mesh.id === "__root__") {
      }
      else {
        mesh.receiveShadows = false;
        shadowList.push(mesh);
      }
    })
    // Shadow
    if (lightTypeUsedForShadow === "directional") {
      let shadowLight = scene.getLightByName("directionalKeyLight");
      shadowLight.shadowMaxZ = 6.0;
      shadowLight.shadowMinZ = 0;
      shadowLight.renderingGroupId = 1;
      let shadowGenerator = new BABYLON.ShadowGenerator(1024, shadowLight);
      shadowGenerator.getShadowMap().renderList.push(...shadowList);
      shadowGenerator.setDarkness(0.5);
      shadowGenerator.enableSoftTransparentShadow = true;
      shadowGenerator.transparencyShadow = true;
      shadowGenerator.useContactHardeningShadow = true;
      shadowGenerator.bias = 0.000001;
    }
    else {
      let shadowLight = scene.getLightByName("pointLight2");
      let shadowGenerator = new BABYLON.ShadowGenerator(1024, shadowLight);
      shadowGenerator.getShadowMap().renderList.push(...shadowList);
      shadowGenerator.enableSoftTransparentShadow = true;
      shadowGenerator.transparencyShadow = true;
      shadowGenerator.useContactHardeningShadow = true;
    }
  }

  const setCamera = scene => {
    const arcCamera = createArcRotateCamera(scene);

    // Create default pipeline
    // var defaultPipeline = new BABYLON.DefaultRenderingPipeline("default", true, scene, [arcCamera]);
    // var curve = new BABYLON.ColorCurves();
    // // curve.globalHue = 200;
    // // curve.globalDensity = 80;
    // curve.globalSaturation = -10;
    // // curve.highlightsHue = 20;
    // // curve.highlightsDensity = 80;
    // // curve.highlightsSaturation = -80;
    // // curve.shadowsHue = 2;
    // // curve.shadowsDensity = 80;
    // // curve.shadowsSaturation = 40;
    // defaultPipeline.imageProcessing.colorCurvesEnabled = true;
    // defaultPipeline.imageProcessing.colorCurves = curve;
    // // defaultPipeline.depthOfField.focalLength = 150;

    var hdrTexture;
    createLightSetup(scene);
    var engine = scene.getEngine();
    var width = engine.getRenderWidth();
    var height = engine.getRenderHeight();
    var ratio = width / height;
    var orthoMeasure = 12;

    //reset orthographic ratio when canvas is resized
    engine.onResizeObservable.add(function () {
      if (
        engine.getRenderWidth() !== width ||
        engine.getRenderHeight() !== height
      ) {
        width = engine.getRenderWidth();
        height = engine.getRenderHeight();
        ratio = width / height;
        arcCamera.orthoLeft = ratio * orthoMeasure * -1;
        arcCamera.orthoRight = ratio * orthoMeasure;
      }
    });
    return {
      arcCamera: arcCamera,
      // shadowGenerator: shadowGenerator,
      hdrTexture: hdrTexture
    };
  };

  const resetCamera = (scene) => {
    // const { gender } = selectedProductOption;
    const x = 0.0, y = 0.8, z = 0.0;
    moveActiveCamera(scene, {
      alpha: Math.PI / 2,
      beta: Math.PI / 2.5,
      radius: 2.75,
      target: { x, y, z }
    });
  }

  const createAnimation = ({ property, from, to }) => {
    const FRAMES_PER_SECOND = 60;
    const ease = new CubicEase();
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

    const animation = Animation.CreateAnimation(
      property,
      Animation.ANIMATIONTYPE_FLOAT,
      FRAMES_PER_SECOND,
      ease
    );
    animation.setKeys([
      {
        frame: 0,
        value: from,
      },
      {
        frame: 100,
        value: to,
      },
    ]);

    return animation;
  }

  const moveActiveCamera = (scene, { radius, alpha, beta, target }) => {
    const arcCamera = scene.activeCamera;
    const SPEED_RATIO = 4;
    const LOOP_MODE = false;
    const FROM_FRAME = 0;
    const TO_FRAME = 100;
    arcCamera.animations = [
      createAnimation({
        property: "radius",
        from: arcCamera.radius,
        to: radius,
      }),
      createAnimation({
        property: "beta",
        from: arcCamera.beta,
        to: beta,
      }),
      createAnimation({
        property: "alpha",
        from: ((arcCamera.alpha % 6.28) + 6.28) % 6.28,
        to: alpha,
      }),
      createAnimation({
        property: "target.x",
        from: arcCamera.target.x,
        to: target.x,
      }),
      createAnimation({
        property: "target.y",
        from: arcCamera.target.y,
        to: target.y,
      }),
      createAnimation({
        property: "target.z",
        from: arcCamera.target.z,
        to: target.z,
      }),
    ];
    scene.beginAnimation(arcCamera, FROM_FRAME, TO_FRAME, LOOP_MODE, SPEED_RATIO);
  }

  const getSkyboxConfig = (brand) => {
    if (brand && skyboxConfig[brand]) {
      return skyboxConfig[brand];
    }
    return skyboxConfig['default']
  }

  const configureSkybox = (scene, settings, acesEnabled) => {
    if (settings.pathFor6Images) {
      const texture = new BABYLON.CubeTexture(settings.pathFor6Images, scene);
      scene.environmentTexture = texture;
      scene.environmentTexture.rotationY = settings.rotation;
      scene.createDefaultSkybox(texture, true, 100);

      scene.environmentIntensity = settings.intensity;
      scene.imageProcessingConfiguration.exposure = settings.exposure || settings.intensity;
      scene.imageProcessingConfiguration.contrast = settings.contrast || settings.intensity;
    }
    scene.imageProcessingConfiguration.toneMappingEnabled = true;
    scene.imageProcessingConfiguration.toneMappingType = acesEnabled
      ? BABYLON.ImageProcessingConfiguration.TONEMAPPING_ACES
      : BABYLON.ImageProcessingConfiguration.TONEMAPPING_STANDARD;
  };

  const configureLights = (scene, lightSettings) => {
    const {
      ambientLight, directionalKeyLight, directionalBackLight, directionalFillLight,
      pointLight1, pointLight2, pointLight3, pointLight4
    } = getLightObject(scene);
    // Disable all lights initially
    disableLights([ambientLight, directionalKeyLight, directionalBackLight, directionalFillLight, pointLight1, pointLight2, pointLight3, pointLight4]);

    switch (lightSettings.type) {
      case LIGHT_SETTING_TYPES.DIRECTIONAL:
        enableLights([directionalKeyLight, directionalBackLight, directionalFillLight]);
        setLightProperties(directionalKeyLight, lightSettings.directionalLightSettings.keyLightSettings);
        setLightProperties(directionalFillLight, lightSettings.directionalLightSettings.fillLightSettings);
        setLightProperties(directionalBackLight, lightSettings.directionalLightSettings.backLightSettings);
        break;

      case LIGHT_SETTING_TYPES.POINT:
        enableLights([pointLight1, pointLight2, pointLight3, pointLight4]);
        scene.environmentTexture = null;
        setLightProperties(pointLight1, lightSettings.pointLightSettings.pointLight1Settings);
        setLightProperties(pointLight2, lightSettings.pointLightSettings.pointLight2Settings);
        setLightProperties(pointLight3, lightSettings.pointLightSettings.pointLight3Settings);
        setLightProperties(pointLight4, lightSettings.pointLightSettings.pointLight4Settings);
        break;

      case LIGHT_SETTING_TYPES.POINT_AMBIENT:
        enableLights([ambientLight, pointLight1, pointLight2, pointLight3]);
        scene.environmentTexture = null;
        setLightProperties(pointLight1, lightSettings.pointLightSettings.pointLight1Settings);
        setLightProperties(pointLight2, lightSettings.pointLightSettings.pointLight2Settings);
        setLightProperties(pointLight3, lightSettings.pointLightSettings.pointLight3Settings);
        setLightProperties(ambientLight, lightSettings.pointLightSettings.pointLight4Settings);
        break;

      case LIGHT_SETTING_TYPES.ENV_LIGHT:
        const { ambientLightSettings, envLightSettings } = lightSettings || {};
        const { exposure, contrast, path, intensity, rotationY } = envLightSettings;
        if (ambientLightSettings) {
          enableLights([ambientLight]);
          setLightProperties(ambientLight, ambientLightSettings);
        };
        scene.environmentTexture = new BABYLON.CubeTexture(path, scene);
        scene.environmentIntensity = intensity;
        scene.environmentTexture.rotationY = rotationY;
        if (exposure) scene.imageProcessingConfiguration.exposure = exposure;
        if (contrast) scene.imageProcessingConfiguration.contrast = contrast;
        break;

      default:
        console.log("Unknown light type:", lightSettings.type);
    }
  }

  const disposeSkybox = (scene) => {
    const hdrSkyBox = scene.getMeshByName("hdrSkyBox");
    if (hdrSkyBox) {
      hdrSkyBox.dispose();
      scene.environmentTexture = null;
    }
  };

  const disposeMeshesByName = (scene, nameFragment) => {
    scene.meshes.forEach((mesh) => {
      if (mesh.name.includes(nameFragment)) mesh.dispose();
    });
  };

  const importDome = async (scene, domeSettings, id) => {
    try {
      const result = await SceneLoader.ImportMeshAsync("", domeSettings.domePath, "", scene);
      configureDomeMeshes(result.meshes, domeSettings);

      result.meshes[0].name = `domeParent_${id}`;
      result.meshes[0].scaling = new BABYLON.Vector3(-1.2, 1.2, 1.2);
      result.meshes[0].rotation = new BABYLON.Vector3(0.0, 0.0, 0.0);
    } catch (error) {
      console.error("Error importing dome:", error);
    }
  };

  const createEnvironment = async (scene, selectedSkybox, showLoader) => {
    try {
      if (isEmpty(selectedSkybox)) return;

      const { product_path, shoes } = selectedProductOption;
      const selectedSkyboxConfig = get(selectedSkybox, 'productList', []).find(
        (config) => {
          if (config.primary === "shoes" && Array.isArray(config?.productIds)) {
            return config.productIds.indexOf(shoes) > -1;
          }
          return config.product_path === product_path
        }) || selectedSkybox.default || selectedSkybox;

      const { skyboxSettings, domeSettings, lightSettings } = selectedSkyboxConfig;
      const { acesEnabled, id } = selectedSkybox;

      // Early return if dome already exists
      if (scene.getMeshByName(`domeParent_${id}`)) return;

      // Show loader if required
      // if (showLoader) setSkyBoxLoader(true);

      // Dispose of existing skybox
      disposeSkybox(scene, "hdrSkyBox");

      // Configure skybox if pathFor6Images exists
      configureSkybox(scene, skyboxSettings, acesEnabled);

      // Configure lighting
      configureLights(scene, lightSettings);

      // Remove existing domes
      disposeMeshesByName(scene, "domeParent");

      // Import and configure dome
      if (domeSettings.domePath) {
        await importDome(scene, domeSettings, id);
      }

      // Handle shadow generation
      // const shadowType = domeSettings.domePath || lightSettings?.type !== "env" ? "dynamic" : "static";
      const shadowType = "dynamic"
      const lightTypeUsedForShadow = lightSettings.type;
      if (selectedProductOption.brand !== 'virgio') {
        generateShadows(scene, shadowType, lightTypeUsedForShadow);
      }

      // Hide loader
      // if (showLoader) {
      //   setTimeout(() => setSkyBoxLoader(false), 300);
      // }
    } catch (error) {
      console.error("Error in createEnvironment:", error);
    }
  };


  const createScene = (scene, assetKey) => {
    scene.getEngine().displayLoadingUI();
    const camera = setCamera(scene, assetKey);
    resetCamera(scene);
    setUploadedFileName("");
    setShowVariations(false);
    ImportClothModel(scene, assetKey);
    scene.executeWhenReady(se => {
      //Used to stop the loader
      let options = new BABYLON.SceneOptimizerOptions(30, 2000);
      // options.addOptimization(new BABYLON.MergeMeshesOptimization(0));
      options.addOptimization(new BABYLON.ParticlesOptimization(0));
      options.addOptimization(new BABYLON.ShadowsOptimization(0));
      options.addOptimization(new BABYLON.LensFlaresOptimization(0));
      options.addOptimization(new BABYLON.PostProcessesOptimization(0));
      options.addOptimization(new BABYLON.TextureOptimization(0, 256));
      options.addOptimization(new BABYLON.RenderTargetsOptimization(0));
      options.addOptimization(new BABYLON.HardwareScalingOptimization(2, 1.5));
      // options.addOptimization(new BABYLON.HardwareScalingOptimization(1, 0.9));
      const optimizer = BABYLON.SceneOptimizer.OptimizeAsync(scene, options,
        function () {
          // On success
          console.log("success", optimizer.currentFrameRate);
        }, function () {
          console.log("fail", optimizer.currentFrameRate);
          // FPS target not reached
        });
      const brand = get(brandOption, 'value', '');
      const sceneObject = { ...sceneRef.current, [assetKey]: scene };
      setSceneObj(sceneObject);
      scene.clearColor = new BABYLON.Color3(1, 1, 1);
      const skyboxList = getSkyboxConfig(brand);
      setSkyboxList(skyboxList);
      const assets = sceneObject && Object.keys(sceneObject);
      scene.getEngine().hideLoadingUI()
      if (showLightSettings) {
        sceneObject[assets[0]].debugLayer.show();
      }
      // if (isIos()) {
      //   const fxaa = new BABYLON.FxaaPostProcess("fxaa", 1, scene.activeCamera);
      // }
      createEnvironment(scene, !isEmpty(selectedSkybox) ? selectedSkybox : skyboxList[0]);
      if (brand === "enamor") {
        console.log("depth peeling renderer");
        // const depthPeelingRenderer = new BABYLON.DepthPeelingRenderer(scene);
        // scene.enablePrePassRenderer(); // Required for depth peeling
        // scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("depthPeelingRenderer", camera.arcCamera);
        // Enable order-independent transparency
        // scene.useOrderIndependentTransparency = true;
        const hairMesh = scene.getMeshByName("Shoulder_Hair_OBJ");
        console.log("hairMaterial", hairMesh);
        if (hairMesh) {
          // hairMesh.material.alphaMode = BABYLON.Constants.ALPHA_PREMULTIPLIED_PORTERDUFF;
          // hairMesh.material.transparencyMode = BABYLON.Material.MATERIAL_ALPHATEST; // For smooth blending
          // hairMesh.material.zOffset = -0.1; // Adjust to prevent depth issues
        }
      }
    }, true);
    scene.onPointerObservable.add((pointerInfo) => {
      // eslint-disable-next-line default-case
      switch (pointerInfo.type) {
        case BABYLON.PointerEventTypes.POINTERTAP:
          resetCamera(scene);
          break;
      }
    })
  }

  const onBrandsChange = (e, value) => {
    setBrandOption(value);
    if (value?.value !== brandOption?.value) {
      setSelectedProductOption(null);
      const brand = get(value, 'value', '');
      fetchProducts(brand);
      setSceneObj({});
      setSelectedSkybox({});
      setSkyboxList([]);
      toggleEnvironment(false);
      if (value?.value == 'enamor') {
        setIsInnerWear(true);
      } else {
        setIsInnerWear(false);
      }
    }
  }
  const onProductsChange = (e, value) => {
    setSelectedProductOption(value);
    const brand = get(brandOption, 'value', '');
    fetchProducts(brand, value);
    setSceneObj({});
  }

  const onBodyTypeChange = (e, value) => {
    setSelectedBodyTypeOption(value);
    setSceneObj({});
  }

  const onCupTypeChange = (e, value) => {
    setSelectedCupTypeOption(value);
    setSceneObj({});
  }

  const onPositionTypeChange = (e, value) => {
    setSelectedPositionTypeOption(value);
    setSceneObj({});
  }
  const onShapeTypeChange = (e, value) => {
    setSelectedShapeTypeOption(value);
    setSceneObj({});
  }

  const onBodySizeChange = (e, value) => {
    setSelectedBodySizeOption(value);
    setSceneObj({});
  }


  const createGUI = async (scene) => {
    if (loadedGUI) {
      loadedGUI.destroy();
      setloadedGUI(null);
    }
    const gui = new dat.GUI({ name: "datGUI" });
    gui.domElement.id = "datGUI";
    gui.domElement.style.marginTop = "0px";
    gui.domElement.style.zIndex = 1;
    gui.domElement.style.position = "absolute";
    gui.domElement.style.right = 0;
    var options = {
      Animate_Key_DirectionalLight: false,
      Speed_KeyLight_Animation: 1,
      Animate_PointLight1: false,
      Speed_PointLight1_Animation: 1,
      Bump_Texture: 2,
      Saturation: 0.0
    }

    let dl1Animation = null;
    let pl1Animation = null;
    const animateDirectionalLight = () => {
      const keyLight = scene.getLightByName("directionalKeyLight");
      const position = keyLight.position;

      //Create an animation at 30 FPS
      let animationKeyLight = new BABYLON.Animation("animationKeyLight", "position", 30,
        BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
        BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
      let animationKeyLightDirection = new BABYLON.Animation("animationKeyLightRotation", "direction", 30,
        BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
        BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
      // Animation keys
      let keysP = [];
      let keysD = [];
      const numberOfKeys = 100;
      for (let i = 0; i <= numberOfKeys; i++) {
        const angle = (i * 360 / numberOfKeys) * Math.PI / 180;
        const x = Math.cos(angle);
        const z = Math.sin(angle);
        const newPosition = new BABYLON.Vector3(x, position.y, z);
        const newDirection = new BABYLON.Vector3(-x * 2, -position.y, -z * 2);

        keysP.push({
          frame: i,
          value: newPosition
        });
        keysD.push({
          frame: i,
          value: newDirection
        });
      }

      animationKeyLight.setKeys(keysP);
      animationKeyLightDirection.setKeys(keysD);
      keyLight.animations.push(animationKeyLight);
      keyLight.animations.push(animationKeyLightDirection);
      dl1Animation = scene.beginAnimation(keyLight, 0, 100, true, options.Speed_KeyLight_Animation);
    }
    const animatePointLight = () => {
      const pointLight1 = scene.getLightByName("pointLight1");
      if (pointLight1 === null) { return; }
      const position = pointLight1.position;

      //Create an animation at 30 FPS
      let animationPointLight1 = new BABYLON.Animation("animationPointLight1", "position", 30,
        BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
        BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
      // Animation keys
      let keys = [];
      const numberOfKeys = 100;
      for (let i = 0; i <= numberOfKeys; i++) {
        const angle = (i * 360 / numberOfKeys) * Math.PI / 180;
        const x = Math.cos(angle);
        const z = Math.sin(angle);
        const newPosition = new BABYLON.Vector3(x, position.y, z);

        keys.push({
          frame: i,
          value: newPosition
        });
      }

      animationPointLight1.setKeys(keys);
      pointLight1.animations.push(animationPointLight1);
      pl1Animation = scene.beginAnimation(pointLight1, 0, 100, true, options.Speed_PointLight1_Animation);
    }

    // const keyLightGUI = gui.addFolder("Key_DirectionalLight");
    // keyLightGUI.add(options, "Animate_Key_DirectionalLight").name("Animate").onChange(function (value) {
    //   if (value) {
    //     animateDirectionalLight();
    //   }
    //   else {
    //     if (dl1Animation) {
    //       dl1Animation.stop();
    //       dl1Animation = null;
    //     }
    //   }
    // });
    // keyLightGUI.add(options, "Speed_KeyLight_Animation").name("Speed").onChange(function (value) {
    //   if (dl1Animation) {
    //     dl1Animation.speedRatio = value;
    //   }
    //   else {
    //     return;
    //   }
    // });
    // keyLightGUI.open();
    // const pointLight1GUI = gui.addFolder("PointLight_1");
    // pointLight1GUI.add(options, "Animate_PointLight1").name("Animate").onChange(function(value) {
    //   if (value) {
    //     animatePointLight();  
    //   }
    //   else {
    //     if (pl1Animation) {
    //       pl1Animation.stop();
    //       pl1Animation = null;
    //     }
    //   }  
    // });
    // pointLight1GUI.add(options, "Speed_PointLight1_Animation").name("Speed").onChange(function (value) {
    //   if (pl1Animation) {
    //     pl1Animation.speedRatio = value;
    //   }
    //   else {
    //     return;
    //   }
    // });
    // pointLight1GUI.open();

    // const sceneGUI = gui.addFolder("Scene_Colours");
    // sceneGUI.add(options, "Saturation").name("Saturation").min(-100.0).max(100.0).onChange(function (value) {
    //   var defaultPipeline = new BABYLON.DefaultRenderingPipeline("default", true, scene, [scene.activeCamera]);
    //   var curve = defaultPipeline.imageProcessing.colorCurves[0] //new BABYLON.ColorCurves();
    //   // curve.globalHue = 200;
    //   // curve.globalDensity = 80;
    //   curve.globalSaturation = value;
    //   // curve.highlightsHue = 20;
    //   // curve.highlightsDensity = 80;
    //   // curve.highlightsSaturation = -80;
    //   // curve.shadowsHue = 2;
    //   // curve.shadowsDensity = 80;
    //   // curve.shadowsSaturation = 40;
    //   // defaultPipeline.imageProcessing.colorCurvesEnabled = true;
    //   defaultPipeline.imageProcessing.colorCurves = curve //.globalSaturation(value);
    //   // defaultPipeline.depthOfField.focalLength = 150;
    // });
    // sceneGUI.open()

    gui.open();
    setloadedGUI(gui);
  }

  const toggleLights = () => {
    toggleLightSettings(!showLightSettings);
  }

  const handleUpload = async (e) => {
    let files = e.target.files;
    if (files && files.length) {
      let filename = files[0].name;
      const assets = assetsObject && Object.keys(assetsObject);
      console.log(assets);
      if (assets) {
        const index = assets.find(asset => asset === `${selectedBodySizeOption.value}_${selectedBodyTypeOption.value}`)
        const scene = sceneObj[index];
        // const uploadedMesh = scene.getMeshByName('uploadedAsset');
        // if (uploadedMesh) {
        //   uploadedMesh.dispose();
        // }
        try {
          const result = await SceneLoader.ImportMeshAsync(
            null,
            '',
            files[0],
            scene
          );
          result.meshes[0].name = filename;
          result.meshes.forEach(mesh => {
            mesh.renderingGroupId = 1;
          })
          setUploadedFileName(filename);
        } catch (e) {
          console.log(e);
        }
      } else {

      }
    } else {
      const assets = assetsObject && Object.keys(assetsObject);
      const scene = sceneObj[assets[0]];
      const uploadedMesh = scene.getMeshByName('uploadedAsset');
      if (uploadedMesh) {
        uploadedMesh.dispose();
      }
    }
  }

  const uploadEnvironment = () => {
    if (uploadRef && uploadRef.current) {
      uploadRef.current.click();
    }
  }

  const renderUploadMode = () => {
    const assetKey = assets.find(assetKey => {
      if (selectedProductOption == null)
        return undefined
      if (selectedProductOption?.brand == "enamor") {
        const assetKeyList = assetKey.split("__");
        if (assetKeyList.length > 2) {


          const bodySize = assetKeyList[0];
          const cupType = assetKeyList[1];
          const shapeType = assetKeyList[2];
          const positionType = assetKeyList[3];

          //TODO need to add more check
          return cupType === selectedCupTypeOption.value && bodySize === selectedBodySizeOption.value;
        } return undefined;
      }
      let bodySize = assetKey.split("__")[0];
      const bodyType = assetKey.split("__")[1];
      const assetKeyList = assetKey.split("__");

      if (assetKeyList.length > 2) {
        return undefined;
      }

      return bodyType === selectedBodyTypeOption.value && bodySize === selectedBodySizeOption.value;
    });
    if (assetKey) {
      return <div className={`Dopplr__Sandbox__Scene`}>
        <SceneComponent
          onSceneReady={(scene) => createScene(scene, assetKey)}
          loader={sceneLoading}
          setLoader={setSceneLoading}
          style={{ display: 'block', width: '100%', height: '100%' }}
        />
      </div>
    }
  }

  const renderLocalAsset = () => {
    return <div className={`Dopplr__Sandbox__Scene`}>
      <SceneComponent
        onSceneReady={(scene) => createScene(scene, 'localAsset')}
        loader={sceneLoading}
        setLoader={setSceneLoading}
        style={{ display: 'block', width: '100%', height: '100%' }}
      />
    </div>
  }

  const assets = assetsObject && Object.keys(assetsObject);
  return (
    <div className='Dopplr__Sandbox'>
      <div className='Dopplr__Sandbox__Selections'>
        <Select
          id="brand"
          label={"Select a brand"}
          className={'Dopplr__Sandbox__AutoComplete'}
          options={BRANDS}
          onChange={onBrandsChange}
          value={brandOption}
          onInputChange={(e, value) => setBrandInput(value)}
          inputValue={brandInput}
        />
        <Select
          id="product"
          label={"Select a product"}
          className={'Dopplr__Sandbox__AutoComplete'}
          options={productOptions}
          onChange={onProductsChange}
          value={selectedProductOption}
          onInputChange={(e, value) => {
            setProductInput(value);
          }
          }
          inputValue={productInput}
        />
        {!isInnerWear && <Select
          id={"bodyType"}
          label={"Select body type"}
          className={'Dopplr__Sandbox__AutoComplete'}
          options={bodyOptions}
          onChange={onBodyTypeChange}
          value={selectedBodyTypeOption}
          onInputChange={(e, value) => setBodyTypeInput(value)}
          inputValue={bodyTypeInput}
        />}
        {isInnerWear && <Select
          id={"cupType"}
          label={"Select cup type"}
          className={'Dopplr__Sandbox__AutoComplete'}
          options={cupOptions}
          onChange={onCupTypeChange}
          value={selectedCupTypeOption}
          onInputChange={(e, value) => setCupTypeInput(value)}
          inputValue={cupTypeInput}
        />}
        {isInnerWear && <Select
          id={"shapeType"}
          label={"Select shape type"}
          className={'Dopplr__Sandbox__AutoComplete'}
          options={shapeOptions}
          onChange={onShapeTypeChange}
          value={selectedShapeTypeOption}
          onInputChange={(e, value) => setShapeTypeInput(value)}
          inputValue={shapeTypeInput}
        />
        }
        {isInnerWear && <Select
          id={"positionType"}
          label={"Select position type"}
          className={'Dopplr__Sandbox__AutoComplete'}
          options={positionOptions}
          onChange={onPositionTypeChange}
          value={selectedPositionTypeOption}
          onInputChange={(e, value) => setPositionTypeInput(value)}
          inputValue={positionTypeInput}
        />
        }
        {props.uploadMode && <Select
          label={"Select body size"}
          className={'Dopplr__Sandbox__AutoComplete'}
          options={bodySizeOptions}
          onChange={onBodySizeChange}
          value={selectedBodySizeOption}
        />}
        {skyboxList && skyboxList.length > 0 && !props.uploadMode && <div className={`Dopplr__Sandbox__Debug ${showLightSettings ? 'show' : ''}`} onClick={() => toggleLights()} >{showLightSettings ? 'Disable debug' : 'Enable debug'}</div>}
        {skyboxList && skyboxList.length > 0 && <img src={showEnvironments ? SkyBoxWhite : SkyBoxBlack} alt="Skybox" className={`${showEnvironments ? 'show' : ''}`} onClick={() => toggleEnvironment(!showEnvironments)} />}
        {showLightSettings && <div className='Upload'>
          <input
            id="attachment-camera"
            ref={uploadRef}
            type="file"
            name={uploadedFileName}
            onChange={e => {
              handleUpload(e);
            }}
          />
        </div>}
        {!sceneLoading && showVariationAction && <div
          className={`Dopplr__SandboxVariations`}
        >
          <Radio
            checked={!showVariations}
            onChange={() => setShowVariations(false)}
            value={false}
            size={'small'}
            name="radio-buttons"
            sx={{
              '&.Mui-checked': {
                color: 'black',
              }
            }}
          />
          <Radio
            checked={showVariations}
            onChange={() => setShowVariations(true)}
            value={true}
            size={'small'}
            name="radio-buttons"
            sx={{
              '&.Mui-checked': {
                color: 'black',
              }
            }}
          />
        </div>}
      </div>
      <div className='Dopplr__Sandbox__Container'>
        {loading && <Loader className={'Dopplr__Sandbox__Loader'} />}
        {!props.uploadMode && assets && assets.length > 0 && !loading && assets.map((assetKey, index) => {
          const label = assetKey.split("__")[0];
          const medium = label === 'medium';
          return <div key={index} style={{ display: showLightSettings && !medium ? 'none' : 'block' }} className={`Dopplr__Sandbox__Scene ${showLightSettings ? `Scene_${index}` : ''}`}>
            <div className='Dopplr__Sandbox__Label'>{label}</div>
            <SceneComponent
              onSceneReady={(scene) => createScene(scene, assetKey)}
              loader={sceneLoading}
              setLoader={setSceneLoading}
              style={{ display: 'block', width: '100%', height: '100%' }}
            />
          </div>
        })}
        {!loading && props.uploadMode && assets && assets.length > 0 && renderUploadMode(assets)}
      </div>
      <div className={`Dopplr__SkyboxWrapperSandbox${showEnvironments ? ' show' : ''}`}>
        {skyboxList && skyboxList.length > 0 && <div className='Dopplr__SkyboxWrapperSandbox__List'>
          {skyboxList.map(skybox => {
            const { thumbnailImage, id } = skybox;
            const selected = selectedSkybox && id === selectedSkybox.id;
            return <div key={id} className={`Dopplr__SkyboxWrapperSandbox__List__Skybox__Item ${selected ? ' selected' : 'overlay'}`} onClick={() => setSelectedSkybox(skybox)}>
              <img className='Dopplr__SkyboxWrapperSandbox__List__Skybox__Thumbnail' src={thumbnailImage ? thumbnailImage : NoSkybox} alt="" />
            </div>
          })}
        </div>}
      </div>
    </div >
  )
}

export default Sandbox;