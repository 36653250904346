import OasisnuIndianTop from "assets/images/oasis/nuIndianTop.png";

const fabIndiaList =

    [
        {
            id: 1,
            src: OasisnuIndianTop,
            product_path: "mo1NItop_0",
            product_counter_part: "mo1NIbottom_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Orange Printed Chinese Collar Slim Fit Short Kurta",
            labels: "Oasis Kurta - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this kalamkari short kurta by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Orange"
            ],
            price: "$210",
            brand: "fabindia",
            avatar: "v7",
            shoes: 'niOutfit'
        },
    ];
export default fabIndiaList;