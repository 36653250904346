const rareismProductList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 2,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '2A_0',
        'product_counter_part': '2B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 3,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '3A_0',
        'product_counter_part': '3B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 4,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '4A_0',
        'product_counter_part': '4B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 5,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '5A_0',
        'product_counter_part': '5B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 6,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '6A_0',
        'product_counter_part': '6B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 7,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '7A_0',
        'product_counter_part': '7B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 8,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '8A_0',
        'product_counter_part': '8B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 9,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '9A_0',
        'product_counter_part': '9B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 10,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '10A_0',
        'product_counter_part': '10B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 11,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '11A_0',
        'product_counter_part': '11B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 12,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '12A_0',
        'product_counter_part': '12B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 13,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '13A_0',
        'product_counter_part': '13B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 14,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '14A_0',
        'product_counter_part': '14B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 15,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '15A_0',
        'product_counter_part': '15B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'rareism',
        'avatar': 'v23',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
];

export default rareismProductList;