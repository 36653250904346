const fefProductList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fef',
        'avatar': 'v20',
        'shoes': 'flat1',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg',
    },
    {
        'id': 2,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '2A_0',
        'product_counter_part': '2B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fef',
        'avatar': 'v20',
        'shoes': 'female-white-sneaker',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 3,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '3A_0',
        'product_counter_part': '3B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'fef',
        'avatar': 'v15',
        'shoes': 'male-white-sneaker',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 4,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '4A_0',
        'product_counter_part': '4B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fef',
        'avatar': 'v21',
        'shoes': 'flat2',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 5,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '5A_0',
        'product_counter_part': '5B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'fef',
        'avatar': 'v15',
        'shoes': 'loafer',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 6,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '6A_0',
        'product_counter_part': '6B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fef',
        'avatar': 'v21',
        'shoes': 'flat2',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 7,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '7A_0',
        'product_counter_part': '7B_0',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'fef',
        'avatar': 'v21',
        'shoes': 'flat2',
        'primary': 'shoes',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
];

export default fefProductList;