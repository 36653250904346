
import Sculpt1 from 'assets/images/cava/Sculpt1.jpg';
import Adjusted2 from 'assets/images/cava/Adjusted2.jpg';
import Sweatshirt4 from 'assets/images/cava/Sweatshirt4.jpg';
import Reflex1 from 'assets/images/cava/reflex1.jpg';
import Puma679460 from 'assets/images/puma/679460.jpg';
import Puma677865_0 from 'assets/images/puma/677865_0.png';
import Puma677867_0 from 'assets/images/puma/677867_0.png';
import Puma677867_1 from 'assets/images/puma/677867_1.png';
import NicobarDress from "assets/images/nicobar/Nicobar_Dress.png";
import NicobarFunkeyMonkey from "assets/images/nicobar/Nicobar_Funkey_Monkey.png";
import Chase1 from 'assets/images/cava/Chase1.jpg';
import BSWYEWomen from 'assets/images/ccclub/Be_Sure_of_What_You_Eating_women.jpg';
const iffProductList = [
    {
        id: 1,
        src: Sculpt1,
        product_path: "sculttop_0",
        product_counter_part: "scultbottom_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "80% Polyester, 20% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        }
    },
    {
        id: 2,
        src: Adjusted2,
        product_path: "actopfull_1",
        product_counter_part: "acbottomfull_1",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "100% Cotton",
            bottom: "100% Cotton",
        },
        materialConfig: {
            "actopfull_1": {
                emissive: {
                    'FABRIC 1_FRONT': "#265568",
                    'French Terry & Fleece_FRONT': '#265568',
                    'Lace_FRONT': "#265568",
                    'HIDE': '#265568',
                }
            },
            "acbottomfull_1": {
                emissive: {
                    'FABRIC 1_FRONT': '#265568',
                    'French Terry & Fleece_FRONT': '#265568',
                    'Lace_FRONT': '#265568',
                }
            }
        },
        variations: {
            'detachConfig': {
                'actopfull_1': [
                    'HIDE',
                    'Material2054',
                    'Material1596011',
                    'Material1596004',
                ],
            },
        }
    },
    {
        id: 3,
        src: Sweatshirt4,
        product_path: "sweatshirt_3",
        product_counter_part: "jogger_3",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "60% Cotton, 40% Polyester",
            bottom: "60% Cotton, 40% Polyester",
        },
        materialConfig: {
            'sweatshirt_3': {
                emissive: {
                    'sweatshirt_FRONT': "#16161D",
                    'Rib_2x1_FRONT': '#1A1A20',
                }
            },
            'jogger_3': {
                emissive: {
                    'Knit_Fleece_Terry_FRONT': '#16161D',
                    'Lace_FRONT_798774': '#16161D',
                }
            }
        },
    },
    {
        id: 4,
        src: Reflex1,
        product_path: "reflextop_0",
        product_counter_part: "reflexbottom_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon, 10% Elastane",
            bottom: "90% Nylon, 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            }
        }
    },
    {
        id: 5,
        src: Puma679460,
        product_path: "679460_0",
        product_counter_part: "679464_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[78, 62], [83, 67], [88, 72], [95, 79], [103, 87], [111, 96], [119, 105], [127, 114],],
        productCounterPartSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[71, 58], [76, 62], [81, 66], [88, 72], [96, 79], [104, 87], [112, 95], [120, 103]]
    },
    {
        id: 6,
        src: NicobarDress,
        product_path: "WAD607_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "nicobar",
        avatar: "v16",
        shoes: 'nicobar_wad607',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[81.28, 66.04], [86.33, 71.12], [91.45, 76.20], [96.5, 82.28], [101.6, 86.36], [111.75, 96.52], [122, 106.68],], productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 7,
        src: NicobarFunkeyMonkey,
        product_path: "MT31FItop_0",
        product_counter_part: "MT31FIbottom_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Top",
        labels: "MT31_FI_top",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "nicobar",
        avatar: "v11",
        shoes: 'nicobar_mt31',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[92.71, 64.4], [97.79, 68.4], [102.87, 72.4], [107.95, 77.4], [113.03, 83.4], [123.19, 89.4], [133.35, 94.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 8,
        src: BSWYEWomen,
        product_path: "bsof2top_0",
        product_counter_part: "bsofbottom2_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "ccclub",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 9,
        src: Chase1,
        product_path: "chasetop_0",
        product_counter_part: "chasebottom_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[99, 63.5], [104, 68.58], [109, 72.4], [114.3, 77.4], [119.3, 83.4], [124.4, 89.4]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[94, 63.5], [99, 68.58], [104.14, 73.66], [109.22, 78.74], [114.3, 83.82], [119.38, 88.9]],
        fabric: {
            top: "90% Nylon, 10% Elasthane",
            bottom: "90% Nylon, 10% Elasthane",
        }
    },
    {
        id: 10,
        src: Puma677867_0,
        product_path: "677867_0",
        product_counter_part: "677866X_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 11,
        src: Puma677867_1,
        product_path: "677867_1",
        product_counter_part: "677866X_1",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 12,
        src: Puma677865_0,
        product_path: "677865_0",
        product_counter_part: "677866_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
];

export default iffProductList;
