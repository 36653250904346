const rareRabbitProductList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 2,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '2A_0',
        'product_counter_part': '2B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 3,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '3A_0',
        'product_counter_part': '3B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 4,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '4A_0',
        'product_counter_part': '4B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 5,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '5A_0',
        'product_counter_part': '5B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 6,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '6A_0',
        'product_counter_part': '6B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 7,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '7A_0',
        'product_counter_part': '7B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 8,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '8A_0',
        'product_counter_part': '8B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 9,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '9A_0',
        'product_counter_part': '9B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 10,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '10A_0',
        'product_counter_part': '10B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 11,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '11A_0',
        'product_counter_part': '11B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 12,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '12A_0',
        'product_counter_part': '12B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 13,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '13A_0',
        'product_counter_part': '13B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 14,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '14A_0',
        'product_counter_part': '14B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 15,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '15A_0',
        'product_counter_part': '15B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 16,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '16A_0',
        'product_counter_part': '16B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 17,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '17A_0',
        'product_counter_part': '17B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 18,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '18A_0',
        'product_counter_part': '18B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 19,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '19A_0',
        'product_counter_part': '19B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 20,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '20A_0',
        'product_counter_part': '20B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 21,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '21A_0',
        'product_counter_part': '21B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 22,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '22A_0',
        'product_counter_part': '22B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 23,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '23A_0',
        'product_counter_part': '23B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 24,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '24A_0',
        'product_counter_part': '24B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 25,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '25A_0',
        'product_counter_part': '25B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 26,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '26A_0',
        'product_counter_part': '26B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 27,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '27A_0',
        'product_counter_part': '27B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 28,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '28A_0',
        'product_counter_part': '28B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 29,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '29A_0',
        'product_counter_part': '29B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 30,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '30A_0',
        'product_counter_part': '30B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 31,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '31A_0',
        'product_counter_part': '31B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 32,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '32A_0',
        'product_counter_part': '32B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 33,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '33A_0',
        'product_counter_part': '33B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 34,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '34A_0',
        'product_counter_part': '34B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 35,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '35A_0',
        'product_counter_part': '35B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 36,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '36A_0',
        'product_counter_part': '36B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 37,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '37A_0',
        'product_counter_part': '37B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 38,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '38A_0',
        'product_counter_part': '38B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 39,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '39A_0',
        'product_counter_part': '39B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 40,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '40A_0',
        'product_counter_part': '40B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 41,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '41A_0',
        'product_counter_part': '41B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 42,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '42A_0',
        'product_counter_part': '42B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 43,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '43A_0',
        'product_counter_part': '43B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 44,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '44A_0',
        'product_counter_part': '44B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 45,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '45A_0',
        'product_counter_part': '45B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 46,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '46A_0',
        'product_counter_part': '46B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 47,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '47A_0',
        'product_counter_part': '47B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 48,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '48A_0',
        'product_counter_part': '48B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 49,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '49A_0',
        'product_counter_part': '49B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 50,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '50A_0',
        'product_counter_part': '50B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 51,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '51A_0',
        'product_counter_part': '51B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 52,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '52A_0',
        'product_counter_part': '52B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 53,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '53A_0',
        'product_counter_part': '53B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 54,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '54A_0',
        'product_counter_part': '54B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 55,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '55A_0',
        'product_counter_part': '55B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 56,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '56A_0',
        'product_counter_part': '56B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 57,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '57A_0',
        'product_counter_part': '57B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 58,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '58A_0',
        'product_counter_part': '58B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 59,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '59A_0',
        'product_counter_part': '59B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 60,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '60A_0',
        'product_counter_part': '60B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 61,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '61A_0',
        'product_counter_part': '61B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 62,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '62A_0',
        'product_counter_part': '62B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 63,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '63A_0',
        'product_counter_part': '63B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 64,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '64A_0',
        'product_counter_part': '64B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 65,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '65A_0',
        'product_counter_part': '65B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 66,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '66A_0',
        'product_counter_part': '66B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 67,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '67A_0',
        'product_counter_part': '67B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 68,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '68A_0',
        'product_counter_part': '68B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 69,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '69A_0',
        'product_counter_part': '69B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 70,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '70A_0',
        'product_counter_part': '70B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 71,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '71A_0',
        'product_counter_part': '71B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 72,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '72A_0',
        'product_counter_part': '72B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 73,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '73A_0',
        'product_counter_part': '73B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 74,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '74A_0',
        'product_counter_part': '74B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 75,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '75A_0',
        'product_counter_part': '75B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 76,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '76A_0',
        'product_counter_part': '76B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 77,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '77A_0',
        'product_counter_part': '77B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 78,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '78A_0',
        'product_counter_part': '78B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 79,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '79A_0',
        'product_counter_part': '79B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 80,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '80A_0',
        'product_counter_part': '80B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 81,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '81A_0',
        'product_counter_part': '81B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 82,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '82A_0',
        'product_counter_part': '82B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 83,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '83A_0',
        'product_counter_part': '83B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 84,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '84A_0',
        'product_counter_part': '84B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 85,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '85A_0',
        'product_counter_part': '85B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 86,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '86A_0',
        'product_counter_part': '86B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 87,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '87A_0',
        'product_counter_part': '87B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 88,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '88A_0',
        'product_counter_part': '88B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 89,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '89A_0',
        'product_counter_part': '89B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 90,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '90A_0',
        'product_counter_part': '90B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 91,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '91A_0',
        'product_counter_part': '91B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 92,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '92A_0',
        'product_counter_part': '92B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 93,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '93A_0',
        'product_counter_part': '93B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 94,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '94A_0',
        'product_counter_part': '94B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 95,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '95A_0',
        'product_counter_part': '95B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 96,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '96A_0',
        'product_counter_part': '96B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 97,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '97A_0',
        'product_counter_part': '97B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 98,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '98A_0',
        'product_counter_part': '98B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    }, {
        'id': 99,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '99A_0',
        'product_counter_part': '99B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52, 99.06, 101.6, 106.68, 114.3, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productCounterPartSizeValues': [
            [83.82, 98.6],
            [86.36, 101.6],
            [88.9, 104.6],
            [91.44, 107.57],
            [96.52, 113.56],
            [104.14, 122.53],
            [109.22, 128.5],
            [114.3, 134.47]
        ],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
]

export default rareRabbitProductList;