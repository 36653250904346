import SaveTheTreesMen from 'assets/images/ccclub/Save_the_trees_men.jpg';
import bh1 from 'assets/images/beinghuman/1.webp';
import bh2 from 'assets/images/beinghuman/2.webp';
import bh4 from 'assets/images/beinghuman/4.webp';
import bh5 from 'assets/images/beinghuman/5.webp';
import bh6 from 'assets/images/beinghuman/6.webp';
import bh7 from 'assets/images/beinghuman/7.webp';
import bh8 from 'assets/images/beinghuman/8.webp';
import bh9 from 'assets/images/beinghuman/9.webp';
import bh10 from 'assets/images/beinghuman/10.webp';
import bh12 from 'assets/images/beinghuman/12.webp';
import bh14 from 'assets/images/beinghuman/14.webp';
import bh15 from 'assets/images/beinghuman/15.webp';
import bh16 from 'assets/images/beinghuman/16.webp';
import bh19 from 'assets/images/beinghuman/19.webp';
import bh20 from 'assets/images/beinghuman/20.webp';
import bh21 from 'assets/images/beinghuman/21.webp';
import bh22 from 'assets/images/beinghuman/22.webp';
import bh23 from 'assets/images/beinghuman/23.webp';
import bh24 from 'assets/images/beinghuman/24.webp';
import bh25 from 'assets/images/beinghuman/25.webp';
import bh26 from 'assets/images/beinghuman/26.webp';
import bh27 from 'assets/images/beinghuman/27.webp';
import bh28 from 'assets/images/beinghuman/28.webp';
import bh29 from 'assets/images/beinghuman/29.webp';
import bh30 from 'assets/images/beinghuman/30.webp';
import bh31 from 'assets/images/beinghuman/31.webp';
import bh32 from 'assets/images/beinghuman/32.webp';
import bh34 from 'assets/images/beinghuman/34.webp';
import bh35 from 'assets/images/beinghuman/35.webp';
import bh36 from 'assets/images/beinghuman/36.webp';
import bh37 from 'assets/images/beinghuman/37.webp';
import bh42 from 'assets/images/beinghuman/42.webp';
import bh43 from 'assets/images/beinghuman/43.webp';
import bh44 from 'assets/images/beinghuman/44.webp';
import bh45 from 'assets/images/beinghuman/45.webp';
import bh46 from 'assets/images/beinghuman/46.webp';
import bh47 from 'assets/images/beinghuman/47.webp';
import bh48 from 'assets/images/beinghuman/48.webp';
import bh49 from 'assets/images/beinghuman/49.webp';
import bh50 from 'assets/images/beinghuman/50.webp';
import bh51 from 'assets/images/beinghuman/51.webp';
import bh52 from 'assets/images/beinghuman/52.webp';
import bh53 from 'assets/images/beinghuman/53.webp';
import bh54 from 'assets/images/beinghuman/54.webp';
import bh55 from 'assets/images/beinghuman/55.webp';
import bh56 from 'assets/images/beinghuman/56.webp';
import bh57 from 'assets/images/beinghuman/57.webp';
import bh58 from 'assets/images/beinghuman/58.webp';
import bh59 from 'assets/images/beinghuman/59.webp';
import bh60 from 'assets/images/beinghuman/60.webp';
import bh61 from 'assets/images/beinghuman/61.webp';
import bh62 from 'assets/images/beinghuman/62.webp';
import bh63 from 'assets/images/beinghuman/63.webp';
import bh64 from 'assets/images/beinghuman/64.webp';
import bh65 from 'assets/images/beinghuman/65.webp';
import bh66 from 'assets/images/beinghuman/66.webp';
import bh67 from 'assets/images/beinghuman/67.webp';
import bh68 from 'assets/images/beinghuman/68.webp';
import bh69 from 'assets/images/beinghuman/69.webp';
import bh70 from 'assets/images/beinghuman/70.webp';
import bh71 from 'assets/images/beinghuman/71.webp';
import bh72 from 'assets/images/beinghuman/72.webp';
import bh73 from 'assets/images/beinghuman/73.webp';
import bh74 from 'assets/images/beinghuman/74.webp';
import bh75 from 'assets/images/beinghuman/75.webp';
import bh76 from 'assets/images/beinghuman/76.webp';
import bh80 from 'assets/images/beinghuman/80.webp';
import bh82 from 'assets/images/beinghuman/82.webp';
import bh87 from 'assets/images/beinghuman/87.webp';
import bh88 from 'assets/images/beinghuman/88.webp';
import bh89 from 'assets/images/beinghuman/89.webp';
import bh90 from 'assets/images/beinghuman/90.webp';
import bh91 from 'assets/images/beinghuman/91.webp';
import bh92 from 'assets/images/beinghuman/92.webp';
import bh93 from 'assets/images/beinghuman/93.webp';
import bh94 from 'assets/images/beinghuman/94.webp';
import bh95 from 'assets/images/beinghuman/95.webp';
import bh96 from 'assets/images/beinghuman/96.webp';
import bh97 from 'assets/images/beinghuman/97.webp';
import bh98 from 'assets/images/beinghuman/98.webp';
import bh99 from 'assets/images/beinghuman/99.webp';
import bh100 from 'assets/images/beinghuman/100.webp';
import bh101 from 'assets/images/beinghuman/101.webp';
import bh102 from 'assets/images/beinghuman/102.webp';
import bh103 from 'assets/images/beinghuman/103.webp';
import bh104 from 'assets/images/beinghuman/104.webp';
import bh105 from 'assets/images/beinghuman/105.webp';
import bh106 from 'assets/images/beinghuman/106.webp';
import bh107 from 'assets/images/beinghuman/107.webp';
import bh108 from 'assets/images/beinghuman/108.webp';
import bh109 from 'assets/images/beinghuman/109.webp';
import bh110 from 'assets/images/beinghuman/110.webp';
import bh111 from 'assets/images/beinghuman/111.webp';
import bh113 from 'assets/images/beinghuman/113.webp';
import bh114 from 'assets/images/beinghuman/114.webp';
import bh115 from 'assets/images/beinghuman/115.webp';
import bh116 from 'assets/images/beinghuman/116.webp';
import bh117 from 'assets/images/beinghuman/117.webp';
import bh118 from 'assets/images/beinghuman/118.webp';
import bh119 from 'assets/images/beinghuman/119.webp';
import bh120 from 'assets/images/beinghuman/120.webp';
import bh121 from 'assets/images/beinghuman/121.webp';

export const beingHumanProductList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': 'BHTS24031_0',
        'product_counter_part': 'BHDI24099_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
    },
    {
        'id': 1001,
        'productSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'productCounterPartSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'groupId': 'BHDI24099',
        'product_path': 'BHDI24099_0',
        'product_counter_part': 'BHTS24031_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productTitle': 'Being Human Men Skinnyfit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24099-MID TONE_1.jpg'
    },
    {
        'id': 2,
        'productSlug': 'being-human-men-none-t-shirts-jet-black-8905271445012',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-mid-tone-8905271476627',
        'groupId': 'BHTS24079',
        'product_path': 'BHT24079_0',
        'product_counter_part': 'BHDI24168_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men None T-Shirts-Jet Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24079-JET BLACK_1.jpg'
    },
    {
        'id': 1002,
        'productSlug': 'being-human-men-slim-fit-denim-mid-tone-8905271476627',
        'productCounterPartSlug': 'being-human-men-none-t-shirts-jet-black-8905271445012',
        'groupId': 'BHDI24168',
        'product_path': 'BHDI24168_0',
        'product_counter_part': 'BHT24079_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [101.6,
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78],
        'productTitle': 'Being Human Men Slim Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24168-MID TONE_1.jpg'
    },
    {
        'id': 4,
        'productSlug': 'being-human-men-regular-fit-t-shirts-charcoal-8905271451495',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'groupId': 'BHP24020',
        'product_path': 'BHP24020_0',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Charcoal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24020-CHARCOAL_1.jpg'
    },
    {
        'id': 1004,
        'productSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-charcoal-8905271451495',
        'groupId': 'BHDSI24109',
        'product_path': 'BHDSI24109_0',
        'product_counter_part': 'BHP24020_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productTitle': 'Being Human Men Shorts Fit Shorts-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDSI24109-MID TONE_1.jpg'
    },
    {
        'id': 5,
        'productSlug': 'being-human-men-regular-fit-t-shirts-navy-8905271451570',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'groupId': 'BHP24020',
        'product_path': 'BHP24020_1',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24020-NAVY_1.jpg'
    },
    {
        'id': 6,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271453178',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'groupId': 'BHP24028',
        'product_path': 'BHP24028_0',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24028-WHITE_1.jpg'
    },
    {
        'id': 7,
        'productSlug': 'being-human-men-regular-fit-t-shirts-black-8905271453093',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'groupId': 'BHP24028',
        'product_path': 'BHP24028_1',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24028-BLACK_1.jpg'
    },
    {
        'id': 8,
        'productSlug': 'being-human-men-regular-fit-t-shirts-ltgrey-8905271458098',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'groupId': 'BHP24035',
        'product_path': 'BHP24035_0',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Lt.Grey',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24035-LT.GREY_1.jpg'
    },
    {
        'id': 9,
        'productSlug': 'being-human-men-regular-fit-t-shirts-light-blue-8905271451891',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'groupId': 'BHP24038',
        'product_path': 'BHP24038_0',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Light Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24038-LIGHT BLUE_1.jpg'
    },
    {
        'id': 10,
        'productSlug': 'being-human-men-regular-fit-t-shirts-pastel-pink-8905271472452',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475347',
        'groupId': 'BHP24046',
        'product_path': 'BHP24046_0',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Pastel Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24046-PASTEL PINK_1.jpg'
    },
    {
        'id': 12,
        'productSlug': 'being-human-men-slim-fit-shirts-deep-ocean-8905271459491',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24014',
        'product_path': 'BHMLS24014_0',
        'product_counter_part': 'BHDI24093_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24014-DEEP OCEAN_1.jpg'
    },
    {
        'id': 1012,
        'productSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-deep-ocean-8905271459491',
        'groupId': 'BHDI24093',
        'product_path': 'BHDI24093_0',
        'product_counter_part': 'BHMLS24014_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productTitle': 'Being Human Men Jogger Fit Denim-Light Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24093-LIGHT TONE_1.jpg'
    },
    {
        'id': 14,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271445876',
        'productCounterPartSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474401',
        'groupId': 'BHMLS24017',
        'product_path': 'BHMLS24017_0',
        'product_counter_part': 'BHDI24137_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[73.66, 86.66],
        [78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [96.52, 113.56],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24017-BLACK_1.jpg'
    },
    {
        'id': 1014,
        'productSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474401',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-black-8905271445876',
        'groupId': 'BHDI24137',
        'product_path': 'BHDI24137_0',
        'product_counter_part': 'BHMLS24017_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[73.66, 86.66],
        [78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [96.52, 113.56],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productTitle': 'Being Human Men Slim Straight Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24137-MID TONE_1.jpg'
    },
    {
        'id': 15,
        'productSlug': 'being-human-men-regular-fit-t-shirts-forest-green-8905271453499',
        'productCounterPartSlug': 'being-human-men-boot-cut-fit-denim-light-tone-8905271467366',
        'groupId': 'BHP24003',
        'product_path': 'BHP24003_0',
        'product_counter_part': 'BHDI24030_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Forest Green',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24003-FOREST GREEN_1.jpg'
    },
    {
        'id': 1015,
        'productSlug': 'being-human-men-boot-cut-fit-denim-light-tone-8905271467366',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-forest-green-8905271453499',
        'groupId': 'BHDI24030',
        'product_path': 'BHDI24030_0',
        'product_counter_part': 'BHP24003_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[82.55, 97.13],
        [85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productTitle': 'Being Human Men Boot Cut Fit Denim-Light Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24030-LIGHT TONE_1.jpg'
    },
    {
        'id': 16,
        'productSlug': 'being-human-men-slim-fit-shirts-whitered-8905271459736',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475040',
        'groupId': 'BHMSS24002',
        'product_path': 'BHMSS24002_0',
        'product_counter_part': 'BHDI24059_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44],
        [124.46, 146.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White/Red',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24002-WHITE&RED_1.jpg'
    },
    {
        'id': 1016,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475040',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-whitered-8905271459736',
        'groupId': 'BHDI24059',
        'product_path': 'BHDI24059_0',
        'product_counter_part': 'BHMSS24002_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44],
        [124.46, 146.43]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24059-MID TONE_1.jpg'
    },
    {
        'id': 17.0,
        'productSlug': 'being-human-men-slim-fit-shirts-ltbluecoral-8905271483243',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475392',
        'groupId': 'BHMSS24013',
        'product_path': 'BHMSS24013_0',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]]
    },
    {
        'id': 1017,
        'productSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475392',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-ltbluecoral-8905271483243',
        'groupId': 'BHDSI24109',
        'product_path': 'BHDSI24109_0',
        'product_counter_part': 'BHMSS24013_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productTitle': 'Being Human Men Shorts Fit Shorts-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDSI24109-MID TONE_1.jpg'
    },
    {
        'id': 18.0,
        'productSlug': 'being-human-men-slim-fit-shirts-chalkyellow-8905271483113',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-mid-tone-8905271475392',
        'groupId': 'BHMSS24013',
        'product_path': 'BHMSS24013_1',
        'product_counter_part': 'BHDSI24109_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]]
    },
    {
        'id': 19,
        'productSlug': 'being-human-men-slim-fit-shirts-greenblue-8905271446279',
        'productCounterPartSlug': 'being-human-men-shorts-fit-shorts-black-tone-8905271466130',
        'groupId': 'BHMSS24027',
        'product_path': 'BHMSS24027_0',
        'product_counter_part': 'BHDSI24195_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Green/Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24027-GREEN&BLUE_1.jpg'
    },
    {
        'id': 1019,
        'productSlug': 'being-human-men-shorts-fit-shorts-black-tone-8905271466130',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-greenblue-8905271446279',
        'groupId': 'BHDSI24195',
        'product_path': 'BHDSI24195_0',
        'product_counter_part': 'BHMSS24027_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productTitle': 'Being Human Men Shorts Fit Shorts-Black Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDSI24195-BLACK TONE_1.jpg'
    },
    {
        'id': 20,
        'productSlug': 'being-human-men-none-t-shirts-coffee-8905271442493',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24012',
        'product_path': '20A_0',
        'product_counter_part': '20B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Coffee',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24012-COFFEE_1.jpg'
    },
    {
        'id': 21,
        'productSlug': 'being-human-men-none-t-shirts-midnight-blue-8905271442400',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24012',
        'product_path': '21A_0',
        'product_counter_part': '21B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Midnight Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24012-MIDNIGHT BLUE_1.jpg'
    },
    {
        'id': 22,
        'productSlug': 'being-human-men-none-t-shirts-charcoal-8905271442578',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24014',
        'product_path': '22A_0',
        'product_counter_part': '22B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Charcoal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24014-CHARCOAL_1.jpg'
    },
    {
        'id': 23,
        'productSlug': 'being-human-men-none-t-shirts-mineral-red-8905271442653',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24014',
        'product_path': '23A_0',
        'product_counter_part': '23B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Mineral Red',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24014-MINERAL RED_1.jpg'
    },
    {
        'id': 24,
        'productSlug': 'being-human-men-none-t-shirts-powder-pink-8905271444046',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24037',
        'product_path': '24A_0',
        'product_counter_part': '24B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'],
        'productSizeValues': [99.06, 101.6, 104.14, 109.22, 116.84, 121.92, 127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Powder Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24037-POWDER PINK_1.jpg'
    },
    {
        'id': 25,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271444114',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24037',
        'product_path': '25A_0',
        'product_counter_part': '25B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'],
        'productSizeValues': [99.06, 101.6, 104.14, 109.22, 116.84, 121.92, 127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24037-WHITE_1.jpg'
    },
    {
        'id': 26,
        'productSlug': 'being-human-men-slim-fit-t-shirts-chalk-8905271469902',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24006',
        'product_path': '26A_0',
        'product_counter_part': '26B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Chalk',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24006-CHALK_1.jpg'
    },
    {
        'id': 27,
        'productSlug': 'being-human-men-slim-fit-t-shirts-black-8905271470076',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24009',
        'product_path': '27A_0',
        'product_counter_part': '27B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24009-BLACK_1.jpg'
    },
    {
        'id': 28,
        'productSlug': 'being-human-men-slim-fit-t-shirts-green-lake-8905271470151',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24009',
        'product_path': '28A_0',
        'product_counter_part': '28B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Green Lake',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24009-GREEN LAKE_6.jpg'
    },
    {
        'id': 29,
        'productSlug': 'being-human-men-none-t-shirts-navy-8905271444770',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24057',
        'product_path': '29A_0',
        'product_counter_part': '29B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24057-NAVY_1.jpg'
    },
    {
        'id': 30,
        'productSlug': 'being-human-men-none-t-shirts-deep-ocean-8905271444695',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24057',
        'product_path': '30A_0',
        'product_counter_part': '30B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24057-DEEP OCEAN_6.jpg'
    },
    {
        'id': 31,
        'productSlug': 'being-human-men-none-t-shirts-chalk-8905271442738',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24015',
        'product_path': '31A_0',
        'product_counter_part': '31B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-Chalk',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24015-CHALK_1.jpg'
    },
    {
        'id': 32,
        'productSlug': 'being-human-men-none-t-shirts-deep-ocean-8905271442813',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24015',
        'product_path': '32A_0',
        'product_counter_part': '32B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24015-DEEP OCEAN_6.jpg'
    },
    {
        'id': 33,
        'productSlug': 'being-human-men-slim-fit-shirts-whiteyellow-8905271459873',
        'productCounterPartSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474685',
        'groupId': 'BHMSS24002\n',
        'product_path': '33A_0',
        'product_counter_part': '33B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [100.33, 118.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93],
        [128.27, 150.9]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White/Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24002-WHITE&YELLOW_1.jpg'
    },
    {
        'id': 1033.0,
        'productSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474685',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-whiteyellow-8905271459873',
        'groupId': 'BHDI24143\n',
        'product_path': '33B_0',
        'product_counter_part': '33A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [100.33, 118.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93],
        [128.27, 150.9]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0]
    },
    {
        'id': 34,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271460213',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475040',
        'groupId': 'BHMSS24014',
        'product_path': '34A_0',
        'product_counter_part': '34B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44],
        [124.46, 146.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24014-BLACK_1.jpg'
    },
    {
        'id': 35,
        'productSlug': 'being-human-men-slim-fit-shirts-deep-ocean-8905271460299',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475040',
        'groupId': 'BHMSS24014',
        'product_path': '35A_0',
        'product_counter_part': '35B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44],
        [124.46, 146.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24014-DEEP OCEAN_6.jpg'
    },
    {
        'id': 36,
        'productSlug': 'being-human-men-slim-fit-shirts-yellowcharcoal-8905271460138',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475040',
        'groupId': 'BHMSS24010',
        'product_path': '36A_0',
        'product_counter_part': '36B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44],
        [124.46, 146.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Yellow/Charcoal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24010-YELLOW&CHARCOAL_1.jpg'
    },
    {
        'id': 37,
        'productSlug': 'being-human-men-slim-fit-shirts-ltbluecharcoal-8905271460053',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475040',
        'groupId': 'BHMSS24010',
        'product_path': '37A_0',
        'product_counter_part': '37B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44],
        [124.46, 146.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Lt.Blue/Charcoal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24010-LT.BLUE&CHARCOAL_1.jpg'
    },
    {
        'id': 38,
        'productSlug': 'being-human-men-slim-fit-shirts-blackgrey-8905271446019',
        'productCounterPartSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474685',
        'groupId': 'BHMSS24009',
        'product_path': '38A_0',
        'product_counter_part': '38B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [100.33, 118.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93],
        [128.27, 150.9]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black/Grey',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24009-BLACK&GREY_1.jpg'
    },
    {
        'id': 39,
        'productSlug': 'being-human-men-slim-fit-shirts-chalkwhite-8905271446040',
        'productCounterPartSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474685',
        'groupId': 'BHMSS24009',
        'product_path': '39A_0',
        'product_counter_part': '39B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [100.33, 118.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93],
        [128.27, 150.9]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Chalk/White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24009-CHALK&WHITE_1.jpg'
    },
    {
        'id': 40,
        'productSlug': 'being-human-men-slim-fit-shirts-chalk-8905271459972',
        'productCounterPartSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474685',
        'groupId': 'BHMSS24006',
        'product_path': '40A_0',
        'product_counter_part': '40B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [100.33, 118.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93],
        [128.27, 150.9]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Chalk',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24006-CHALK_1.jpg'
    },
    {
        'id': 41,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271459897',
        'productCounterPartSlug': 'being-human-men-slim-straight-fit-denim-mid-tone-8905271474685',
        'groupId': 'BHMSS24006',
        'product_path': '41A_0',
        'product_counter_part': '41B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [100.33, 118.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93],
        [128.27, 150.9]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24006-BLACK_1.jpg'
    },
    {
        'id': 42,
        'productSlug': 'being-human-men-slim-fit-shirts-chalknavy-8905271483267',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463672',
        'groupId': 'BHMSS24016',
        'product_path': '42A_0',
        'product_counter_part': '42B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Chalk/Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24016-CHALK&NAVY_1.jpg'
    },
    {
        'id': 1042,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463672',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-chalknavy-8905271483267',
        'groupId': 'BHDI24049',
        'product_path': '42B_0',
        'product_counter_part': '42A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24049-MID TONE_1.jpg'
    },
    {
        'id': 43,
        'productSlug': 'being-human-men-slim-fit-shirts-navyolive-8905271483342',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463672',
        'groupId': 'BHMSS24016',
        'product_path': '43A_0',
        'product_counter_part': '43B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Navy/Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24016-NAVY&OLIVE_1.jpg'
    },
    {
        'id': 44,
        'productSlug': 'being-human-men-none-t-shirts-mineral-red-8905271442325',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24011',
        'product_path': '44A_0',
        'product_counter_part': '44B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Mineral Red',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24011-MINERAL RED_1.jpg'
    },
    {
        'id': 45,
        'productSlug': 'being-human-men-none-t-shirts-olive-8905271442257',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24011',
        'product_path': '45A_0',
        'product_counter_part': '45B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24011-OLIVE_1.jpg'
    },
    {
        'id': 46,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271443476',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24025',
        'product_path': '46A_0',
        'product_counter_part': '46B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24025-BLACK_1.jpg'
    },
    {
        'id': 47,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271443391',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24025',
        'product_path': '47A_0',
        'product_counter_part': '47B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24025-WHITE_1.jpg'
    },
    {
        'id': 48,
        'productSlug': 'being-human-men-regular-fit-t-shirts-ltgrey-8905271457534',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24012',
        'product_path': '48A_0',
        'product_counter_part': '48B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Lt.Grey',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24012-LT.GREY_1.jpg'
    },
    {
        'id': 1048,
        'productSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-ltgrey-8905271457534',
        'groupId': 'BHDI24051',
        'product_path': '48B_0',
        'product_counter_part': '48A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productTitle': 'Being Human Men Cropped Fit Denim-Dark Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24051-DARK TONE_1.jpg'
    },
    {
        'id': 49,
        'productSlug': 'being-human-men-regular-fit-t-shirts-deep-ocean-8905271457459',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24012',
        'product_path': '49A_0',
        'product_counter_part': '49B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24012-DEEP OCEAN_1.jpg'
    },
    {
        'id': 50,
        'productSlug': 'being-human-men-regular-fit-t-shirts-charcoal-8905271457619',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24013',
        'product_path': '50A_0',
        'product_counter_part': '50B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Charcoal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24013-CHARCOAL_1.jpg'
    },
    {
        'id': 51,
        'productSlug': 'being-human-men-regular-fit-t-shirts-deep-ocean-8905271457695',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24013',
        'product_path': '51A_0',
        'product_counter_part': '51B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24013-DEEP OCEAN_1.jpg'
    },
    {
        'id': 52,
        'productSlug': 'being-human-men-regular-fit-t-shirts-light-blue-8905271451655',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24022',
        'product_path': '52A_0',
        'product_counter_part': '52B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Light Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24022-LIGHT BLUE_1.jpg'
    },
    {
        'id': 53,
        'productSlug': 'being-human-men-regular-fit-t-shirts-snow-white-8905271451730',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24022',
        'product_path': '53A_0',
        'product_counter_part': '53B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Snow White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24022-SNOW WHITE_1.jpg'
    },
    {
        'id': 54,
        'productSlug': 'being-human-men-regular-fit-t-shirts-midnight-blue-8905271451815',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24024',
        'product_path': '54A_0',
        'product_counter_part': '54B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Midnight Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24024-MIDNIGHT BLUE_1.jpg'
    },
    {
        'id': 55,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271452133',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24041',
        'product_path': '55A_0',
        'product_counter_part': '55B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24041-WHITE_1.jpg'
    },
    {
        'id': 56,
        'productSlug': 'being-human-men-regular-fit-t-shirts-navy-8905271452058',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271490685',
        'groupId': 'BHP24041',
        'product_path': '56A_0',
        'product_counter_part': '56B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24041-NAVY_1.jpg'
    },
    {
        'id': 57,
        'productSlug': 'being-human-men-regular-fit-t-shirts-charcoal-8905271453413',
        'productCounterPartSlug': 'being-human-men-boot-cut-fit-denim-light-tone-8905271467366',
        'groupId': 'BHP24003',
        'product_path': '57A_0',
        'product_counter_part': '57B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Charcoal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24003-CHARCOAL_1.jpg'
    },
    {
        'id': 58,
        'productSlug': 'being-human-men-slim-fit-shirts-whiteyellow-8905271456117',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24065',
        'product_path': '58A_0',
        'product_counter_part': '58B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White/Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24065-WHITE&YELLOW_1.jpg'
    },
    {
        'id': 59,
        'productSlug': 'being-human-men-slim-fit-shirts-whitenavy-8905271456261',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24066',
        'product_path': '59A_0',
        'product_counter_part': '59B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White/Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24066-WHITE&NAVY_1.jpg'
    },
    {
        'id': 60,
        'productSlug': 'being-human-men-slim-fit-shirts-navywhite-8905271456193',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24066',
        'product_path': '60A_0',
        'product_counter_part': '60B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Navy/White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24066-NAVY&WHITE_1.jpg'
    },
    {
        'id': 61,
        'productSlug': 'being-human-men-regular-fit-t-shirts-black-8905271447795',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '61A_0',
        'product_counter_part': '61B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-BLACK_1.jpg'
    },
    {
        'id': 1061,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-black-8905271447795',
        'groupId': 'BHDI24175',
        'product_path': '61B_0',
        'product_counter_part': '61A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24175-MID TONE_1.jpg'
    },
    {
        'id': 62,
        'productSlug': 'being-human-men-regular-fit-t-shirts-blareney-8905271447870',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '62A_0',
        'product_counter_part': '62B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Blareney',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-BLARENEY_1.jpg'
    },
    {
        'id': 63,
        'productSlug': 'being-human-men-regular-fit-t-shirts-bright-marigold-8905271447955',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '63A_0',
        'product_counter_part': '63B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Bright Marigold',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-BRIGHT MARIGOLD_1.jpg'
    },
    {
        'id': 64,
        'productSlug': 'being-human-men-regular-fit-t-shirts-fiesta-8905271448129',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '64A_0',
        'product_counter_part': '64B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Fiesta',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-FIESTA_1.jpg'
    },
    {
        'id': 65,
        'productSlug': 'being-human-men-regular-fit-t-shirts-navy-8905271448181',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '65A_0',
        'product_counter_part': '65B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-NAVY_1.jpg'
    },
    {
        'id': 66,
        'productSlug': 'being-human-men-regular-fit-t-shirts-peach-parfait-8905271448266',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '66A_0',
        'product_counter_part': '66B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Peach Parfait',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-PEACH PARFAIT_1.jpg'
    },
    {
        'id': 67,
        'productSlug': 'being-human-men-regular-fit-t-shirts-sachet-pink-8905271448341',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '67A_0',
        'product_counter_part': '67B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Sachet Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-SACHET PINK_1.jpg'
    },
    {
        'id': 68,
        'productSlug': 'being-human-men-regular-fit-t-shirts-vibrant-yellow-8905271448426',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '68A_0',
        'product_counter_part': '68B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Vibrant Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-VIBRANT YELLOW_1.jpg'
    },
    {
        'id': 69,
        'productSlug': 'being-human-men-regular-fit-t-shirts-winery-8905271448587',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24096',
        'product_path': '69A_0',
        'product_counter_part': '69B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Winery',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24096-WINERY_1.jpg'
    },
    {
        'id': 70,
        'productSlug': 'being-human-men-regular-fit-t-shirts-black-8905271446750',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24076',
        'product_path': '70A_0',
        'product_counter_part': '70B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [91.44,
            93.98,
            96.52,
            101.6,
            109.22,
            114.3,
            119.38,
            124.46],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24076-BLACK_1.jpg'
    },
    {
        'id': 71,
        'productSlug': 'being-human-men-regular-fit-t-shirts-marron-8905271446910',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24076',
        'product_path': '71A_0',
        'product_counter_part': '71B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Marron',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24076-MARRON_1.jpg'
    },
    {
        'id': 72,
        'productSlug': 'being-human-men-regular-fit-t-shirts-lake-blue-8905271446835',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24076',
        'product_path': '72A_0',
        'product_counter_part': '72B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Lake Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24076-LAKE BLUE_1.jpg'
    },
    {
        'id': 73,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271447078',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24076',
        'product_path': '73A_0',
        'product_counter_part': '73B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24076-WHITE_1.jpg'
    },
    {
        'id': 74,
        'productSlug': 'being-human-men-regular-fit-t-shirts-rust-8905271446996',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24076',
        'product_path': '74A_0',
        'product_counter_part': '74B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Rust',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24076-RUST_1.jpg'
    },
    {
        'id': 75,
        'productSlug': 'being-human-men-regular-fit-t-shirts-mineral-red-8905271453970',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24039',
        'product_path': '75A_0',
        'product_counter_part': '75B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Mineral Red',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24039-MINERAL RED_1.jpg'
    },
    {
        'id': 76,
        'productSlug': 'being-human-men-regular-fit-t-shirts-ltgrey-8905271453895',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24039',
        'product_path': '76A_0',
        'product_counter_part': '76B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Lt.Grey',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24039-LT.GREY_1.jpg'
    },
    {
        'id': 77,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271450153',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHLSP24114',
        'product_path': '77A_0',
        'product_counter_part': '77B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24,
            43.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24107-WHITE_1.jpg'
    },
    {
        'id': 78,
        'productSlug': 'being-human-men-slim-fit-shirts-navy-8905271439066',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHLSP24114',
        'product_path': '78A_0',
        'product_counter_part': '78B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHLSP24114-NAVY_1.jpg'
    },
    {
        'id': 79,
        'productSlug': 'being-human-men-slim-fit-shirts-beige-8905271438854',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHLSP24114',
        'product_path': '79A_0',
        'product_counter_part': '79B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Beige',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHLSP24114-BEIGE_1.jpg'
    },
    {
        'id': 80,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271482413',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24016',
        'product_path': '80A_0',
        'product_counter_part': '80B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'],
        'productSizeValues': [104.14, 109.22, 114.3, 121.92, 127.0, 132.08, 137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24016-BLACK_6.jpg'
    },
    {
        'id': 81,
        'productSlug': 'being-human-men-slim-fit-shirts-olive-8905271488842',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24009',
        'product_path': '81A_0',
        'product_counter_part': '81B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL'],
        'productSizeValues': [101.6, 106.68, 111.76, 119.38, 124.46, 129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24009-OLIVE_1.jpg'
    },
    {
        'id': 82,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271450207',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24107',
        'product_path': '82A_0',
        'product_counter_part': '82B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24107-WHITE_1.jpg'
    },
    {
        'id': 87,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271482253',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '87A_0',
        'product_counter_part': '87B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-WHITE_1.jpg'
    },
    {
        'id': 1087,
        'productSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-white-8905271482253',
        'groupId': 'BHDI24060',
        'product_path': '87B_0',
        'product_counter_part': '87A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productTitle': 'Being Human Men Cropped Fit Denim-Dark Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24060-DARK TONE_1.jpg'
    },
    {
        'id': 88,
        'productSlug': 'being-human-men-slim-fit-shirts-sky-8905271482093',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '88A_0',
        'product_counter_part': '88B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Sky',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-SKY_1.jpg'
    },
    {
        'id': 89,
        'productSlug': 'being-human-men-slim-fit-shirts-mint-8905271482017',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '89A_0',
        'product_counter_part': '89B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Mint',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-MINT_1.jpg'
    },
    {
        'id': 90,
        'productSlug': 'being-human-men-slim-fit-shirts-ltpink-8905271481935',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '90A_0',
        'product_counter_part': '90B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Lt.Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-LT.PINK_1.jpg'
    },
    {
        'id': 91,
        'productSlug': 'being-human-men-slim-fit-shirts-dkolive-8905271481850',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '91A_0',
        'product_counter_part': '91B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Dk.Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-DK.OLIVE_1.jpg'
    },
    {
        'id': 92,
        'productSlug': 'being-human-men-slim-fit-shirts-teal-8905271482178',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '92A_0',
        'product_counter_part': '92B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Teal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-TEAL_1.jpg'
    },
    {
        'id': 93,
        'productSlug': 'being-human-men-slim-fit-shirts-yellow-8905271482338',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '93A_0',
        'product_counter_part': '93B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-YELLOW_1.jpg'
    },
    {
        'id': 94,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271481775',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271466451',
        'groupId': 'BHMSS24117',
        'product_path': '94A_0',
        'product_counter_part': '94B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24117-BLACK_1.jpg'
    },
    {
        'id': 95,
        'productSlug': 'being-human-men-slim-fit-t-shirts-mineral-red-8905271470304',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24010',
        'product_path': '95A_0',
        'product_counter_part': '95B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Mineral Red',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24010-MINERAL RED_1.jpg'
    },
    {
        'id': 96,
        'productSlug': 'being-human-men-slim-fit-t-shirts-chalk-8905271470229',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24010',
        'product_path': '96A_0',
        'product_counter_part': '96B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Chalk',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24010-CHALK_1.jpg'
    },
    {
        'id': 97,
        'productSlug': 'being-human-men-slim-fit-t-shirts-olive-8905271470380',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24010',
        'product_path': '97A_0',
        'product_counter_part': '97B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24010-OLIVE_1.jpg'
    },
    {
        'id': 98,
        'productSlug': 'being-human-men-slim-fit-t-shirts-white-8905271470465',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24020',
        'product_path': '98A_0',
        'product_counter_part': '98B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24020-WHITE_1.jpg'
    },
    {
        'id': 99,
        'productSlug': 'being-human-men-slim-fit-t-shirts-green-foam-8905271471110',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24034',
        'product_path': '99A_0',
        'product_counter_part': '99B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24034-GREEN FOAM_1.jpg'
    },
    {
        'id': 100,
        'productSlug': 'being-human-men-slim-fit-t-shirts-coral-8905271471035',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24034',
        'product_path': '100A_0',
        'product_counter_part': '100B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Coral',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24034-CORAL_1.jpg'
    },
    {
        'id': 101,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271468998',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467893',
        'groupId': 'BHTS24019',
        'product_path': '101A_0',
        'product_counter_part': '101B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24019-BLACK_1.jpg'
    },
    {
        'id': 1101.0,
        'productSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467893',
        'productCounterPartSlug': 'being-human-men-none-t-shirts-black-8905271468998',
        'groupId': 'BHDI24099',
        'product_path': '101B_0',
        'product_counter_part': '101A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0]
    },
    {
        'id': 102,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469070',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467893',
        'groupId': 'BHTS24019',
        'product_path': '102A_0',
        'product_counter_part': '102B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24019-GREEN FOAM_1.jpg'
    },
    {
        'id': 103,
        'productSlug': 'being-human-men-none-t-shirts-pale-yellow-8905271442974',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24018',
        'product_path': '103A_0',
        'product_counter_part': '103B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-Pale Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24018-PALE YELLOW_1.jpg'
    },
    {
        'id': 104,
        'productSlug': 'being-human-men-none-t-shirts-mint-8905271442899',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24018',
        'product_path': '104A_0',
        'product_counter_part': '104B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-Mint',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24018-MINT_1.jpg'
    },
    {
        'id': 105,
        'productSlug': 'being-human-men-none-t-shirts-olive-8905271443872',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24033',
        'product_path': '105A_0',
        'product_counter_part': '105B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24033-OLIVE_1.jpg'
    },
    {
        'id': 106,
        'productSlug': 'being-human-men-none-t-shirts-mineral-red-8905271443797',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24033',
        'product_path': '106A_0',
        'product_counter_part': '106B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Mineral Red',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24033-MINERAL RED_1.jpg'
    },
    {
        'id': 107,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271444282',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24041',
        'product_path': '107A_0',
        'product_counter_part': '107B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24041-BLACK_1.jpg'
    },
    {
        'id': 108,
        'productSlug': 'being-human-men-none-t-shirts-mauve-haze-8905271444190',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24041',
        'product_path': '108A_0',
        'product_counter_part': '108B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Mauve Haze',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24041-MAUVE HAZE_1.jpg'
    },
    {
        'id': 109,
        'productSlug': 'being-human-men-slim-fit-t-shirts-mint-8905271470878',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24032',
        'product_path': '109A_0',
        'product_counter_part': '109B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Mint',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24032-MINT_1.jpg'
    },
    {
        'id': 110,
        'productSlug': 'being-human-men-none-t-shirts-ltblue-8905271443629',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24030',
        'product_path': '110A_0',
        'product_counter_part': '110B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Lt.Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24030-LT.BLUE_1.jpg'
    },
    {
        'id': 111,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271443711',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24030',
        'product_path': '111A_0',
        'product_counter_part': '111B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24030-GREEN FOAM_1.jpg'
    },
    {
        'id': 112,
        'productSlug': 'being-human-men-slim-fit-t-shirts-white-8905271469711',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24004',
        'product_path': '112A_0',
        'product_counter_part': '112B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24004-WHITE_1.jpg'
    },
    {
        'id': 113,
        'productSlug': 'being-human-men-slim-fit-t-shirts-black-8905271469988',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24007',
        'product_path': '113A_0',
        'product_counter_part': '113B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24007-BLACK_1.jpg'
    },
    {
        'id': 114,
        'productSlug': 'being-human-men-slim-fit-t-shirts-black-8905271469575',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24003',
        'product_path': '114A_0',
        'product_counter_part': '114B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24003-BLACK_1.jpg'
    },
    {
        'id': 115,
        'productSlug': 'being-human-men-slim-fit-t-shirts-white-8905271470953',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24032',
        'product_path': '115A_0',
        'product_counter_part': '115B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24032-WHITE_1.jpg'
    },
    {
        'id': 116,
        'productSlug': 'being-human-men-slim-fit-shirts-bluepink-8905271446194',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463672',
        'groupId': 'BHMSS24026',
        'product_path': '116A_0',
        'product_counter_part': '116B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Blue/Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24026-BLUE&PINK_1.jpg'
    },
    {
        'id': 117,
        'productSlug': 'being-human-men-slim-fit-shirts-bluegreen-8905271446118',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463672',
        'groupId': 'BHMSS24026',
        'product_path': '117A_0',
        'product_counter_part': '117B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Blue/Green',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24026-BLUE&GREEN_1.jpg'
    },
    {
        'id': 118,
        'productSlug': 'being-human-men-slim-fit-shirts-navy-8905271450450',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-grey-tone-8905271490753',
        'groupId': 'BHMSS24101',
        'product_path': '118A_0',
        'product_counter_part': '118B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24101-NAVY_1.jpg'
    },
    {
        'id': 1118,
        'productSlug': 'being-human-men-cropped-fit-denim-grey-tone-8905271490753',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-navy-8905271450450',
        'groupId': 'BHDI24062',
        'product_path': '118B_0',
        'product_counter_part': '118A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productCounterPartSizeTags': ['S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL'],
        'productCounterPartSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productTitle': 'Being Human Men Cropped Fit Denim-Grey Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24062-GREY TONE_1.jpg'
    },
    {
        'id': 119,
        'productSlug': 'being-human-men-slim-fit-shirts-dk-grey-8905271450696',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-grey-tone-8905271490753',
        'groupId': 'BHMSS24103',
        'product_path': '119A_0',
        'product_counter_part': '119B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Dk Grey',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24103-DK GREY_1.jpg'
    },
    {
        'id': 120,
        'productSlug': 'being-human-men-slim-fit-shirts-navy-8905271450856',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-grey-tone-8905271490753',
        'groupId': 'BHMSS24034',
        'product_path': '120A_0',
        'product_counter_part': '120B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24034-NAVY_1.jpg'
    },
    {
        'id': 121,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271450931',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-grey-tone-8905271490753',
        'groupId': 'BHMSS24034',
        'product_path': '121A_0',
        'product_counter_part': '121B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28', '30', '32', '34', '36', '38', '40'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMSS24034-WHITE_1.jpg'
    },
    {
        'id': 122,
        'productSlug': 'being-human-men-none-t-shirts-navy-8905271443124',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24021',
        'product_path': '122A_0',
        'product_counter_part': '122B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24021-NAVY_1.jpg'
    },
    {
        'id': 123,
        'productSlug': 'being-human-men-none-t-shirts-mauve-haze-8905271443155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24021',
        'product_path': '123A_0',
        'product_counter_part': '123B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-Mauve Haze',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24021-MAUVE HAZE_1.jpg'
    },
    {
        'id': 124,
        'productSlug': 'being-human-men-none-t-shirts-olive-8905271437727',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24110',
        'product_path': '124A_0',
        'product_counter_part': '124B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24110-OLIVE_1.jpg'
    },
    {
        'id': 1124,
        'productSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'productCounterPartSlug': 'being-human-men-none-t-shirts-olive-8905271437727',
        'groupId': 'BHDI24003',
        'product_path': '124B_0',
        'product_counter_part': '124A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productTitle': 'Being Human Men Slim Fit Denim-Lt.Grey Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24003-LT.GREY TONE_1.jpg'
    },
    {
        'id': 125,
        'productSlug': 'being-human-men-none-t-shirts-olive-8905271440505',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS 24058',
        'product_path': '125A_0',
        'product_counter_part': '125B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24058-OLIVE_1.jpg'
    },
    {
        'id': 126,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271440598',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS 24058',
        'product_path': '126A_0',
        'product_counter_part': '126B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[80.01, 94.13],
        [85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24058-WHITE_1.jpg'
    },
    {
        'id': 139,
        'productSlug': 'being-human-men-regular-fit-t-shirts-dusty-pink-8905271447375',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24078',
        'product_path': '139A_0',
        'product_counter_part': '139B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Dusty Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24078-DUSTY PINK_1.jpg'
    },
    {
        'id': 140,
        'productSlug': 'being-human-men-regular-fit-t-shirts-pale-yellow-8905271447603',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24078',
        'product_path': '140A_0',
        'product_counter_part': '140B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Pale Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24078-PALE YELLOW_1.jpg'
    },
    {
        'id': 141,
        'productSlug': 'being-human-men-regular-fit-t-shirts-aqua-8905271447207',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24078',
        'product_path': '141A_0',
        'product_counter_part': '141B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Aqua',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24078-AQUA_1.jpg'
    },
    {
        'id': 142,
        'productSlug': 'being-human-men-regular-fit-t-shirts-black-8905271447290',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24078',
        'product_path': '142A_0',
        'product_counter_part': '142B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [88.9,
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0,
            38.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24078-BLACK_1.jpg'
    },
    {
        'id': 143,
        'productSlug': 'being-human-men-regular-fit-t-shirts-navy-8905271447535',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24078',
        'product_path': '143A_0',
        'product_counter_part': '143B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24078-NAVY_1.jpg'
    },
    {
        'id': 144,
        'productSlug': 'being-human-men-regular-fit-t-shirts-peppermint-8905271447696',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24078',
        'product_path': '144A_0',
        'product_counter_part': '144B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Peppermint',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24078-PEPPERMINT_1.jpg'
    },
    {
        'id': 145,
        'productSlug': 'being-human-men-regular-fit-t-shirts-light-blue-8905271446576',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24001',
        'product_path': '145A_0',
        'product_counter_part': '145B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Light Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24001-LIGHT BLUE_1.jpg'
    },
    {
        'id': 146,
        'productSlug': 'being-human-men-regular-fit-t-shirts-green-foam-8905271446491',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24001',
        'product_path': '146A_0',
        'product_counter_part': '146B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24001-GREEN FOAM_1.jpg'
    },
    {
        'id': 147,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271438434',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24300',
        'product_path': '147A_0',
        'product_counter_part': '147B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24300-BLACK_1.jpg'
    },
    {
        'id': 148,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271438755',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24300',
        'product_path': '148A_0',
        'product_counter_part': '148B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24300-WHITE_1.jpg'
    },
    {
        'id': 149,
        'productSlug': 'being-human-men-none-t-shirts-sand-8905271438694',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24300',
        'product_path': '149A_0',
        'product_counter_part': '149B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Sand',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24300-SAND_1.jpg'
    },
    {
        'id': 150,
        'productSlug': 'being-human-men-none-t-shirts-coral-8905271438533',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24300',
        'product_path': '150A_0',
        'product_counter_part': '150B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Coral',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24300-CORAL_1.jpg'
    },
    {
        'id': 151,
        'productSlug': 'being-human-men-none-t-shirts-lemon-8905271438571',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24300',
        'product_path': '151A_0',
        'product_counter_part': '151B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Lemon',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24300-LEMON_1.jpg'
    },
    {
        'id': 152,
        'productSlug': 'being-human-men-none-t-shirts-navy-8905271438342',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24116',
        'product_path': '152A_0',
        'product_counter_part': '152B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24116-NAVY_1.jpg'
    },
    {
        'id': 153,
        'productSlug': 'being-human-men-none-t-shirts-coral-8905271438311',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24116',
        'product_path': '153A_0',
        'product_counter_part': '153B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [88.9,
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0,
            38.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Coral',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24116-CORAL_1.jpg'
    },
    {
        'id': 154,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271475958',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24065',
        'product_path': '154A_0',
        'product_counter_part': '154B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[80.01, 94.13],
        [85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24065-BLACK_6.jpg'
    },
    {
        'id': 155,
        'productSlug': 'being-human-men-none-t-shirts-vivid-blue-8905271476047',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24065',
        'product_path': '155A_0',
        'product_counter_part': '155B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Vivid Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24065-VIVID BLUE_1.jpg'
    },
    {
        'id': 156,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271439721',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24113',
        'product_path': '156A_0',
        'product_counter_part': '156B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24113-WHITE_1.jpg'
    },
    {
        'id': 157,
        'productSlug': 'being-human-men-slim-fit-shirts-forest-green-8905271439479',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24113',
        'product_path': '157A_0',
        'product_counter_part': '157B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Forest Green',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24113-FOREST GREEN_1.jpg'
    },
    {
        'id': 158.0,
        'productSlug': 'being-human-men-slim-fit-shirts-navy-8905271439646',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24113',
        'product_path': '158A_0',
        'product_counter_part': '158B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]]
    },
    {
        'id': 159,
        'productSlug': 'being-human-men-slim-fit-shirts-camel-8905271439318',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24113',
        'product_path': '159A_0',
        'product_counter_part': '159B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Camel',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24113-CAMEL_1.jpg'
    },
    {
        'id': 160,
        'productSlug': 'being-human-men-slim-fit-shirts-coffee-8905271439394',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24113',
        'product_path': '160A_0',
        'product_counter_part': '160B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Coffee',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24113-COFFEE_1.jpg'
    },
    {
        'id': 161,
        'productSlug': 'being-human-men-slim-fit-shirts-midnight-blue-8905271439561',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24113',
        'product_path': '161A_0',
        'product_counter_part': '161B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Midnight Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24113-MIDNIGHT BLUE_1.jpg'
    },
    {
        'id': 162,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271439233',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24113',
        'product_path': '162A_0',
        'product_counter_part': '162B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24113-BLACK_1.jpg'
    },
    {
        'id': 163,
        'productSlug': 'being-human-men-slim-fit-shirts-ltblue-8905271438991',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHLSP24114',
        'product_path': '163A_0',
        'product_counter_part': '163B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24,
            43.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Lt.Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHLSP24114-LT.BLUE_1.jpg'
    },
    {
        'id': 164,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271438922',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHLSP24114',
        'product_path': '164A_0',
        'product_counter_part': '164B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHLSP24114-BLACK_1.jpg'
    },
    {
        'id': 174,
        'productSlug': 'being-human-men-regular-fit-t-shirts-chalk-8905271446651',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24002',
        'product_path': '174A_0',
        'product_counter_part': '174B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Chalk',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24002-CHALK_1.jpg'
    },
    {
        'id': 175,
        'productSlug': 'being-human-men-regular-fit-t-shirts-light-blue-8905271446736',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24002',
        'product_path': '175A_0',
        'product_counter_part': '175B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Light Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24002-LIGHT BLUE_1.jpg'
    },
    {
        'id': 176,
        'productSlug': 'being-human-men-regular-fit-t-shirts-yellow-8905271454502',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24043',
        'product_path': '176A_0',
        'product_counter_part': '176B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24043-YELLOW_1.jpg'
    },
    {
        'id': 177,
        'productSlug': 'being-human-men-regular-fit-t-shirts-blue-8905271454342',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24043',
        'product_path': '177A_0',
        'product_counter_part': '177B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24043-BLUE_1.jpg'
    },
    {
        'id': 178,
        'productSlug': 'being-human-men-regular-fit-t-shirts-mauve-blue-8905271454410',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24043',
        'product_path': '178A_0',
        'product_counter_part': '178B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Mauve Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24043-MAUVE BLUE_6.jpg'
    },
    {
        'id': 179,
        'productSlug': 'being-human-men-semi-fit-t-shirts-powder-pink-8905271470687',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24022',
        'product_path': '179A_0',
        'product_counter_part': '179B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Semi Fit T-Shirts-Powder Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24022-POWDER PINK_1.jpg'
    },
    {
        'id': 1179,
        'productSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'productCounterPartSlug': 'being-human-men-semi-fit-t-shirts-powder-pink-8905271470687',
        'groupId': 'BHDI24099',
        'product_path': '179B_0',
        'product_counter_part': '179A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42'],
        'productSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productTitle': 'Being Human Men Skinnyfit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24099-MID TONE_1.jpg'
    },
    {
        'id': 180,
        'productSlug': 'being-human-men-semi-fit-t-shirts-green-foam-8905271470601',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24022',
        'product_path': '180A_0',
        'product_counter_part': '180B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Semi Fit T-Shirts-Green Foam',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24022-GREEN FOAM_1.jpg'
    },
    {
        'id': 181,
        'productSlug': 'being-human-men-slim-fit-t-shirts-white-8905271469872',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24005',
        'product_path': '181A_0',
        'product_counter_part': '181B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24005-WHITE_1.jpg'
    },
    {
        'id': 182,
        'productSlug': 'being-human-men-slim-fit-t-shirts-ltblue-8905271469773',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24005',
        'product_path': '182A_0',
        'product_counter_part': '182B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men Slim Fit T-Shirts-Lt.Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24005-LT.BLUE_6.jpg'
    },
    {
        'id': 183,
        'productSlug': 'being-human-men-none-t-shirts-dusty-pink-8905271468097',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24092',
        'product_path': '183A_0',
        'product_counter_part': '183B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men None T-Shirts-Dusty Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24092-DUSTY PINK_1.jpg'
    },
    {
        'id': 184,
        'productSlug': 'being-human-men-none-t-shirts-yellow-8905271468394',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24092',
        'product_path': '184A_0',
        'product_counter_part': '184B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men None T-Shirts-Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24092-YELLOW_1.jpg'
    },
    {
        'id': 185,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271468325',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24092',
        'product_path': '185A_0',
        'product_counter_part': '185B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24092-WHITE_1.jpg'
    },
    {
        'id': 187,
        'productSlug': 'being-human-men-none-t-shirts-pista-8905271468240',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24092',
        'product_path': '187A_0',
        'product_counter_part': '187B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men None T-Shirts-Pista',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24092-PISTA_1.jpg'
    },
    {
        'id': 188,
        'productSlug': 'being-human-men-none-t-shirts-navy-8905271468172',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24092',
        'product_path': '188A_0',
        'product_counter_part': '188B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men None T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24092-NAVY_1.jpg'
    },
    {
        'id': 189,
        'productSlug': 'being-human-men-regular-fit-t-shirts-olive-8905271454113',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24005',
        'product_path': '189A_0',
        'product_counter_part': '189B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24005-OLIVE_1.jpg'
    },
    {
        'id': 190,
        'productSlug': 'being-human-men-regular-fit-t-shirts-light-blue-8905271452515',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24034',
        'product_path': '190A_0',
        'product_counter_part': '190B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Light Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24034-LIGHT BLUE_1.jpg'
    },
    {
        'id': 191,
        'productSlug': 'being-human-men-regular-fit-t-shirts-deep-ocean-8905271452430',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24034',
        'product_path': '191A_0',
        'product_counter_part': '191B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24034-DEEP OCEAN_1.jpg'
    },
    {
        'id': 192,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271453079',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24025',
        'product_path': '192A_0',
        'product_counter_part': '192B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[76.2, 89.64],
        [81.28, 95.63],
        [86.36, 101.6],
        [91.44, 107.57],
        [99.06, 116.54],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47],
        [119.38, 140.44]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24025-WHITE_1.jpg'
    },
    {
        'id': 193,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271453710',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24006',
        'product_path': '193A_0',
        'product_counter_part': '193B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24006-WHITE_1.jpg'
    },
    {
        'id': 194,
        'productSlug': 'being-human-men-regular-fit-t-shirts-black-8905271453635',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24006',
        'product_path': '194A_0',
        'product_counter_part': '194B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24006-BLACK_1.jpg'
    },
    {
        'id': 195,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271488996',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24046',
        'product_path': '195A_0',
        'product_counter_part': '195B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24,
            43.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24046-BLACK_1.jpg'
    },
    {
        'id': 196,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271450122',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24072',
        'product_path': '196A_0',
        'product_counter_part': '196B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24072-WHITE_1.jpg'
    },
    {
        'id': 197,
        'productSlug': 'being-human-men-slim-fit-shirts-black-8905271450047',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24072',
        'product_path': '197A_0',
        'product_counter_part': '197B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24072-BLACK_1.jpg'
    },
    {
        'id': 198,
        'productSlug': 'being-human-men-slim-fit-shirts-grey-8905271460619',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24110',
        'product_path': '198A_0',
        'product_counter_part': '198B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Grey',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24110-GREY_1.jpg'
    },
    {
        'id': 199,
        'productSlug': 'being-human-men-slim-fit-shirts-green-8905271455776',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'groupId': 'BHMLS24037',
        'product_path': '200A_0',
        'product_counter_part': '200B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [101.6,
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78,
            44.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Green',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24037-GREEN_1.jpg'
    },
    {
        'id': 200,
        'productSlug': 'being-human-men-slim-fit-shirts-blue-8905271455691',
        'productCounterPartSlug': 'being-human-men-jogger-fit-denim-light-tone-8905271467045',
        'product_path': '199A_0',
        'product_counter_part': '199B_0',
        'groupId': 'BHMLS24037',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [99.06,
            104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24,
            43.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46],
        [121.92, 143.43]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24037-BLUE_1.jpg'
    },
    {
        'id': 203,
        'productSlug': 'being-human-men-none-t-shirts-deep-ocean-8905271469360',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24040',
        'product_path': '203A_0',
        'product_counter_part': '203B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [88.9,
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0,
            38.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24040-DEEP OCEAN_6.jpg'
    },
    {
        'id': 204,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271469308',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24040',
        'product_path': '204A_0',
        'product_counter_part': '204B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24040-BLACK_1.jpg'
    },
    {
        'id': 205,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271443377',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24024',
        'product_path': '205A_0',
        'product_counter_part': '205B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24024-BLACK_1.jpg'
    },
    {
        'id': 206,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271443216',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24024',
        'product_path': '206A_0',
        'product_counter_part': '206B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24024-WHITE_1.jpg'
    },
    {
        'id': 207,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271444435',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24042',
        'product_path': '207A_0',
        'product_counter_part': '207B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24042-WHITE_1.jpg'
    },
    {
        'id': 208,
        'productSlug': 'being-human-men-none-t-shirts-charcoal-8905271444350',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24042',
        'product_path': '208A_0',
        'product_counter_part': '208B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [88.9,
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127.0,
            38.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Charcoal',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24042-CHARCOAL_1.jpg'
    },
    {
        'id': 209,
        'productSlug': 'being-human-men-none-t-shirts-snow-white-8905271472360',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24358',
        'product_path': '209A_0',
        'product_counter_part': '209B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Snow White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24358-SNOW WHITE_6.jpg'
    },
    {
        'id': 210,
        'productSlug': 'being-human-men-none-t-shirts-black-8905271440680',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24063',
        'product_path': '210A_0',
        'product_counter_part': '210B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24063-BLACK_1.jpg'
    },
    {
        'id': 211,
        'productSlug': 'being-human-men-none-t-shirts-wine-8905271440758',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24063',
        'product_path': '211A_0',
        'product_counter_part': '211B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[80.01, 94.13],
        [85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93]],
        'productTitle': 'Being Human Men None T-Shirts-Wine',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24063-WINE_6.jpg'
    },
    {
        'id': 212,
        'productSlug': 'being-human-men-none-crew-neck-black-8905271490111',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24064',
        'product_path': '212A_0',
        'product_counter_part': '212B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            134.62,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[80.01, 94.13],
        [85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93]],
        'productTitle': 'Being Human Men None Crew Neck-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24064-BLACK_1.jpg'
    },
    {
        'id': 213,
        'productSlug': 'being-human-men-none-crew-neck-black-8905271489870',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24043',
        'product_path': '213A_0',
        'product_counter_part': '213B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            134.62,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[80.01, 94.13],
        [85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [107.95, 127.0],
        [113.03, 132.97],
        [118.11, 138.96],
        [123.19, 144.93]],
        'productTitle': 'Being Human Men None Crew Neck-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24043-BLACK_1.jpg'
    },
    {
        'id': 214,
        'productSlug': 'being-human-men-none-t-shirts-ltblue-8905271443605',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS 24029',
        'product_path': '214A_0',
        'product_counter_part': '214B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Lt.Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24029-LT.BLUE_1.jpg'
    },
    {
        'id': 221,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271452676',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-light-tone-8905271464167',
        'groupId': 'BHP24051',
        'product_path': '221A_0',
        'product_counter_part': '221B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24051-WHITE_1.jpg'
    },
    {
        'id': 1221,
        'productSlug': 'being-human-men-cropped-fit-denim-light-tone-8905271464167',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-white-8905271452676',
        'groupId': 'BHDI24064',
        'product_path': '221B_0',
        'product_counter_part': '221A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Light Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24064-LIGHT TONE_1.jpg'
    },
    {
        'id': 222,
        'productSlug': 'being-human-men-regular-fit-t-shirts-yellow-8905271452737',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463863',
        'groupId': 'BHP24051',
        'product_path': '222A_0',
        'product_counter_part': '222B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24051-YELLOW_6.jpg'
    },
    {
        'id': 1222,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463863',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-yellow-8905271452737',
        'groupId': 'BHDI24056',
        'product_path': '222B_0',
        'product_counter_part': '222A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24056-MID TONE_1.jpg'
    },
    {
        'id': 223,
        'productSlug': 'being-human-men-regular-fit-t-shirts-navy-8905271452591',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24051',
        'product_path': '223A_0',
        'product_counter_part': '223B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Navy',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24051-NAVY_1.jpg'
    },
    {
        'id': 224,
        'productSlug': 'being-human-men-regular-fit-t-shirts-midlight-blue-8905271458319',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271476276',
        'groupId': 'BHP24045',
        'product_path': '224A_0',
        'product_counter_part': '224B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Midlight Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24045-MIDLIGHT BLUE_1.jpg'
    },
    {
        'id': 1224,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271476276',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-midlight-blue-8905271458319',
        'groupId': 'BHDI24163',
        'product_path': '224B_0',
        'product_counter_part': '224A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24163-MID TONE_1.jpg'
    },
    {
        'id': 225,
        'productSlug': 'being-human-men-regular-fit-t-shirts-ltgrey-8905271458234',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477280',
        'groupId': 'BHP24045',
        'product_path': '225A_0',
        'product_counter_part': '225B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Lt.Grey',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24045-LT.GREY_1.jpg'
    },
    {
        'id': 1225,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477280',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-ltgrey-8905271458234',
        'groupId': 'BHDI24178',
        'product_path': '225B_0',
        'product_counter_part': '225A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24178-MID TONE_1.jpg'
    },
    {
        'id': 226,
        'productSlug': 'being-human-men-regular-fit-t-shirts-red-8905271458395',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24045',
        'product_path': '226A_0',
        'product_counter_part': '226B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Red',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24045-RED_1.jpg'
    },
    {
        'id': 227.0,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271454267',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-light-tone-8905271476511',
        'groupId': 'BHP24008',
        'product_path': '227A_0',
        'product_counter_part': '227B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]]
    },
    {
        'id': 1227,
        'productSlug': 'being-human-men-cropped-fit-denim-light-tone-8905271476511',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-white-8905271454267',
        'groupId': 'BHDI24166',
        'product_path': '227B_0',
        'product_counter_part': '227A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Light Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24166-LIGHT TONE_1.jpg'
    },
    {
        'id': 228.0,
        'productSlug': 'being-human-men-regular-fit-t-shirts-mineral-red-8905271454199',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463023',
        'groupId': 'BHP24008',
        'product_path': '228A_0',
        'product_counter_part': '228B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]]
    },
    {
        'id': 1228,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271463023',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-mineral-red-8905271454199',
        'groupId': 'BHDI24020',
        'product_path': '228B_0',
        'product_counter_part': '228A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24020-MID TONE_1.jpg'
    },
    {
        'id': 231,
        'productSlug': 'being-human-men-none-t-shirts-white-8905271437864',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24112',
        'product_path': '231A_0',
        'product_counter_part': '231B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24112-WHITE_6.jpg'
    },
    {
        'id': 232,
        'productSlug': 'being-human-men-none-t-shirts-snow-white-8905271471431',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24351',
        'product_path': '232A_0',
        'product_counter_part': '232B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': 'Being Human Men None T-Shirts-Snow White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24351-SNOW WHITE_6.jpg'
    },
    {
        'id': 233,
        'productSlug': 'being-human-men-none-t-shirts-snowwhite-8905271471585',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24352',
        'product_path': '233A_0',
        'product_counter_part': '233B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-Snowwhite',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24352-SNOWWHITE_6.jpg'
    },
    {
        'id': 234,
        'productSlug': 'being-human-men-none-t-shirts-black-beauty-8905271471554',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24352',
        'product_path': '234A_0',
        'product_counter_part': '234B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None T-Shirts-Black Beauty',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24352-BLACK BEAUTY_6.jpg'
    },
    {
        'id': 235,
        'productSlug': 'being-human-men-none-crew-neck-powder-pink-8905271483700',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24017',
        'product_path': '235A_0',
        'product_counter_part': '235B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            134.62,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None Crew Neck-Powder Pink',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24017-POWDER PINK_6.jpg'
    },
    {
        'id': 236,
        'productSlug': 'being-human-men-none-crew-neck-black-8905271489887',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467946',
        'groupId': 'BHTS24050',
        'product_path': '236A_0',
        'product_counter_part': '236B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            137.16,
            41.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[78.74, 92.63],
        [83.82, 98.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men None Crew Neck-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24050-BLACK_1.jpg'
    },
    {
        'id': 238,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271450436',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271467632',
        'groupId': 'BHMLS24117',
        'product_path': '238A_0',
        'product_counter_part': '238B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94],
        [125.73, 147.93]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24117-WHITE_1.jpg'
    },
    {
        'id': 1238,
        'productSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271467632',
        'productCounterPartSlug': 'being-human-men-slim-fit-shirts-white-8905271450436',
        'groupId': 'BHDI24039',
        'product_path': '238B_0',
        'product_counter_part': '238A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94],
        [125.73, 147.93]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Dark Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24039-DARK TONE_1.jpg'
    },
    {
        'id': 239,
        'productSlug': 'being-human-men-slim-fit-shirts-ltblue-8905271450283',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271467632',
        'groupId': 'BHMLS24117',
        'product_path': '239A_0',
        'product_counter_part': '239B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94],
        [125.73, 147.93]],
        'productTitle': 'Being Human Men Slim Fit Shirts-Lt.Blue',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24117-LT.BLUE_1.jpg'
    },
    {
        'id': 240,
        'productSlug': 'being-human-men-slim-fit-shirts-white-8905271450436',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-dark-tone-8905271467632',
        'groupId': 'BHMLS24117',
        'product_path': '240A_0',
        'product_counter_part': '240B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7,
            42.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[82.55, 97.13],
        [87.63, 103.1],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94],
        [125.73, 147.93]],
        'productTitle': 'Being Human Men Slim Fit Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHMLS24117-WHITE_1.jpg'
    },
    {
        'id': 241.0,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271454267',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475156',
        'groupId': 'BHP240101',
        'product_path': '241A_0',
        'product_counter_part': '241B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]]
    },
    {
        'id': 1241,
        'productSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271475156',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-white-8905271454267',
        'groupId': 'BHDI24065',
        'product_path': '241B_0',
        'product_counter_part': '241A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Mid Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24065-MID TONE_1.jpg'
    },
    {
        'id': 242.0,
        'productSlug': 'being-human-men-regular-fit-t-shirts-mineral-red-8905271454199',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-black-tone-8905271464099',
        'groupId': 'BHP240101',
        'product_path': '242A_0',
        'product_counter_part': '242B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]]
    },
    {
        'id': 1242,
        'productSlug': 'being-human-men-cropped-fit-denim-black-tone-8905271464099',
        'productCounterPartSlug': 'being-human-men-regular-fit-t-shirts-mineral-red-8905271454199',
        'groupId': 'BHDI24063',
        'product_path': '242B_0',
        'product_counter_part': '242A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['28', '30', '32', '34', '36', '38', '40', '42', '44'],
        'productSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productCounterPartSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productCounterPartSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productTitle': 'Being Human Men Cropped Fit Denim-Black Tone',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHDI24063-BLACK TONE_1.jpg'
    },
    {
        'id': 243,
        'productSlug': 'being-human-men-regular-fit-t-shirts-olive-8905271454663',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24052',
        'product_path': '243A_0',
        'product_counter_part': '243B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Olive',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24052-OLIVE_1.jpg'
    },
    {
        'id': 244,
        'productSlug': 'being-human-men-regular-fit-t-shirts-black-8905271454588',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-mid-tone-8905271477013',
        'groupId': 'BHP24052',
        'product_path': '244A_0',
        'product_counter_part': '244B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-Black',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24052-BLACK_1.jpg'
    },
    {
        'id': 245,
        'productSlug': 'being-human-men-none-t-shirts-deep-ocean-8905271440260',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24049',
        'product_path': '245A_0',
        'product_counter_part': '245B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54,
            39.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Deep Ocean',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24049-DEEP OCEAN_5.jpg'
    },
    {
        'id': 246.0,
        'productSlug': 'eing-human-men-none-t-shirts-yellow-8905271440413',
        'productCounterPartSlug': 'being-human-men-slim-fit-denim-ltgrey-tone-8905271462231',
        'groupId': 'BHTS24049',
        'product_path': '246A_0',
        'product_counter_part': '246B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        'productSizeValues': [93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127.0,
            132.08,
            40.0],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[85.09, 100.1],
        [87.63, 103.1],
        [90.17, 106.07],
        [92.71, 109.07],
        [97.79, 115.04],
        [105.41, 124.0],
        [110.49, 130.0],
        [115.57, 135.97],
        [120.65, 141.94]],
        'productTitle': 'Being Human Men None T-Shirts-Yellow',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24049-YELLOW_1.jpg',
    }];
export const beingHumansProductList = [
    {
        id: 1,
        productSlug: "being-human-men-none-t-shirts-green-foam-8905271469155",
        src: bh1,
        name: "being-human-men-none-t-shirts-green-foam-8905271469155",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24031",
        product_path: "BHTS24031_0",
        product_counter_part: "BHDI24099_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 2,
        productSlug: "being-human-men-none-t-shirts-jet-black-8905271445012",
        src: bh2,
        name: "being-human-men-none-t-shirts-jet-black-8905271445012",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-slim-fit-denim-mid-tone-8905271476627",
        groupId: "BHTS24079",
        product_path: "BHT24079_0",
        product_counter_part: "BHDI24168_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 3,
        src: SaveTheTreesMen,
        product_path: "BHMSS24002_0",
        product_counter_part: "BHDI24143_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000122",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 4,
        productSlug: "being-human-men-regular-fit-t-shirts-charcoal-8905271451495",
        src: bh4,
        name: "being-human-men-regular-fit-t-shirts-charcoal-8905271451495",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24020",
        product_path: "BHP24020_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 5,
        productSlug: "being-human-men-regular-fit-t-shirts-navy-8905271451570",
        src: bh5,
        name: "being-human-men-regular-fit-t-shirts-navy-8905271451570",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24020",
        product_path: "BHP24020_1",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 6,
        productSlug: "being-human-men-regular-fit-t-shirts-white-8905271453178",
        src: bh6,
        name: "being-human-men-regular-fit-t-shirts-white-8905271453178",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24028",
        product_path: "BHP24028_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 7,
        productSlug: "being-human-men-regular-fit-t-shirts-black-8905271453093",
        src: bh7,
        name: "being-human-men-regular-fit-t-shirts-black-8905271453093",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24028",
        product_path: "BHP24028_1",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 8,
        productSlug: "being-human-men-regular-fit-t-shirts-ltgrey-8905271458098",
        src: bh8,
        name: "being-human-men-regular-fit-t-shirts-ltgrey-8905271458098",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24035",
        product_path: "BHP24035_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 9,
        productSlug: "being-human-men-regular-fit-t-shirts-light-blue-8905271451891",
        src: bh9,
        name: "being-human-men-regular-fit-t-shirts-light-blue-8905271451891",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24038",
        product_path: "BHP24038_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 10,
        productSlug: "being-human-men-regular-fit-t-shirts-pastel-pink-8905271472452",
        src: bh10,
        name: "being-human-men-regular-fit-t-shirts-pastel-pink-8905271472452",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24046",
        product_path: "BHP24046_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 11,
        src: SaveTheTreesMen,
        product_path: "BHMSS24002CO_0",
        product_counter_part: "BHD124059_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000384",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 12,
        productSlug: "being-human-men-slim-fit-shirts-deep-ocean-8905271459491",
        src: bh12,
        name: "being-human-men-slim-fit-shirts-deep-ocean-8905271459491",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24014",
        product_path: "BHMLS24014_0",
        product_counter_part: "BHDI24093_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 13,
        src: SaveTheTreesMen,
        product_path: "BHFLS24001_0",
        product_counter_part: "BHKTP24005_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000128",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 14,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271445876",
        src: bh14,
        name: "being-human-men-slim-fit-shirts-black-8905271445876",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-slim-straight-fit-denim-mid-tone-8905271474401",
        groupId: "BHMLS24017",
        product_path: "BHMLS24017_0",
        product_counter_part: "BHDI24137_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                73.66,
                86.66
            ],
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                96.52,
                113.55
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 15,
        productSlug: "being-human-men-regular-fit-t-shirts-forest-green-8905271453499",
        src: bh15,
        name: "being-human-men-regular-fit-t-shirts-forest-green-8905271453499",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-boot-cut-fit-denim-light-tone-8905271467366",
        groupId: "BHP24003",
        product_path: "BHP24003_0",
        product_counter_part: "BHDI24030_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                85.09,
                100.11
            ],
            [
                87.63,
                103.09
            ],
            [
                90.17,
                106.08
            ],
            [
                95.25,
                112.06
            ],
            [
                102.87,
                121.02
            ],
            [
                107.95,
                127
            ],
            [
                113.03,
                132.98
            ],
            [
                118.11,
                138.95
            ]
        ]
    },
    {
        id: 16,
        productSlug: "being-human-men-slim-fit-shirts-whitered-8905271459736",
        src: bh16,
        name: "being-human-men-slim-fit-shirts-whitered-8905271459736",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24002",
        product_path: "BHMSS24002_0",
        product_counter_part: "BHDI24059_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 17,
        src: SaveTheTreesMen,
        product_path: "BHMSS24013_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000380",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 18,
        src: SaveTheTreesMen,
        product_path: "BHMSS24013_1",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000550",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 19,
        productSlug: "being-human-men-slim-fit-shirts-greenblue-8905271446279",
        src: bh19,
        name: "being-human-men-slim-fit-shirts-greenblue-8905271446279",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-black-tone-8905271466130",
        groupId: "BHMSS24027",
        product_path: "BHMSS24027_0",
        product_counter_part: "BHDSI24195_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 20,
        productSlug: "being-human-men-none-t-shirts-coffee-8905271442493",
        src: bh20,
        name: "being-human-men-none-t-shirts-coffee-8905271442493",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24012",
        product_path: "20A_0",
        product_counter_part: "20B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 21,
        productSlug: "being-human-men-none-t-shirts-midnight-blue-8905271442400",
        src: bh21,
        name: "being-human-men-none-t-shirts-midnight-blue-8905271442400",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24012",
        product_path: "21A_0",
        product_counter_part: "21B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 22,
        productSlug: "being-human-men-none-t-shirts-charcoal-8905271442578",
        src: bh22,
        name: "being-human-men-none-t-shirts-charcoal-8905271442578",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24014",
        product_path: "22A_0",
        product_counter_part: "22B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 23,
        productSlug: "being-human-men-none-t-shirts-mineral-red-8905271442653",
        src: bh23,
        name: "being-human-men-none-t-shirts-mineral-red-8905271442653",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24014",
        product_path: "23A_0",
        product_counter_part: "23B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 24,
        productSlug: "being-human-men-none-t-shirts-powder-pink-8905271444046",
        src: bh24,
        name: "being-human-men-none-t-shirts-powder-pink-8905271444046",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24037",
        product_path: "24A_0",
        product_counter_part: "24B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 25,
        productSlug: "being-human-men-none-t-shirts-white-8905271444114",
        src: bh25,
        name: "being-human-men-none-t-shirts-white-8905271444114",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24037",
        product_path: "25A_0",
        product_counter_part: "25B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 26,
        productSlug: "being-human-men-slim-fit-t-shirts-chalk-8905271469902",
        src: bh26,
        name: "being-human-men-slim-fit-t-shirts-chalk-8905271469902",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24006",
        product_path: "26A_0",
        product_counter_part: "26B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 27,
        productSlug: "being-human-men-slim-fit-t-shirts-black-8905271470076",
        src: bh27,
        name: "being-human-men-slim-fit-t-shirts-black-8905271470076",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24009",
        product_path: "27A_0",
        product_counter_part: "27B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 28,
        productSlug: "being-human-men-slim-fit-t-shirts-green-lake-8905271470151",
        src: bh28,
        name: "being-human-men-slim-fit-t-shirts-green-lake-8905271470151",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24009",
        product_path: "28A_0",
        product_counter_part: "28B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 29,
        productSlug: "being-human-men-none-t-shirts-navy-8905271444770",
        src: bh29,
        name: "being-human-men-none-t-shirts-navy-8905271444770",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24057",
        product_path: "29A_0",
        product_counter_part: "29B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 30,
        productSlug: "being-human-men-none-t-shirts-deep-ocean-8905271444695",
        src: bh30,
        name: "being-human-men-none-t-shirts-deep-ocean-8905271444695",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24057",
        product_path: "30A_0",
        product_counter_part: "30B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 31,
        productSlug: "being-human-men-none-t-shirts-chalk-8905271442738",
        src: bh31,
        name: "being-human-men-none-t-shirts-chalk-8905271442738",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24015",
        product_path: "31A_0",
        product_counter_part: "31B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 32,
        productSlug: "being-human-men-none-t-shirts-deep-ocean-8905271442813",
        src: bh32,
        name: "being-human-men-none-t-shirts-deep-ocean-8905271442813",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24015",
        product_path: "32A_0",
        product_counter_part: "32B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 33,
        src: SaveTheTreesMen,
        product_path: "33A_0",
        product_counter_part: "33B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 34,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271460213",
        src: bh34,
        name: "being-human-men-slim-fit-shirts-black-8905271460213",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24014",
        product_path: "34A_0",
        product_counter_part: "34B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 35,
        productSlug: "being-human-men-slim-fit-shirts-deep-ocean-8905271460299",
        src: bh35,
        name: "being-human-men-slim-fit-shirts-deep-ocean-8905271460299",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24014",
        product_path: "35A_0",
        product_counter_part: "35B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 36,
        productSlug: "being-human-men-slim-fit-shirts-yellowcharcoal-8905271460138",
        src: bh36,
        name: "being-human-men-slim-fit-shirts-yellowcharcoal-8905271460138",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24010",
        product_path: "36A_0",
        product_counter_part: "36B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 37,
        productSlug: "being-human-men-slim-fit-shirts-ltbluecharcoal-8905271460053",
        src: bh37,
        name: "being-human-men-slim-fit-shirts-ltbluecharcoal-8905271460053",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24010",
        product_path: "37A_0",
        product_counter_part: "37B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 38,
        src: SaveTheTreesMen,
        product_path: "38A_0",
        product_counter_part: "38B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 39,
        src: SaveTheTreesMen,
        product_path: "39A_0",
        product_counter_part: "39B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 40,
        src: SaveTheTreesMen,
        product_path: "40A_0",
        product_counter_part: "40B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 41,
        src: SaveTheTreesMen,
        product_path: "41A_0",
        product_counter_part: "41B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 42,
        productSlug: "being-human-men-slim-fit-shirts-chalknavy-8905271483267",
        src: bh42,
        name: "being-human-men-slim-fit-shirts-chalknavy-8905271483267",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24016",
        product_path: "42A_0",
        product_counter_part: "42B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 43,
        productSlug: "being-human-men-slim-fit-shirts-navyolive-8905271483342",
        src: bh43,
        name: "being-human-men-slim-fit-shirts-navyolive-8905271483342",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24016",
        product_path: "43A_0",
        product_counter_part: "43B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 44,
        productSlug: "being-human-men-none-t-shirts-mineral-red-8905271442325",
        src: bh44,
        name: "being-human-men-none-t-shirts-mineral-red-8905271442325",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24011",
        product_path: "44A_0",
        product_counter_part: "44B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 45,
        productSlug: "being-human-men-none-t-shirts-olive-8905271442257",
        src: bh45,
        name: "being-human-men-none-t-shirts-olive-8905271442257",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24011",
        product_path: "45A_0",
        product_counter_part: "45B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 46,
        productSlug: "being-human-men-none-t-shirts-black-8905271443476",
        src: bh46,
        name: "being-human-men-none-t-shirts-black-8905271443476",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24025",
        product_path: "46A_0",
        product_counter_part: "46B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 47,
        productSlug: "being-human-men-none-t-shirts-white-8905271443391",
        src: bh47,
        name: "being-human-men-none-t-shirts-white-8905271443391",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24025",
        product_path: "47A_0",
        product_counter_part: "47B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 48,
        productSlug: "being-human-men-regular-fit-t-shirts-ltgrey-8905271457534",
        src: bh48,
        name: "being-human-men-regular-fit-t-shirts-ltgrey-8905271457534",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24012",
        product_path: "48A_0",
        product_counter_part: "48B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 49,
        productSlug: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457459",
        src: bh49,
        name: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457459",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24012",
        product_path: "49A_0",
        product_counter_part: "49B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 50,
        productSlug: "being-human-men-regular-fit-t-shirts-charcoal-8905271457619",
        src: bh50,
        name: "being-human-men-regular-fit-t-shirts-charcoal-8905271457619",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24013",
        product_path: "50A_0",
        product_counter_part: "50B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 51,
        productSlug: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457695",
        src: bh51,
        name: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457695",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24013",
        product_path: "51A_0",
        product_counter_part: "51B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 52,
        productSlug: "being-human-men-regular-fit-t-shirts-light-blue-8905271451655",
        src: bh52,
        name: "being-human-men-regular-fit-t-shirts-light-blue-8905271451655",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24022",
        product_path: "52A_0",
        product_counter_part: "52B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 53,
        productSlug: "being-human-men-regular-fit-t-shirts-snow-white-8905271451730",
        src: bh53,
        name: "being-human-men-regular-fit-t-shirts-snow-white-8905271451730",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24022",
        product_path: "53A_0",
        product_counter_part: "53B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 54,
        productSlug: "being-human-men-regular-fit-t-shirts-midnight-blue-8905271451815",
        src: bh54,
        name: "being-human-men-regular-fit-t-shirts-midnight-blue-8905271451815",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24024",
        product_path: "54A_0",
        product_counter_part: "54B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 55,
        productSlug: "being-human-men-regular-fit-t-shirts-white-8905271452133",
        src: bh55,
        name: "being-human-men-regular-fit-t-shirts-white-8905271452133",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24041",
        product_path: "55A_0",
        product_counter_part: "55B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 56,
        productSlug: "being-human-men-regular-fit-t-shirts-navy-8905271452058",
        src: bh56,
        name: "being-human-men-regular-fit-t-shirts-navy-8905271452058",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24041",
        product_path: "56A_0",
        product_counter_part: "56B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 57,
        productSlug: "being-human-men-regular-fit-t-shirts-charcoal-8905271453413",
        src: bh57,
        name: "being-human-men-regular-fit-t-shirts-charcoal-8905271453413",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-boot-cut-fit-denim-light-tone-8905271467366",
        groupId: "BHP24003",
        product_path: "57A_0",
        product_counter_part: "57B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                85.09,
                100.11
            ],
            [
                87.63,
                103.09
            ],
            [
                90.17,
                106.08
            ],
            [
                95.25,
                112.06
            ],
            [
                102.87,
                121.02
            ],
            [
                107.95,
                127
            ],
            [
                113.03,
                132.98
            ],
            [
                118.11,
                138.95
            ]
        ]
    },
    {
        id: 58,
        productSlug: "being-human-men-slim-fit-shirts-whiteyellow-8905271456117",
        src: bh58,
        name: "being-human-men-slim-fit-shirts-whiteyellow-8905271456117",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24065",
        product_path: "58A_0",
        product_counter_part: "58B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 59,
        productSlug: "being-human-men-slim-fit-shirts-whitenavy-8905271456261",
        src: bh59,
        name: "being-human-men-slim-fit-shirts-whitenavy-8905271456261",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24066",
        product_path: "59A_0",
        product_counter_part: "59B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 60,
        productSlug: "being-human-men-slim-fit-shirts-navywhite-8905271456193",
        src: bh60,
        name: "being-human-men-slim-fit-shirts-navywhite-8905271456193",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24066",
        product_path: "60A_0",
        product_counter_part: "60B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 61,
        productSlug: "being-human-men-regular-fit-t-shirts-black-8905271447795",
        src: bh61,
        name: "being-human-men-regular-fit-t-shirts-black-8905271447795",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "61A_0",
        product_counter_part: "61B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 62,
        productSlug: "being-human-men-regular-fit-t-shirts-blareney-8905271447870",
        src: bh62,
        name: "being-human-men-regular-fit-t-shirts-blareney-8905271447870",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "62A_0",
        product_counter_part: "62B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 63,
        productSlug: "being-human-men-regular-fit-t-shirts-bright-marigold-8905271447955",
        src: bh63,
        name: "being-human-men-regular-fit-t-shirts-bright-marigold-8905271447955",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "63A_0",
        product_counter_part: "63B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 64,
        productSlug: "being-human-men-regular-fit-t-shirts-fiesta-8905271448129",
        src: bh64,
        name: "being-human-men-regular-fit-t-shirts-fiesta-8905271448129",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "64A_0",
        product_counter_part: "64B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 65,
        productSlug: "being-human-men-regular-fit-t-shirts-navy-8905271448181",
        src: bh65,
        name: "being-human-men-regular-fit-t-shirts-navy-8905271448181",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "65A_0",
        product_counter_part: "65B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 66,
        productSlug: "being-human-men-regular-fit-t-shirts-peach-parfait-8905271448266",
        src: bh66,
        name: "being-human-men-regular-fit-t-shirts-peach-parfait-8905271448266",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "66A_0",
        product_counter_part: "66B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 67,
        productSlug: "being-human-men-regular-fit-t-shirts-sachet-pink-8905271448341",
        src: bh67,
        name: "being-human-men-regular-fit-t-shirts-sachet-pink-8905271448341",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "67A_0",
        product_counter_part: "67B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 68,
        productSlug: "being-human-men-regular-fit-t-shirts-vibrant-yellow-8905271448426",
        src: bh68,
        name: "being-human-men-regular-fit-t-shirts-vibrant-yellow-8905271448426",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "68A_0",
        product_counter_part: "68B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 69,
        productSlug: "being-human-men-regular-fit-t-shirts-winery-8905271448587",
        src: bh69,
        name: "being-human-men-regular-fit-t-shirts-winery-8905271448587",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "69A_0",
        product_counter_part: "69B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 70,
        productSlug: "being-human-men-regular-fit-t-shirts-black-8905271446750",
        src: bh70,
        name: "being-human-men-regular-fit-t-shirts-black-8905271446750",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "70A_0",
        product_counter_part: "70B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            93.98,
            96.52,
            101.6,
            109.22,
            114.3,
            119.38,
            124.46
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 71,
        productSlug: "being-human-men-regular-fit-t-shirts-marron-8905271446910",
        src: bh71,
        name: "being-human-men-regular-fit-t-shirts-marron-8905271446910",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "71A_0",
        product_counter_part: "71B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 72,
        productSlug: "being-human-men-regular-fit-t-shirts-lake-blue-8905271446835",
        src: bh72,
        name: "being-human-men-regular-fit-t-shirts-lake-blue-8905271446835",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "72A_0",
        product_counter_part: "72B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 73,
        productSlug: "being-human-men-regular-fit-t-shirts-white-8905271447078",
        src: bh73,
        name: "being-human-men-regular-fit-t-shirts-white-8905271447078",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "73A_0",
        product_counter_part: "73B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 74,
        productSlug: "being-human-men-regular-fit-t-shirts-rust-8905271446996",
        src: bh74,
        name: "being-human-men-regular-fit-t-shirts-rust-8905271446996",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "74A_0",
        product_counter_part: "74B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 75,
        productSlug: "being-human-men-regular-fit-t-shirts-mineral-red-8905271453970",
        src: bh75,
        name: "being-human-men-regular-fit-t-shirts-mineral-red-8905271453970",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24039",
        product_path: "75A_0",
        product_counter_part: "75B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 76,
        productSlug: "being-human-men-regular-fit-t-shirts-ltgrey-8905271453895",
        src: bh76,
        name: "being-human-men-regular-fit-t-shirts-ltgrey-8905271453895",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24039",
        product_path: "76A_0",
        product_counter_part: "76B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 77,
        src: SaveTheTreesMen,
        product_path: "77A_0",
        product_counter_part: "77B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 78,
        src: SaveTheTreesMen,
        product_path: "78A_0",
        product_counter_part: "78B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 79,
        src: SaveTheTreesMen,
        product_path: "79A_0",
        product_counter_part: "79B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 80,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271482413",
        src: bh80,
        name: "being-human-men-slim-fit-shirts-black-8905271482413",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24016",
        product_path: "80A_0",
        product_counter_part: "80B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 81,
        src: SaveTheTreesMen,
        product_path: "81A_0",
        product_counter_part: "81B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 82,
        src: SaveTheTreesMen,
        product_path: "82A_0",
        product_counter_part: "82B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 83,
        src: SaveTheTreesMen,
        product_path: "83A_0",
        product_counter_part: "83B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 84,
        src: SaveTheTreesMen,
        product_path: "84A_0",
        product_counter_part: "84B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 85,
        src: SaveTheTreesMen,
        product_path: "85A_0",
        product_counter_part: "85B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 86,
        src: SaveTheTreesMen,
        product_path: "86A_0",
        product_counter_part: "86B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 87,
        productSlug: "being-human-men-slim-fit-shirts-white-8905271482253",
        src: bh87,
        name: "being-human-men-slim-fit-shirts-white-8905271482253",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "87A_0",
        product_counter_part: "87B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 88,
        productSlug: "being-human-men-slim-fit-shirts-sky-8905271482093",
        src: bh88,
        name: "being-human-men-slim-fit-shirts-sky-8905271482093",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "88A_0",
        product_counter_part: "88B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 89,
        productSlug: "being-human-men-slim-fit-shirts-mint-8905271482017",
        src: bh89,
        name: "being-human-men-slim-fit-shirts-mint-8905271482017",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "89A_0",
        product_counter_part: "89B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 90,
        productSlug: "being-human-men-slim-fit-shirts-ltpink-8905271481935",
        src: bh90,
        name: "being-human-men-slim-fit-shirts-ltpink-8905271481935",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "90A_0",
        product_counter_part: "90B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 91,
        productSlug: "being-human-men-slim-fit-shirts-dkolive-8905271481850",
        src: bh91,
        name: "being-human-men-slim-fit-shirts-dkolive-8905271481850",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "91A_0",
        product_counter_part: "91B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 92,
        productSlug: "being-human-men-slim-fit-shirts-teal-8905271482178",
        src: bh92,
        name: "being-human-men-slim-fit-shirts-teal-8905271482178",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "92A_0",
        product_counter_part: "92B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 93,
        productSlug: "being-human-men-slim-fit-shirts-yellow-8905271482338",
        src: bh93,
        name: "being-human-men-slim-fit-shirts-yellow-8905271482338",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "93A_0",
        product_counter_part: "93B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 94,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271481775",
        src: bh94,
        name: "being-human-men-slim-fit-shirts-black-8905271481775",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "94A_0",
        product_counter_part: "94B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 95,
        productSlug: "being-human-men-slim-fit-t-shirts-mineral-red-8905271470304",
        src: bh95,
        name: "being-human-men-slim-fit-t-shirts-mineral-red-8905271470304",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24010",
        product_path: "95A_0",
        product_counter_part: "95B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 96,
        productSlug: "being-human-men-slim-fit-t-shirts-chalk-8905271470229",
        src: bh96,
        name: "being-human-men-slim-fit-t-shirts-chalk-8905271470229",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24010",
        product_path: "96A_0",
        product_counter_part: "96B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 97,
        productSlug: "being-human-men-slim-fit-t-shirts-olive-8905271470380",
        src: bh97,
        name: "being-human-men-slim-fit-t-shirts-olive-8905271470380",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24010",
        product_path: "97A_0",
        product_counter_part: "97B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 98,
        productSlug: "being-human-men-slim-fit-t-shirts-white-8905271470465",
        src: bh98,
        name: "being-human-men-slim-fit-t-shirts-white-8905271470465",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24020",
        product_path: "98A_0",
        product_counter_part: "98B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 99,
        productSlug: "being-human-men-slim-fit-t-shirts-green-foam-8905271471110",
        src: bh99,
        name: "being-human-men-slim-fit-t-shirts-green-foam-8905271471110",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24034",
        product_path: "99A_0",
        product_counter_part: "99B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 100,
        productSlug: "being-human-men-slim-fit-t-shirts-coral-8905271471035",
        src: bh100,
        name: "being-human-men-slim-fit-t-shirts-coral-8905271471035",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24034",
        product_path: "100A_0",
        product_counter_part: "100B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 101,
        productSlug: "being-human-men-none-t-shirts-black-8905271468998",
        src: bh101,
        name: "being-human-men-none-t-shirts-black-8905271468998",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467893",
        groupId: "BHTS24019",
        product_path: "101A_0",
        product_counter_part: "101B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 102,
        productSlug: "being-human-men-none-t-shirts-green-foam-8905271469070",
        src: bh102,
        name: "being-human-men-none-t-shirts-green-foam-8905271469070",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467893",
        groupId: "BHTS24019",
        product_path: "102A_0",
        product_counter_part: "102B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 103,
        productSlug: "being-human-men-none-t-shirts-pale-yellow-8905271442974",
        src: bh103,
        name: "being-human-men-none-t-shirts-pale-yellow-8905271442974",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24018",
        product_path: "103A_0",
        product_counter_part: "103B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 104,
        productSlug: "being-human-men-none-t-shirts-mint-8905271442899",
        src: bh104,
        name: "being-human-men-none-t-shirts-mint-8905271442899",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24018",
        product_path: "104A_0",
        product_counter_part: "104B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 105,
        productSlug: "being-human-men-none-t-shirts-olive-8905271443872",
        src: bh105,
        name: "being-human-men-none-t-shirts-olive-8905271443872",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24033",
        product_path: "105A_0",
        product_counter_part: "105B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 106,
        productSlug: "being-human-men-none-t-shirts-mineral-red-8905271443797",
        src: bh106,
        name: "being-human-men-none-t-shirts-mineral-red-8905271443797",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24033",
        product_path: "106A_0",
        product_counter_part: "106B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 107,
        productSlug: "being-human-men-none-t-shirts-black-8905271444282",
        src: bh107,
        name: "being-human-men-none-t-shirts-black-8905271444282",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24041",
        product_path: "107A_0",
        product_counter_part: "107B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 108,
        productSlug: "being-human-men-none-t-shirts-mauve-haze-8905271444190",
        src: bh108,
        name: "being-human-men-none-t-shirts-mauve-haze-8905271444190",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24041",
        product_path: "108A_0",
        product_counter_part: "108B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 109,
        productSlug: "being-human-men-slim-fit-t-shirts-mint-8905271470878",
        src: bh109,
        name: "being-human-men-slim-fit-t-shirts-mint-8905271470878",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24032",
        product_path: "109A_0",
        product_counter_part: "109B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 110,
        productSlug: "being-human-men-none-t-shirts-ltblue-8905271443629",
        src: bh110,
        name: "being-human-men-none-t-shirts-ltblue-8905271443629",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24030",
        product_path: "110A_0",
        product_counter_part: "110B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 111,
        productSlug: "being-human-men-none-t-shirts-green-foam-8905271443711",
        src: bh111,
        name: "being-human-men-none-t-shirts-green-foam-8905271443711",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24030",
        product_path: "111A_0",
        product_counter_part: "111B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 112,
        src: SaveTheTreesMen,
        product_path: "112A_0",
        product_counter_part: "112B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 113,
        productSlug: "being-human-men-slim-fit-t-shirts-black-8905271469988",
        src: bh113,
        name: "being-human-men-slim-fit-t-shirts-black-8905271469988",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24007",
        product_path: "113A_0",
        product_counter_part: "113B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 114,
        src: SaveTheTreesMen,
        product_path: "114A_0",
        product_counter_part: "114B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 115,
        productSlug: "being-human-men-slim-fit-t-shirts-white-8905271470953",
        src: bh115,
        name: "being-human-men-slim-fit-t-shirts-white-8905271470953",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24032",
        product_path: "115A_0",
        product_counter_part: "115B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 116,
        productSlug: "being-human-men-slim-fit-shirts-bluepink-8905271446194",
        src: bh116,
        name: "being-human-men-slim-fit-shirts-bluepink-8905271446194",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24026",
        product_path: "116A_0",
        product_counter_part: "116B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 117,
        productSlug: "being-human-men-slim-fit-shirts-bluegreen-8905271446118",
        src: bh117,
        name: "being-human-men-slim-fit-shirts-bluegreen-8905271446118",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24026",
        product_path: "117A_0",
        product_counter_part: "117B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 118,
        productSlug: "being-human-men-slim-fit-shirts-navy-8905271450450",
        src: bh118,
        name: "being-human-men-slim-fit-shirts-navy-8905271450450",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24101",
        product_path: "118A_0",
        product_counter_part: "118B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 119,
        productSlug: "being-human-men-slim-fit-shirts-dk-grey-8905271450696",
        src: bh119,
        name: "being-human-men-slim-fit-shirts-dk-grey-8905271450696",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24103",
        product_path: "119A_0",
        product_counter_part: "119B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 120,
        productSlug: "being-human-men-slim-fit-shirts-navy-8905271450856",
        src: bh120,
        name: "being-human-men-slim-fit-shirts-navy-8905271450856",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24034",
        product_path: "120A_0",
        product_counter_part: "120B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 121,
        productSlug: "being-human-men-slim-fit-shirts-white-8905271450931",
        src: bh121,
        name: "being-human-men-slim-fit-shirts-white-8905271450931",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24034",
        product_path: "121A_0",
        product_counter_part: "121B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 122,
        src: SaveTheTreesMen,
        product_path: "122A_0",
        product_counter_part: "122B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 123,
        src: SaveTheTreesMen,
        product_path: "123A_0",
        product_counter_part: "123B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 124,
        src: SaveTheTreesMen,
        product_path: "124A_0",
        product_counter_part: "124B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 125,
        src: SaveTheTreesMen,
        product_path: "125A_0",
        product_counter_part: "125B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 126,
        src: SaveTheTreesMen,
        product_path: "126A_0",
        product_counter_part: "126B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 127,
        src: SaveTheTreesMen,
        product_path: "127A_0",
        product_counter_part: "127B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 128,
        src: SaveTheTreesMen,
        product_path: "128A_0",
        product_counter_part: "128B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 129,
        src: SaveTheTreesMen,
        product_path: "129A_0",
        product_counter_part: "129B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 130,
        src: SaveTheTreesMen,
        product_path: "130A_0",
        product_counter_part: "130B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 131,
        src: SaveTheTreesMen,
        product_path: "131A_0",
        product_counter_part: "131B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 132,
        src: SaveTheTreesMen,
        product_path: "132A_0",
        product_counter_part: "132B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 133,
        src: SaveTheTreesMen,
        product_path: "133A_0",
        product_counter_part: "133B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 134,
        src: SaveTheTreesMen,
        product_path: "134A_0",
        product_counter_part: "134B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 135,
        src: SaveTheTreesMen,
        product_path: "135A_0",
        product_counter_part: "135B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 136,
        src: SaveTheTreesMen,
        product_path: "136A_0",
        product_counter_part: "136B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 137,
        src: SaveTheTreesMen,
        product_path: "137A_0",
        product_counter_part: "137B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 138,
        src: SaveTheTreesMen,
        product_path: "138A_0",
        product_counter_part: "138B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 139,
        src: SaveTheTreesMen,
        product_path: "139A_0",
        product_counter_part: "139B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 140,
        src: SaveTheTreesMen,
        product_path: "140A_0",
        product_counter_part: "140B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 141,
        src: SaveTheTreesMen,
        product_path: "141A_0",
        product_counter_part: "141B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 142,
        src: SaveTheTreesMen,
        product_path: "142A_0",
        product_counter_part: "142B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 143,
        src: SaveTheTreesMen,
        product_path: "143A_0",
        product_counter_part: "143B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 144,
        src: SaveTheTreesMen,
        product_path: "144A_0",
        product_counter_part: "144B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 145,
        src: SaveTheTreesMen,
        product_path: "145A_0",
        product_counter_part: "145B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 146,
        src: SaveTheTreesMen,
        product_path: "146A_0",
        product_counter_part: "146B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 147,
        src: SaveTheTreesMen,
        product_path: "147A_0",
        product_counter_part: "147B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 148,
        src: SaveTheTreesMen,
        product_path: "148A_0",
        product_counter_part: "148B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 149,
        src: SaveTheTreesMen,
        product_path: "149A_0",
        product_counter_part: "149B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 150,
        src: SaveTheTreesMen,
        product_path: "150A_0",
        product_counter_part: "150B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 151,
        src: SaveTheTreesMen,
        product_path: "151A_0",
        product_counter_part: "151B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 152,
        src: SaveTheTreesMen,
        product_path: "152A_0",
        product_counter_part: "152B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 153,
        src: SaveTheTreesMen,
        product_path: "153A_0",
        product_counter_part: "153B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 154,
        src: SaveTheTreesMen,
        product_path: "154A_0",
        product_counter_part: "154B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 155,
        src: SaveTheTreesMen,
        product_path: "155A_0",
        product_counter_part: "155B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 156,
        src: SaveTheTreesMen,
        product_path: "156A_0",
        product_counter_part: "156B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 157,
        src: SaveTheTreesMen,
        product_path: "157A_0",
        product_counter_part: "157B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 158,
        src: SaveTheTreesMen,
        product_path: "158A_0",
        product_counter_part: "158B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 159,
        src: SaveTheTreesMen,
        product_path: "159A_0",
        product_counter_part: "159B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 160,
        src: SaveTheTreesMen,
        product_path: "160A_0",
        product_counter_part: "160B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 161,
        src: SaveTheTreesMen,
        product_path: "161A_0",
        product_counter_part: "161B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 162,
        src: SaveTheTreesMen,
        product_path: "162A_0",
        product_counter_part: "162B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 163,
        src: SaveTheTreesMen,
        product_path: "163A_0",
        product_counter_part: "163B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 164,
        src: SaveTheTreesMen,
        product_path: "164A_0",
        product_counter_part: "164B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 165,
        src: SaveTheTreesMen,
        product_path: "165A_0",
        product_counter_part: "165B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 166,
        src: SaveTheTreesMen,
        product_path: "166A_0",
        product_counter_part: "166B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 167,
        src: SaveTheTreesMen,
        product_path: "167A_0",
        product_counter_part: "167B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 168,
        src: SaveTheTreesMen,
        product_path: "168A_0",
        product_counter_part: "168B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 169,
        src: SaveTheTreesMen,
        product_path: "169A_0",
        product_counter_part: "169B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 170,
        src: SaveTheTreesMen,
        product_path: "170A_0",
        product_counter_part: "170B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 171,
        src: SaveTheTreesMen,
        product_path: "171A_0",
        product_counter_part: "171B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 172,
        src: SaveTheTreesMen,
        product_path: "172A_0",
        product_counter_part: "172B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 173,
        src: SaveTheTreesMen,
        product_path: "173A_0",
        product_counter_part: "173B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 174,
        src: SaveTheTreesMen,
        product_path: "174A_0",
        product_counter_part: "174B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 175,
        src: SaveTheTreesMen,
        product_path: "175A_0",
        product_counter_part: "175B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 176,
        src: SaveTheTreesMen,
        product_path: "176A_0",
        product_counter_part: "176B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 177,
        src: SaveTheTreesMen,
        product_path: "177A_0",
        product_counter_part: "177B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 178,
        src: SaveTheTreesMen,
        product_path: "178A_0",
        product_counter_part: "178B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 179,
        src: SaveTheTreesMen,
        product_path: "179A_0",
        product_counter_part: "179B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 180,
        src: SaveTheTreesMen,
        product_path: "180A_0",
        product_counter_part: "180B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 181,
        src: SaveTheTreesMen,
        product_path: "181A_0",
        product_counter_part: "181B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 182,
        src: SaveTheTreesMen,
        product_path: "182A_0",
        product_counter_part: "182B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 183,
        src: SaveTheTreesMen,
        product_path: "183A_0",
        product_counter_part: "183B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 184,
        src: SaveTheTreesMen,
        product_path: "184A_0",
        product_counter_part: "184B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 185,
        src: SaveTheTreesMen,
        product_path: "185A_0",
        product_counter_part: "185B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 186,
        src: SaveTheTreesMen,
        product_path: "186A_0",
        product_counter_part: "186B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 187,
        src: SaveTheTreesMen,
        product_path: "187A_0",
        product_counter_part: "187B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 188,
        src: SaveTheTreesMen,
        product_path: "188A_0",
        product_counter_part: "188B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 189,
        src: SaveTheTreesMen,
        product_path: "189A_0",
        product_counter_part: "189B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 190,
        src: SaveTheTreesMen,
        product_path: "190A_0",
        product_counter_part: "190B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 191,
        src: SaveTheTreesMen,
        product_path: "191A_0",
        product_counter_part: "191B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 192,
        src: SaveTheTreesMen,
        product_path: "192A_0",
        product_counter_part: "192B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 193,
        src: SaveTheTreesMen,
        product_path: "193A_0",
        product_counter_part: "193B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 194,
        src: SaveTheTreesMen,
        product_path: "194A_0",
        product_counter_part: "194B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 195,
        src: SaveTheTreesMen,
        product_path: "195A_0",
        product_counter_part: "195B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 196,
        src: SaveTheTreesMen,
        product_path: "196A_0",
        product_counter_part: "196B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 197,
        src: SaveTheTreesMen,
        product_path: "197A_0",
        product_counter_part: "197B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 198,
        src: SaveTheTreesMen,
        product_path: "198A_0",
        product_counter_part: "198B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 199,
        src: SaveTheTreesMen,
        product_path: "199A_0",
        product_counter_part: "199B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 200,
        src: SaveTheTreesMen,
        product_path: "200A_0",
        product_counter_part: "200B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 201,
        src: SaveTheTreesMen,
        product_path: "201A_0",
        product_counter_part: "201B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 202,
        src: SaveTheTreesMen,
        product_path: "202A_0",
        product_counter_part: "202B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 203,
        src: SaveTheTreesMen,
        product_path: "203A_0",
        product_counter_part: "203B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 204,
        src: SaveTheTreesMen,
        product_path: "204A_0",
        product_counter_part: "204B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 205,
        src: SaveTheTreesMen,
        product_path: "205A_0",
        product_counter_part: "205B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 206,
        src: SaveTheTreesMen,
        product_path: "206A_0",
        product_counter_part: "206B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 207,
        src: SaveTheTreesMen,
        product_path: "207A_0",
        product_counter_part: "207B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 208,
        src: SaveTheTreesMen,
        product_path: "208A_0",
        product_counter_part: "208B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 209,
        src: SaveTheTreesMen,
        product_path: "209A_0",
        product_counter_part: "209B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 210,
        src: SaveTheTreesMen,
        product_path: "210A_0",
        product_counter_part: "210B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 211,
        src: SaveTheTreesMen,
        product_path: "211A_0",
        product_counter_part: "211B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 212,
        src: SaveTheTreesMen,
        product_path: "212A_0",
        product_counter_part: "212B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 213,
        src: SaveTheTreesMen,
        product_path: "213A_0",
        product_counter_part: "213B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 214,
        src: SaveTheTreesMen,
        product_path: "214A_0",
        product_counter_part: "214B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 215,
        src: SaveTheTreesMen,
        product_path: "215A_0",
        product_counter_part: "215B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 216,
        src: SaveTheTreesMen,
        product_path: "216A_0",
        product_counter_part: "216B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 217,
        src: SaveTheTreesMen,
        product_path: "217A_0",
        product_counter_part: "217B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 218,
        src: SaveTheTreesMen,
        product_path: "218A_0",
        product_counter_part: "218B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 219,
        src: SaveTheTreesMen,
        product_path: "219A_0",
        product_counter_part: "219B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 220,
        src: SaveTheTreesMen,
        product_path: "220A_0",
        product_counter_part: "220B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 221,
        src: SaveTheTreesMen,
        product_path: "221A_0",
        product_counter_part: "221B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 222,
        src: SaveTheTreesMen,
        product_path: "222A_0",
        product_counter_part: "222B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 223,
        src: SaveTheTreesMen,
        product_path: "223A_0",
        product_counter_part: "223B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 224,
        src: SaveTheTreesMen,
        product_path: "224A_0",
        product_counter_part: "224B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 225,
        src: SaveTheTreesMen,
        product_path: "225A_0",
        product_counter_part: "225B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 226,
        src: SaveTheTreesMen,
        product_path: "226A_0",
        product_counter_part: "226B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 227,
        src: SaveTheTreesMen,
        product_path: "227A_0",
        product_counter_part: "227B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 228,
        src: SaveTheTreesMen,
        product_path: "228A_0",
        product_counter_part: "228B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 229,
        src: SaveTheTreesMen,
        product_path: "229A_0",
        product_counter_part: "229B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 230,
        src: SaveTheTreesMen,
        product_path: "230A_0",
        product_counter_part: "230B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 231,
        src: SaveTheTreesMen,
        product_path: "231A_0",
        product_counter_part: "231B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 232,
        src: SaveTheTreesMen,
        product_path: "232A_0",
        product_counter_part: "232B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 233,
        src: SaveTheTreesMen,
        product_path: "233A_0",
        product_counter_part: "233B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 234,
        src: SaveTheTreesMen,
        product_path: "234A_0",
        product_counter_part: "234B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 235,
        src: SaveTheTreesMen,
        product_path: "235A_0",
        product_counter_part: "235B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 236,
        src: SaveTheTreesMen,
        product_path: "236A_0",
        product_counter_part: "236B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 237,
        src: SaveTheTreesMen,
        product_path: "237A_0",
        product_counter_part: "237B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 238,
        src: SaveTheTreesMen,
        product_path: "238A_0",
        product_counter_part: "238B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 239,
        src: SaveTheTreesMen,
        product_path: "239A_0",
        product_counter_part: "239B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 240,
        src: SaveTheTreesMen,
        product_path: "240A_0",
        product_counter_part: "240B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 241,
        src: SaveTheTreesMen,
        product_path: "241A_0",
        product_counter_part: "241B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 242,
        src: SaveTheTreesMen,
        product_path: "242A_0",
        product_counter_part: "242B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 243,
        src: SaveTheTreesMen,
        product_path: "243A_0",
        product_counter_part: "243B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 244,
        src: SaveTheTreesMen,
        product_path: "244A_0",
        product_counter_part: "244B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 245,
        src: SaveTheTreesMen,
        product_path: "245A_0",
        product_counter_part: "245B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 246,
        src: SaveTheTreesMen,
        product_path: "246A_0",
        product_counter_part: "246B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 247,
        src: SaveTheTreesMen,
        product_path: "247A_0",
        product_counter_part: "247B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 248,
        src: SaveTheTreesMen,
        product_path: "248A_0",
        product_counter_part: "248B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 249,
        src: SaveTheTreesMen,
        product_path: "249A_0",
        product_counter_part: "249B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 250,
        src: SaveTheTreesMen,
        product_path: "250A_0",
        product_counter_part: "250B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 251,
        src: SaveTheTreesMen,
        product_path: "251A_0",
        product_counter_part: "251B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 252,
        src: SaveTheTreesMen,
        product_path: "252A_0",
        product_counter_part: "252B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 253,
        src: SaveTheTreesMen,
        product_path: "253A_0",
        product_counter_part: "253B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 254,
        src: SaveTheTreesMen,
        product_path: "254A_0",
        product_counter_part: "254B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 255,
        src: SaveTheTreesMen,
        product_path: "255A_0",
        product_counter_part: "255B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 256,
        src: SaveTheTreesMen,
        product_path: "256A_0",
        product_counter_part: "256B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 257,
        src: SaveTheTreesMen,
        product_path: "257A_0",
        product_counter_part: "257B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 258,
        src: SaveTheTreesMen,
        product_path: "258A_0",
        product_counter_part: "258B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 259,
        src: SaveTheTreesMen,
        product_path: "259A_0",
        product_counter_part: "259B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 260,
        src: SaveTheTreesMen,
        product_path: "260A_0",
        product_counter_part: "260B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 261,
        src: SaveTheTreesMen,
        product_path: "261A_0",
        product_counter_part: "261B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 262,
        src: SaveTheTreesMen,
        product_path: "262A_0",
        product_counter_part: "262B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 263,
        src: SaveTheTreesMen,
        product_path: "263A_0",
        product_counter_part: "263B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 264,
        src: SaveTheTreesMen,
        product_path: "264A_0",
        product_counter_part: "264B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 265,
        src: SaveTheTreesMen,
        product_path: "265A_0",
        product_counter_part: "265B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 266,
        src: SaveTheTreesMen,
        product_path: "266A_0",
        product_counter_part: "266B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 267,
        src: SaveTheTreesMen,
        product_path: "267A_0",
        product_counter_part: "267B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 268,
        src: SaveTheTreesMen,
        product_path: "268A_0",
        product_counter_part: "268B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 269,
        src: SaveTheTreesMen,
        product_path: "269A_0",
        product_counter_part: "269B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 270,
        src: SaveTheTreesMen,
        product_path: "270A_0",
        product_counter_part: "270B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 271,
        src: SaveTheTreesMen,
        product_path: "271A_0",
        product_counter_part: "271B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 272,
        src: SaveTheTreesMen,
        product_path: "272A_0",
        product_counter_part: "272B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 273,
        src: SaveTheTreesMen,
        product_path: "273A_0",
        product_counter_part: "273B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 274,
        src: SaveTheTreesMen,
        product_path: "274A_0",
        product_counter_part: "274B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 275,
        src: SaveTheTreesMen,
        product_path: "275A_0",
        product_counter_part: "275B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 276,
        src: SaveTheTreesMen,
        product_path: "276A_0",
        product_counter_part: "276B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 277,
        src: SaveTheTreesMen,
        product_path: "277A_0",
        product_counter_part: "277B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 278,
        src: SaveTheTreesMen,
        product_path: "278A_0",
        product_counter_part: "278B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 279,
        src: SaveTheTreesMen,
        product_path: "279A_0",
        product_counter_part: "279B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 280,
        src: SaveTheTreesMen,
        product_path: "280A_0",
        product_counter_part: "280B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 281,
        src: SaveTheTreesMen,
        product_path: "281A_0",
        product_counter_part: "281B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 282,
        src: SaveTheTreesMen,
        product_path: "282A_0",
        product_counter_part: "282B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 283,
        src: SaveTheTreesMen,
        product_path: "283A_0",
        product_counter_part: "283B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 284,
        src: SaveTheTreesMen,
        product_path: "284A_0",
        product_counter_part: "284B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 285,
        src: SaveTheTreesMen,
        product_path: "285A_0",
        product_counter_part: "285B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 286,
        src: SaveTheTreesMen,
        product_path: "286A_0",
        product_counter_part: "286B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 287,
        src: SaveTheTreesMen,
        product_path: "287A_0",
        product_counter_part: "287B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 288,
        src: SaveTheTreesMen,
        product_path: "288A_0",
        product_counter_part: "288B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 289,
        src: SaveTheTreesMen,
        product_path: "289A_0",
        product_counter_part: "289B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 290,
        src: SaveTheTreesMen,
        product_path: "290A_0",
        product_counter_part: "290B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 291,
        src: SaveTheTreesMen,
        product_path: "291A_0",
        product_counter_part: "291B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 292,
        src: SaveTheTreesMen,
        product_path: "292A_0",
        product_counter_part: "292B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 293,
        src: SaveTheTreesMen,
        product_path: "293A_0",
        product_counter_part: "293B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 294,
        src: SaveTheTreesMen,
        product_path: "294A_0",
        product_counter_part: "294B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 295,
        src: SaveTheTreesMen,
        product_path: "295A_0",
        product_counter_part: "295B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 296,
        src: SaveTheTreesMen,
        product_path: "296A_0",
        product_counter_part: "296B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 297,
        src: SaveTheTreesMen,
        product_path: "297A_0",
        product_counter_part: "297B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 298,
        src: SaveTheTreesMen,
        product_path: "298A_0",
        product_counter_part: "298B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 299,
        src: SaveTheTreesMen,
        product_path: "299A_0",
        product_counter_part: "299B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 300,
        src: SaveTheTreesMen,
        product_path: "300A_0",
        product_counter_part: "300B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 301,
        src: SaveTheTreesMen,
        product_path: "301A_0",
        product_counter_part: "301B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 302,
        src: SaveTheTreesMen,
        product_path: "302A_0",
        product_counter_part: "302B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 303,
        src: SaveTheTreesMen,
        product_path: "303A_0",
        product_counter_part: "303B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 304,
        src: SaveTheTreesMen,
        product_path: "304A_0",
        product_counter_part: "304B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 305,
        src: SaveTheTreesMen,
        product_path: "305A_0",
        product_counter_part: "305B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 306,
        src: SaveTheTreesMen,
        product_path: "306A_0",
        product_counter_part: "306B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 307,
        src: SaveTheTreesMen,
        product_path: "307A_0",
        product_counter_part: "307B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 308,
        src: SaveTheTreesMen,
        product_path: "308A_0",
        product_counter_part: "308B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 309,
        src: SaveTheTreesMen,
        product_path: "309A_0",
        product_counter_part: "309B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 310,
        src: SaveTheTreesMen,
        product_path: "310A_0",
        product_counter_part: "310B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 311,
        src: SaveTheTreesMen,
        product_path: "311A_0",
        product_counter_part: "311B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        fit: 'oversize',
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 312,
        src: SaveTheTreesMen,
        product_path: "312A_0",
        product_counter_part: "312B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 313,
        src: SaveTheTreesMen,
        product_path: "313A_0",
        product_counter_part: "313B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 314,
        src: SaveTheTreesMen,
        product_path: "314A_0",
        product_counter_part: "314B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 315,
        src: SaveTheTreesMen,
        product_path: "315A_0",
        product_counter_part: "315B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 316,
        src: SaveTheTreesMen,
        product_path: "316A_0",
        product_counter_part: "316B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 317,
        src: SaveTheTreesMen,
        product_path: "317A_0",
        product_counter_part: "317B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 318,
        src: SaveTheTreesMen,
        product_path: "318A_0",
        product_counter_part: "318B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 319,
        src: SaveTheTreesMen,
        product_path: "319A_0",
        product_counter_part: "319B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 320,
        src: SaveTheTreesMen,
        product_path: "320A_0",
        product_counter_part: "320B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 321,
        src: SaveTheTreesMen,
        product_path: "321A_0",
        product_counter_part: "321B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 322,
        src: SaveTheTreesMen,
        product_path: "322A_0",
        product_counter_part: "322B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 323,
        src: SaveTheTreesMen,
        product_path: "323A_0",
        product_counter_part: "323B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 324,
        src: SaveTheTreesMen,
        product_path: "324A_0",
        product_counter_part: "324B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 325,
        src: SaveTheTreesMen,
        product_path: "325A_0",
        product_counter_part: "325B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 326,
        src: SaveTheTreesMen,
        product_path: "326A_0",
        product_counter_part: "326B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 327,
        src: SaveTheTreesMen,
        product_path: "327A_0",
        product_counter_part: "327B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 328,
        src: SaveTheTreesMen,
        product_path: "328A_0",
        product_counter_part: "328B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 329,
        src: SaveTheTreesMen,
        product_path: "329A_0",
        product_counter_part: "329B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 330,
        src: SaveTheTreesMen,
        product_path: "330A_0",
        product_counter_part: "330B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 331,
        src: SaveTheTreesMen,
        product_path: "331A_0",
        product_counter_part: "331B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 332,
        src: SaveTheTreesMen,
        product_path: "332A_0",
        product_counter_part: "332B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 333,
        src: SaveTheTreesMen,
        product_path: "333A_0",
        product_counter_part: "333B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 334,
        src: SaveTheTreesMen,
        product_path: "334A_0",
        product_counter_part: "334B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 335,
        src: SaveTheTreesMen,
        product_path: "335A_0",
        product_counter_part: "335B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 336,
        src: SaveTheTreesMen,
        product_path: "336A_0",
        product_counter_part: "336B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 337,
        src: SaveTheTreesMen,
        product_path: "337A_0",
        product_counter_part: "337B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 338,
        src: SaveTheTreesMen,
        product_path: "338A_0",
        product_counter_part: "338B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 339,
        src: SaveTheTreesMen,
        product_path: "339A_0",
        product_counter_part: "339B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 340,
        src: SaveTheTreesMen,
        product_path: "340A_0",
        product_counter_part: "340B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 341,
        src: SaveTheTreesMen,
        product_path: "341A_0",
        product_counter_part: "341B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 342,
        src: SaveTheTreesMen,
        product_path: "342A_0",
        product_counter_part: "342B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 343,
        src: SaveTheTreesMen,
        product_path: "343A_0",
        product_counter_part: "343B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 344,
        src: SaveTheTreesMen,
        product_path: "344A_0",
        product_counter_part: "344B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 345,
        src: SaveTheTreesMen,
        product_path: "345A_0",
        product_counter_part: "345B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 346,
        src: SaveTheTreesMen,
        product_path: "346A_0",
        product_counter_part: "346B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 347,
        src: SaveTheTreesMen,
        product_path: "347A_0",
        product_counter_part: "347B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 348,
        src: SaveTheTreesMen,
        product_path: "348A_0",
        product_counter_part: "348B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 349,
        src: SaveTheTreesMen,
        product_path: "349A_0",
        product_counter_part: "349B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 350,
        src: SaveTheTreesMen,
        product_path: "350A_0",
        product_counter_part: "350B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 351,
        src: SaveTheTreesMen,
        product_path: "351A_0",
        product_counter_part: "351B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 352,
        src: SaveTheTreesMen,
        product_path: "352A_0",
        product_counter_part: "352B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 353,
        src: SaveTheTreesMen,
        product_path: "353A_0",
        product_counter_part: "353B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 354,
        src: SaveTheTreesMen,
        product_path: "354A_0",
        product_counter_part: "354B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 355,
        src: SaveTheTreesMen,
        product_path: "355A_0",
        product_counter_part: "355B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 356,
        src: SaveTheTreesMen,
        product_path: "356A_0",
        product_counter_part: "356B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 357,
        src: SaveTheTreesMen,
        product_path: "357A_0",
        product_counter_part: "357B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 358,
        src: SaveTheTreesMen,
        product_path: "358A_0",
        product_counter_part: "358B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 359,
        src: SaveTheTreesMen,
        product_path: "359A_0",
        product_counter_part: "359B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 360,
        src: SaveTheTreesMen,
        product_path: "360A_0",
        product_counter_part: "360B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 361,
        src: SaveTheTreesMen,
        product_path: "361A_0",
        product_counter_part: "361B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 362,
        src: SaveTheTreesMen,
        product_path: "362A_0",
        product_counter_part: "362B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 363,
        src: SaveTheTreesMen,
        product_path: "363A_0",
        product_counter_part: "363B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 364,
        src: SaveTheTreesMen,
        product_path: "364A_0",
        product_counter_part: "364B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 365,
        src: SaveTheTreesMen,
        product_path: "365A_0",
        product_counter_part: "365B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 366,
        src: SaveTheTreesMen,
        product_path: "366A_0",
        product_counter_part: "366B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghumans",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    }
]

export const beingHumanProductListQA = [
    {
        id: 1,
        productSlug: "being-human-men-none-t-shirts-green-foam-8905271469155",
        src: bh1,
        name: "being-human-men-none-t-shirts-green-foam-8905271469155",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24031",
        product_path: "BHTS24031_0",
        product_counter_part: "BHDI24099_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 2,
        productSlug: "being-human-men-none-t-shirts-jet-black-8905271445012",
        src: bh2,
        name: "being-human-men-none-t-shirts-jet-black-8905271445012",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-slim-fit-denim-mid-tone-8905271476627",
        groupId: "BHTS24079",
        product_path: "BHT24079_0",
        product_counter_part: "BHDI24168_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 3,
        src: SaveTheTreesMen,
        product_path: "BHMSS24002_0",
        product_counter_part: "BHDI24143_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000122",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 4,
        productSlug: "being-human-men-regular-fit-t-shirts-charcoal-8905271451495",
        src: bh4,
        name: "being-human-men-regular-fit-t-shirts-charcoal-8905271451495",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24020",
        product_path: "BHP24020_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 5,
        productSlug: "being-human-men-regular-fit-t-shirts-navy-8905271451570",
        src: bh5,
        name: "being-human-men-regular-fit-t-shirts-navy-8905271451570",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24020",
        product_path: "BHP24020_1",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 6,
        productSlug: "being-human-men-regular-fit-t-shirts-white-8905271453178",
        src: bh6,
        name: "being-human-men-regular-fit-t-shirts-white-8905271453178",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24028",
        product_path: "BHP24028_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 7,
        productSlug: "being-human-men-regular-fit-t-shirts-black-8905271453093",
        src: bh7,
        name: "being-human-men-regular-fit-t-shirts-black-8905271453093",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24028",
        product_path: "BHP24028_1",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 8,
        productSlug: "being-human-men-regular-fit-t-shirts-ltgrey-8905271458098",
        src: bh8,
        name: "being-human-men-regular-fit-t-shirts-ltgrey-8905271458098",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24035",
        product_path: "BHP24035_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 9,
        productSlug: "being-human-men-regular-fit-t-shirts-light-blue-8905271451891",
        src: bh9,
        name: "being-human-men-regular-fit-t-shirts-light-blue-8905271451891",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24038",
        product_path: "BHP24038_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 10,
        productSlug: "being-human-men-regular-fit-t-shirts-pastel-pink-8905271472452",
        src: bh10,
        name: "being-human-men-regular-fit-t-shirts-pastel-pink-8905271472452",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-mid-tone-8905271475347",
        groupId: "BHP24046",
        product_path: "BHP24046_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 11,
        src: SaveTheTreesMen,
        product_path: "BHMSS24002CO_0",
        product_counter_part: "BHD124059_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000384",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 12,
        productSlug: "being-human-men-slim-fit-shirts-deep-ocean-8905271459491",
        src: bh12,
        name: "being-human-men-slim-fit-shirts-deep-ocean-8905271459491",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24014",
        product_path: "BHMLS24014_0",
        product_counter_part: "BHDI24093_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 13,
        src: SaveTheTreesMen,
        product_path: "BHFLS24001_0",
        product_counter_part: "BHKTP24005_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000128",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 14,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271445876",
        src: bh14,
        name: "being-human-men-slim-fit-shirts-black-8905271445876",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-slim-straight-fit-denim-mid-tone-8905271474401",
        groupId: "BHMLS24017",
        product_path: "BHMLS24017_0",
        product_counter_part: "BHDI24137_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                73.66,
                86.66
            ],
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                96.52,
                113.55
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 15,
        productSlug: "being-human-men-regular-fit-t-shirts-forest-green-8905271453499",
        src: bh15,
        name: "being-human-men-regular-fit-t-shirts-forest-green-8905271453499",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-boot-cut-fit-denim-light-tone-8905271467366",
        groupId: "BHP24003",
        product_path: "BHP24003_0",
        product_counter_part: "BHDI24030_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                85.09,
                100.11
            ],
            [
                87.63,
                103.09
            ],
            [
                90.17,
                106.08
            ],
            [
                95.25,
                112.06
            ],
            [
                102.87,
                121.02
            ],
            [
                107.95,
                127
            ],
            [
                113.03,
                132.98
            ],
            [
                118.11,
                138.95
            ]
        ]
    },
    {
        id: 16,
        productSlug: "being-human-men-slim-fit-shirts-whitered-8905271459736",
        src: bh16,
        name: "being-human-men-slim-fit-shirts-whitered-8905271459736",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24002",
        product_path: "BHMSS24002_0",
        product_counter_part: "BHDI24059_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 17,
        src: SaveTheTreesMen,
        product_path: "BHMSS24013_0",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000380",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 18,
        src: SaveTheTreesMen,
        product_path: "BHMSS24013_1",
        product_counter_part: "BHDSI24109_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000550",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 19,
        productSlug: "being-human-men-slim-fit-shirts-greenblue-8905271446279",
        src: bh19,
        name: "being-human-men-slim-fit-shirts-greenblue-8905271446279",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-shorts-fit-shorts-black-tone-8905271466130",
        groupId: "BHMSS24027",
        product_path: "BHMSS24027_0",
        product_counter_part: "BHDSI24195_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 20,
        productSlug: "being-human-men-none-t-shirts-coffee-8905271442493",
        src: bh20,
        name: "being-human-men-none-t-shirts-coffee-8905271442493",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24012",
        product_path: "20A_0",
        product_counter_part: "20B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 21,
        productSlug: "being-human-men-none-t-shirts-midnight-blue-8905271442400",
        src: bh21,
        name: "being-human-men-none-t-shirts-midnight-blue-8905271442400",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24012",
        product_path: "21A_0",
        product_counter_part: "21B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 22,
        productSlug: "being-human-men-none-t-shirts-charcoal-8905271442578",
        src: bh22,
        name: "being-human-men-none-t-shirts-charcoal-8905271442578",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24014",
        product_path: "22A_0",
        product_counter_part: "22B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 23,
        productSlug: "being-human-men-none-t-shirts-mineral-red-8905271442653",
        src: bh23,
        name: "being-human-men-none-t-shirts-mineral-red-8905271442653",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24014",
        product_path: "23A_0",
        product_counter_part: "23B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 24,
        productSlug: "being-human-men-none-t-shirts-powder-pink-8905271444046",
        src: bh24,
        name: "being-human-men-none-t-shirts-powder-pink-8905271444046",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24037",
        product_path: "24A_0",
        product_counter_part: "24B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 25,
        productSlug: "being-human-men-none-t-shirts-white-8905271444114",
        src: bh25,
        name: "being-human-men-none-t-shirts-white-8905271444114",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24037",
        product_path: "25A_0",
        product_counter_part: "25B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 26,
        productSlug: "being-human-men-slim-fit-t-shirts-chalk-8905271469902",
        src: bh26,
        name: "being-human-men-slim-fit-t-shirts-chalk-8905271469902",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24006",
        product_path: "26A_0",
        product_counter_part: "26B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 27,
        productSlug: "being-human-men-slim-fit-t-shirts-black-8905271470076",
        src: bh27,
        name: "being-human-men-slim-fit-t-shirts-black-8905271470076",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24009",
        product_path: "27A_0",
        product_counter_part: "27B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 28,
        productSlug: "being-human-men-slim-fit-t-shirts-green-lake-8905271470151",
        src: bh28,
        name: "being-human-men-slim-fit-t-shirts-green-lake-8905271470151",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24009",
        product_path: "28A_0",
        product_counter_part: "28B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 29,
        productSlug: "being-human-men-none-t-shirts-navy-8905271444770",
        src: bh29,
        name: "being-human-men-none-t-shirts-navy-8905271444770",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24057",
        product_path: "29A_0",
        product_counter_part: "29B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 30,
        productSlug: "being-human-men-none-t-shirts-deep-ocean-8905271444695",
        src: bh30,
        name: "being-human-men-none-t-shirts-deep-ocean-8905271444695",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24057",
        product_path: "30A_0",
        product_counter_part: "30B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 31,
        productSlug: "being-human-men-none-t-shirts-chalk-8905271442738",
        src: bh31,
        name: "being-human-men-none-t-shirts-chalk-8905271442738",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24015",
        product_path: "31A_0",
        product_counter_part: "31B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 32,
        productSlug: "being-human-men-none-t-shirts-deep-ocean-8905271442813",
        src: bh32,
        name: "being-human-men-none-t-shirts-deep-ocean-8905271442813",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24015",
        product_path: "32A_0",
        product_counter_part: "32B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 33,
        src: SaveTheTreesMen,
        product_path: "33A_0",
        product_counter_part: "33B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 34,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271460213",
        src: bh34,
        name: "being-human-men-slim-fit-shirts-black-8905271460213",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24014",
        product_path: "34A_0",
        product_counter_part: "34B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 35,
        productSlug: "being-human-men-slim-fit-shirts-deep-ocean-8905271460299",
        src: bh35,
        name: "being-human-men-slim-fit-shirts-deep-ocean-8905271460299",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24014",
        product_path: "35A_0",
        product_counter_part: "35B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 36,
        productSlug: "being-human-men-slim-fit-shirts-yellowcharcoal-8905271460138",
        src: bh36,
        name: "being-human-men-slim-fit-shirts-yellowcharcoal-8905271460138",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24010",
        product_path: "36A_0",
        product_counter_part: "36B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 37,
        productSlug: "being-human-men-slim-fit-shirts-ltbluecharcoal-8905271460053",
        src: bh37,
        name: "being-human-men-slim-fit-shirts-ltbluecharcoal-8905271460053",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271475040",
        groupId: "BHMSS24010",
        product_path: "37A_0",
        product_counter_part: "37B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ],
            [
                124.46,
                146.42
            ]
        ]
    },
    {
        id: 38,
        src: SaveTheTreesMen,
        product_path: "38A_0",
        product_counter_part: "38B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 39,
        src: SaveTheTreesMen,
        product_path: "39A_0",
        product_counter_part: "39B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 40,
        src: SaveTheTreesMen,
        product_path: "40A_0",
        product_counter_part: "40B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 41,
        src: SaveTheTreesMen,
        product_path: "41A_0",
        product_counter_part: "41B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 42,
        productSlug: "being-human-men-slim-fit-shirts-chalknavy-8905271483267",
        src: bh42,
        name: "being-human-men-slim-fit-shirts-chalknavy-8905271483267",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24016",
        product_path: "42A_0",
        product_counter_part: "42B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 43,
        productSlug: "being-human-men-slim-fit-shirts-navyolive-8905271483342",
        src: bh43,
        name: "being-human-men-slim-fit-shirts-navyolive-8905271483342",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24016",
        product_path: "43A_0",
        product_counter_part: "43B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 44,
        productSlug: "being-human-men-none-t-shirts-mineral-red-8905271442325",
        src: bh44,
        name: "being-human-men-none-t-shirts-mineral-red-8905271442325",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24011",
        product_path: "44A_0",
        product_counter_part: "44B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 45,
        productSlug: "being-human-men-none-t-shirts-olive-8905271442257",
        src: bh45,
        name: "being-human-men-none-t-shirts-olive-8905271442257",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24011",
        product_path: "45A_0",
        product_counter_part: "45B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 46,
        productSlug: "being-human-men-none-t-shirts-black-8905271443476",
        src: bh46,
        name: "being-human-men-none-t-shirts-black-8905271443476",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24025",
        product_path: "46A_0",
        product_counter_part: "46B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 47,
        productSlug: "being-human-men-none-t-shirts-white-8905271443391",
        src: bh47,
        name: "being-human-men-none-t-shirts-white-8905271443391",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24025",
        product_path: "47A_0",
        product_counter_part: "47B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 48,
        productSlug: "being-human-men-regular-fit-t-shirts-ltgrey-8905271457534",
        src: bh48,
        name: "being-human-men-regular-fit-t-shirts-ltgrey-8905271457534",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24012",
        product_path: "48A_0",
        product_counter_part: "48B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 49,
        productSlug: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457459",
        src: bh49,
        name: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457459",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24012",
        product_path: "49A_0",
        product_counter_part: "49B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 50,
        productSlug: "being-human-men-regular-fit-t-shirts-charcoal-8905271457619",
        src: bh50,
        name: "being-human-men-regular-fit-t-shirts-charcoal-8905271457619",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24013",
        product_path: "50A_0",
        product_counter_part: "50B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 51,
        productSlug: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457695",
        src: bh51,
        name: "being-human-men-regular-fit-t-shirts-deep-ocean-8905271457695",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24013",
        product_path: "51A_0",
        product_counter_part: "51B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 52,
        productSlug: "being-human-men-regular-fit-t-shirts-light-blue-8905271451655",
        src: bh52,
        name: "being-human-men-regular-fit-t-shirts-light-blue-8905271451655",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24022",
        product_path: "52A_0",
        product_counter_part: "52B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 53,
        productSlug: "being-human-men-regular-fit-t-shirts-snow-white-8905271451730",
        src: bh53,
        name: "being-human-men-regular-fit-t-shirts-snow-white-8905271451730",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24022",
        product_path: "53A_0",
        product_counter_part: "53B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 54,
        productSlug: "being-human-men-regular-fit-t-shirts-midnight-blue-8905271451815",
        src: bh54,
        name: "being-human-men-regular-fit-t-shirts-midnight-blue-8905271451815",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24024",
        product_path: "54A_0",
        product_counter_part: "54B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 55,
        productSlug: "being-human-men-regular-fit-t-shirts-white-8905271452133",
        src: bh55,
        name: "being-human-men-regular-fit-t-shirts-white-8905271452133",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24041",
        product_path: "55A_0",
        product_counter_part: "55B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 56,
        productSlug: "being-human-men-regular-fit-t-shirts-navy-8905271452058",
        src: bh56,
        name: "being-human-men-regular-fit-t-shirts-navy-8905271452058",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271490685",
        groupId: "BHP24041",
        product_path: "56A_0",
        product_counter_part: "56B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 57,
        productSlug: "being-human-men-regular-fit-t-shirts-charcoal-8905271453413",
        src: bh57,
        name: "being-human-men-regular-fit-t-shirts-charcoal-8905271453413",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-boot-cut-fit-denim-light-tone-8905271467366",
        groupId: "BHP24003",
        product_path: "57A_0",
        product_counter_part: "57B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                85.09,
                100.11
            ],
            [
                87.63,
                103.09
            ],
            [
                90.17,
                106.08
            ],
            [
                95.25,
                112.06
            ],
            [
                102.87,
                121.02
            ],
            [
                107.95,
                127
            ],
            [
                113.03,
                132.98
            ],
            [
                118.11,
                138.95
            ]
        ]
    },
    {
        id: 58,
        productSlug: "being-human-men-slim-fit-shirts-whiteyellow-8905271456117",
        src: bh58,
        name: "being-human-men-slim-fit-shirts-whiteyellow-8905271456117",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24065",
        product_path: "58A_0",
        product_counter_part: "58B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 59,
        productSlug: "being-human-men-slim-fit-shirts-whitenavy-8905271456261",
        src: bh59,
        name: "being-human-men-slim-fit-shirts-whitenavy-8905271456261",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24066",
        product_path: "59A_0",
        product_counter_part: "59B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            106.68,
            111.76,
            116.84,
            124.46,
            129.54,
            134.62,
            139.7,
            144.78
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 60,
        productSlug: "being-human-men-slim-fit-shirts-navywhite-8905271456193",
        src: bh60,
        name: "being-human-men-slim-fit-shirts-navywhite-8905271456193",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24066",
        product_path: "60A_0",
        product_counter_part: "60B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 61,
        productSlug: "being-human-men-regular-fit-t-shirts-black-8905271447795",
        src: bh61,
        name: "being-human-men-regular-fit-t-shirts-black-8905271447795",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "61A_0",
        product_counter_part: "61B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 62,
        productSlug: "being-human-men-regular-fit-t-shirts-blareney-8905271447870",
        src: bh62,
        name: "being-human-men-regular-fit-t-shirts-blareney-8905271447870",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "62A_0",
        product_counter_part: "62B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 63,
        productSlug: "being-human-men-regular-fit-t-shirts-bright-marigold-8905271447955",
        src: bh63,
        name: "being-human-men-regular-fit-t-shirts-bright-marigold-8905271447955",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "63A_0",
        product_counter_part: "63B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 64,
        productSlug: "being-human-men-regular-fit-t-shirts-fiesta-8905271448129",
        src: bh64,
        name: "being-human-men-regular-fit-t-shirts-fiesta-8905271448129",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "64A_0",
        product_counter_part: "64B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 65,
        productSlug: "being-human-men-regular-fit-t-shirts-navy-8905271448181",
        src: bh65,
        name: "being-human-men-regular-fit-t-shirts-navy-8905271448181",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "65A_0",
        product_counter_part: "65B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 66,
        productSlug: "being-human-men-regular-fit-t-shirts-peach-parfait-8905271448266",
        src: bh66,
        name: "being-human-men-regular-fit-t-shirts-peach-parfait-8905271448266",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "66A_0",
        product_counter_part: "66B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 67,
        productSlug: "being-human-men-regular-fit-t-shirts-sachet-pink-8905271448341",
        src: bh67,
        name: "being-human-men-regular-fit-t-shirts-sachet-pink-8905271448341",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "67A_0",
        product_counter_part: "67B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 68,
        productSlug: "being-human-men-regular-fit-t-shirts-vibrant-yellow-8905271448426",
        src: bh68,
        name: "being-human-men-regular-fit-t-shirts-vibrant-yellow-8905271448426",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "68A_0",
        product_counter_part: "68B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 69,
        productSlug: "being-human-men-regular-fit-t-shirts-winery-8905271448587",
        src: bh69,
        name: "being-human-men-regular-fit-t-shirts-winery-8905271448587",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24096",
        product_path: "69A_0",
        product_counter_part: "69B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 70,
        productSlug: "being-human-men-regular-fit-t-shirts-black-8905271446750",
        src: bh70,
        name: "being-human-men-regular-fit-t-shirts-black-8905271446750",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "70A_0",
        product_counter_part: "70B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            93.98,
            96.52,
            101.6,
            109.22,
            114.3,
            119.38,
            124.46
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 71,
        productSlug: "being-human-men-regular-fit-t-shirts-marron-8905271446910",
        src: bh71,
        name: "being-human-men-regular-fit-t-shirts-marron-8905271446910",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "71A_0",
        product_counter_part: "71B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 72,
        productSlug: "being-human-men-regular-fit-t-shirts-lake-blue-8905271446835",
        src: bh72,
        name: "being-human-men-regular-fit-t-shirts-lake-blue-8905271446835",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "72A_0",
        product_counter_part: "72B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 73,
        productSlug: "being-human-men-regular-fit-t-shirts-white-8905271447078",
        src: bh73,
        name: "being-human-men-regular-fit-t-shirts-white-8905271447078",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "73A_0",
        product_counter_part: "73B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 74,
        productSlug: "being-human-men-regular-fit-t-shirts-rust-8905271446996",
        src: bh74,
        name: "being-human-men-regular-fit-t-shirts-rust-8905271446996",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24076",
        product_path: "74A_0",
        product_counter_part: "74B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                76.2,
                89.65
            ],
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                99.06,
                116.54
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ],
            [
                119.38,
                140.45
            ]
        ]
    },
    {
        id: 75,
        productSlug: "being-human-men-regular-fit-t-shirts-mineral-red-8905271453970",
        src: bh75,
        name: "being-human-men-regular-fit-t-shirts-mineral-red-8905271453970",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24039",
        product_path: "75A_0",
        product_counter_part: "75B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 76,
        productSlug: "being-human-men-regular-fit-t-shirts-ltgrey-8905271453895",
        src: bh76,
        name: "being-human-men-regular-fit-t-shirts-ltgrey-8905271453895",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271477013",
        groupId: "BHP24039",
        product_path: "76A_0",
        product_counter_part: "76B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 77,
        src: SaveTheTreesMen,
        product_path: "77A_0",
        product_counter_part: "77B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 78,
        src: SaveTheTreesMen,
        product_path: "78A_0",
        product_counter_part: "78B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 79,
        src: SaveTheTreesMen,
        product_path: "79A_0",
        product_counter_part: "79B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 80,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271482413",
        src: bh80,
        name: "being-human-men-slim-fit-shirts-black-8905271482413",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-jogger-fit-denim-light-tone-8905271467045",
        groupId: "BHMLS24016",
        product_path: "80A_0",
        product_counter_part: "80B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42",
            "44"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ],
            [
                121.92,
                143.44
            ]
        ]
    },
    {
        id: 81,
        src: SaveTheTreesMen,
        product_path: "81A_0",
        product_counter_part: "81B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 82,
        src: SaveTheTreesMen,
        product_path: "82A_0",
        product_counter_part: "82B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 83,
        src: SaveTheTreesMen,
        product_path: "83A_0",
        product_counter_part: "83B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 84,
        src: SaveTheTreesMen,
        product_path: "84A_0",
        product_counter_part: "84B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 85,
        src: SaveTheTreesMen,
        product_path: "85A_0",
        product_counter_part: "85B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 86,
        src: SaveTheTreesMen,
        product_path: "86A_0",
        product_counter_part: "86B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 87,
        productSlug: "being-human-men-slim-fit-shirts-white-8905271482253",
        src: bh87,
        name: "being-human-men-slim-fit-shirts-white-8905271482253",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "87A_0",
        product_counter_part: "87B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 88,
        productSlug: "being-human-men-slim-fit-shirts-sky-8905271482093",
        src: bh88,
        name: "being-human-men-slim-fit-shirts-sky-8905271482093",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "88A_0",
        product_counter_part: "88B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 89,
        productSlug: "being-human-men-slim-fit-shirts-mint-8905271482017",
        src: bh89,
        name: "being-human-men-slim-fit-shirts-mint-8905271482017",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "89A_0",
        product_counter_part: "89B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 90,
        productSlug: "being-human-men-slim-fit-shirts-ltpink-8905271481935",
        src: bh90,
        name: "being-human-men-slim-fit-shirts-ltpink-8905271481935",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "90A_0",
        product_counter_part: "90B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 91,
        productSlug: "being-human-men-slim-fit-shirts-dkolive-8905271481850",
        src: bh91,
        name: "being-human-men-slim-fit-shirts-dkolive-8905271481850",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "91A_0",
        product_counter_part: "91B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 92,
        productSlug: "being-human-men-slim-fit-shirts-teal-8905271482178",
        src: bh92,
        name: "being-human-men-slim-fit-shirts-teal-8905271482178",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "92A_0",
        product_counter_part: "92B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 93,
        productSlug: "being-human-men-slim-fit-shirts-yellow-8905271482338",
        src: bh93,
        name: "being-human-men-slim-fit-shirts-yellow-8905271482338",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "93A_0",
        product_counter_part: "93B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 94,
        productSlug: "being-human-men-slim-fit-shirts-black-8905271481775",
        src: bh94,
        name: "being-human-men-slim-fit-shirts-black-8905271481775",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-dark-tone-8905271466451",
        groupId: "BHMSS24117",
        product_path: "94A_0",
        product_counter_part: "94B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 95,
        productSlug: "being-human-men-slim-fit-t-shirts-mineral-red-8905271470304",
        src: bh95,
        name: "being-human-men-slim-fit-t-shirts-mineral-red-8905271470304",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24010",
        product_path: "95A_0",
        product_counter_part: "95B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 96,
        productSlug: "being-human-men-slim-fit-t-shirts-chalk-8905271470229",
        src: bh96,
        name: "being-human-men-slim-fit-t-shirts-chalk-8905271470229",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24010",
        product_path: "96A_0",
        product_counter_part: "96B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 97,
        productSlug: "being-human-men-slim-fit-t-shirts-olive-8905271470380",
        src: bh97,
        name: "being-human-men-slim-fit-t-shirts-olive-8905271470380",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24010",
        product_path: "97A_0",
        product_counter_part: "97B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 98,
        productSlug: "being-human-men-slim-fit-t-shirts-white-8905271470465",
        src: bh98,
        name: "being-human-men-slim-fit-t-shirts-white-8905271470465",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24020",
        product_path: "98A_0",
        product_counter_part: "98B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 99,
        productSlug: "being-human-men-slim-fit-t-shirts-green-foam-8905271471110",
        src: bh99,
        name: "being-human-men-slim-fit-t-shirts-green-foam-8905271471110",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24034",
        product_path: "99A_0",
        product_counter_part: "99B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 100,
        productSlug: "being-human-men-slim-fit-t-shirts-coral-8905271471035",
        src: bh100,
        name: "being-human-men-slim-fit-t-shirts-coral-8905271471035",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24034",
        product_path: "100A_0",
        product_counter_part: "100B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 101,
        productSlug: "being-human-men-none-t-shirts-black-8905271468998",
        src: bh101,
        name: "being-human-men-none-t-shirts-black-8905271468998",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467893",
        groupId: "BHTS24019",
        product_path: "101A_0",
        product_counter_part: "101B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 102,
        productSlug: "being-human-men-none-t-shirts-green-foam-8905271469070",
        src: bh102,
        name: "being-human-men-none-t-shirts-green-foam-8905271469070",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467893",
        groupId: "BHTS24019",
        product_path: "102A_0",
        product_counter_part: "102B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 103,
        productSlug: "being-human-men-none-t-shirts-pale-yellow-8905271442974",
        src: bh103,
        name: "being-human-men-none-t-shirts-pale-yellow-8905271442974",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24018",
        product_path: "103A_0",
        product_counter_part: "103B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 104,
        productSlug: "being-human-men-none-t-shirts-mint-8905271442899",
        src: bh104,
        name: "being-human-men-none-t-shirts-mint-8905271442899",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24018",
        product_path: "104A_0",
        product_counter_part: "104B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08,
            137.16
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                78.74,
                92.64
            ],
            [
                83.82,
                98.61
            ],
            [
                88.9,
                104.59
            ],
            [
                93.98,
                110.56
            ],
            [
                101.6,
                119.53
            ],
            [
                106.68,
                125.51
            ],
            [
                111.76,
                131.48
            ],
            [
                116.84,
                137.46
            ]
        ]
    },
    {
        id: 105,
        productSlug: "being-human-men-none-t-shirts-olive-8905271443872",
        src: bh105,
        name: "being-human-men-none-t-shirts-olive-8905271443872",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24033",
        product_path: "105A_0",
        product_counter_part: "105B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 106,
        productSlug: "being-human-men-none-t-shirts-mineral-red-8905271443797",
        src: bh106,
        name: "being-human-men-none-t-shirts-mineral-red-8905271443797",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24033",
        product_path: "106A_0",
        product_counter_part: "106B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 107,
        productSlug: "being-human-men-none-t-shirts-black-8905271444282",
        src: bh107,
        name: "being-human-men-none-t-shirts-black-8905271444282",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24041",
        product_path: "107A_0",
        product_counter_part: "107B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 108,
        productSlug: "being-human-men-none-t-shirts-mauve-haze-8905271444190",
        src: bh108,
        name: "being-human-men-none-t-shirts-mauve-haze-8905271444190",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24041",
        product_path: "108A_0",
        product_counter_part: "108B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 109,
        productSlug: "being-human-men-slim-fit-t-shirts-mint-8905271470878",
        src: bh109,
        name: "being-human-men-slim-fit-t-shirts-mint-8905271470878",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24032",
        product_path: "109A_0",
        product_counter_part: "109B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 110,
        productSlug: "being-human-men-none-t-shirts-ltblue-8905271443629",
        src: bh110,
        name: "being-human-men-none-t-shirts-ltblue-8905271443629",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24030",
        product_path: "110A_0",
        product_counter_part: "110B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 111,
        productSlug: "being-human-men-none-t-shirts-green-foam-8905271443711",
        src: bh111,
        name: "being-human-men-none-t-shirts-green-foam-8905271443711",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24030",
        product_path: "111A_0",
        product_counter_part: "111B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            91.44,
            96.52,
            99.06,
            101.6,
            106.68,
            114.3,
            119.38,
            124.46,
            129.54
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 112,
        src: SaveTheTreesMen,
        product_path: "112A_0",
        product_counter_part: "112B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 113,
        productSlug: "being-human-men-slim-fit-t-shirts-black-8905271469988",
        src: bh113,
        name: "being-human-men-slim-fit-t-shirts-black-8905271469988",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24007",
        product_path: "113A_0",
        product_counter_part: "113B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            93.98,
            96.52,
            99.06,
            104.14,
            111.76,
            116.84,
            121.92,
            127
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 114,
        src: SaveTheTreesMen,
        product_path: "114A_0",
        product_counter_part: "114B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 115,
        productSlug: "being-human-men-slim-fit-t-shirts-white-8905271470953",
        src: bh115,
        name: "being-human-men-slim-fit-t-shirts-white-8905271470953",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-skinnyfit-denim-mid-tone-8905271467892",
        groupId: "BHTS24032",
        product_path: "115A_0",
        product_counter_part: "115B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            99.06,
            101.6,
            104.14,
            109.22,
            116.84,
            121.92,
            127,
            132.08
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40",
            "42"
        ],
        productCounterPartSizeValues: [
            [
                83.82,
                98.61
            ],
            [
                86.36,
                101.6
            ],
            [
                88.9,
                104.59
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 116,
        productSlug: "being-human-men-slim-fit-shirts-bluepink-8905271446194",
        src: bh116,
        name: "being-human-men-slim-fit-shirts-bluepink-8905271446194",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24026",
        product_path: "116A_0",
        product_counter_part: "116B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 117,
        productSlug: "being-human-men-slim-fit-shirts-bluegreen-8905271446118",
        src: bh117,
        name: "being-human-men-slim-fit-shirts-bluegreen-8905271446118",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-mid-tone-8905271463672",
        groupId: "BHMSS24026",
        product_path: "117A_0",
        product_counter_part: "117B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                82.55,
                97.12
            ],
            [
                87.63,
                103.09
            ],
            [
                92.71,
                109.07
            ],
            [
                97.79,
                115.05
            ],
            [
                105.41,
                124.01
            ],
            [
                110.49,
                129.99
            ],
            [
                115.57,
                135.96
            ]
        ]
    },
    {
        id: 118,
        productSlug: "being-human-men-slim-fit-shirts-navy-8905271450450",
        src: bh118,
        name: "being-human-men-slim-fit-shirts-navy-8905271450450",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24101",
        product_path: "118A_0",
        product_counter_part: "118B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 119,
        productSlug: "being-human-men-slim-fit-shirts-dk-grey-8905271450696",
        src: bh119,
        name: "being-human-men-slim-fit-shirts-dk-grey-8905271450696",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24103",
        product_path: "119A_0",
        product_counter_part: "119B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            101.6,
            106.68,
            111.76,
            119.38,
            124.46,
            129.54,
            134.62,
            139.7
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 120,
        productSlug: "being-human-men-slim-fit-shirts-navy-8905271450856",
        src: bh120,
        name: "being-human-men-slim-fit-shirts-navy-8905271450856",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24034",
        product_path: "120A_0",
        product_counter_part: "120B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 121,
        productSlug: "being-human-men-slim-fit-shirts-white-8905271450931",
        src: bh121,
        name: "being-human-men-slim-fit-shirts-white-8905271450931",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        productCounterPartSlug: "being-human-men-cropped-fit-denim-grey-tone-8905271490753",
        groupId: "BHMSS24034",
        product_path: "121A_0",
        product_counter_part: "121B_0",
        garment_type: "top",
        gender: "male",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "S",
            "M",
            "L",
            "XL",
            "2XL",
            "3XL",
            "4XL",
            "5XL"
        ],
        productSizeValues: [
            104.14,
            109.22,
            114.3,
            121.92,
            127,
            132.08,
            137.16,
            142.24
        ],
        productCounterPartSizeTags: [
            "28",
            "30",
            "32",
            "34",
            "36",
            "38",
            "40"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                95.62
            ],
            [
                86.36,
                101.6
            ],
            [
                91.44,
                107.58
            ],
            [
                96.52,
                113.55
            ],
            [
                104.14,
                122.52
            ],
            [
                109.22,
                128.49
            ],
            [
                114.3,
                134.47
            ]
        ]
    },
    {
        id: 122,
        src: SaveTheTreesMen,
        product_path: "122A_0",
        product_counter_part: "122B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 123,
        src: SaveTheTreesMen,
        product_path: "123A_0",
        product_counter_part: "123B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 124,
        src: SaveTheTreesMen,
        product_path: "124A_0",
        product_counter_part: "124B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 125,
        src: SaveTheTreesMen,
        product_path: "125A_0",
        product_counter_part: "125B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 126,
        src: SaveTheTreesMen,
        product_path: "126A_0",
        product_counter_part: "126B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 127,
        src: SaveTheTreesMen,
        product_path: "127A_0",
        product_counter_part: "127B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 128,
        src: SaveTheTreesMen,
        product_path: "128A_0",
        product_counter_part: "128B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 129,
        src: SaveTheTreesMen,
        product_path: "129A_0",
        product_counter_part: "129B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 130,
        src: SaveTheTreesMen,
        product_path: "130A_0",
        product_counter_part: "130B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 131,
        src: SaveTheTreesMen,
        product_path: "131A_0",
        product_counter_part: "131B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 132,
        src: SaveTheTreesMen,
        product_path: "132A_0",
        product_counter_part: "132B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 133,
        src: SaveTheTreesMen,
        product_path: "133A_0",
        product_counter_part: "133B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 134,
        src: SaveTheTreesMen,
        product_path: "134A_0",
        product_counter_part: "134B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 135,
        src: SaveTheTreesMen,
        product_path: "135A_0",
        product_counter_part: "135B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 136,
        src: SaveTheTreesMen,
        product_path: "136A_0",
        product_counter_part: "136B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 137,
        src: SaveTheTreesMen,
        product_path: "137A_0",
        product_counter_part: "137B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 138,
        src: SaveTheTreesMen,
        product_path: "138A_0",
        product_counter_part: "138B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 139,
        src: SaveTheTreesMen,
        product_path: "139A_0",
        product_counter_part: "139B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 140,
        src: SaveTheTreesMen,
        product_path: "140A_0",
        product_counter_part: "140B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 141,
        src: SaveTheTreesMen,
        product_path: "141A_0",
        product_counter_part: "141B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 142,
        src: SaveTheTreesMen,
        product_path: "142A_0",
        product_counter_part: "142B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 143,
        src: SaveTheTreesMen,
        product_path: "143A_0",
        product_counter_part: "143B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 144,
        src: SaveTheTreesMen,
        product_path: "144A_0",
        product_counter_part: "144B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 145,
        src: SaveTheTreesMen,
        product_path: "145A_0",
        product_counter_part: "145B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 146,
        src: SaveTheTreesMen,
        product_path: "146A_0",
        product_counter_part: "146B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 147,
        src: SaveTheTreesMen,
        product_path: "147A_0",
        product_counter_part: "147B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 148,
        src: SaveTheTreesMen,
        product_path: "148A_0",
        product_counter_part: "148B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 149,
        src: SaveTheTreesMen,
        product_path: "149A_0",
        product_counter_part: "149B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 150,
        src: SaveTheTreesMen,
        product_path: "150A_0",
        product_counter_part: "150B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 151,
        src: SaveTheTreesMen,
        product_path: "151A_0",
        product_counter_part: "151B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 152,
        src: SaveTheTreesMen,
        product_path: "152A_0",
        product_counter_part: "152B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 153,
        src: SaveTheTreesMen,
        product_path: "153A_0",
        product_counter_part: "153B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 154,
        src: SaveTheTreesMen,
        product_path: "154A_0",
        product_counter_part: "154B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 155,
        src: SaveTheTreesMen,
        product_path: "155A_0",
        product_counter_part: "155B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 156,
        src: SaveTheTreesMen,
        product_path: "156A_0",
        product_counter_part: "156B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 157,
        src: SaveTheTreesMen,
        product_path: "157A_0",
        product_counter_part: "157B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 158,
        src: SaveTheTreesMen,
        product_path: "158A_0",
        product_counter_part: "158B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 159,
        src: SaveTheTreesMen,
        product_path: "159A_0",
        product_counter_part: "159B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 160,
        src: SaveTheTreesMen,
        product_path: "160A_0",
        product_counter_part: "160B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 161,
        src: SaveTheTreesMen,
        product_path: "161A_0",
        product_counter_part: "161B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 162,
        src: SaveTheTreesMen,
        product_path: "162A_0",
        product_counter_part: "162B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 163,
        src: SaveTheTreesMen,
        product_path: "163A_0",
        product_counter_part: "163B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 164,
        src: SaveTheTreesMen,
        product_path: "164A_0",
        product_counter_part: "164B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 165,
        src: SaveTheTreesMen,
        product_path: "165A_0",
        product_counter_part: "165B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 166,
        src: SaveTheTreesMen,
        product_path: "166A_0",
        product_counter_part: "166B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 167,
        src: SaveTheTreesMen,
        product_path: "167A_0",
        product_counter_part: "167B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 168,
        src: SaveTheTreesMen,
        product_path: "168A_0",
        product_counter_part: "168B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 169,
        src: SaveTheTreesMen,
        product_path: "169A_0",
        product_counter_part: "169B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 170,
        src: SaveTheTreesMen,
        product_path: "170A_0",
        product_counter_part: "170B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 171,
        src: SaveTheTreesMen,
        product_path: "171A_0",
        product_counter_part: "171B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 172,
        src: SaveTheTreesMen,
        product_path: "172A_0",
        product_counter_part: "172B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 173,
        src: SaveTheTreesMen,
        product_path: "173A_0",
        product_counter_part: "173B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 174,
        src: SaveTheTreesMen,
        product_path: "174A_0",
        product_counter_part: "174B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 175,
        src: SaveTheTreesMen,
        product_path: "175A_0",
        product_counter_part: "175B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 176,
        src: SaveTheTreesMen,
        product_path: "176A_0",
        product_counter_part: "176B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 177,
        src: SaveTheTreesMen,
        product_path: "177A_0",
        product_counter_part: "177B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 178,
        src: SaveTheTreesMen,
        product_path: "178A_0",
        product_counter_part: "178B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 179,
        src: SaveTheTreesMen,
        product_path: "179A_0",
        product_counter_part: "179B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 180,
        src: SaveTheTreesMen,
        product_path: "180A_0",
        product_counter_part: "180B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 181,
        src: SaveTheTreesMen,
        product_path: "181A_0",
        product_counter_part: "181B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 182,
        src: SaveTheTreesMen,
        product_path: "182A_0",
        product_counter_part: "182B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 183,
        src: SaveTheTreesMen,
        product_path: "183A_0",
        product_counter_part: "183B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 184,
        src: SaveTheTreesMen,
        product_path: "184A_0",
        product_counter_part: "184B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 185,
        src: SaveTheTreesMen,
        product_path: "185A_0",
        product_counter_part: "185B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 186,
        src: SaveTheTreesMen,
        product_path: "186A_0",
        product_counter_part: "186B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 187,
        src: SaveTheTreesMen,
        product_path: "187A_0",
        product_counter_part: "187B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 188,
        src: SaveTheTreesMen,
        product_path: "188A_0",
        product_counter_part: "188B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 189,
        src: SaveTheTreesMen,
        product_path: "189A_0",
        product_counter_part: "189B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 190,
        src: SaveTheTreesMen,
        product_path: "190A_0",
        product_counter_part: "190B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 191,
        src: SaveTheTreesMen,
        product_path: "191A_0",
        product_counter_part: "191B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 192,
        src: SaveTheTreesMen,
        product_path: "192A_0",
        product_counter_part: "192B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 193,
        src: SaveTheTreesMen,
        product_path: "193A_0",
        product_counter_part: "193B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 194,
        src: SaveTheTreesMen,
        product_path: "194A_0",
        product_counter_part: "194B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 195,
        src: SaveTheTreesMen,
        product_path: "195A_0",
        product_counter_part: "195B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 196,
        src: SaveTheTreesMen,
        product_path: "196A_0",
        product_counter_part: "196B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 197,
        src: SaveTheTreesMen,
        product_path: "197A_0",
        product_counter_part: "197B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 198,
        src: SaveTheTreesMen,
        product_path: "198A_0",
        product_counter_part: "198B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 199,
        src: SaveTheTreesMen,
        product_path: "199A_0",
        product_counter_part: "199B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 200,
        src: SaveTheTreesMen,
        product_path: "200A_0",
        product_counter_part: "200B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 201,
        src: SaveTheTreesMen,
        product_path: "201A_0",
        product_counter_part: "201B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 202,
        src: SaveTheTreesMen,
        product_path: "202A_0",
        product_counter_part: "202B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 203,
        src: SaveTheTreesMen,
        product_path: "203A_0",
        product_counter_part: "203B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 204,
        src: SaveTheTreesMen,
        product_path: "204A_0",
        product_counter_part: "204B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 205,
        src: SaveTheTreesMen,
        product_path: "205A_0",
        product_counter_part: "205B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 206,
        src: SaveTheTreesMen,
        product_path: "206A_0",
        product_counter_part: "206B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 207,
        src: SaveTheTreesMen,
        product_path: "207A_0",
        product_counter_part: "207B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 208,
        src: SaveTheTreesMen,
        product_path: "208A_0",
        product_counter_part: "208B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 209,
        src: SaveTheTreesMen,
        product_path: "209A_0",
        product_counter_part: "209B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 210,
        src: SaveTheTreesMen,
        product_path: "210A_0",
        product_counter_part: "210B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 211,
        src: SaveTheTreesMen,
        product_path: "211A_0",
        product_counter_part: "211B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 212,
        src: SaveTheTreesMen,
        product_path: "212A_0",
        product_counter_part: "212B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 213,
        src: SaveTheTreesMen,
        product_path: "213A_0",
        product_counter_part: "213B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 214,
        src: SaveTheTreesMen,
        product_path: "214A_0",
        product_counter_part: "214B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 215,
        src: SaveTheTreesMen,
        product_path: "215A_0",
        product_counter_part: "215B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 216,
        src: SaveTheTreesMen,
        product_path: "216A_0",
        product_counter_part: "216B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 217,
        src: SaveTheTreesMen,
        product_path: "217A_0",
        product_counter_part: "217B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 218,
        src: SaveTheTreesMen,
        product_path: "218A_0",
        product_counter_part: "218B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 219,
        src: SaveTheTreesMen,
        product_path: "219A_0",
        product_counter_part: "219B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 220,
        src: SaveTheTreesMen,
        product_path: "220A_0",
        product_counter_part: "220B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 221,
        src: SaveTheTreesMen,
        product_path: "221A_0",
        product_counter_part: "221B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 222,
        src: SaveTheTreesMen,
        product_path: "222A_0",
        product_counter_part: "222B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 223,
        src: SaveTheTreesMen,
        product_path: "223A_0",
        product_counter_part: "223B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 224,
        src: SaveTheTreesMen,
        product_path: "224A_0",
        product_counter_part: "224B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 225,
        src: SaveTheTreesMen,
        product_path: "225A_0",
        product_counter_part: "225B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 226,
        src: SaveTheTreesMen,
        product_path: "226A_0",
        product_counter_part: "226B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 227,
        src: SaveTheTreesMen,
        product_path: "227A_0",
        product_counter_part: "227B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 228,
        src: SaveTheTreesMen,
        product_path: "228A_0",
        product_counter_part: "228B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 229,
        src: SaveTheTreesMen,
        product_path: "229A_0",
        product_counter_part: "229B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 230,
        src: SaveTheTreesMen,
        product_path: "230A_0",
        product_counter_part: "230B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 231,
        src: SaveTheTreesMen,
        product_path: "231A_0",
        product_counter_part: "231B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 232,
        src: SaveTheTreesMen,
        product_path: "232A_0",
        product_counter_part: "232B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 233,
        src: SaveTheTreesMen,
        product_path: "233A_0",
        product_counter_part: "233B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 234,
        src: SaveTheTreesMen,
        product_path: "234A_0",
        product_counter_part: "234B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 235,
        src: SaveTheTreesMen,
        product_path: "235A_0",
        product_counter_part: "235B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 236,
        src: SaveTheTreesMen,
        product_path: "236A_0",
        product_counter_part: "236B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 237,
        src: SaveTheTreesMen,
        product_path: "237A_0",
        product_counter_part: "237B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 238,
        src: SaveTheTreesMen,
        product_path: "238A_0",
        product_counter_part: "238B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 239,
        src: SaveTheTreesMen,
        product_path: "239A_0",
        product_counter_part: "239B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 240,
        src: SaveTheTreesMen,
        product_path: "240A_0",
        product_counter_part: "240B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 241,
        src: SaveTheTreesMen,
        product_path: "241A_0",
        product_counter_part: "241B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 242,
        src: SaveTheTreesMen,
        product_path: "242A_0",
        product_counter_part: "242B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 243,
        src: SaveTheTreesMen,
        product_path: "243A_0",
        product_counter_part: "243B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 244,
        src: SaveTheTreesMen,
        product_path: "244A_0",
        product_counter_part: "244B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 245,
        src: SaveTheTreesMen,
        product_path: "245A_0",
        product_counter_part: "245B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 246,
        src: SaveTheTreesMen,
        product_path: "246A_0",
        product_counter_part: "246B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 247,
        src: SaveTheTreesMen,
        product_path: "247A_0",
        product_counter_part: "247B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 248,
        src: SaveTheTreesMen,
        product_path: "248A_0",
        product_counter_part: "248B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 249,
        src: SaveTheTreesMen,
        product_path: "249A_0",
        product_counter_part: "249B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 250,
        src: SaveTheTreesMen,
        product_path: "250A_0",
        product_counter_part: "250B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 251,
        src: SaveTheTreesMen,
        product_path: "251A_0",
        product_counter_part: "251B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 252,
        src: SaveTheTreesMen,
        product_path: "252A_0",
        product_counter_part: "252B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 253,
        src: SaveTheTreesMen,
        product_path: "253A_0",
        product_counter_part: "253B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 254,
        src: SaveTheTreesMen,
        product_path: "254A_0",
        product_counter_part: "254B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 255,
        src: SaveTheTreesMen,
        product_path: "255A_0",
        product_counter_part: "255B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 256,
        src: SaveTheTreesMen,
        product_path: "256A_0",
        product_counter_part: "256B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 257,
        src: SaveTheTreesMen,
        product_path: "257A_0",
        product_counter_part: "257B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 258,
        src: SaveTheTreesMen,
        product_path: "258A_0",
        product_counter_part: "258B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 259,
        src: SaveTheTreesMen,
        product_path: "259A_0",
        product_counter_part: "259B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 260,
        src: SaveTheTreesMen,
        product_path: "260A_0",
        product_counter_part: "260B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 261,
        src: SaveTheTreesMen,
        product_path: "261A_0",
        product_counter_part: "261B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 262,
        src: SaveTheTreesMen,
        product_path: "262A_0",
        product_counter_part: "262B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 263,
        src: SaveTheTreesMen,
        product_path: "263A_0",
        product_counter_part: "263B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 264,
        src: SaveTheTreesMen,
        product_path: "264A_0",
        product_counter_part: "264B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 265,
        src: SaveTheTreesMen,
        product_path: "265A_0",
        product_counter_part: "265B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 266,
        src: SaveTheTreesMen,
        product_path: "266A_0",
        product_counter_part: "266B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 267,
        src: SaveTheTreesMen,
        product_path: "267A_0",
        product_counter_part: "267B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 268,
        src: SaveTheTreesMen,
        product_path: "268A_0",
        product_counter_part: "268B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 269,
        src: SaveTheTreesMen,
        product_path: "269A_0",
        product_counter_part: "269B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 270,
        src: SaveTheTreesMen,
        product_path: "270A_0",
        product_counter_part: "270B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 271,
        src: SaveTheTreesMen,
        product_path: "271A_0",
        product_counter_part: "271B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 272,
        src: SaveTheTreesMen,
        product_path: "272A_0",
        product_counter_part: "272B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 273,
        src: SaveTheTreesMen,
        product_path: "273A_0",
        product_counter_part: "273B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 274,
        src: SaveTheTreesMen,
        product_path: "274A_0",
        product_counter_part: "274B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 275,
        src: SaveTheTreesMen,
        product_path: "275A_0",
        product_counter_part: "275B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 276,
        src: SaveTheTreesMen,
        product_path: "276A_0",
        product_counter_part: "276B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 277,
        src: SaveTheTreesMen,
        product_path: "277A_0",
        product_counter_part: "277B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 278,
        src: SaveTheTreesMen,
        product_path: "278A_0",
        product_counter_part: "278B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 279,
        src: SaveTheTreesMen,
        product_path: "279A_0",
        product_counter_part: "279B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 280,
        src: SaveTheTreesMen,
        product_path: "280A_0",
        product_counter_part: "280B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 281,
        src: SaveTheTreesMen,
        product_path: "281A_0",
        product_counter_part: "281B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 282,
        src: SaveTheTreesMen,
        product_path: "282A_0",
        product_counter_part: "282B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 283,
        src: SaveTheTreesMen,
        product_path: "283A_0",
        product_counter_part: "283B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 284,
        src: SaveTheTreesMen,
        product_path: "284A_0",
        product_counter_part: "284B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 285,
        src: SaveTheTreesMen,
        product_path: "285A_0",
        product_counter_part: "285B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 286,
        src: SaveTheTreesMen,
        product_path: "286A_0",
        product_counter_part: "286B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 287,
        src: SaveTheTreesMen,
        product_path: "287A_0",
        product_counter_part: "287B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 288,
        src: SaveTheTreesMen,
        product_path: "288A_0",
        product_counter_part: "288B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 289,
        src: SaveTheTreesMen,
        product_path: "289A_0",
        product_counter_part: "289B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 290,
        src: SaveTheTreesMen,
        product_path: "290A_0",
        product_counter_part: "290B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 291,
        src: SaveTheTreesMen,
        product_path: "291A_0",
        product_counter_part: "291B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 292,
        src: SaveTheTreesMen,
        product_path: "292A_0",
        product_counter_part: "292B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 293,
        src: SaveTheTreesMen,
        product_path: "293A_0",
        product_counter_part: "293B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 294,
        src: SaveTheTreesMen,
        product_path: "294A_0",
        product_counter_part: "294B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 295,
        src: SaveTheTreesMen,
        product_path: "295A_0",
        product_counter_part: "295B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 296,
        src: SaveTheTreesMen,
        product_path: "296A_0",
        product_counter_part: "296B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 297,
        src: SaveTheTreesMen,
        product_path: "297A_0",
        product_counter_part: "297B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 298,
        src: SaveTheTreesMen,
        product_path: "298A_0",
        product_counter_part: "298B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 299,
        src: SaveTheTreesMen,
        product_path: "299A_0",
        product_counter_part: "299B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 300,
        src: SaveTheTreesMen,
        product_path: "300A_0",
        product_counter_part: "300B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 301,
        src: SaveTheTreesMen,
        product_path: "301A_0",
        product_counter_part: "301B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 302,
        src: SaveTheTreesMen,
        product_path: "302A_0",
        product_counter_part: "302B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 303,
        src: SaveTheTreesMen,
        product_path: "303A_0",
        product_counter_part: "303B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 304,
        src: SaveTheTreesMen,
        product_path: "304A_0",
        product_counter_part: "304B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 305,
        src: SaveTheTreesMen,
        product_path: "305A_0",
        product_counter_part: "305B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 306,
        src: SaveTheTreesMen,
        product_path: "306A_0",
        product_counter_part: "306B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 307,
        src: SaveTheTreesMen,
        product_path: "307A_0",
        product_counter_part: "307B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 308,
        src: SaveTheTreesMen,
        product_path: "308A_0",
        product_counter_part: "308B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 309,
        src: SaveTheTreesMen,
        product_path: "309A_0",
        product_counter_part: "309B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 310,
        src: SaveTheTreesMen,
        product_path: "310A_0",
        product_counter_part: "310B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 311,
        src: SaveTheTreesMen,
        product_path: "311A_0",
        product_counter_part: "311B_0",
        productSlug: 'being-human-men-none-t-shirts-green-foam-8905271469155',
        productCounterPartSlug: 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        fit: 'oversize',
        productSizeTags: [
            'XS',
            'S',
            'M',
            'L',
            'XL',
            '2XL',
            '3XL',
            '4XL',
            '5XL',
            '6XL'],
        productSizeValues: [
            121.92,
            127.0,
            132.08,
            137.16,
            144.78,
            160.02,
            167.64,
            177.8,
            190.5,
            52.0],
        productCounterPartSizeTags: [
            '28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'
        ],
        productCounterPartSizeValues: [[80.01, 94.13],
        [85.09, 100.1],
        [90.17, 106.07],
        [95.25, 112.06],
        [102.87, 121.03],
        [118.11, 138.96],
        [125.73, 147.93],
        [135.89, 159.87],
        [146.05, 171.83]],
    },
    {
        id: 312,
        src: SaveTheTreesMen,
        product_path: "312A_0",
        product_counter_part: "312B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 313,
        src: SaveTheTreesMen,
        product_path: "313A_0",
        product_counter_part: "313B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 314,
        src: SaveTheTreesMen,
        product_path: "314A_0",
        product_counter_part: "314B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 315,
        src: SaveTheTreesMen,
        product_path: "315A_0",
        product_counter_part: "315B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 316,
        src: SaveTheTreesMen,
        product_path: "316A_0",
        product_counter_part: "316B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 317,
        src: SaveTheTreesMen,
        product_path: "317A_0",
        product_counter_part: "317B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 318,
        src: SaveTheTreesMen,
        product_path: "318A_0",
        product_counter_part: "318B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 319,
        src: SaveTheTreesMen,
        product_path: "319A_0",
        product_counter_part: "319B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 320,
        src: SaveTheTreesMen,
        product_path: "320A_0",
        product_counter_part: "320B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 321,
        src: SaveTheTreesMen,
        product_path: "321A_0",
        product_counter_part: "321B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 322,
        src: SaveTheTreesMen,
        product_path: "322A_0",
        product_counter_part: "322B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 323,
        src: SaveTheTreesMen,
        product_path: "323A_0",
        product_counter_part: "323B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 324,
        src: SaveTheTreesMen,
        product_path: "324A_0",
        product_counter_part: "324B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 325,
        src: SaveTheTreesMen,
        product_path: "325A_0",
        product_counter_part: "325B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 326,
        src: SaveTheTreesMen,
        product_path: "326A_0",
        product_counter_part: "326B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 327,
        src: SaveTheTreesMen,
        product_path: "327A_0",
        product_counter_part: "327B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 328,
        src: SaveTheTreesMen,
        product_path: "328A_0",
        product_counter_part: "328B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 329,
        src: SaveTheTreesMen,
        product_path: "329A_0",
        product_counter_part: "329B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 330,
        src: SaveTheTreesMen,
        product_path: "330A_0",
        product_counter_part: "330B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 331,
        src: SaveTheTreesMen,
        product_path: "331A_0",
        product_counter_part: "331B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 332,
        src: SaveTheTreesMen,
        product_path: "332A_0",
        product_counter_part: "332B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 333,
        src: SaveTheTreesMen,
        product_path: "333A_0",
        product_counter_part: "333B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 334,
        src: SaveTheTreesMen,
        product_path: "334A_0",
        product_counter_part: "334B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 335,
        src: SaveTheTreesMen,
        product_path: "335A_0",
        product_counter_part: "335B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 336,
        src: SaveTheTreesMen,
        product_path: "336A_0",
        product_counter_part: "336B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 337,
        src: SaveTheTreesMen,
        product_path: "337A_0",
        product_counter_part: "337B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 338,
        src: SaveTheTreesMen,
        product_path: "338A_0",
        product_counter_part: "338B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 339,
        src: SaveTheTreesMen,
        product_path: "339A_0",
        product_counter_part: "339B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 340,
        src: SaveTheTreesMen,
        product_path: "340A_0",
        product_counter_part: "340B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 341,
        src: SaveTheTreesMen,
        product_path: "341A_0",
        product_counter_part: "341B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 342,
        src: SaveTheTreesMen,
        product_path: "342A_0",
        product_counter_part: "342B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 343,
        src: SaveTheTreesMen,
        product_path: "343A_0",
        product_counter_part: "343B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 344,
        src: SaveTheTreesMen,
        product_path: "344A_0",
        product_counter_part: "344B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 345,
        src: SaveTheTreesMen,
        product_path: "345A_0",
        product_counter_part: "345B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 346,
        src: SaveTheTreesMen,
        product_path: "346A_0",
        product_counter_part: "346B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 347,
        src: SaveTheTreesMen,
        product_path: "347A_0",
        product_counter_part: "347B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 348,
        src: SaveTheTreesMen,
        product_path: "348A_0",
        product_counter_part: "348B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 349,
        src: SaveTheTreesMen,
        product_path: "349A_0",
        product_counter_part: "349B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 350,
        src: SaveTheTreesMen,
        product_path: "350A_0",
        product_counter_part: "350B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 351,
        src: SaveTheTreesMen,
        product_path: "351A_0",
        product_counter_part: "351B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 352,
        src: SaveTheTreesMen,
        product_path: "352A_0",
        product_counter_part: "352B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 353,
        src: SaveTheTreesMen,
        product_path: "353A_0",
        product_counter_part: "353B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 354,
        src: SaveTheTreesMen,
        product_path: "354A_0",
        product_counter_part: "354B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 355,
        src: SaveTheTreesMen,
        product_path: "355A_0",
        product_counter_part: "355B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 356,
        src: SaveTheTreesMen,
        product_path: "356A_0",
        product_counter_part: "356B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 357,
        src: SaveTheTreesMen,
        product_path: "357A_0",
        product_counter_part: "357B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 358,
        src: SaveTheTreesMen,
        product_path: "358A_0",
        product_counter_part: "358B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 359,
        src: SaveTheTreesMen,
        product_path: "359A_0",
        product_counter_part: "359B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 360,
        src: SaveTheTreesMen,
        product_path: "360A_0",
        product_counter_part: "360B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 361,
        src: SaveTheTreesMen,
        product_path: "361A_0",
        product_counter_part: "361B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 362,
        src: SaveTheTreesMen,
        product_path: "362A_0",
        product_counter_part: "362B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 363,
        src: SaveTheTreesMen,
        product_path: "363A_0",
        product_counter_part: "363B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 364,
        src: SaveTheTreesMen,
        product_path: "364A_0",
        product_counter_part: "364B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 365,
        src: SaveTheTreesMen,
        product_path: "365A_0",
        product_counter_part: "365B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    },
    {
        id: 366,
        src: SaveTheTreesMen,
        product_path: "366A_0",
        product_counter_part: "366B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        productSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productSizeValues: [
            [
                78.74,
                66.04
            ],
            [
                83.82,
                71.12
            ],
            [
                88.9,
                76.2
            ],
            [
                93.98,
                83.82
            ],
            [
                99.06,
                91.44
            ],
            [
                106.68,
                99.06
            ]
        ],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ]
    }
]