import SaveTheTreesMen from 'assets/images/ccclub/Save_the_trees_men.jpg';
import Sculpt1 from 'assets/images/cava/Sculpt1.jpg';

const testProductList = [
    {
        id: 0,
        src: SaveTheTreesMen,
        product_path: "shtbottom1_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "test",
        avatar: "v14",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 1,
        src: Sculpt1,
        product_path: "scultbottom_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "test",
        avatar: "v18",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[83.82, 64.4], [91.95, 68.4], [98.75, 72.4], [106.25, 77.4], [113.75, 83.4], [120.98, 89.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    }
];

export default testProductList;