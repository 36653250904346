import misschaseImg from "assets/images/misschase/misschase.png";
import SaveTheTreesMen from 'assets/images/ccclub/Save_the_trees_men.jpg';
const missChaseList = [
    {
        'id': 1,
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'productSlug':'dummy',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'misschase',
        'avatar': 'v20',
        'shoes': 'flat1',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Miss Chase',
        'productImage': misschaseImg,
    },
    {
        'id': 2,
        'groupId': 'BHTS24031',
        'product_path': '2A_0',
        'product_counter_part': '2B_0',
        'productSlug':'dummy',
        'garment_type': 'top',
        'gender': 'female',
        'brand': 'misschase',
        'avatar': 'v20',
        'shoes': 'flat1',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        'productTitle': 'Miss Chase',
        'productImage': SaveTheTreesMen,
    },
];
export default missChaseList;