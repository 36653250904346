import Puma679460 from 'assets/images/puma/679460.jpg'
import Puma677865_0 from 'assets/images/puma/677865_0.png'
import Puma677865_1 from 'assets/images/puma/677865_1.png'
import Puma677865_2 from 'assets/images/puma/677865_2.png'
import Puma677867_0 from 'assets/images/puma/677867_0.png'
import Puma677867_1 from 'assets/images/puma/677867_1.png'
import Puma677867_2 from 'assets/images/puma/677867_2.png'
import Puma679459 from 'assets/images/puma/679459.jpg'

const pumaProductList = [
    {
        id: 3,
        src: Puma677865_0,
        product_path: "677865_0",
        product_counter_part: "677866_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 4,
        src: Puma677865_1,
        product_path: "677865_1",
        product_counter_part: "677866_1",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 5,
        src: Puma677865_2,
        product_path: "677865_2",
        product_counter_part: "677866_2",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 6,
        src: Puma677867_0,
        product_path: "677867_0",
        product_counter_part: "677866X_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 7,
        src: Puma677867_1,
        product_path: "677867_1",
        product_counter_part: "677866X_1",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 8,
        src: Puma677867_2,
        product_path: "677867_2",
        product_counter_part: "677866X_2",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[77, 64], [85, 72], [93, 80], [101, 88], [109, 96], [119, 106], [129, 117]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[86, 62], [94, 69], [102, 76], [110, 83], [120, 91], [130, 101]]
    },
    {
        id: 1,
        src: Puma679459,
        product_path: "679459_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[78, 62], [83, 67], [88, 72], [95, 79], [103, 87], [111, 96], [119, 105], [127, 114],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 2,
        src: Puma679460,
        product_path: "679460_0",
        product_counter_part: "679464_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Puma Dress",
        labels: "Puma Dress - LYKPCSLPW53691",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "puma",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[78, 62], [83, 67], [88, 72], [95, 79], [103, 87], [111, 96], [119, 105], [127, 114],],
        productCounterPartSizeTags: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[71, 58], [76, 62], [81, 66], [88, 72], [96, 79], [104, 87], [112, 95], [120, 103]]
    },
];

export default pumaProductList;