import Sculpt1 from 'assets/images/cava/Sculpt1.jpg';
import Sculpt2 from 'assets/images/cava/Sculpt2.jpg';
import Sculpt3 from 'assets/images/cava/Sculpt3.jpg';
import Sculpt4 from 'assets/images/cava/Sculpt4.jpg';

import Chase1 from 'assets/images/cava/Chase1.jpg';
import Chase2 from 'assets/images/cava/Chase2.jpg';
import Chase3 from 'assets/images/cava/Chase3.jpg';

import Adjusted1 from 'assets/images/cava/Adjusted1.jpg';
import Adjusted2 from 'assets/images/cava/Adjusted2.jpg';

import Sweatshirt1 from 'assets/images/cava/Sweatshirt1.jpeg';
import Sweatshirt3 from 'assets/images/cava/Sweatshirt3.jpg';
import Sweatshirt4 from 'assets/images/cava/Sweatshirt4.jpg';
import Sweatshirt5 from 'assets/images/cava/Sweatshirt5.jpeg';

import Punk1 from 'assets/images/cava/Punk1.jpeg';
import Punk2 from 'assets/images/cava/Punk2.jpeg';
import Punk3 from 'assets/images/cava/Punk3.jpeg';
import Punk4 from 'assets/images/cava/Punk4.jpeg';

import Reflex1 from 'assets/images/cava/reflex1.jpg';
import Reflex2 from 'assets/images/cava/reflex2.jpg';
import Reflex3 from 'assets/images/cava/reflex3.jpg';

import Zipper0 from 'assets/images/cava/Zipper_0.jpg';
import Zipper1 from 'assets/images/cava/Zipper_1.jpg';
import Zipper2 from 'assets/images/cava/Zipper_2.jpg';
import Zipper3 from 'assets/images/cava/Zipper_3.jpg';

const cavaProductList = [
    {
        id: 0,
        src: Chase1,
        product_path: "chasetop_0",
        product_counter_part: "chasebottom_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[99, 63.5], [104, 68.58], [109, 72.4], [114.3, 77.4], [119.3, 83.4], [124.4, 89.4]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[94, 63.5], [99, 68.58], [104.14, 73.66], [109.22, 78.74], [114.3, 83.82], [119.38, 88.9]],
        fabric: {
            top: "90% Nylon, 10% Elasthane",
            bottom: "90% Nylon, 10% Elasthane",
        }
    },
    {
        id: 1,
        src: Chase2,
        product_path: "chasetop_1",
        product_counter_part: "chasebottom_1",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[99, 63.5], [104, 68.58], [109, 72.4], [114.3, 77.4], [119.3, 83.4], [124.4, 89.4]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[94, 63.5], [99, 68.58], [104.14, 73.66], [109.22, 78.74], [114.3, 83.82], [119.38, 88.9]],
        fabric: {
            top: "90% Nylon, 10% Elasthane",
            bottom: "90% Nylon, 10% Elasthane",
        }
    },
    {
        id: 2,
        src: Chase3,
        product_path: "chasetop_2",
        product_counter_part: "chasebottom_2",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[99, 63.5], [104, 68.58], [109, 72.4], [114.3, 77.4], [119.3, 83.4], [124.4, 89.4]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[94, 63.5], [99, 68.58], [104.14, 73.66], [109.22, 78.74], [114.3, 83.82], [119.38, 88.9]],
        fabric: {
            top: "90% Nylon, 10% Elasthane",
            bottom: "90% Nylon, 10% Elasthane",
        }
    },
    {
        id: 5,
        src: Adjusted1,
        product_path: "actopfull_0",
        product_counter_part: "acbottomfull_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "100% Cotton",
            bottom: "100% Cotton",
        },
        variations: {
            'detachConfig': {
                'actopfull_0': [
                    'HIDE',
                    'Material2054',
                    'Material1596011',
                    'Material1596004',
                ],
            },
        },
    },
    {
        id: 6,
        src: Adjusted2,
        product_path: "actopfull_1",
        product_counter_part: "acbottomfull_1",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "100% Cotton",
            bottom: "100% Cotton",
        },
        materialConfig: {
            "actopfull_1": {
                emissive: {
                    'FABRIC 1_FRONT': "#265568",
                    'French Terry & Fleece_FRONT': '#265568',
                    'Lace_FRONT': "#265568",
                    'HIDE': '#265568',
                }
            },
            "acbottomfull_1": {
                emissive: {
                    'FABRIC 1_FRONT': '#265568',
                    'French Terry & Fleece_FRONT': '#265568',
                    'Lace_FRONT': '#265568',
                }
            }
        },
        variations: {
            'detachConfig': {
                'actopfull_1': [
                    'HIDE',
                    'Material2054',
                    'Material1596011',
                    'Material1596004',
                ],
            },
        }
    },
    {
        id: 7,
        src: Sculpt1,
        product_path: "sculttop_0",
        product_counter_part: "scultbottom_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "80% Polyester, 20% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        }
    },
    {
        id: 8,
        src: Sculpt2,
        product_path: "sculttop_1",
        product_counter_part: "scultbottom_1",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "80% Polyester, 20% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        }
    },
    {
        id: 9,
        src: Sculpt3,
        product_path: "sculttop_2",
        product_counter_part: "scultbottom_2",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "80% Polyester, 20% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        },
        materialConfig: {
            "sculttop_2": {
                emissive: {
                    'Main_FRONT': "#863069",
                    'PT_FABRIC_FRONT': "#863069",
                    'Material2034841': "#863069",
                    'Material2034989': "#863069",
                }
            },
            "scultbottom_2": {
                emissive: {
                    'Main_FRONT': "#863069",
                    'waistband_FRONT': "#863069",
                }
            }
        }
    },
    {
        id: 10,
        src: Sculpt4,
        product_path: "sculttop_3",
        product_counter_part: "scultbottom_3",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "80% Polyester, 20% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        },
        materialConfig: {
            "sculttop_3": {
                emissive: {
                    'Main_FRONT': "#985337",
                    'PT_FABRIC_FRONT': "#985337",
                    'Material2034841': "#985337",
                    'Material2034989': "#985337",
                }
            },
            "scultbottom_3": {
                emissive: {
                    'Main_FRONT': '#985337',
                    'waistband_FRONT': '#985337',
                }
            }
        }
    },
    {
        id: 11,
        src: Sweatshirt1,
        product_path: "sweatshirt_0",
        product_counter_part: "jogger_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "60% Cotton, 40% Polyester",
            bottom: "60% Cotton, 40% Polyester",
        }
    },
    {
        id: 13,
        src: Sweatshirt3,
        product_path: "sweatshirt_2",
        product_counter_part: "jogger_2",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "60% Cotton, 40% Polyester",
            bottom: "60% Cotton, 40% Polyester",
        }
    },
    {
        id: 14,
        src: Sweatshirt4,
        product_path: "sweatshirt_3",
        product_counter_part: "jogger_3",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "60% Cotton, 40% Polyester",
            bottom: "60% Cotton, 40% Polyester",
        },
        materialConfig: {
            'sweatshirt_3': {
                emissive: {
                    'sweatshirt_FRONT': "#16161D",
                    'Rib_2x1_FRONT': '#1A1A20',
                }
            },
            'jogger_3': {
                emissive: {
                    'Knit_Fleece_Terry_FRONT': '#16161D',
                    'Lace_FRONT_798774': '#16161D',
                }
            }
        },
    },
    {
        id: 15,
        src: Sweatshirt5,
        product_path: "sweatshirt_4",
        product_counter_part: "jogger_4",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "60% Cotton, 40% Polyester",
            bottom: "60% Cotton, 40% Polyester",
        }
    },
    {
        id: 16,
        src: Punk1,
        product_path: "punktop_0",
        product_counter_part: "sssculptbottom_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "95% Cotton, 5% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        },

    },
    {
        id: 17,
        src: Punk2,
        product_path: "punktop_1",
        product_counter_part: "sssculptbottom_1",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "95% Cotton, 5% Elastane",
            bottom: "60% Cotton, 40% Polyester",
        },
        materialConfig: {
            'sssculptbottom_1': {
                emissive: {
                    'Knit_Fleece_Terry_FRONT': "#3A7473",
                    'Lace_FRONT': '#3A7473',
                }
            }
        },
    },
    {
        id: 18,
        src: Punk3,
        product_path: "punktop_2",
        product_counter_part: "sssculptbottom_2",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "95% Cotton, 5% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        },
        materialConfig: {
            'sssculptbottom_2': {
                emissive: {
                    'Main_FRONT': "#863069",
                    'waistband_FRONT': '#863069',
                }
            }
        },
    },
    {
        id: 19,
        src: Punk4,
        product_path: "punktop_3",
        product_counter_part: "sssculptbottom_3",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "95% Cotton, 5% Elastane",
            bottom: "80% Polyester, 20% Elastane",
        }
    },
    {
        id: 20,
        src: Reflex1,
        product_path: "reflextop_0",
        product_counter_part: "reflexbottom_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon, 10% Elastane",
            bottom: "90% Nylon, 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            }
        }
    },
    {
        id: 21,
        src: Reflex2,
        product_path: "reflextop_1",
        product_counter_part: "reflexbottom_1",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon 10% Elastane",
            bottom: "90% Nylon 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            },
        }
    },
    {
        id: 22,
        src: Reflex3,
        product_path: "reflextop_2",
        product_counter_part: "reflexbottom_2",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon 10% Elastane",
            bottom: "90% Nylon 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            },
        }
    },
    {
        id: 23,
        src: Zipper0,
        product_path: "zippertop_0",
        product_counter_part: "bellbottom_0",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon 10% Elastane",
            bottom: "90% Nylon 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            },
        }
    },
    {
        id: 24,
        src: Zipper1,
        product_path: "zippertop_1",
        product_counter_part: "bellbottom_1",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon 10% Elastane",
            bottom: "90% Nylon 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            },
        }
    },
    {
        id: 25,
        src: Zipper2,
        product_path: "zippertop_2",
        product_counter_part: "bellbottom_2",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon 10% Elastane",
            bottom: "90% Nylon 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            },
        }
    },
    {
        id: 26,
        src: Zipper3,
        product_path: "zippertop_3",
        product_counter_part: "bellbottom_3",
        garment_type: "top",
        gender: "female",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "cava",
        avatar: "v17",
        shoes: '679459',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[78.74, 66.04], [83.82, 71.12], [88.9, 76.2], [93.98, 83.82], [99.06, 91.44], [106.68, 99.06]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[81.28, 63.5], [88.9, 72.39], [96.52, 80.01], [104.14, 88.9], [111.76, 97.79], [119.38, 105.41]],
        fabric: {
            top: "90% Nylon 10% Elastane",
            bottom: "90% Nylon 10% Elastane",
        },
        variations: {
            'glowConfig': {
                showAmbientLight: false,
                materialConfig: {
                    'FABRIC 1 Copy 1_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    },
                    'reflective_FRONT_': {
                        emissiveColor: "#FFFFFF",
                    }
                }
            },
        }
    },
];

export default cavaProductList;