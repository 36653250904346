// const superKicksProductList = [
//     {
//         'id': 1,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '1',
//         'primary': 'top',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://www.superkicks.in/cdn/shop/products/1-3_c4a86ac6-b85f-46f2-96fa-cffc3ce36e53.jpg?v=1681121167&width=493'
//     },
//     {
//         'id': 2,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '1',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 3,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '3',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 4,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '4',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 5,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '5',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 6,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '6',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 7,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '7',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 8,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '8',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 9,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '9',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 10,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '10',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 11,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '11',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 12,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '12',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 12,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '12',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 13,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '13',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 14,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '14',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 15,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '15A_0',
//         'product_counter_part': '15B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '15',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 16,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '16A_0',
//         'product_counter_part': '16B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '16',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 17,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '17',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 18,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '18A_0',
//         'product_counter_part': '18B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '18',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 19,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '19',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 20,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '20',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 21,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '21',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 22,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '22',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 23,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '23',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 24,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '24A_0',
//         'product_counter_part': '24B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '24',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 25,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '25',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 26,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '26',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 27,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '27',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 28,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '28',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 29,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '29',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
//     {
//         'id': 30,
//         'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
//         'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
//         'groupId': 'BHTS24031',
//         'product_path': '1A_0',
//         'product_counter_part': '1B_0',
//         'garment_type': 'top',
//         'gender': 'male',
//         'brand': 'superkicks',
//         'avatar': 'v15',
//         'shoes': '30',
//         'primary': 'shoes',
//         'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
//         'productSizeValues': [
//             101.6,
//             106.68,
//             111.76,
//             116.84,
//             121.92,
//             127.0
//         ],
//         'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
//         'productCounterPartSizeValues': [[78.74, 89.6],
//         [83.82, 94.6],
//         [88.9, 99.06],
//         [93.9, 104.6],
//         [99.0, 109.5],],
//         'productTitle': 'Being Human Men None T-Shirts-Green Foam',
//         'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHTS24031-GREEN FOAM_1.jpg'
//     },
// ];
const superKicksProductList = [
    {
        'id': 1,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'air-force-1-07-triple-white-1-shoes-for-men',
        'primary': 'top',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '1',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_714a25e4-6ea0-4523-a2ad-a2b953f7a1e1.png?v=1719334920&width=493",
    },
    {
        'id': 2,
        'productSlug': 'superkicks-essential-cargo-pants',
        'productCounterPartSlug': 'superkicks-skate-everyday-tee-white',
        'shoesSlug': 'air-force-1-07-triple-white-1-shoes-for-men',
        'primary': 'bottom',
        'product_path': '1B_0',
        'product_counter_part': '1A_0',
        'garment_type': 'bottom',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '1',
        'productCounterPartSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/superkicks-essential-cargo-pants1_jpg.png?v=1706698078&width=493",
    },
    {
        'id': 3,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'air-force-1-07-triple-white-1-shoes-for-men',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '1',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5]],
        "productImage": "https://www.superkicks.in/cdn/shop/products/1-3_c4a86ac6-b85f-46f2-96fa-cffc3ce36e53.jpg?v=1681121167&width=493",
    },
    {
        'id': 4,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'air-force-1-07-white-aquarius-blue-white',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '3',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_b083f487-1cfa-4cf8-bf01-2691999e166e.jpg?v=1717057865&width=493",
    },
    {
        'id': 5,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'air-force-1-07-white-malachite-white',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '4',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1-2024-02-06T155224.543.jpg?v=1707215426&width=493",
    },
    {
        'id': 6,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'air-force-1-07-white-lt-smoke-grey-white-shoes-for-men',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '5',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_1f7e744a-47ab-4a9c-bbdf-a355f2a96ef2.jpg?v=1704806153&width=493",
    },
    {
        'id': 7,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'nike-air-force-1-07-white-university-gold-white',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '6',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/2-2024-07-25T143909.194.jpg?v=1721898582&width=493",
    },
    {
        'id': 8,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'air-force-1-07-black-black-black-shoes-for-men',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '7',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/ECOMS-2023_58b8f0c3-46de-4e95-b115-460bebc72923.jpg?v=1688474261&width=493",
    },
    {
        'id': 9,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'air-force-1-07-white-photo-blue-white-shoes-for-men',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '8',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_5da6cd41-b769-4444-ab53-a3ba1950fc6d.jpg?v=1705044327&width=493",
    },
    {
        'id': 10,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'nike-air-force-1-07-white-dragon-red-white',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '9',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_bf26e570-88f4-436f-8b00-c854412c52bb.jpg?v=1723452413&width=493",
    },
    {
        'id': 11,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'nike-air-force-1-07-white-midnight-navy-white',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '10',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_dcced875-aa69-4687-a1ce-50f4d2f5e0d0.jpg?v=1719231633&width=493",
    },
    {
        'id': 12,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'air-force-1-07-white-pink-rise-white-shoes-for-men',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '11',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/PHOTOSHOP1_8d659cee-01c0-4930-bcda-118e605cfdce.jpg?v=1703858223&width=493",
    },
    {
        'id': 13,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'nike-air-force-1-07-lv8-white-safety-orange-white',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '12',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/2_4bded848-e138-463e-9bdc-05c84b5541f2.jpg?v=1728395524&width=493",
    },
    {
        'id': 14,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'air-force-1-07-white-pink-rise-white-shoes-for-men',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '13',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/PHOTOSHOP1_8d659cee-01c0-4930-bcda-118e605cfdce.jpg?v=1703858223&width=493",
    },
    {
        'id': 15,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'nike-air-force-1-07-black-university-red-black',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '14',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/2_ce343a56-a7b4-4f19-9773-8f42a890a1bc.jpg?v=1728395552&width=493",
    },
    {
        'id': 16,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'nike-wmns-air-force-1-07-sail-sesame-vintage-green',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '15',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_baf144a0-7293-4266-8eb3-88fcaa5bee78.jpg?v=1717569348&width=493",
    },
    {
        'id': 17,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'wmns-air-force-1-07-white-black-shoes-for-women',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '16',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/DD8959-103-5.jpg?v=1690284836&width=493",
    },
    {
        'id': 18,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': '',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '17',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/products/1-3_c4a86ac6-b85f-46f2-96fa-cffc3ce36e53.jpg?v=1681121167&width=493",
    },
    {
        'id': 19,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'air-force-1-07-white-obsidian-fir-phantom',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '18',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_12d6da8c-d1d5-44f6-acb9-b624b719d0d7.jpg?v=1712233597&width=493",
    },
    {
        'id': 20,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'nike-wmns-air-force-1-07-essential-sail-sail-black',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '19',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_081a893d-b7e6-4789-9cec-8ae29c263aca.jpg?v=1718197877&width=493",
    },
    {
        'id': 21,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'air-force-1-07-platinum-violet-sail-coconut-milk-volt-women-sneakers-shoes',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '20',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/DV3808-001.jpg-3.jpg?v=1705929924&width=493",
    },
    {
        'id': 22,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'air-force-1-07-white-black',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '21',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_2eb1142b-8fb6-45a4-ba8b-6e821796e370.jpg?v=1689076901&width=493",
    },
    {
        'id': 23,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'nike-air-force-1-07-lv8-phantom-lt-smoke-grey-gum-yellow',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '22',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_273d86d4-e7ae-4b55-9504-888acc0d69e1.jpg?v=1720441970&width=493",
    },
    {
        'id': 24,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'nike-air-force-1-07-lv8-phantom-baltic-blue-gum-yellow',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '23',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_f81be6ec-5295-4c0f-a65c-1a8086ac2704.jpg?v=1720610226&width=493",
    },
    {
        'id': 25,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'nike-air-force-1-07-lv8-jade-horizon-summit-white',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '24',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/4_6ced8277-5eb6-4a5d-87f9-340fa523fd29.jpg?v=1728395339&width=493",
    },
    {
        'id': 26,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'nike-air-force-1-07-lv8-phantom-malachite-gum-yellow',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '25',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/4_f3ecbbeb-89de-43ad-afd2-14e6f6db5e70.jpg?v=1723189444&width=493",
    },
    {
        'id': 27,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'air-force-1-07-lv8-white-phantom-summit-white',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '26',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_fac2bcb3-adc8-4afd-8c8a-4c4a23c42f3b.jpg?v=1717057951&width=493",
    },
    {
        'id': 28,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'air-force-1-07-lv8-light-bone-summit-white-lt-iron-ore',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '27',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_b7ebf28e-5584-417e-bb5f-b2b0c1ea8fd4.jpg?v=1715411226&width=493",
    },
    {
        'id': 29,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'air-force-1-07-fresh-coconut-milk-coconut-milk',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '28',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_bb2ace7b-1a83-4252-bed0-b33fadc42ab7.jpg?v=1713868865&width=493",
    },
    {
        'id': 30,
        'productSlug': 'superkicks-skate-everyday-tee-white',
        'productCounterPartSlug': 'superkicks-essential-cargo-pants',
        'shoesSlug': 'wmns-air-force-1-07-next-nature-white-dusty-cactus-white-volt',
        'primary': 'shoes',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'superkicks',
        'avatar': 'v15',
        'shoes': '29',
        'productSizeTags': ['XS', 'S', 'M', 'L', 'XL', '2XL'],
        'productSizeValues': [
            101.6,
            106.68,
            111.76,
            116.84,
            121.92,
            127.0
        ],
        'productCounterPartSizeTags': ['S', 'M', 'L', 'XL', '2XL'],
        'productCounterPartSizeValues': [[78.74, 89.6],
        [83.82, 94.6],
        [88.9, 99.06],
        [93.9, 104.6],
        [99.0, 109.5],],
        "productImage": "https://www.superkicks.in/cdn/shop/files/1_9fe38682-89d2-4c1d-b6af-33b4f8d7ef17.jpg?v=1715607493&width=493",
    },
]
export default superKicksProductList;