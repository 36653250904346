
import OasisF21 from "assets/images/oasis/FemaleOutfit21.png";

const SANProductList =
    [
        {
            id: 0,
            src: OasisF21,
            product_path: "em_0",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 1,
            src: OasisF21,
            product_path: "nr_0",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 2,
            src: OasisF21,
            product_path: "vb1_0",
            product_counter_part: "vt1_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 3,
            src: OasisF21,
            product_path: "vt1_0",
            product_counter_part: "vb1_0",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 4,
            src: OasisF21,
            product_path: "vt2_0",
            product_counter_part: "vb2_0",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 5,
            src: OasisF21,
            product_path: "vb2_0",
            product_counter_part: "vt2_0",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 6,
            src: OasisF21,
            product_path: "vb2_1",
            product_counter_part: "vt2_1",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 7,
            src: OasisF21,
            product_path: "vt2_1",
            product_counter_part: "vb2_1",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 8,
            src: OasisF21,
            product_path: "nt_0",
            product_counter_part: "nb_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 9,
            src: OasisF21,
            product_path: "nt_1",
            product_counter_part: "nb_1",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 10,
            src: OasisF21,
            product_path: "nt_2",
            product_counter_part: "nb_2",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 11,
            src: OasisF21,
            product_path: "nt_3",
            product_counter_part: "nb_3",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 12,
            src: OasisF21,
            product_path: "nb_0",
            product_counter_part: "nt_0",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 13,
            src: OasisF21,
            product_path: "nb_1",
            product_counter_part: "nt_1",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 14,
            src: OasisF21,
            product_path: "nb_2",
            product_counter_part: "nt_2",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
        {
            id: 15,
            src: OasisF21,
            product_path: "nb_3",
            product_counter_part: "nt_3",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "SAN",
            avatar: "v11",
            shoes: 'hs'
        },
    ];
export default SANProductList;