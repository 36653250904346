const enamorProductList = [{'id': 1,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '1A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'avatar': 'enamor1',
    'shoes': '',
    'primary': 'bra',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '32DD',
     '34B',
     '34C',
     '34D',
     '34DD',
     '34F',
     '34G',
     '36B',
     '36C',
     '36D',
     '36DD',
     '36F',
     '36G',
     '38B',
     '38C',
     '38D',
     '38DD',
     '38F',
     '38G',
     '40B',
     '40C',
     '40D',
     '40DD',
     '40F',
     '40G',
     '42B',
     '42C',
     '42D',
     '42DD',
     '42F',
     '42G',
     '44B',
     '44C',
     '44D',
     '44DD',
     '44F',
     '44G'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[68, 72], [90, 92]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[75, 77], [95, 96]],
     [[73, 77], [97, 100]],
     [[73, 77], [101, 104]],
     [[73, 77], [105, 108]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[78, 82], [102, 105]],
     [[78, 82], [106, 109]],
     [[78, 82], [110, 113]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]],
     [[83, 87], [107, 110]],
     [[83, 87], [111, 114]],
     [[83, 87], [115, 118]],
     [[88, 92], [104, 106]],
     [[88, 92], [106, 108]],
     [[88, 92], [108, 111]],
     [[88, 92], [112, 115]],
     [[88, 92], [116, 119]],
     [[88, 92], [120, 123]],
     [[93, 97], [109, 111]],
     [[93, 97], [112, 114]],
     [[93, 97], [113, 116]],
     [[93, 97], [117, 120]],
     [[93, 97], [121, 124]],
     [[93, 97], [125, 128]],
     [[98, 102], [114, 116]],
     [[98, 102], [116, 118]],
     [[98, 102], [118, 121]],
     [[98, 102], [122, 125]],
     [[98, 102], [127, 130]],
     [[98, 102], [130, 133]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Body Transform F096 Ultimate Curve Support Bra for Women- Full Coverage, Non Padded and Wirefree - Pearl',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_c8f4616f-7a4d-4be7-93bf-861a3fd2f438.jpg?v=1728380940'},
   {'id': 2,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '2A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor2',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '32DD',
     '34B',
     '34C',
     '34D',
     '34DD',
     '34F',
     '34G',
     '36B',
     '36C',
     '36D',
     '36DD',
     '36F',
     '36G',
     '38B',
     '38C',
     '38D',
     '38DD',
     '38F',
     '38G',
     '40B',
     '40C',
     '40D',
     '40DD',
     '40F',
     '40G',
     '42B',
     '42C',
     '42D',
     '42DD',
     '42F',
     '42G',
     '44B',
     '44C',
     '44D',
     '44DD',
     '44F',
     '44G'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[68, 72], [90, 92]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[75, 77], [95, 96]],
     [[73, 77], [97, 100]],
     [[73, 77], [101, 104]],
     [[73, 77], [105, 108]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[78, 82], [102, 105]],
     [[78, 82], [106, 109]],
     [[78, 82], [110, 113]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]],
     [[83, 87], [107, 110]],
     [[83, 87], [111, 114]],
     [[83, 87], [115, 118]],
     [[88, 92], [104, 106]],
     [[88, 92], [106, 108]],
     [[88, 92], [108, 111]],
     [[88, 92], [112, 115]],
     [[88, 92], [116, 119]],
     [[88, 92], [120, 123]],
     [[93, 97], [109, 111]],
     [[93, 97], [112, 114]],
     [[93, 97], [113, 116]],
     [[93, 97], [117, 120]],
     [[93, 97], [121, 124]],
     [[93, 97], [125, 128]],
     [[98, 102], [114, 116]],
     [[98, 102], [116, 118]],
     [[98, 102], [118, 121]],
     [[98, 102], [122, 125]],
     [[98, 102], [127, 130]],
     [[98, 102], [130, 133]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Butterfly Cleavage Enhancer Plunge Push-Up Bra | F091',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_4123b8bb-9a23-4365-a889-bfa0c8c370a0.jpg?v=1728380323'},
   {'id': 3,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '3A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor3',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '32DD',
     '34B',
     '34C',
     '34D',
     '34DD',
     '34F',
     '34G',
     '36B',
     '36C',
     '36D',
     '36DD',
     '36F',
     '36G',
     '38B',
     '38C',
     '38D',
     '38DD',
     '38F',
     '38G',
     '40B',
     '40C',
     '40D',
     '40DD',
     '40F',
     '40G',
     '42B',
     '42C',
     '42D',
     '42DD',
     '42F',
     '42G',
     '44B',
     '44C',
     '44D',
     '44DD',
     '44F',
     '44G'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[68, 72], [90, 92]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[75, 77], [95, 96]],
     [[73, 77], [97, 100]],
     [[73, 77], [101, 104]],
     [[73, 77], [105, 108]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[78, 82], [102, 105]],
     [[78, 82], [106, 109]],
     [[78, 82], [110, 113]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]],
     [[83, 87], [107, 110]],
     [[83, 87], [111, 114]],
     [[83, 87], [115, 118]],
     [[88, 92], [104, 106]],
     [[88, 92], [106, 108]],
     [[88, 92], [108, 111]],
     [[88, 92], [112, 115]],
     [[88, 92], [116, 119]],
     [[88, 92], [120, 123]],
     [[93, 97], [109, 111]],
     [[93, 97], [112, 114]],
     [[93, 97], [113, 116]],
     [[93, 97], [117, 120]],
     [[93, 97], [121, 124]],
     [[93, 97], [125, 128]],
     [[98, 102], [114, 116]],
     [[98, 102], [116, 118]],
     [[98, 102], [118, 121]],
     [[98, 102], [122, 125]],
     [[98, 102], [127, 130]],
     [[98, 102], [130, 133]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 4,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '4A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor4',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '32DD',
     '34B',
     '34C',
     '34D',
     '34DD',
     '34F',
     '34G',
     '36B',
     '36C',
     '36D',
     '36DD',
     '36F',
     '36G',
     '38B',
     '38C',
     '38D',
     '38DD',
     '38F',
     '38G',
     '40B',
     '40C',
     '40D',
     '40DD',
     '40F',
     '40G',
     '42B',
     '42C',
     '42D',
     '42DD',
     '42F',
     '42G',
     '44B',
     '44C',
     '44D',
     '44DD',
     '44F',
     '44G'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[68, 72], [90, 92]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[75, 77], [95, 96]],
     [[73, 77], [97, 100]],
     [[73, 77], [101, 104]],
     [[73, 77], [105, 108]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[78, 82], [102, 105]],
     [[78, 82], [106, 109]],
     [[78, 82], [110, 113]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]],
     [[83, 87], [107, 110]],
     [[83, 87], [111, 114]],
     [[83, 87], [115, 118]],
     [[88, 92], [104, 106]],
     [[88, 92], [106, 108]],
     [[88, 92], [108, 111]],
     [[88, 92], [112, 115]],
     [[88, 92], [116, 119]],
     [[88, 92], [120, 123]],
     [[93, 97], [109, 111]],
     [[93, 97], [112, 114]],
     [[93, 97], [113, 116]],
     [[93, 97], [117, 120]],
     [[93, 97], [121, 124]],
     [[93, 97], [125, 128]],
     [[98, 102], [114, 116]],
     [[98, 102], [116, 118]],
     [[98, 102], [118, 121]],
     [[98, 102], [122, 125]],
     [[98, 102], [127, 130]],
     [[98, 102], [130, 133]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Long Line Lift T-Shirt Bra | F040',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_7c266169-1946-4bfa-a54c-6a7312025a89.jpg?v=1728300806'},
   {'id': 5,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '5A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor5',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '32DD',
     '34B',
     '34C',
     '34D',
     '34DD',
     '34F',
     '34G',
     '36B',
     '36C',
     '36D',
     '36DD',
     '36F',
     '36G',
     '38B',
     '38C',
     '38D',
     '38DD',
     '38F',
     '38G',
     '40B',
     '40C',
     '40D',
     '40DD',
     '40F',
     '40G',
     '42B',
     '42C',
     '42D',
     '42DD',
     '42F',
     '42G',
     '44B',
     '44C',
     '44D',
     '44DD',
     '44F',
     '44G'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[68, 72], [90, 92]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[75, 77], [95, 96]],
     [[73, 77], [97, 100]],
     [[73, 77], [101, 104]],
     [[73, 77], [105, 108]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[78, 82], [102, 105]],
     [[78, 82], [106, 109]],
     [[78, 82], [110, 113]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]],
     [[83, 87], [107, 110]],
     [[83, 87], [111, 114]],
     [[83, 87], [115, 118]],
     [[88, 92], [104, 106]],
     [[88, 92], [106, 108]],
     [[88, 92], [108, 111]],
     [[88, 92], [112, 115]],
     [[88, 92], [116, 119]],
     [[88, 92], [120, 123]],
     [[93, 97], [109, 111]],
     [[93, 97], [112, 114]],
     [[93, 97], [113, 116]],
     [[93, 97], [117, 120]],
     [[93, 97], [121, 124]],
     [[93, 97], [125, 128]],
     [[98, 102], [114, 116]],
     [[98, 102], [116, 118]],
     [[98, 102], [118, 121]],
     [[98, 102], [122, 125]],
     [[98, 102], [127, 130]],
     [[98, 102], [130, 133]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor InvisiBra F084 Seamless Ultra Smoothening with Invisible Edges T-Shirt Bra - Padded Wirefree Medium Coverage - Nude',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_cedbbae6-e2fa-47e8-9ce2-302510daf22a.jpg?v=1728378363'},
   {'id': 6,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '6A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor6',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '32DD',
     '34B',
     '34C',
     '34D',
     '34DD',
     '34F',
     '34G',
     '36B',
     '36C',
     '36D',
     '36DD',
     '36F',
     '36G',
     '38B',
     '38C',
     '38D',
     '38DD',
     '38F',
     '38G',
     '40B',
     '40C',
     '40D',
     '40DD',
     '40F',
     '40G',
     '42B',
     '42C',
     '42D',
     '42DD',
     '42F',
     '42G',
     '44B',
     '44C',
     '44D',
     '44DD',
     '44F',
     '44G'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[68, 72], [90, 92]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[75, 77], [95, 96]],
     [[73, 77], [97, 100]],
     [[73, 77], [101, 104]],
     [[73, 77], [105, 108]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[78, 82], [102, 105]],
     [[78, 82], [106, 109]],
     [[78, 82], [110, 113]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]],
     [[83, 87], [107, 110]],
     [[83, 87], [111, 114]],
     [[83, 87], [115, 118]],
     [[88, 92], [104, 106]],
     [[88, 92], [106, 108]],
     [[88, 92], [108, 111]],
     [[88, 92], [112, 115]],
     [[88, 92], [116, 119]],
     [[88, 92], [120, 123]],
     [[93, 97], [109, 111]],
     [[93, 97], [112, 114]],
     [[93, 97], [113, 116]],
     [[93, 97], [117, 120]],
     [[93, 97], [121, 124]],
     [[93, 97], [125, 128]],
     [[98, 102], [114, 116]],
     [[98, 102], [116, 118]],
     [[98, 102], [118, 121]],
     [[98, 102], [122, 125]],
     [[98, 102], [127, 130]],
     [[98, 102], [130, 133]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Full Support Smooth Super Lift Bra | FB12',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_9d7ad596-4d11-49e9-ae91-322870840d9f.jpg?v=1728453138'},
   {'id': 7,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '7A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor7',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '32DD',
     '34B',
     '34C',
     '34D',
     '34DD',
     '34F',
     '34G',
     '36B',
     '36C',
     '36D',
     '36DD',
     '36F',
     '36G',
     '38B',
     '38C',
     '38D',
     '38DD',
     '38F',
     '38G',
     '40B',
     '40C',
     '40D',
     '40DD',
     '40F',
     '40G',
     '42B',
     '42C',
     '42D',
     '42DD',
     '42F',
     '42G',
     '44B',
     '44C',
     '44D',
     '44DD',
     '44F',
     '44G'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[68, 72], [90, 92]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[75, 77], [95, 96]],
     [[73, 77], [97, 100]],
     [[73, 77], [101, 104]],
     [[73, 77], [105, 108]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[78, 82], [102, 105]],
     [[78, 82], [106, 109]],
     [[78, 82], [110, 113]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]],
     [[83, 87], [107, 110]],
     [[83, 87], [111, 114]],
     [[83, 87], [115, 118]],
     [[88, 92], [104, 106]],
     [[88, 92], [106, 108]],
     [[88, 92], [108, 111]],
     [[88, 92], [112, 115]],
     [[88, 92], [116, 119]],
     [[88, 92], [120, 123]],
     [[93, 97], [109, 111]],
     [[93, 97], [112, 114]],
     [[93, 97], [113, 116]],
     [[93, 97], [117, 120]],
     [[93, 97], [121, 124]],
     [[93, 97], [125, 128]],
     [[98, 102], [114, 116]],
     [[98, 102], [116, 118]],
     [[98, 102], [118, 121]],
     [[98, 102], [122, 125]],
     [[98, 102], [127, 130]],
     [[98, 102], [130, 133]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Full Figure Strapless & Multi-way Bra | F074',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/ENAMORDAY-1_4005_Look_8a331883-be5e-4c72-b8b0-a16a8582f402.jpg?v=1718885520'},
   {'id': 8,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '8A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor8',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '32DD',
     '34B',
     '34C',
     '34D',
     '34DD',
     '34F',
     '34G',
     '36B',
     '36C',
     '36D',
     '36DD',
     '36F',
     '36G',
     '38B',
     '38C',
     '38D',
     '38DD',
     '38F',
     '38G',
     '40B',
     '40C',
     '40D',
     '40DD',
     '40F',
     '40G',
     '42B',
     '42C',
     '42D',
     '42DD',
     '42F',
     '42G',
     '44B',
     '44C',
     '44D',
     '44DD',
     '44F',
     '44G'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[68, 72], [90, 92]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[75, 77], [95, 96]],
     [[73, 77], [97, 100]],
     [[73, 77], [101, 104]],
     [[73, 77], [105, 108]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[78, 82], [102, 105]],
     [[78, 82], [106, 109]],
     [[78, 82], [110, 113]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]],
     [[83, 87], [107, 110]],
     [[83, 87], [111, 114]],
     [[83, 87], [115, 118]],
     [[88, 92], [104, 106]],
     [[88, 92], [106, 108]],
     [[88, 92], [108, 111]],
     [[88, 92], [112, 115]],
     [[88, 92], [116, 119]],
     [[88, 92], [120, 123]],
     [[93, 97], [109, 111]],
     [[93, 97], [112, 114]],
     [[93, 97], [113, 116]],
     [[93, 97], [117, 120]],
     [[93, 97], [121, 124]],
     [[93, 97], [125, 128]],
     [[98, 102], [114, 116]],
     [[98, 102], [116, 118]],
     [[98, 102], [118, 121]],
     [[98, 102], [122, 125]],
     [[98, 102], [127, 130]],
     [[98, 102], [130, 133]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Fab-Cool A165 Antimicrobial Ultimate Coverage Cotton T-shirt Bra for Women- High Coverage, Padded and Wirefree - Tibetan Stone',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/ENAMORDAY-1_2141_Front.jpg?v=1719231022'},
   {'id': 9,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '9A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor9',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '32DD',
     '34B',
     '34C',
     '34D',
     '34DD',
     '34F',
     '34G',
     '36B',
     '36C',
     '36D',
     '36DD',
     '36F',
     '36G',
     '38B',
     '38C',
     '38D',
     '38DD',
     '38F',
     '38G',
     '40B',
     '40C',
     '40D',
     '40DD',
     '40F',
     '40G',
     '42B',
     '42C',
     '42D',
     '42DD',
     '42F',
     '42G',
     '44B',
     '44C',
     '44D',
     '44DD',
     '44F',
     '44G'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[68, 72], [90, 92]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[75, 77], [95, 96]],
     [[73, 77], [97, 100]],
     [[73, 77], [101, 104]],
     [[73, 77], [105, 108]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[78, 82], [102, 105]],
     [[78, 82], [106, 109]],
     [[78, 82], [110, 113]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]],
     [[83, 87], [107, 110]],
     [[83, 87], [111, 114]],
     [[83, 87], [115, 118]],
     [[88, 92], [104, 106]],
     [[88, 92], [106, 108]],
     [[88, 92], [108, 111]],
     [[88, 92], [112, 115]],
     [[88, 92], [116, 119]],
     [[88, 92], [120, 123]],
     [[93, 97], [109, 111]],
     [[93, 97], [112, 114]],
     [[93, 97], [113, 116]],
     [[93, 97], [117, 120]],
     [[93, 97], [121, 124]],
     [[93, 97], [125, 128]],
     [[98, 102], [114, 116]],
     [[98, 102], [116, 118]],
     [[98, 102], [118, 121]],
     [[98, 102], [122, 125]],
     [[98, 102], [127, 130]],
     [[98, 102], [130, 133]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Fab-Cool A042 Side Support Shaper Stretch Cotton Everyday Bra for Women- High Coverage, Non Padded and Wirefree - Skin',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/ENAMORDAY-1_1459_Front_e7ec799d-f7ac-4bae-87ca-4544ce4b6942.jpg?v=1718884874'},
   {'id': 10,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '10A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor10',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '32DD',
     '34B',
     '34C',
     '34D',
     '34DD',
     '34F',
     '34G',
     '36B',
     '36C',
     '36D',
     '36DD',
     '36F',
     '36G',
     '38B',
     '38C',
     '38D',
     '38DD',
     '38F',
     '38G',
     '40B',
     '40C',
     '40D',
     '40DD',
     '40F',
     '40G',
     '42B',
     '42C',
     '42D',
     '42DD',
     '42F',
     '42G',
     '44B',
     '44C',
     '44D',
     '44DD',
     '44F',
     '44G'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[68, 72], [90, 92]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[75, 77], [95, 96]],
     [[73, 77], [97, 100]],
     [[73, 77], [101, 104]],
     [[73, 77], [105, 108]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[78, 82], [102, 105]],
     [[78, 82], [106, 109]],
     [[78, 82], [110, 113]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]],
     [[83, 87], [107, 110]],
     [[83, 87], [111, 114]],
     [[83, 87], [115, 118]],
     [[88, 92], [104, 106]],
     [[88, 92], [106, 108]],
     [[88, 92], [108, 111]],
     [[88, 92], [112, 115]],
     [[88, 92], [116, 119]],
     [[88, 92], [120, 123]],
     [[93, 97], [109, 111]],
     [[93, 97], [112, 114]],
     [[93, 97], [113, 116]],
     [[93, 97], [117, 120]],
     [[93, 97], [121, 124]],
     [[93, 97], [125, 128]],
     [[98, 102], [114, 116]],
     [[98, 102], [116, 118]],
     [[98, 102], [118, 121]],
     [[98, 102], [122, 125]],
     [[98, 102], [127, 130]],
     [[98, 102], [130, 133]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor BambooBliss A077 Ultimate Softness Innovation Bamboo Cotton Full Support T-shirt Bra for Women- High Coverage, Padded and Wirefree',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/ENAMOR_DAY-1_3071_Look.jpg?v=1719230353w'},
   {'id': 11,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '11A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'avatar': 'enamor11',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '32DD',
     '34B',
     '34C',
     '34D',
     '34DD',
     '34F',
     '34G',
     '36B',
     '36C',
     '36D',
     '36DD',
     '36F',
     '36G',
     '38B',
     '38C',
     '38D',
     '38DD',
     '38F',
     '38G',
     '40B',
     '40C',
     '40D',
     '40DD',
     '40F',
     '40G',
     '42B',
     '42C',
     '42D',
     '42DD',
     '42F',
     '42G',
     '44B',
     '44C',
     '44D',
     '44DD',
     '44F',
     '44G'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[68, 72], [90, 92]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[75, 77], [95, 96]],
     [[73, 77], [97, 100]],
     [[73, 77], [101, 104]],
     [[73, 77], [105, 108]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[78, 82], [102, 105]],
     [[78, 82], [106, 109]],
     [[78, 82], [110, 113]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]],
     [[83, 87], [107, 110]],
     [[83, 87], [111, 114]],
     [[83, 87], [115, 118]],
     [[88, 92], [104, 106]],
     [[88, 92], [106, 108]],
     [[88, 92], [108, 111]],
     [[88, 92], [112, 115]],
     [[88, 92], [116, 119]],
     [[88, 92], [120, 123]],
     [[93, 97], [109, 111]],
     [[93, 97], [112, 114]],
     [[93, 97], [113, 116]],
     [[93, 97], [117, 120]],
     [[93, 97], [121, 124]],
     [[93, 97], [125, 128]],
     [[98, 102], [114, 116]],
     [[98, 102], [116, 118]],
     [[98, 102], [118, 121]],
     [[98, 102], [122, 125]],
     [[98, 102], [127, 130]],
     [[98, 102], [130, 133]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Smooth Super Lift Classic Full Support Bra | A112',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/A112-0118_Look_adbf7811-6512-4aea-9deb-59d6688b2403.jpg?v=1718885267'},
   {'id': 12,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '12A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor12',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 13,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '13A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor13',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 14,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '14A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor14',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 15,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '15A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor15',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 16,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '16A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor16',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 17,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '17A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor17',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 18,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '18A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor18',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 19,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '19A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor19',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 20,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '20A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor20',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 21,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '21A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor21',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 22,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '22A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor22',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 23,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '23A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor23',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 24,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '24A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor24',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 25,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '25A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor25',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 26,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '26A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor26',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 27,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '27A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor27',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 28,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '28A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor28',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 29,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '29A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor29',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 30,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '30A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor30',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 31,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '31A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor31',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 32,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '32A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor32',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 33,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '33A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor33',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 34,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '34A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor34',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 35,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '35A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor35',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 36,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '36A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor36',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 37,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '37A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor37',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 38,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '38A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor38',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 39,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '39A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor39',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 40,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '40A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor40',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 41,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '41A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor41',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 42,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '42A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor42',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 43,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '43A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor43',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 44,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '44A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor44',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 45,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '45A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor45',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 46,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '46A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor46',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 47,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '47A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor47',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 48,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '48A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor48',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 49,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '49A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor49',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 50,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '50A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor50',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 51,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '51A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor51',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 52,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '52A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor52',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 53,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '53A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor53',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 54,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '54A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor54',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 55,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '55A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor55',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 56,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '56A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor56',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 57,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '57A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor57',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 58,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '58A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor58',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 59,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '59A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor59',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 60,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '60A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor60',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 61,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '61A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor61',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 62,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '62A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor62',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 63,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '63A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor63',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 64,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '64A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor64',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 65,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '65A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor65',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 66,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '66A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor66',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 67,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '67A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor67',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 68,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '68A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor68',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 69,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '69A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor69',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 70,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '70A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor70',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 71,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '71A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor71',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 72,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '72A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor72',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 73,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '73A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor73',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 74,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '74A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor74',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 75,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '75A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor75',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 76,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '76A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor76',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'},
   {'id': 77,
    'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
    'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
    'groupId': 'BHTS24031',
    'product_path': '77A_0',
    'product_counter_part': '',
    'garment_type': 'top',
    'gender': 'female',
    'brand': 'enamor',
    'primary': 'bra',
    'avatar': 'enamor77',
    'shoes': '',
    'productSizeTags': ['32B',
     '32C',
     '32D',
     '34B',
     '34C',
     '34D',
     '36B',
     '36C',
     '36D',
     '38B',
     '38C',
     '38D'],
    'productSizeValues': [[[68, 72], [84, 86]],
     [[68, 72], [86, 88]],
     [[68, 72], [88, 90]],
     [[73, 77], [89, 91]],
     [[73, 77], [91, 93]],
     [[73, 77], [91, 93]],
     [[78, 82], [94, 96]],
     [[78, 82], [96, 98]],
     [[78, 82], [98, 101]],
     [[83, 87], [99, 101]],
     [[83, 87], [101, 103]],
     [[83, 87], [103, 106]]],
    'productCounterPartSizeTags': ['28',
     '30',
     '32',
     '34',
     '36',
     '38',
     '40',
     '42'],
    'productCounterPartSizeValues': [[83.82, 98.6],
     [86.36, 101.6],
     [88.9, 104.6],
     [91.44, 107.57],
     [96.52, 113.56],
     [104.14, 122.53],
     [109.22, 128.5],
     [114.3, 134.47]],
    'productTitle': 'Enamor Perfect Coverage Supima Cotton T-Shirt Bra For Everyday Comfort - Padded, Non-Wired Bra & Medium Coverage Bra | A039',
    'productImage': 'https://www.enamor.co.in/cdn/shop/files/1_e12bc08a-cd10-4ade-a261-a02a17f6a39d.jpg?v=1725886023'}]

export default enamorProductList;