import AHDRG0845RG from 'assets/images/abfrl/AHDRG0845RG.png';
import VWDRG1150RG from 'assets/images/abfrl/VWDRG1150RG.jpg';
import ALKCG0224SG from 'assets/images/abfrl/ALKCG0224SG.jpeg';
import VAPTG0010KPNJ from 'assets/images/abfrl/VAPTG0010KPNJ.jpeg';
import VASRG0004RG from 'assets/images/abfrl/VASRG0004RG.jpeg';
import PXSFG0041SS from 'assets/images/abfrl/PXSFG0041SS.jpeg';
import LPTFG0154CR from 'assets/images/abfrl/LPTFG0154CR.jpeg';
import AHKCG0014RG from 'assets/images/abfrl/AHKCG0014RG.jpeg';
import AHKBG0141RG from 'assets/images/abfrl/AHKBG0141RG.jpeg';
import AHCTG0395RG from 'assets/images/abfrl/AHCTG0395RG.jpeg';
import AHCTG0238RG from 'assets/images/abfrl/AHCTG0238RG.jpeg';
import AHDNG0177RX from 'assets/images/abfrl/AHDNG0177RX.jpeg';
import VWSTG0025RG from 'assets/images/abfrl/VWSTG0025RG.jpeg';
import VWJSG0019RG from 'assets/images/abfrl/VWJSG0019RG.jpeg';
import AHDRG0889RG from 'assets/images/abfrl/AHDRG0889RG.jpeg';
import E6 from 'assets/images/abfrl/IMG_0055.jpeg';
import E7 from 'assets/images/abfrl/IMG_0070.jpeg';

export const abfrlV2ProductList = [
    // {
    //     id: 3,
    //     src: E5,
    //     product_path: "AE270223TS_0",
    //     product_counter_part: "AS270223DJ_0",
    //     garment_type: "top",
    //     gender: "female",
    //     productPath: "/abfrl/idrisTshirt.glb",
    //     environmentImage: "",
    //     name: "Louis Philippe Sport Tshirt ",
    //     labels: "Louis Philippe Sport - LYKPCSLPW53691",
    //     description:
    //       "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
    //     bullet_description: [
    //       "Regular fit",
    //       "100% cotton",
    //       "Half Sleeves",
    //       "Ocassion: Casual",
    //       "Neck: Polo Neck",
    //       "Pattern: Stripe",
    //       "Color: White",
    //       "Collection: LY True Casual"
    //     ],
    //     price: "$210",
    //     brand: "abfrl",
    //     avatar: "v12",
    //     shoes: 'ae01'
    // },
]

export const abfrlProductList = [
    // {
    //   id: 0,
    //   src: E1,
    //   product_path: "v3/LPKPG0510RG_0",
    //   product_counter_part: "v3/LPTFG0043SLTS_0",
    //   garment_type: "top",
    //   gender: "male",
    //   productPath: "/abfrl/idrisTshirt.glb",
    //   environmentImage: "",
    //   name: "Louis Philippe Sport Tshirt ",
    //   labels: "Louis Philippe Sport - LYKPCSLPW53691",
    //   description:
    //     "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
    //   bullet_description: [
    //     "Regular fit",
    //     "100% cotton",
    //     "Half Sleeves",
    //     "Ocassion: Casual",
    //     "Neck: Polo Neck",
    //     "Pattern: Stripe",
    //     "Color: White",
    //     "Collection: LY True Casual"
    //   ],
    //   price: "$210",
    //   brand: "abfrl",
    //   avatar: "v4",
    // },
    // {
    //   id: 1,
    //   src: E2,
    //   product_path: "v3/PESFG0205SL_0",
    //   product_counter_part: "v3/LPTFG0043SL_0",
    //   garment_type: "top",
    //   gender: "male",
    //   productPath: "/abfrl/idrisShirt.glb",
    //   environmentImage: "/environment/pillars_1k.env",
    //   name: "Louis Philippe Casual Shirt ",
    //   labels: "Louis Philippe Jeans - LRSFCSLPW06094",
    //   description:
    //     "Showcase your refined sartorial taste in this Pink Check shirt from Louis Philippe Jeans at a Casual event. Tailored in Slim Fit , this shirt features a Regular Collar , Full Sleeves , and is crafted from exquisite 100% Cotton.",
    //   bullet_description: [
    //     "Slim fit",
    //     "100% cotton",
    //     "Full Sleeves",
    //     "Regular Cuff",
    //     "Ocassion: Casual",
    //     "Neck: Polo Neck",
    //     "Pattern: Check",
    //     "Color: Pink",
    //     "Collection: LR Collection"
    //   ],
    //   price: "$210",
    //   brand: "abfrl",
    //   avatar: "v4",
    // },
    // {
    //   id: 2,
    //   src: E3,
    //   product_path: "v3/LPTFG0043SL_0",
    //   product_counter_part: "v3/PESFG0205SL_0",
    //   garment_Type: "top",
    //   gender: "male",
    //   productPath: "/abfrl/idrisTshirt.glb",
    //   environmentImage: "/environment/blue_lagoon_04k.env",
    //   exposure: "0.6",
    //   name: "Louis Philippe  ",
    //   labels: "Louis Philippe Sport - LYTFACTPX98750",
    //   description:
    //     "A pair of well-tailored trousers is a great style investment to a gentleman's wardrobe. These Grey Check Slim Fit trousers from Louis Philippe Sport by Louis Philippe are both smart and comfortable.",
    //   bullet_description: [
    //     "Slim fit",
    //     "100% cotton",
    //     "Ocassion: Casual",
    //     "Trouser Front: Flat Front",
    //     "Pattern: Check",
    //     "Color: Grey",
    //     "Collection: LY Athwork",
    //   ],
    //   price: "$210",
    //   brand: "abfrl",
    //   avatar: "v4",
    // },
    {
        id: 4,
        src: VWDRG1150RG,
        product_path: "VWDRG1150RG_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#FFFFFF",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v15",
        shoes: 'VWDRG1150RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[81.2, 63.4], [85.2, 67.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 5,
        src: AHDRG0845RG,
        product_path: "AHDRG0845RG_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#8E5858",
        changeableItemEmissiveColor: "#F2A9A9",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v15",
        shoes: 'VWDRG1150RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 6,
        src: E6,
        product_path: "VWTSG1553RG_0",
        product_counter_part: "AHKBG0036RG_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[81.2, 63.4], [85.2, 67.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: ["26", "28", "30", "32", "34", "36", "38"],
        productCounterPartSizeValues: [[71.3, 62.4], [76.3, 67.4], [81.3, 72.4], [86.3, 77.4], [92.3, 82.4], [98.3, 87.4], [104.3, 92.4],]
    },
    {
        id: 7,
        src: E7,
        product_path: "AHKBG0036RG_0",
        product_counter_part: "VWTSG1553RG_0",
        garment_type: "bottom",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "BALANCE HI RISE LEGGINGS",
        labels: "Van Heusen Woman - AHKBG0036RG",
        description:
            "These full-length high-waisted leggings from our Proactive collection will support you no matter how intense your workout. Designed in a regular fit, they feature a sculpted high waist for a flattering outline. Utility pockets on the side keep your hands free. The premium fabric wicks moisture away and dries quickly to keep you feeling cool during workout.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["26", "28", "30", "32", "34", "36", "38"],
        productSizeValues: [[71.3, 62.4], [76.3, 67.4], [81.3, 72.4], [86.3, 77.4], [92.3, 82.4], [98.3, 87.4], [104.3, 92.4],],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[81.2, 63.4], [85.2, 67.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],]
    },
    // {
    //   id: 8,
    //   src: LRSHG0131SL,
    //   product_path: "LRSHG0131SL_0",
    //   product_counter_part: "LRSHG0131SLBP_0",
    //   garment_type: "top",
    //   gender: "male",
    //   productPath: "/abfrl/idrisTshirt.glb",
    //   environmentImage: "",
    //   name: "Louis Philippe Sport Tshirt ",
    //   labels: "Louis Philippe Sport - LYKPCSLPW53691",
    //   description:
    //     "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
    //   bullet_description: [
    //     "Regular fit",
    //     "100% cotton",
    //     "Half Sleeves",
    //     "Ocassion: Casual",
    //     "Neck: Polo Neck",
    //     "Pattern: Stripe",
    //     "Color: White",
    //     "Collection: LY True Casual"
    //   ],
    //   price: "$210",
    //   brand: "abfrl",
    //   avatar: "v9",
    //   shoes: 'LYSFG0170SLv9shoes',
    //   productSizeTags: ["38",	"39",	"40",	"41",	"42",	"43",	"44",	"45",	"46",	"47",	"48"],
    //   productSizeValues:[[86.5,70.5],	[91.5,75.5],	[96.5,80.5],	[100.1,84.5],	[103.5,88.5],	[107.1,94.5],	[110.5,100.5],	[113.5,106.5],	[116.5,112.5],	[119.5,117.5],	[122.5,122.5],],
    //   productCounterPartSizeTags: ["26",	"28",	"30",	"32",	"34",	"36",	"38",	"40",	"42",	"44",	"46",	"48",	"50"],
    //   productCounterPartSizeValues:[[68.3,60.5],	[73.3,65.5],	[78.3,70.5],	[83.3,75.5],	[88.3,80.5],	[93.3,85.5],	[98.3,90.5],	[103.3,95.5],	[108.3,100.5],	[113.3,105.5],	[118.3,110.5],	[123.3,115.5],	[128.3,120.5],]
    // },
    // {
    //   id: 9,
    //   src: LYSFG0170SL,
    //   product_path: "LYSFG0170SL_0",
    //   product_counter_part: "LYSFG0170SLBP_0",
    //   garment_type: "top",
    //   gender: "male",
    //   productPath: "/abfrl/idrisTshirt.glb",
    //   environmentImage: "",
    //   name: "Louis Philippe Sport Tshirt ",
    //   labels: "Louis Philippe Sport - LYKPCSLPW53691",
    //   description:
    //     "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
    //   bullet_description: [
    //     "Regular fit",
    //     "100% cotton",
    //     "Half Sleeves",
    //     "Ocassion: Casual",
    //     "Neck: Polo Neck",
    //     "Pattern: Stripe",
    //     "Color: White",
    //     "Collection: LY True Casual"
    //   ],
    //   price: "$210",
    //   brand: "abfrl",
    //   avatar: "v9",
    //   shoes: 'LYSFG0170SLv9shoes',
    //   productSizeTags: ["37",	"38",	"39",	"40",	"41",	"42",	"43",	"44",	"45",	"46",	"47",	"48",	"49",	"50"],
    //   productSizeValues:[[81.5,65.5],	[86.5,70.5],	[91.5,75.5],	[96.5,80.5],	[100.1,84.5],	[103.5,88.5],	[107.1,94.5],	[110.5,100.5],	[113.5,106.5],	[116.5,112.5],	[119.5,117.5],	[122.5,122.5],	[125.5,127.5],	[128.5,132.5],],
    //   productCounterPartSizeTags: ["26",	"28",	"30",	"32",	"34",	"36",	"38",	"40",	"42",	"44",	"46",	"48",	"50"],
    //   productCounterPartSizeValues:[[68.3,60.5],	[73.3,65.5],	[78.3,70.5],	[83.3,75.5],	[88.3,80.5],	[93.3,85.5],	[98.3,90.5],	[103.3,95.5],	[108.3,100.5],	[113.3,105.5],	[118.3,110.5],	[123.3,115.5],	[128.3,120.5],]

    // },
    {
        id: 15,
        src: AHKCG0014RG,
        product_path: "AHKCG0014RG_0",
        product_counter_part: "AHKBG0141RG_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        sharpMaterialsList: ["PT_FABRIC_FRONT_1485263", "PT_FABRIC_FRONT_1504691"],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: ["26", "28", "30", "32", "34", "36", "38"],
        productCounterPartSizeValues: [[71.3, 62.4], [76.3, 67.4], [81.3, 72.4], [86.3, 77.4], [92.3, 82.4], [98.3, 87.4], [104.3, 92.4],]
    },
    {
        id: 16,
        src: AHKBG0141RG,
        product_path: "AHKBG0141RG_0",
        product_counter_part: "AHKCG0014RG_0",
        garment_type: "bottom",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["26", "28", "30", "32", "34", "36", "38"],
        productSizeValues: [[71.3, 62.4], [76.3, 67.4], [81.3, 72.4], [86.3, 77.4], [92.3, 82.4], [98.3, 87.4], [104.3, 92.4],],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],]
    },
    {
        id: 17,
        src: AHCTG0395RG,
        product_path: "AHCTG0395RG_0",
        product_counter_part: "AHKBG0036RGX_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: ["26", "28", "30", "32", "34", "36", "38"],
        productCounterPartSizeValues: [[71.3, 62.4], [76.3, 67.4], [81.3, 72.4], [86.3, 77.4], [92.3, 82.4], [98.3, 87.4], [104.3, 92.4],]
    },
    {
        id: 18,
        src: AHCTG0238RG,
        product_path: "AHCTG0238RG_0",
        product_counter_part: "AHDNG0177RX_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: ["26", "27", "28", "29", "30", "32", "34", "36", "38"],
        productCounterPartSizeValues: [[71.3, 62.4], [73.8, 64.9], [76.3, 67.4], [78.8, 69.9], [81.3, 72.4], [83.8, 74.9], [86.8, 77.4], [89.8, 79.9], [92.8, 82.4]]
    },
    {
        id: 19,
        src: AHDNG0177RX,
        product_path: "AHDNG0177RX_0",
        product_counter_part: "AHCTG0238RG_0",
        garment_type: "bottom",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["26", "27", "28", "29", "30", "32", "34", "36", "38"],
        productSizeValues: [[71.3, 62.4], [73.8, 64.9], [76.3, 67.4], [78.8, 69.9], [81.3, 72.4], [83.8, 74.9], [86.8, 77.4], [89.8, 79.9], [92.8, 82.4]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],]
    },
    {
        id: 20,
        src: VWSTG0025RG,
        product_path: "VWSTG0025RG_0",
        product_counter_part: "AHDNG0177RXY_0",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[80.2, 63.4], [85.2, 67.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: ["26", "27", "28", "29", "30", "32", "34", "36", "38"],
        productCounterPartSizeValues: [[71.3, 62.4], [73.8, 64.9], [76.3, 67.4], [78.8, 69.9], [81.3, 72.4], [83.8, 74.9], [86.8, 77.4], [89.8, 79.9], [92.8, 82.4]],
    },
    {
        id: 21,
        src: VWJSG0019RG,
        product_path: "VWJSG0019RG_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "WOMEN BLUE TEXTURED CASUAL V NECK TOP",
        labels: "Van Heusen Woman - VWTSG1553RG",
        description:
            "This V Neck Blue Textured top from Van Heusen is a perfect addition to your style repertoire.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v13",
        shoes: 'VWTSG1553RGAHKBG0036RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [100.2, 82.4], [106.2, 88.4], [112.2, 94.4], [117.2, 99.4]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[76.3, 68.4], [81.3, 72.4], [86.3, 77.4], [91.3, 82.4], [97.3, 88.4], [103.3, 94.4], [108.3, 99.4]],
    },
    {
        id: 22,
        src: AHDRG0889RG,
        product_path: "AHDRG0889RG_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        changeableItemAlbedoColor: "#00FF00",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v15",
        shoes: 'VWDRG1150RGShoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4]],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 10,
        src: ALKCG0224SG,
        product_path: "ALKCG0224SG_0",
        product_counter_part: "VAPTG0010KPNJ_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v9",
        shoes: 'LYSFG0170SLv9shoes',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[86.5, 70.5], [91.5, 75.5], [96.5, 80.5], [101.5, 85.5], [109.1, 93.1], [116.7, 100.7], [121.7, 105.7],],
        productCounterPartSizeTags: ["S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[73.3, 65.5], [78.3, 70.5], [83.3, 75.5], [88.3, 80.5], [93.3, 85.5], [98.3, 90.5]]
    },
    {
        id: 11,
        src: VAPTG0010KPNJ,
        product_path: "VAPTG0010KPNJ_0",
        product_counter_part: "ALKCG0224SG_0",
        garment_type: "bottom",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v9",
        shoes: 'LYSFG0170SLv9shoes',
        productSizeTags: ["S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[73.3, 65.5], [78.3, 70.5], [83.3, 75.5], [88.3, 80.5], [93.3, 85.5], [98.3, 90.5]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[86.5, 70.5], [91.5, 75.5], [96.5, 80.5], [101.5, 85.5], [109.1, 93.1], [116.7, 100.7], [121.7, 105.7],]
    },
    {//need to be calculated size chart
        id: 12,
        src: VASRG0004RG,
        product_path: "VASRG0004RG_0",
        product_counter_part: "ALKCG0224SGX_0",
        garment_type: "bottom",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v9",
        shoes: 'LYSFG0170SLv9shoes',
        productSizeTags: ["28", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50"],
        productSizeValues: [[68.3, 60.5], [73.3, 65.5], [78.3, 70.5], [83.3, 75.5], [88.3, 80.5], [93.3, 85.5], [98.3, 90.5], [103.3, 95.5], [108.3, 100.5], [113.3, 105.5], [118.3, 110.5], [123.3, 115.5]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productCounterPartSizeValues: [[86.5, 70.5], [91.5, 75.5], [96.5, 80.5], [101.5, 85.5], [109.1, 93.1], [116.7, 100.7], [121.7, 105.7],],
    },
    {
        id: 13,
        src: PXSFG0041SS,
        product_path: "PXSFG0041SS_0",
        product_counter_part: "LPTFG0154CR_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v10",
        shoes: 'niOutfit',
        productSizeTags: ["36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46"],
        productSizeValues: [[84.5, 66.5], [87.5, 69.9], [90.5, 73.5], [93.5, 76.5], [96.5, 80.5], [99.1, 84.1], [101.5, 87.5], [105.5, 91.5], [109.5, 95.5], [114.1, 100.5], [118.5, 105.5],],
        productCounterPartSizeTags: ["28", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50"],
        productCounterPartSizeValues: [[68.3, 60.5], [73.3, 65.5], [78.3, 70.5], [83.3, 75.5], [88.3, 80.5], [93.3, 85.5], [98.3, 90.5], [103.3, 95.5], [108.3, 100.5], [113.3, 105.5], [118.3, 110.5], [123.3, 115.5]]
    },
    {
        id: 14,
        src: LPTFG0154CR,
        product_path: "LPTFG0154CR_0",
        product_counter_part: "PXSFG0041SS_0",
        garment_type: "bottom",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "abfrl",
        avatar: "v7",
        shoes: 'niOutfit',
        productSizeTags: ["28", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50"],
        productSizeValues: [[68.3, 60.5], [73.3, 65.5], [78.3, 70.5], [83.3, 75.5], [88.3, 80.5], [93.3, 85.5], [98.3, 90.5], [103.3, 95.5], [108.3, 100.5], [113.3, 105.5], [118.3, 110.5], [123.3, 115.5]],
        productCounterPartSizeTags: ["36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46"],
        productCounterPartSizeValues: [[84.5, 66.5], [87.5, 69.9], [90.5, 73.5], [93.5, 76.5], [96.5, 80.5], [99.1, 84.1], [101.5, 87.5], [105.5, 91.5], [109.5, 95.5], [114.1, 100.5], [118.5, 105.5],]
    },
    // {
    //   id: 8,
    //   src: LRSHG0131SL,
    //   product_path: "LRSHG0131SL_0",
    //   product_counter_part: "LRSHG0131SLBP_0",
    //   garment_type: "top",
    //   gender: "male",
    //   productPath: "/abfrl/idrisTshirt.glb",
    //   environmentImage: "",
    //   name: "Louis Philippe Sport Tshirt ",
    //   labels: "Louis Philippe Sport - LYKPCSLPW53691",
    //   description:
    //     "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
    //   bullet_description: [
    //     "Regular fit",
    //     "100% cotton",
    //     "Half Sleeves",
    //     "Ocassion: Casual",
    //     "Neck: Polo Neck",
    //     "Pattern: Stripe",
    //     "Color: White",
    //     "Collection: LY True Casual"
    //   ],
    //   price: "$210",
    //   brand: "abfrl",
    //   avatar: "v8",
    //   shoes: 'LRSHG0131SLv8shoes'
    // },
    // {
    //   id: 9,
    //   src: LYSFG0170SL,
    //   product_path: "LYSFG0170SL_0",
    //   product_counter_part: "LYSFG0170SLBP_0",
    //   garment_type: "top",
    //   gender: "male",
    //   productPath: "/abfrl/idrisTshirt.glb",
    //   environmentImage: "",
    //   name: "Louis Philippe Sport Tshirt ",
    //   labels: "Louis Philippe Sport - LYKPCSLPW53691",
    //   description:
    //     "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
    //   bullet_description: [
    //     "Regular fit",
    //     "100% cotton",
    //     "Half Sleeves",
    //     "Ocassion: Casual",
    //     "Neck: Polo Neck",
    //     "Pattern: Stripe",
    //     "Color: White",
    //     "Collection: LY True Casual"
    //   ],
    //   price: "$210",
    //   brand: "abfrl",
    //   avatar: "v8",
    //   shoes: 'LRSHG0131SLv8shoes'
    // }
]