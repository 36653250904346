import SaveTheTreesMen from 'assets/images/ccclub/Save_the_trees_men.jpg';

const lacosteDemoList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': "Rare Rabbit Men's Zavet-T Light Green Cotton Polyester Fabric Short Sleeves Crew Neck Oversized Fit Self Stripe Textured T-Shirt",
        'productImage': 'https://thehouseofrare.com/cdn/shop/files/ZAVET-TLIGHTGREEN__4_3b09be07-a397-4207-a0e0-3dc4e1e9e37d.jpg?v=1718173522',
    },
    {
        'id': 5,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '5A_0',
        'product_counter_part': '5B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': "Rare Rabbit Men\'s Crofty Blue Cotton Lycra Fabric Full Sleeves Collared Neck Regular Fit Plain Shirt",
        'productImage': 'https://thehouseofrare.com/cdn/shop/files/CROFTYBLUE06837.jpg?v=1720244301'
    },
    {
        'id': 6,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '6A_0',
        'product_counter_part': '6B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': "RARE RABBIT GRAPHIC BACK PRINT OVERSIZED T-SHIRT MINT - OFF WHITE",
        'productImage': 'https://thehouseofrare.com/cdn/shop/files/Mintoffwhite__MINTOFFWHITE03150.jpg?v=1722577495'
        // 'productTitle': "RARE RABBIT COTTON STRETCH PLAIN TROUSERS POKE-2-WHITE",
        // 'productImage': 'https://thehouseofrare.com/cdn/shop/files/poke2white-3079.jpg?v=1689675599'
    },
    {
        'id': 221,
        'productSlug': 'being-human-men-regular-fit-t-shirts-white-8905271452676',
        'productCounterPartSlug': 'being-human-men-cropped-fit-denim-light-tone-8905271464167',
        'groupId': 'BHP24051',
        'product_path': '221A_0',
        'product_counter_part': '221B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'beinghuman',
        'avatar': 'v14',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42',
            '44'],
        'productCounterPartSizeValues': [[81.28, 95.63],
        [83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [93.98, 110.57],
        [101.6, 119.53],
        [106.68, 125.5],
        [111.76, 131.47],
        [116.84, 137.46]],
        'productTitle': 'Being Human Men Regular Fit T-Shirts-White',
        'productImage': 'https://pictures.kartmax.in/cover/live/600x650/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24051-WHITE_1.jpg'
    },
    {
        id: 266,
        src: SaveTheTreesMen,
        productSlug: 'being-human-men-regular-fit-t-shirts-white-8905271452676',
        product_path: "266A_0",
        product_counter_part: "266B_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        name: "Being Human",
        labels: "SS24000133",
        description: "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beinghuman",
        avatar: "v14",
        shoes: "677865",
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        productCounterPartSizeTags: [
            "XS",
            "S",
            "M",
            "L",
            "XL",
            "XXL"
        ],
        productCounterPartSizeValues: [
            [
                81.28,
                63.5
            ],
            [
                88.9,
                72.39
            ],
            [
                96.52,
                80.01
            ],
            [
                104.14,
                88.9
            ],
            [
                111.76,
                97.79
            ],
            [
                119.38,
                105.41
            ]
        ],
        productImage: "https://pictures.kartmax.in/cover/live/600x800/quality=6/sites/w0ACRcy407Pb14QWUoSQ/product-images/BHP24029-POWDER%20PINK_6.jpg"
    },
];

export default lacosteDemoList;