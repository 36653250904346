const rarerabbitDemoList = [
    {
        'id': 1,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '1A_0',
        'product_counter_part': '1B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': "Rare Rabbit Men's Zavet-T Light Green Cotton Polyester Fabric Short Sleeves Crew Neck Oversized Fit Self Stripe Textured T-Shirt",
        'productImage': 'https://thehouseofrare.com/cdn/shop/files/ZAVET-TLIGHTGREEN__4_3b09be07-a397-4207-a0e0-3dc4e1e9e37d.jpg?v=1718173522',
    },
    {
        'id': 5,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '5A_0',
        'product_counter_part': '5B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': "Rare Rabbit Men\'s Crofty Blue Cotton Lycra Fabric Full Sleeves Collared Neck Regular Fit Plain Shirt",
        'productImage': 'https://thehouseofrare.com/cdn/shop/files/CROFTYBLUE06837.jpg?v=1720244301'
    },
    {
        'id': 6,
        'productSlug': 'being-human-men-none-t-shirts-green-foam-8905271469155',
        'productCounterPartSlug': 'being-human-men-skinnyfit-denim-mid-tone-8905271467892',
        'groupId': 'BHTS24031',
        'product_path': '6A_0',
        'product_counter_part': '6B_0',
        'garment_type': 'top',
        'gender': 'male',
        'brand': 'rarerabbit',
        'avatar': 'v15',
        'shoes': '677865',
        'productSizeTags': ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
        'productSizeValues': [104.14,
            109.22,
            114.3,
            121.92,
            127.0,
            132.08,
            137.16,
            142.24],
        'productCounterPartSizeTags': ['28',
            '30',
            '32',
            '34',
            '36',
            '38',
            '40',
            '42'],
        'productCounterPartSizeValues': [[83.82, 98.6],
        [86.36, 101.6],
        [88.9, 104.6],
        [91.44, 107.57],
        [96.52, 113.56],
        [104.14, 122.53],
        [109.22, 128.5],
        [114.3, 134.47]],
        'productTitle': "RARE RABBIT GRAPHIC BACK PRINT OVERSIZED T-SHIRT MINT - OFF WHITE",
        'productImage': 'https://thehouseofrare.com/cdn/shop/files/Mintoffwhite__MINTOFFWHITE03150.jpg?v=1722577495'
        // 'productTitle': "RARE RABBIT COTTON STRETCH PLAIN TROUSERS POKE-2-WHITE",
        // 'productImage': 'https://thehouseofrare.com/cdn/shop/files/poke2white-3079.jpg?v=1689675599'
    },
];

export default rarerabbitDemoList;
