import HalfCard from 'components/HalfCard';
import React, { useEffect, useRef, useState } from 'react';
import "./CatalogList.scss";
import { IFRAME_ACTIONS } from 'actions/IframeActions';
import MixPanel from 'appAnalytics/mixPanel';
import { EVENT_ACTIONS } from 'appAnalytics/eventCategories';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { styled } from '@mui/material/styles';
import { isIpad, isIphone, isTouchFriendly } from 'utils';

const Puller = styled('div')(({ theme }) => ({
    width: 60,
    height: 5,
    backgroundColor: '#888888',
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 30px)',
}));
const CatalogList = ({ isFirstTimeUser, showCatalogList, setShowCatalogList, catalogList = [], selectedProduct, loading, eventCategory, isShoeViewMode }) => {
    const [container, setContainer] = useState(null);
    const containerRef = useRef(null);
    const handleProductSelection = (product) => {
        if (!loading) {
            MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.NEW_PRODUCT_SELECTED, { ...product });
            window.parent.postMessage({ message: IFRAME_ACTIONS.NEW_PRODUCT_SELECTED, value: product }, "*");
        }
    }
    useEffect(() => {
        let container = document.getElementById('swipeContainer');
        if (container) {
            container.remove();
        }
        container = document.createElement('div');
        container.id = 'swipeContainer';
        const sibling = document.querySelector('.WO_OF_FloatingButtonContainer');
        sibling.append(container);
        setContainer(container);
    }, [isFirstTimeUser, isShoeViewMode]);

    const drawerBleeding = isFirstTimeUser || isShoeViewMode ? (showCatalogList ? 30 : 106) : showCatalogList ? 125 : 156;
    const catalogLContainerHeight = drawerBleeding - 35;
    const enableClick = (isIpad || isIphone) || !isTouchFriendly;
    const handleTouchStart = (event) => {
        const container = containerRef.current;
        if (container) {
            // Prevent the drawer swipe gesture if the content is scrollable and not at the top
            const canScrollUp = container.scrollTop > 0;
            if (canScrollUp) {
                event.stopPropagation();
            }
        }
    };
    if (!container) {
        return null;
    }
    return (
        <SwipeableDrawer
            container={container}
            anchor="bottom"
            open={showCatalogList}
            onClose={() => setShowCatalogList(false)}
            onOpen={() => setShowCatalogList(true)}
            onClick={() => setShowCatalogList(!showCatalogList)}
            swipeAreaWidth={drawerBleeding}
            sx={{
                '& .MuiPaper-root': {
                    maxHeight: `calc(60% - ${drawerBleeding}px)`,
                    overflow: 'visible',
                },
            }}
            disableBackdropTransition={true}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: -drawerBleeding,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    visibility: 'visible',
                    right: 0,
                    left: 0,
                    backgroundColor: '#ffffff',
                    height: drawerBleeding,
                    pointerEvents: !enableClick ? 'touch' : 'all',
                    cursor: 'pointer',
                    borderTop: '1px solid rgb(220, 220, 220)'
                }}
            >
                <Puller />
                <div
                    style={{ marginTop: '1.5em', fontSize: '0.8em', textAlign: 'center' }}
                >
                    {!showCatalogList ? (!enableClick ? 'Slide up to see related products' : 'Click here to see related products') : ' '}
                </div>
            </div>
            {showCatalogList &&
            <div className='Dopplr_CatalogList'
                ref={containerRef}
                style={{ marginTop: isFirstTimeUser ? '1em' : -catalogLContainerHeight }}
                onTouchStart={handleTouchStart}>
                {catalogList.map((product, i) => {
                    const isSelected = selectedProduct && selectedProduct.id === product.id;
                    return <div
                        key={i}
                        onClick={(e) => {
                            e.preventDefault();
                            handleProductSelection(product);
                        }}
                        className={`ProductCard`}
                    >
                        <div
                            className={`ProductCard__Image ${isSelected ? "selected" : "overlay"
                                }`}
                        >
                            <img src={product.productImage || product.src || ''} alt={product.productTitle} />
                        </div>
                        <div
                            className={`ProductCard__Description ${isSelected ? "selectedText" : ""
                                }`}
                        >
                            {/* {product.name} */}
                        </div>
                    </div>
                })}
            </div>
        }
        </SwipeableDrawer>
    )
    // return (
    //     <HalfCard
    //         visible={showCatalogList}
    //         key={'catalogList'}
    //         onClose={() => setShowCatalogList(false)}
    //         showCloseButton={true}
    //         showHeader={true}
    //         title={"Catalog List"}
    //         closeOnBackgroundClick={true}
    //         whiteBackground={true}
    //         bodyClassName={"Dopplr_CatalogList"}
    //         wrapperClassName={"Dopplr_CatalogList_Wrapper"}
    //         zIndex={loading ? -1 : 1}
    //     >
    //         {/* <div className='Dopplr_CatalogList'> */}
    //         {catalogList.map((product, i) => {
    //             const isSelected = selectedProduct && selectedProduct.id === product.id;
    //             return <div
    //                 key={i}
    //                 onClick={() => handleProductSelection(product)}
    //                 className={`ProductCard`}
    //             >
    //                 <div
    //                     className={`ProductCard__Image ${isSelected ? "selected" : "overlay"
    //                         }`}
    //                 >
    //                     <img src={product.productImage || product.src || ''} alt={product.productTitle} />
    //                 </div>
    //                 <div
    //                     className={`ProductCard__Description ${isSelected ? "selectedText" : ""
    //                         }`}
    //                 >
    //                     {/* {product.name} */}
    //                 </div>
    //             </div>
    //         })}
    //         {/* </div> */}
    //     </HalfCard>
    // )
};

export default CatalogList;
