import Chase1 from 'assets/images/cava/Chase1.jpg';
const beyondExtremesProductList = [
    {
        id: 0,
        src: Chase1,
        product_path: "hydrotop_0",
        product_counter_part: "stadiumbottom_0",
        garment_type: "top",
        gender: "male",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "CAVA",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "beyondextremes",
        avatar: "v12",
        shoes: '677865',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productSizeValues: [[99, 63.5], [104, 68.58], [109, 72.4], [114.3, 77.4], [119.3, 83.4], [124.4, 89.4]],
        productCounterPartSizeTags: ["XS", "S", "M", "L", "XL", "XXL"],
        productCounterPartSizeValues: [[94, 63.5], [99, 68.58], [104.14, 73.66], [109.22, 78.74], [114.3, 83.82], [119.38, 88.9]],
        fabric: {
            top: "90% Nylon, 10% Elasthane",
            bottom: "90% Nylon, 10% Elasthane",
        }
    },
];

export default beyondExtremesProductList;
