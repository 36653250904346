import OasisF1 from "assets/images/oasis/FemaleOutfit1.png";
import OasisF21 from "assets/images/oasis/FemaleOutfit21.png";
import OasisF22 from "assets/images/oasis/FemaleOutfit22.png";
import OasisF31 from "assets/images/oasis/FemaleOutfit31.png";
import OasisF32 from "assets/images/oasis/FemaleOutfit32.png";
import OasisM11 from "assets/images/oasis/MaleOutfit11.png";
import OasisM12 from "assets/images/oasis/MaleOutfit12.png";
import OasisM21 from "assets/images/oasis/MaleOutfit21.png";
import OasisM22 from "assets/images/oasis/MaleOutfit22.png";
import OasisM31 from "assets/images/oasis/MaleOutfit31.png";
import OasisM32 from "assets/images/oasis/MaleOutfit32.png";
import OasisnuIndianTop from "assets/images/oasis/nuIndianTop.png";
import OasisnuIndianBottom from "assets/images/oasis/nuIndianBottom.png";
import ValentinoM1 from "assets/images/valentino/ValentinoM1.png";
import ValentinoF1 from "assets/images/valentino/ValentinoF1.png";
import ValentinoM2 from "assets/images/valentino/ValentinoM2.png";

export const cluster1ProductList =
    [
        // {
        //   id: 19,
        //   src: Milan,
        //   product_path: "kurta",
        //   product_counter_part: "pant",
        //   garment_type: "top",
        //   gender: "male",
        //   environmentImage: "",
        //   name: "NUindian Orange Printed Chinese Collar Slim Fit Short Kurta with Beige Wool Blend Hand Woven Shawl",
        //   labels: "NUindian Orange Printed Chinese Collar Slim Fit Short Kurta with Beige Wool Blend Hand Woven Shawl",
        //   description:
        //     "Strike a balance of trendiness and sophistication with this fabulous short kurta along with a gorgeous two-tone shawl, your ethnic attire will appear great.",
        //   bullet_description: [
        //     "Material: Cotton",
        //     "Style: Fitted",
        //     "Neck: Chinese Collar",
        //     "Sleeves: Full Sleeves",
        //     "Fit: Slim Fit",
        //     "Length: Thigh Length",
        //   ],
        //   price: "$210",
        //   brand: "milan",
        //   avatar: "v6",
        //   shoes: 'mo1'
        // },
        {
            id: 3,
            src: OasisnuIndianTop,
            product_path: "mo1NItop_0",
            product_counter_part: "mo1NIbottom_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Orange Printed Chinese Collar Slim Fit Short Kurta",
            labels: "Oasis Kurta - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this kalamkari short kurta by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Orange"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 4,
            src: OasisnuIndianBottom,
            product_path: "mo1NIbottom_0",
            product_counter_part: "mo1NItop_0",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Stretch Cotton Trouser in Pastel Color",
            labels: "Oasis Trouser - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this trouser by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: Brown",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 5,
            src: OasisM11,
            product_path: "mo1sh_0",
            product_counter_part: "mo1tr_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Kalamkari Blue Shirt",
            labels: "Oasis Shirt- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this kalamkari blue shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Pattern: Stripe",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 50,
            src: OasisM11,
            product_path: "mo1sh_0",
            product_counter_part: "mo1shorts_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Kalamkari Blue Shirt",
            labels: "Oasis Shirt- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this kalamkari blue shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Pattern: Stripe",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 51,
            src: OasisM11,
            product_path: "mo1sh_1",
            product_counter_part: "mo1shorts_1",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Kalamkari Blue Shirt",
            labels: "Oasis Shirt- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this kalamkari blue shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Pattern: Stripe",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 52,
            src: OasisM11,
            product_path: "mo1sh_2",
            product_counter_part: "mo1shorts_2",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Kalamkari Blue Shirt",
            labels: "Oasis Shirt- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this kalamkari blue shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Pattern: Stripe",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 53,
            src: OasisM11,
            product_path: "mo1sh_3",
            product_counter_part: "mo1shorts_3",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Kalamkari Blue Shirt",
            labels: "Oasis Shirt- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this kalamkari blue shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Pattern: Stripe",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 6,
            src: OasisM12,
            product_path: "mo1tr_0",
            product_counter_part: "mo1sh_0",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Stretch Cotton Trouser in Pastel Color",
            labels: "OASIS Trouser- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this stretch cotton trouser by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 7,
            src: OasisM21,
            product_path: "mo2ss_0",
            product_counter_part: "mo2tr_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "V-Neck Blue Tshirt",
            labels: "OASIS Tshirts - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this V-Neck blue tshirts by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 71,
            src: OasisM21,
            product_path: "mo2ss_1",
            product_counter_part: "mo2tr_1",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "V-Neck Blue Tshirt",
            labels: "OASIS Tshirts - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this V-Neck blue tshirts by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'v8shoesrbk'
        },
        {
            id: 72,
            src: OasisM21,
            product_path: "mo2ss_2",
            product_counter_part: "mo2tr_2",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "V-Neck Blue Tshirt",
            labels: "OASIS Tshirts - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this V-Neck blue tshirts by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'v8shoesrbk'
        },
        {
            id: 73,
            src: OasisM21,
            product_path: "mo2ss_3",
            product_counter_part: "mo2tr_3",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "V-Neck Blue Tshirt",
            labels: "OASIS Tshirts - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this V-Neck blue tshirts by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'v8shoesrbk'
        },
        {
            id: 8,
            src: OasisM22,
            product_path: "mo2tr_0",
            product_counter_part: "mo2ss_0",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Stretch Cotton Trouser in Pastel Color",
            labels: "OASIS Trouser - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this stretch cotton trouse by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: Brown",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 81,
            src: OasisM22,
            product_path: "mo2tr_1",
            product_counter_part: "mo2ss_1",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Stretch Cotton Trouser in Pastel Color",
            labels: "OASIS Trouser - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this stretch cotton trouse by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: Brown",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'v8shoesrbk'
        },
        {
            id: 82,
            src: OasisM22,
            product_path: "mo2tr_2",
            product_counter_part: "mo2ss_2",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Stretch Cotton Trouser in Pastel Color",
            labels: "OASIS Trouser - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this stretch cotton trouse by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: Brown",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'v8shoesrbk'
        },
        {
            id: 83,
            src: OasisM22,
            product_path: "mo2tr_3",
            product_counter_part: "mo2ss_3",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Stretch Cotton Trouser in Pastel Color",
            labels: "OASIS Trouser - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this stretch cotton trouse by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: Brown",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'v8shoesrbk'
        },
        {
            id: 9,
            src: OasisM31,
            product_path: "mo3sh_0",
            product_counter_part: "mo3tr_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Shirt",
            labels: "OASIS Shirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this blue checked sleeve shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 10,
            src: OasisM32,
            product_path: "mo3tr_0",
            product_counter_part: "mo3sh_0",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Trouser",
            labels: "OASIS Trouser- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Blue Cotton Trouser by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'mo1'
        },
        {
            id: 101,
            src: OasisM31,
            product_path: "mo3sh_1",
            product_counter_part: "mo3tr_1",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Shirt",
            labels: "OASIS Shirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this blue checked sleeve shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 102,
            src: OasisM31,
            product_path: "mo3sh_2",
            product_counter_part: "mo3tr_2",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Shirt",
            labels: "OASIS Shirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this blue checked sleeve shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 103,
            src: OasisM31,
            product_path: "mo3sh_3",
            product_counter_part: "mo3tr_3",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Shirt",
            labels: "OASIS Shirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this blue checked sleeve shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 104,
            src: OasisM31,
            product_path: "mo3tr_1",
            product_counter_part: "mo3sh_1",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Shirt",
            labels: "OASIS Shirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this blue checked sleeve shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 105,
            src: OasisM31,
            product_path: "mo3tr_2",
            product_counter_part: "mo3sh_2",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Shirt",
            labels: "OASIS Shirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this blue checked sleeve shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 106,
            src: OasisM31,
            product_path: "mo3tr_3",
            product_counter_part: "mo3sh_3",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Cotton Shirt",
            labels: "OASIS Shirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this blue checked sleeve shirt by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'niOutfit'
        },
        {
            id: 11,
            src: ValentinoM1,
            product_path: "mo4oc_0",
            product_counter_part: "mo4sp_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Black Turtle Neck Tshrit",
            labels: "Oasis Tshirt - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Black Tutrtle Neck Tshrit by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Black"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'mo2'
        },
        {
            id: 12,
            src: ValentinoM2,
            product_path: "mo4sp_0",
            product_counter_part: "mo4oc_0",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Black Over Coat",
            labels: "Oasis Over Coat - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Black Over Coat by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Black"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'mo2'
        },
        {
            id: 13,
            src: OasisF1,
            product_path: "fo1d_0",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Floral Tier Mini Dress",
            labels: "Oasis Dress - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Blue Floral Tier Mini Dress by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v6",
            shoes: 'fo1'
        },
        {
            id: 130,
            src: OasisF1,
            product_path: "fo1d_1",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Floral Tier Mini Dress",
            labels: "Oasis Dress - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Blue Floral Tier Mini Dress by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v13",
            shoes: 'VWTSG1553RGAHKBG0036RGShoes'
        },
        {
            id: 131,
            src: OasisF1,
            product_path: "fo1d_2",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Floral Tier Mini Dress",
            labels: "Oasis Dress - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Blue Floral Tier Mini Dress by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v13",
            shoes: 'VWTSG1553RGAHKBG0036RGShoes'
        },
        {
            id: 132,
            src: OasisF1,
            product_path: "fo1d_3",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Blue Floral Tier Mini Dress",
            labels: "Oasis Dress - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Blue Floral Tier Mini Dress by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Blue"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v13",
            shoes: 'VWTSG1553RGAHKBG0036RGShoes'
        },
        {
            id: 14,
            src: OasisF21,
            product_path: "fo2lj_0",
            product_counter_part: "fo2md_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Full Sleeve Coat",
            labels: "Oasis Coat - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Brown Coat by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Brown"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'fo2'
        },
        {
            id: 141,
            src: OasisF21,
            product_path: "fo2lj_0",
            product_counter_part: "fo2md_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Full Sleeve Coat",
            labels: "Oasis Coat - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Brown Coat by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Brown"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'fo2'
        },
        {
            id: 142,
            src: OasisF21,
            product_path: "fo2lj_0",
            product_counter_part: "fo2md_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Full Sleeve Coat",
            labels: "Oasis Coat - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Brown Coat by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Brown"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'fo2'
        },
        {
            id: 143,
            src: OasisF21,
            product_path: "fo2lj_0",
            product_counter_part: "fo2md_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Full Sleeve Coat",
            labels: "Oasis Coat - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this Brown Coat by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Brown"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'fo2'
        },
        {
            id: 15,
            src: OasisF22,
            product_path: "fo2md_0",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Off White & Black Bird Print Cotton Midi Fit and Flare Dress",
            labels: "Oasis Dress - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this off white & black bird print cotton flare Dress by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: White"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v7",
            shoes: 'fo2'
        },
        {
            id: 16,
            src: OasisF31,
            product_path: "fo3top_0",
            product_counter_part: "fo3tro_0",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Green Floral Top",
            labels: "Oasis Top - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this green floral top by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Green"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 160,
            src: OasisF31,
            product_path: "fo3top_1",
            product_counter_part: "fo3tro_1",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Green Floral Top",
            labels: "Oasis Top - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this green floral top by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Green"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 161,
            src: OasisF31,
            product_path: "fo3top_2",
            product_counter_part: "fo3tro_2",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Green Floral Top",
            labels: "Oasis Top - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this green floral top by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Green"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 162,
            src: OasisF31,
            product_path: "fo3top_3",
            product_counter_part: "fo3tro_3",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Green Floral Top",
            labels: "Oasis Top - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this green floral top by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Green"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 17,
            src: OasisF32,
            product_path: "fo3tro_0",
            product_counter_part: "fo3top_0",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "High Waist Trousers",
            labels: "Oasis Trousers- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this high waist trousers by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 170,
            src: OasisF32,
            product_path: "fo3tro_1",
            product_counter_part: "fo3top_1",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "High Waist Trousers",
            labels: "Oasis Trousers- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this high waist trousers by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 171,
            src: OasisF32,
            product_path: "fo3tro_2",
            product_counter_part: "fo3top_2",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "High Waist Trousers",
            labels: "Oasis Trousers- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this high waist trousers by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 172,
            src: OasisF32,
            product_path: "fo3tro_3",
            product_counter_part: "fo3top_3",
            garment_type: "bottom",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "High Waist Trousers",
            labels: "Oasis Trousers- LYKPCSLPW53691",
            description:
                "Complement your everyday style with this high waist trousers by Oasis.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Ocassion: Casual",
                "Color: White",
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v8",
            shoes: 'fo3'
        },
        {
            id: 18,
            src: ValentinoF1,
            product_path: "fo4pd_0",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Embroidered Crepe Couture Pink Dress",
            labels: "Oasis Dress - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this pink dress by Oasis.",
            bullet_description: [
                "Regular fit",
                "Full Sleeves",
                "Ocassion: Casual",
                "Color: Pink"
            ],
            price: "$210",
            brand: "cluster1",
            avatar: "v9",
            shoes: 'fo4'
        }
    ];

export const cluster2ProductList =
    [
        {
            id: 0,
            src: ValentinoF1,
            product_path: "fo1pd_0",
            product_counter_part: "",
            garment_type: "top",
            gender: "female",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "cluster2",
            avatar: "v9",
            shoes: 'fo1'
        },
        {
            id: 1,
            src: ValentinoM1,
            product_path: "mo1oc_0",
            product_counter_part: "mo1sp_0",
            garment_type: "top",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "cluster2",
            avatar: "v7",
            shoes: 'mo1'
        },
        {
            id: 2,
            src: ValentinoM2,
            product_path: "mo1sp_0",
            product_counter_part: "mo1oc_0",
            garment_type: "bottom",
            gender: "male",
            productPath: "/abfrl/idrisTshirt.glb",
            environmentImage: "",
            name: "Louis Philippe Sport Tshirt ",
            labels: "Louis Philippe Sport - LYKPCSLPW53691",
            description:
                "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
            bullet_description: [
                "Regular fit",
                "100% cotton",
                "Half Sleeves",
                "Ocassion: Casual",
                "Neck: Polo Neck",
                "Pattern: Stripe",
                "Color: White",
                "Collection: LY True Casual"
            ],
            price: "$210",
            brand: "cluster2",
            avatar: "v7",
            shoes: 'mo1'
        },
    ];