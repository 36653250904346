import React from 'react'
import HalfCard from "components/HalfCard";
import Button from "components/Button";
import "./ShareAvatar.scss";
import { IFRAME_ACTIONS } from 'actions/IframeActions';
import MixPanel from 'appAnalytics/mixPanel';
import { EVENT_ACTIONS, EVENT_CATEGORIES } from 'appAnalytics/eventCategories';

const ShareAvatar = ({ showShareAvatar, setShareAvatar, shareAvatarImage = '', productTitle }) => {

    const confirmHandler = async () => {
        MixPanel.buttonClicked(EVENT_CATEGORIES.AVATAR_SHARE, EVENT_ACTIONS.SHARE_CLICKED, { url: document.referrer }, true);
        window.parent.postMessage({ message: IFRAME_ACTIONS.SHARE_AVATAR, value: { shareAvatarImage } }, '*');
        // const blob = await (await fetch(shareAvatarImage)).blob();
        // const file = new File([blob], 'dopplr.png', { type: blob.type });
        // navigator.share({
        //     title: '',
        //     text: 'Check out this image!',
        //     url: window.location.href,
        //     files: [file],
        // })
    }
    const downloadBase64File = () => {
        MixPanel.buttonClicked(EVENT_CATEGORIES.AVATAR_SHARE, EVENT_ACTIONS.SAVE_IMAGE, { url: document.referrer }, true);
        const linkSource = `${shareAvatarImage}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = "dopplr.png";
        downloadLink.click();
    }
    return (
        <HalfCard key={"confirm"}
            visible={showShareAvatar}
            bodyClassName={"Dopplr__ShareAvatar__Halfcard"}
            onClose={() => {
                MixPanel.buttonClicked(EVENT_CATEGORIES.AVATAR_SHARE, EVENT_ACTIONS.SHARE_AVATAR_CLOSED);
                setShareAvatar(!showShareAvatar)
            }
            }
            name="Share Avatar"
            showHeader={false}
            whiteBackground={true}
            zIndex={1500}
            showCloseButton={true}
            title={""}
        >
            <div className='Dopplr__ShareAvatar'>
                {shareAvatarImage && <div className='Dopplr__ShareAvatar__Content'>
                    <div className='Dopplr__ShareAvatar__ImageWrapper'>
                        <img src={shareAvatarImage} alt="share avatar" />
                    </div>
                </div>}
                <div className='Dopplr__ShareAvatar__Actions'>
                    <Button
                        type="white-black"
                        onClick={downloadBase64File}
                    >
                        Save Image
                    </Button>
                    <Button
                        type="black-white"
                        onClick={confirmHandler}
                    >
                        Share
                    </Button>
                </div>
            </div>
        </HalfCard>
    )
}

export default ShareAvatar;