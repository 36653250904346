import A1 from "assets/images/adidas/ADIDAS_SWEATSHIRT_1.jpeg";
import A2 from "assets/images/adidas/ADIDAS_SWEATSHIRT_2.jpeg";
import A3 from "assets/images/adidas/ADIDAS_SWEATSHIRT_3.jpeg";
import B1 from "assets/images/adidas/ADIDAS_JOGGERS_1.jpeg";
import B2 from "assets/images/adidas/ADIDAS_JOGGERS_2.jpeg";
import B3 from "assets/images/adidas/ADIDAS_JOGGERS_3.jpeg";
import C1 from "assets/images/adidas/ColoredShirt.jpeg";
import C2 from "assets/images/adidas/WhiteShirt.jpeg";
import D1 from "assets/images/adidas/ColoredShort.jpeg";
import D2 from "assets/images/adidas/WhiteShort.jpeg";
import F1 from "assets/images/adidas/TankTopWhite.png";
import F2 from "assets/images/adidas/TankTopBlack.png";
import F3 from "assets/images/adidas/TankTopPink.png";
import F4 from "assets/images/adidas/TankTopGrey.png";
import F5 from "assets/images/adidas/TankTopYellow.png";

const adidasProductList = [
    {
        id: 0,
        src: A1,
        product_path: "v3/F214CSIM308_2",
        product_counter_part: "v3/F214CSIM309_2",
        gender: "male",
        garment_type: "top",
        environmentImage: "/environment/pillars_1k.env",
        name: "Men Hoodies",
        labels: "Recycled Inspired Hoodie",
        description:
            "Wherever you're headed, wrap yourself in football style. Inspired by the pitch, this adidas hoodie keeps you warm and comfortable with 100% recycled polyester soft fleece.",
        bullet_description: [
            "Regular fit",
            "100% Recycled polyester fleece",
            "Color: White, Green, Black",
            "Machine Wash Warm, Do not dry clean",
        ],
        price: "$210",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 1,
        src: A3,
        product_path: "v3/F214CSIM308_1",
        product_counter_part: "v3/F214CSIM309_1",
        gender: "male",
        garment_type: "top",
        environmentImage: "/environment/pillars_1k.hdr",
        name: "Men Hoodies",
        labels: "Recycled Inspired Hoodie",
        description:
            "Wherever you're headed, wrap yourself in football style. Inspired by the pitch, this adidas hoodie keeps you warm and comfortable with 100% recycled polyester soft fleece.",
        bullet_description: [
            "Regular fit",
            "100% Recycled polyester fleece",
            "Color: White, Green, Black",
            "Machine Wash Warm, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 2,
        src: A2,
        product_path: "v3/F214CSIM308_0",
        product_counter_part: "v3/F214CSIM309_0",
        gender: "male",
        garment_type: "top",
        environmentImage: "/environment/pillars_1k.hdr",
        name: "Men Hoodies",
        labels: "Recycled Inspired Hoodie",
        description:
            "Wherever you're headed, wrap yourself in football style. Inspired by the pitch, this adidas hoodie keeps you warm and comfortable with 100% recycled polyester soft fleece.",
        bullet_description: [
            "Regular fit",
            "100% Recycled polyester fleece",
            "Color: White, Green, Black",
            "Machine Wash Warm, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 3,
        src: B1,
        product_path: "v3/F214CSIM308_2",
        product_counter_part: "v3/F214CSIM309_2",
        gender: "male",
        garment_Type: "top",
        environmentImage: "/environment/pillars_1k.hdr",
        name: "Men Joggers & Trackpants",
        labels: "Recycled Inspired Joggers & Trackpants",
        description:
            "A comfy, modern take on the classic Joggers & Trackpants. Sports is your drive, your passion and your inspiration. Why not show it in your downtime, too? Step out in adidas Joggers & Trackpants and let your athlete identity read it loud and clear. Pair it with our Recycled Inspired Hoodie to complete the look. If you decide to stay in and watch the game, it shines just fine on the couch too",
        bullet_description: [
            "Regular fit",
            "100% Recycled polyester fleece",
            "Color: White, Green, Black",
            "Machine Wash Warm, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 4,
        src: B3,
        product_path: "v3/F214CSIM308_1",
        product_counter_part: "v3/F214CSIM309_1",
        gender: "male",
        garment_Type: "top",
        environmentImage: "/environment/pillars_1k.hdr",
        name: "Men Joggers & Trackpants",
        labels: "Recycled Inspired Joggers & Trackpants",
        description:
            "A comfy, modern take on the classic Joggers & Trackpants. Sports is your drive, your passion and your inspiration. Why not show it in your downtime, too? Step out in adidas Joggers & Trackpants and let your athlete identity read it loud and clear. Pair it with our Recycled Inspired Hoodie to complete the look. If you decide to stay in and watch the game, it shines just fine on the couch too",
        bullet_description: [
            "Regular fit",
            "100% Recycled polyester fleece",
            "Color: White, Green, Black",
            "Machine Wash Warm, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 5,
        src: B2,
        product_path: "v3/F214CSIM308_0",
        product_counter_part: "v3/F214CSIM309_0",
        gender: "male",
        garment_Type: "top",
        environmentImage: "/environment/pillars_1k.hdr",
        name: "Men Joggers & Trackpants",
        labels: "Recycled Inspired Joggers & Trackpants",
        description:
            "A comfy, modern take on the classic Joggers & Trackpants. Sports is your drive, your passion and your inspiration. Why not show it in your downtime, too? Step out in adidas Joggers & Trackpants and let your athlete identity read it loud and clear. Pair it with our Recycled Inspired Hoodie to complete the look. If you decide to stay in and watch the game, it shines just fine on the couch too",
        bullet_description: [
            "Regular fit",
            "100% Recycled polyester fleece",
            "Color: White, Green, Black",
            "Machine Wash Warm, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 6,
        src: C2,
        product_path: "v3/AS222NW248_1",
        product_counter_part: "v3/AS222NW244_1",
        gender: "female",
        garment_type: "top",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women Graphic Shirt with Shorts Set",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. Graphics on this set shows your authentic adidas spirit. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: Black",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 7,
        src: C1,
        product_path: "v3/AS222NW248_0",
        product_counter_part: "v3/AS222NW244_0",
        gender: "female",
        garment_type: "top",
        environmentImage: "/environment/pillars_1k.hdr",
        name: "Women Shirt with Shorts Set",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: White",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 8,
        src: D2,
        product_path: "v3/AS222NW248_1",
        product_counter_part: "v3/AS222NW244_1",
        gender: "female",
        garment_Type: "top",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women Shirt with Shorts Set",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: White",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v5",
    },
    {
        id: 9,
        src: D1,
        product_path: "v3/S221CSIW227_2",
        product_counter_part: "v3/AS222NW244TT_0",
        gender: "female",
        garment_type: "bottom",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women Graphic Shirt with Shorts Set",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. Graphics on this set shows your authentic adidas spirit. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: Black",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v4",
    },
    {
        id: 10,
        src: F1,
        product_path: "v3/S221CSIW227_0",
        product_counter_part: "v3/AS222NW244TT_0",
        gender: "female",
        garment_type: "top",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women tank top with shorts",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: White",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v4",
    },
    {
        id: 11,
        src: F4,
        product_path: "v3/S221CSIW227_1",
        product_counter_part: "v3/AS222NW244TT_0",
        gender: "female",
        garment_type: "top",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women tank top with shorts",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: White",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v4",
    },
    {
        id: 12,
        src: F2,
        product_path: "v3/S221CSIW227_2",
        product_counter_part: "v3/AS222NW244TT_0",
        gender: "female",
        garment_type: "top",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women tank top with shorts",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: White",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v4",
    },
    {
        id: 13,
        src: F5,
        product_path: "v3/S221CSIW227_3",
        product_counter_part: "v3/AS222NW244TT_0",
        gender: "female",
        garment_type: "top",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women tank top with shorts",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: White",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v4",
    },
    {
        id: 14,
        src: F3,
        product_path: "v3/S221CSIW227_4",
        product_counter_part: "v3/AS222NW244TT_0",
        gender: "female",
        garment_type: "top",
        environmentImage: "/environment/blue_lagoon_04k.hdr",
        name: "Women tank top with shorts",
        labels: "",
        description:
            "A sporty shirt with shorts set made for all day comfort. You'll always have your brand by your side, or your sleeves. The rest is just comfort you can wear through your downtime or weekend adventures. Our cotton products support more sustainable cotton farming",
        bullet_description: [
            "Loose Fit",
            "100% Cotton",
            "Color: White",
            "Machine Wash Cold, Do not dry clean",
        ],
        price: "$208",
        material_name: "",
        brand: "adidas",
        avatar: "v4",
    },
];

export default adidasProductList;