import NicobarDress from "../../assets/images/nicobar/Nicobar_Dress.png";
import NicobarFunkeyMonkey from "../../assets/images/nicobar/Nicobar_Funkey_Monkey.png";


export const nicobarFastProductList = [
    {
        id: 1,
        src: NicobarDress,
        product_path: "WAD607_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "nicobar-test",
        avatar: "v16",
        shoes: 'nicobar_wad607',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 2,
        src: NicobarFunkeyMonkey,
        product_path: "MT31FItop_0",
        product_counter_part: "MT31FIbottom_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Top",
        labels: "MT31_FI_top",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "nicobar-test",
        avatar: "v11",
        shoes: 'nicobar_mt31',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[82.2, 64.4], [86.2, 68.4], [90.2, 72.4], [95.2, 77.4], [101.2, 83.4], [107.2, 89.4], [112.2, 94.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
];

export const nicobarProductList = [
    {
        id: 1,
        src: NicobarDress,
        product_path: "WAD607_0",
        product_counter_part: "",
        garment_type: "top",
        gender: "female",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Dress",
        labels: "WA_D607",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "nicobar",
        avatar: "v16",
        shoes: 'nicobar_wad607',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[81.28, 66.04], [86.33, 71.12], [91.45, 76.20], [96.5, 82.28], [101.6, 86.36], [111.75, 96.52], [122, 106.68],], productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
    {
        id: 2,
        src: NicobarFunkeyMonkey,
        product_path: "MT31FItop_0",
        product_counter_part: "MT31FIbottom_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        // changeableItemAlbedoColor: "#8E5858",
        // changeableItemEmissiveColor: "#F2A9A9",
        name: "Nicobar Top",
        labels: "MT31_FI_top",
        description:
            "Step fully into the summer spirit with this leafy all-over print. A flattering slim fit and modern cut make this tee dress a",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "nicobar",
        avatar: "v11",
        shoes: 'nicobar_mt31',
        productSizeTags: ["XS", "S", "M", "L", "XL", "XXL", "3XL"],
        productSizeValues: [[92.71, 64.4], [97.79, 68.4], [102.87, 72.4], [107.95, 77.4], [113.03, 83.4], [123.19, 89.4], [133.35, 94.4],],
        productCounterPartSizeTags: [],
        productCounterPartSizeValues: []
    },
];