export const ASSETS_KEYLIST = {
  "clothUrl": "product_path",
  "bottomUrl": "product_counter_part",
  "shoeUrl": "shoes",
}

export const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
}

export const BRANDS = [

  { label: 'enamor', value: 'enamor' },
  { label: 'burgerbae', value: 'burgerbae' },
  { label: 'fef', value: 'fef' },
  { label: 'misschase', value: 'misschase' },
  { label: 'indianterrain', value: 'indianterrain'},
  { label: 'superkicks', value: 'superkicks' },
  { label: 'rareism', value: 'rareism' },
  { label: 'rarerabbit', value: 'rarerabbit' },
  { label: 'beinghuman', value: 'beinghuman' },
  { label: 'fashor', value: 'fashor' },
  { label: 'adidas', value: 'adidas' },
  { label: 'abfrl', value: 'abfrl' },
  { label: 'oasis', value: 'cluster1' },
  { label: 'SAN', value: 'SAN' },
  { label: 'Ananta', value: 'ANANTA' },
  { label: 'The Souled Store', value: 'thess' },
  { label: 'puma', value: 'puma' },
  { label: 'fabindia', value: 'fabindia' },
  { label: 'nicobar', value: 'nicobar' },
  { label: 'ccclub', value: 'ccclub' },
  { label: 'cava', value: 'cava' },
  { label: 'test', value: 'test' },
  { label: 'beinghumans', value: 'beinghumans' },
  { label: 'virgio', value: 'virgio' },
  { label: 'forevernew', value: 'forevernew' },
  { label: 'nicobar-test', value: 'nicbar-fast' },
  { label: 'beyondextremes', value: 'beyondextremes' },
];
export const ASSET_TYPES = {
  CLOTHES: 0,
  AVATAR: 1,
  SHOES: 2,
}
export const ASSET_TYPE_OPTIONS = [
  { label: "Top", value: ASSET_TYPES.CLOTHES },
  { label: "Bottom", value: ASSET_TYPES.CLOTHES },
  { label: "Avatar", value: ASSET_TYPES.AVATAR },
  { label: "Shoes", value: ASSET_TYPES.SHOES },
]

export const INSTRUCTIONS_TYPE = {
  AVATAR_INTERACTION: 'AVATAR_INTERACTION',
  PERSONALISE: 'PERSONALISE',
  HEATMAP: 'HEATMAP',
}

export const defaultProductMale = {
  id: 5,
  product_path: "mo1sh_0",
  product_counter_part: "mo1tr_0",
  garment_type: "top",
  gender: "male",
  productPath: "/abfrl/idrisTshirt.glb",
  environmentImage: "",
  name: "Louis Philippe Sport Tshirt ",
  labels: "Louis Philippe Sport - LYKPCSLPW53691",
  description:
    "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
  bullet_description: [
    "Regular fit",
    "100% cotton",
    "Half Sleeves",
    "Ocassion: Casual",
    "Neck: Polo Neck",
    "Pattern: Stripe",
    "Color: White",
    "Collection: LY True Casual"
  ],
  price: "$210",
  brand: "cluster1",
  avatar: "v6",
  shoes: 'mo1'
};
export const defaultAbfrlProductMale = {
  id: 0,
  product_path: "PESFG0205SL_0",
  product_counter_part: "LPTFG0043SL_0",
  garment_type: "top",
  gender: "male",
  productPath: "/abfrl/idrisTshirt.glb",
  environmentImage: "",
  name: "Louis Philippe Sport Tshirt ",
  labels: "Louis Philippe Sport - LYKPCSLPW53691",
  description:
    "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
  bullet_description: [
    "Regular fit",
    "100% cotton",
    "Half Sleeves",
    "Ocassion: Casual",
    "Neck: Polo Neck",
    "Pattern: Stripe",
    "Color: White",
    "Collection: LY True Casual"
  ],
  price: "$210",
  brand: "abfrl",
  avatar: "v2"
};

export const GARMENT_TYPE = {
  TOP: 'top',
  BOTTOM: 'bottom',
}

export const ACTION_TYPES = {
  BUST_MEASUREMENT: 'BUST_MEASUREMENT',
  MEASUREMENTS: 'MEASUREMENTS',
  DEFAULT_CREATION: 'DEFAULT_CREATION',
  SELFIE: 'SELFIE',
  DEFAULT: 'DEFAULT',
  NONE: '',
}

export const ANNOTATIONS_TEXT_HEIGHTS = {
  [GENDER.MALE]: {
    chestHeight: 1.3,
    waistHeight: 1.1,
    hipHeight: 0.82,
    highHipHeight: 0.96,
    fabricTop: 1.3,
    fabricBottom: 0.8,
  },
  [GENDER.FEMALE]: {
    chestHeight: 1.2,
    waistHeight: 1.05,
    hipHeight: 0.8,
    highHipHeight: 0.93,
    fabricTop: 1.2,
    fabricBottom: 0.75,
  },
  maleChest: 1.3,
  maleWaist: 1.1,
  maleHip: 0.85,
  maleHighHip: 1.0,
  femaleChest: 1.2,
  femaleWaist: 1.05,
  femaleHip: 0.8,
  femaleHighHip: 0.93
}

export const HEATMAP_COLOR_CODES = {
  TOO_LOOSE: [0 / 255, 121 / 255, 255 / 255, 1],
  LOOSE: [100 / 255, 100 / 255, 255 / 255, 1], // Blue
  NORMAL: [51 / 255, 168 / 255, 101 / 255, 1], // Green
  TIGHT: [253 / 255, 255 / 255, 0 / 255, 1], // Yellow
  TOO_TIGHT: [255 / 255, 80 / 255, 80 / 255, 1],
  SLIGHT_LOOSE: [75 / 255, 134 / 255, 175 / 255, 1],
  TOO_COMFORTABLE: [158 / 255, 227 / 255, 187 / 255, 1],
  BODY_HUGGING: [227 / 255, 120 / 255, 71 / 255, 1],
  SLIGHT_TIGHT: [152 / 255, 205 / 255, 50 / 255, 1],

}

export const BODY_VERTICES_LIMIT = {
  [GENDER.MALE]: {
    'Chest': {
      min: [-0.23, 1.2, 0.05],
      max: [0.23, 1.45, 0.2],
    },
    'Armpits': {
      min: [-0.187, 1.23, -0.09],
      max: [0.187, 1.37, 0.08]
    },
    'Shoulders': {
      min: [-0.23, 1.35, -0.09],
      max: [0.26, 1.45, 0.05],
    },
    'Waist': {
      min: [0.2, 0.89, -0.12],
      max: [-0.2, 0.99, 0.18],
    },
    'Torso': {
      min: [-0.12, 1.0, 0.0],
      max: [0.12, 1.2, 0.18],
    },
    'LoveHandles': {
      min: [-0.187, 0.9, -0.05],
      max: [0.187, 1.1, 0.06],
    },
    'Bums': {
      min: [-0.15, 0.83, -0.155],
      max: [0.15, 0.93, -0.110],
    }
  },
  [GENDER.FEMALE]: {
    'Chest': {
      min: [-0.145, 1.13, -0.125],
      max: [0.145, 1.4, 0.2]
    },
    'Armpits': {
      min: [-0.165, 1.23, -0.05],
      max: [0.165, 1.3, 0.05]
    },
    'Shoulders': {
      min: [-0.19, 1.3, -0.05],
      max: [0.19, 1.37, 0.05]
    },
    'Waist': {
      min: [-0.16, 0.87, -0.17],
      max: [0.16, 0.96, 0.162]
    },
    'Torso': {
      min: [-0.12, 0.96, -0.15],
      max: [0.12, 1.13, 0.162]
    },
    'LoveHandles': {
      min: [-0.15, 0.87, -0.015],
      max: [0.15, 1.0, 0.09]
    },
    'Bums': {
      min: [-0.17, 0.77, -0.155],
      max: [0.17, 0.9, -0.077],
    }
  }
};

export const MEASUREMENT_SCREEN_TYPE = {
  FACE_SELECTION: 'FACE_SELECTION',
  MAIN_MEASUREMENT: 'MAIN_MEASUREMENT',
  BRAND_MEASUREMENT: 'BRAND_MEASUREMENT',
  TUNE_AVATAR_MEASUREMENT: 'TUNE_AVATAR_MEASUREMENT',
  BUST_MEASUREMENT: 'BUST_MEASUREMENT',
};

export const CUSTOMISE_STEPS = {
  [MEASUREMENT_SCREEN_TYPE.FACE_SELECTION]: {
    TAKE_PHOTO: 1,
    SELECT_FROM_SAMPLES: 2,
    PHOTO_SELECTED: 3,
  },
  [MEASUREMENT_SCREEN_TYPE.MAIN_MEASUREMENT]: {
    HEIGHT_WEIGHT: 1,
    OTHER_MEASUREMENTS: 2,
  },
  [MEASUREMENT_SCREEN_TYPE.BRAND_MEASUREMENT]: {
    PICK_BRAND: 1,
    PICK_SIZE: 2,
  },
  [MEASUREMENT_SCREEN_TYPE.TUNE_AVATAR_MEASUREMENT]: {
    TUNE_AVATAR: 1,
  },
  [MEASUREMENT_SCREEN_TYPE.BUST_MEASUREMENT]: {
    BUST_MEASUREMENT: 1,
    BUST_SHAPE_SELECTION: 2,
    BUST_PLACEMENT_SELECTION: 3,
    // SUMMARY: 4,
  }
}

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 2 + ITEM_PADDING_TOP,
    },
  },
};
export const HEIGHT_UNIT = {
  CM: 'cm',
  IN: 'ft in',
}
export const WEIGHT_UNIT = {
  KG: 'kg',
  LB: 'lb',
}
export const OTHER_MEASUREMENTS_UNIT = {
  CM: 'cm',
  IN: 'in',
}
export const heightFtOptions = [4, 5, 6];
export const heightInchOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const BRAND_ID_MAP = {
  'beinghuman': 'BEINGHUMAN1719297799862',
  'rarerabbit': 'BEINGHUMAN1719297799862',
  'burgerbae': 'BEINGHUMAN1719297799862',
  'superkicks': 'BEINGHUMAN1719297799862',
  'fef': 'BEINGHUMAN1719297799862',
  'fashor': 'BEINGHUMAN1719297799862',
  'beinghumans': 'BEINGHUMAN1719297799862',
  'cava': 'BEINGHUMAN1719297799862',
  'ccclub': 'BEINGHUMAN1719297799862',
  'default': 'BEINGHUMAN1719297799862',
}

export const MEASUREMENTS_TYPE = {
  DEFAULT: 0,
  SHAPE_SELECTION: 1,
  BRAND: 2,
  BUST: 3,
}

export const SELFIE_STATUS = {
  VIDEO_PERMISSION_DENIED: 'VIDEO_PERMISSION_DENIED',
  ORIENTATION_PERMISSION_DENIED: 'ORIENTATION_PERMISSION_DENIED',
  FACE_NOT_DETECTED: 'FACE_NOT_DETECTED',
  FACE_DETECTED: 'FACE_DETECTED',
  MULTIPLE_FACE_DETECTED: 'MULTIPLE_FACE_DETECTED',
  VALID_SELFIE: 'VALID_SELFIE',
}

export const TermsAndConditionsURL = {
  'cava': "http://d1pu2jrxdqoaw5.cloudfront.net/cava/cava_terms.html",
  'beinghuman': "https://d1pu2jrxdqoaw5.cloudfront.net/beinghuman/beinghuman_terms.html",
}