import VWDRG1150RG from '../../assets/images/abfrl/VWDRG1150RG.jpg';

const thessProductList = [
    {
        id: 0,
        src: VWDRG1150RG,
        product_path: "ng_0",
        product_counter_part: "bdng_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "thess",
        avatar: "v8",
        shoes: 'v8shoesrbk'
    },
    {
        id: 1,
        src: VWDRG1150RG,
        product_path: "bpwf_0",
        product_counter_part: "bdbpwf_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "thess",
        avatar: "v8",
        shoes: 'v8shoesrbk'
    },
    {
        id: 2,
        src: VWDRG1150RG,
        product_path: "coo_0",
        product_counter_part: "bdcoo_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "thess",
        avatar: "v8",
        shoes: 'v8shoesrbk'
    },
    {
        id: 3,
        src: VWDRG1150RG,
        product_path: "ddsg_0",
        product_counter_part: "bdddsg_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "thess",
        avatar: "v8",
        shoes: 'v8shoesrbk'
    },
    {
        id: 4,
        src: VWDRG1150RG,
        product_path: "modt_0",
        product_counter_part: "bdmodt_0",
        garment_type: "top",
        gender: "male",
        productPath: "/abfrl/idrisTshirt.glb",
        environmentImage: "",
        name: "Louis Philippe Sport Tshirt ",
        labels: "Louis Philippe Sport - LYKPCSLPW53691",
        description:
            "Complement your everyday style with this White T-shirt from Louis Philippe Sport by House of Louis Philippe.",
        bullet_description: [
            "Regular fit",
            "100% cotton",
            "Half Sleeves",
            "Ocassion: Casual",
            "Neck: Polo Neck",
            "Pattern: Stripe",
            "Color: White",
            "Collection: LY True Casual"
        ],
        price: "$210",
        brand: "thess",
        avatar: "v8",
        shoes: 'v8shoesrbk'
    },
];
export default thessProductList;