let SKYBOX_CONFIG = {
    nicobarEnvironment: {
        id: 1,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/fabindia.jpeg",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/Fabindiaenvironment.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#F5F5E8"
                    },
                    pointLight2Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#F5F5E8"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#F5F5E8"
                    },
                    pointLight4Settings: {
                        intensity: 0.35,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
        // productList: [
        //     {
        //         product_path: "AHDRG0845RG_0",
        //         domeSettings: {
        //             domePath: "/environment/Domes/fabric_dome.glb",
        //             domeMaterialAlbedoColor: "#E8E9CB",
        //             floorMaterialAlbedoColor: "#E8E9CB",
        //             domeMaterialTexturePath: "",
        //             floorMaterialTexturePath: ""
        //         },
        //         skyboxSettings: {
        //             pathFor6Images: "",
        //             rotation: 1 * Math.PI / 2,
        //             intensity: 0.5,
        //             exposure: 0.6,
        //             contrast: 1.0
        //         },
        //         lightSettings: {
        //             type: "point",
        //             directionalLightSettings: {
        //                 keyLightSettings: {
        //                     intensity: 2.5,
        //                     position: {
        //                         x: 1.0,
        //                         y: 2.0,
        //                         z: -2.5
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 fillLightSettings: {
        //                     intensity: 5.0,
        //                     position: {
        //                         x: -1.0,
        //                         y: 1.25,
        //                         z: 2.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 backLightSettings: {
        //                     intensity: 4.0,
        //                     position: {
        //                         x: 0.0,
        //                         y: 1.0,
        //                         z: -3.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 sideLightSettings: {
        //                     intensity: 0.0,
        //                     position: {
        //                         x: 0.0,
        //                         y: 4.0,
        //                         z: 0.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 }
        //             },
        //             pointLightSettings: {
        //                 pointLight1Settings: {
        //                     intensity: 3.0,
        //                     position: {
        //                         x: -1.0,
        //                         y: 1.5,
        //                         z: 1.0
        //                     },
        //                     diffuseColor: "#E8E9CB"
        //                 },
        //                 pointLight2Settings: {
        //                     intensity: 8.0,
        //                     position: {
        //                         x: 0.5,
        //                         y: 1.5,
        //                         z: 1.5
        //                     },
        //                     diffuseColor: "#E8E9CB"
        //                 },
        //                 pointLight3Settings: {
        //                     intensity: 4.0,
        //                     position: {
        //                         x: 0.0,
        //                         y: 1.2,
        //                         z: -0.9
        //                     },
        //                     diffuseColor: "#E8E9CB"
        //                 },
        //                 pointLight4Settings: {
        //                     intensity: 2.5,
        //                     position: {
        //                         x: 2.0,
        //                         y: 1.8,
        //                         z: -0.2
        //                     },
        //                     diffuseColor: "#E8E9CB"
        //                 }
        //             }
        //         }
        //     }
        // ]
    },
    pumaEnvironment: {
        id: 2,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/fabindia.jpeg",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/Fabindiaenvironment.glb",
                domeMaterialAlbedoColor: "#FFFFFF",
                floorMaterialAlbedoColor: "#FFFFFF",
                columnMaterialAlbedoColor: "#DFDFDF",
                columnMaterialEmissiveColor: "#B4B4B4",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#D7F0F5"
                    },
                    pointLight2Settings: {
                        intensity: 8.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#D7F0F5"
                    },
                    pointLight3Settings: {
                        intensity: 8.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#D7F0F5"
                    },
                    pointLight4Settings: {
                        intensity: 0.5,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        }
    },
    fabindiaEnvironment: {
        id: 3,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/fabindia.jpeg",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/Fabindiaenvironment.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "point",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 3.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.0
                        },
                        diffuseColor: "#E8E9CB"
                    },
                    pointLight2Settings: {
                        intensity: 12.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#E8E9CB"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.5,
                            z: -2.0
                        },
                        diffuseColor: "#E8E9CB"
                    },
                    pointLight4Settings: {
                        intensity: 3.5,
                        position: {
                            x: 2.0,
                            y: 1.8,
                            z: -0.2
                        },
                        diffuseColor: "#E8E9CB"
                    }
                }
            }
        }
    },
    fabindiaEnvironmentNight: {
        id: 4,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/FabindiaenvironmentNight.jpeg",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/FabindiaenvironmentNight.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "point",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 3.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.0
                        },
                        diffuseColor: "#E8E9CB"
                    },
                    pointLight2Settings: {
                        intensity: 12.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#E8E9CB"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.5,
                            z: -2.0
                        },
                        diffuseColor: "#E8E9CB"
                    },
                    pointLight4Settings: {
                        intensity: 3.5,
                        position: {
                            x: 2.0,
                            y: 1.8,
                            z: -0.2
                        },
                        diffuseColor: "#E8E9CB"
                    }
                }
            }
        }
    },
    satinDomeLight: {
        id: 5,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/Satin_Dome_thumbnail.png",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/satin_paint_baked_dome.glb",
                domeMaterialAlbedoColor: "#C5C5C5",
                floorMaterialAlbedoColor: "#FFFFFF",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "directional",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 1.5,
                        position: {
                            x: 0.5,
                            y: 1.25,
                            z: 1.0
                        },
                        direction: {
                            x: -1.0,
                            y: -0.8,
                            z: -2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 3.5,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        direction: {
                            x: 1.0,
                            y: -1.0,
                            z: -2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 3.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        direction: {
                            x: 0.0,
                            y: 0.0,
                            z: 1.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        direction: {
                            x: 0.0,
                            y: -1.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 1.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 1.0
                        },
                        diffuseColor: "#E8E9CB"
                    },
                    pointLight2Settings: {
                        intensity: 12.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#E8E9CB"
                    },
                    pointLight3Settings: {
                        intensity: 1.0,
                        position: {
                            x: 0.0,
                            y: 1.5,
                            z: -1.0
                        },
                        diffuseColor: "#E8E9CB"
                    },
                    pointLight4Settings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
        // productList: [
        //     {
        //         product_path: "AHDRG0845RG_0",
        //         domeSettings: {
        //             domePath: "/environment/Domes/satin_paint_baked_dome.glb",
        //             domeMaterialAlbedoColor: "#C5C5C5",
        //             floorMaterialAlbedoColor: "#FFFFFF",
        //             domeMaterialTexturePath: "",
        //             floorMaterialTexturePath: ""
        //         },
        //         skyboxSettings: {
        //             pathFor6Images: "",
        //             rotation: 1 * Math.PI / 2,
        //             intensity: 0.5,
        //             exposure: 0.6,
        //             contrast: 1.0
        //         },
        //         lightSettings: {
        //             type: "directional",
        //             directionalLightSettings: {
        //                 keyLightSettings: {
        //                     intensity: 4.0,
        //                     position: {
        //                         x: 0.6,
        //                         y: 1.5,
        //                         z: 1.5
        //                     },
        //                     direction: {
        //                         x: -1.0,
        //                         y: -0.8,
        //                         z: -2.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 fillLightSettings: {
        //                     intensity: 6.0,
        //                     position: {
        //                         x: -1.2,
        //                         y: 1.75,
        //                         z: 2.0
        //                     },
        //                     direction: {
        //                         x: 1.0,
        //                         y: -0.8,
        //                         z: -2.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 backLightSettings: {
        //                     intensity: 5.0,
        //                     position: {
        //                         x: 1.6,
        //                         y: 1.5,
        //                         z: -3.0
        //                     },
        //                     direction: {
        //                         x: -0.5,
        //                         y: -0.25,
        //                         z: 1.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 sideLightSettings: {
        //                     intensity: 0.0,
        //                     position: {
        //                         x: 0.0,
        //                         y: 4.0,
        //                         z: 0.0
        //                     },
        //                     direction: {
        //                         x: 0.0,
        //                         y: -1.0,
        //                         z: 0.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 }
        //             },
        //             pointLightSettings: {
        //                 pointLight1Settings: {
        //                     intensity: 1.0,
        //                     position: {
        //                         x: -1.0,
        //                         y: 1.25,
        //                         z: 1.0
        //                     },
        //                     diffuseColor: "#E8E9CB"
        //                 },
        //                 pointLight2Settings: {
        //                     intensity: 12.0,
        //                     position: {
        //                         x: 0.5,
        //                         y: 1.5,
        //                         z: 1.5
        //                     },
        //                     diffuseColor: "#E8E9CB"
        //                 },
        //                 pointLight3Settings: {
        //                     intensity: 1.0,
        //                     position: {
        //                         x: 0.0,
        //                         y: 1.5,
        //                         z: -1.0
        //                     },
        //                     diffuseColor: "#E8E9CB"
        //                 },
        //                 pointLight4Settings: {
        //                     intensity: 0.0,
        //                     position: {
        //                         x: 0.0,
        //                         y: 4.0,
        //                         z: 0.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 }
        //             }
        //         }
        //     }
        // ]
    },
    columnHallDomeLight: {
        id: 6,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/Column_Hall_Dome_thumbnail.png",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/Column_Hall_dome.glb",
                domeMaterialAlbedoColor: "#FFFFFF",
                floorMaterialAlbedoColor: "#FFFFFF",
                columnMaterialAlbedoColor: "#DFDFDF",
                columnMaterialEmissiveColor: "#B4B4B4",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#D7F0F5"
                    },
                    pointLight2Settings: {
                        intensity: 8.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#D7F0F5"
                    },
                    pointLight3Settings: {
                        intensity: 8.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#D7F0F5"
                    },
                    pointLight4Settings: {
                        intensity: 0.5,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        }
    },
    columnHallwithHangingGarden: {
        id: 7,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/ColumnHall_HangingGarden_thumbnail.png",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/ColumnHall_Hanging_Garden.glb",
                changeableItemMaterialName: "flower",
                domeMaterialAlbedoColor: "#FFFFFF",
                floorMaterialAlbedoColor: "#FFFFFF",
                columnMaterialAlbedoColor: "#DFDFDF",
                columnMaterialEmissiveColor: "#5B5B5B",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#D7F0F5"
                    },
                    pointLight2Settings: {
                        intensity: 8.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#D7F0F5"
                    },
                    pointLight3Settings: {
                        intensity: 8.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#D7F0F5"
                    },
                    pointLight4Settings: {
                        intensity: 0.5,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        }
    },
    clo3DLight: {
        id: 8,
        acesEnabled: false,
        thumbnailImage: "/environment/Domes/Clo3DLighting_thumbnail.png",
        type: "light",
        domeSettings: {
            domePath: "",
            domeMaterialAlbedoColor: "",
            floorMaterialAlbedoColor: "",
            domeMaterialTexturePath: "",
            floorMaterialTexturePath: ""
        },
        skyboxSettings: {
            pathFor6Images: "",
            rotation: 1 * Math.PI / 2,
            intensity: 1.0,
            exposure: 1.0,
            contrast: 1.0
        },
        lightSettings: {
            type: "directional",
            directionalLightSettings: {
                keyLightSettings: {
                    intensity: 1.0,
                    position: {
                        x: -1.5,
                        y: 3.0,
                        z: 1.5
                    },
                    direction: {
                        x: 1.5,
                        y: -3.0,
                        z: -1.5
                    },
                    diffuseColor: "#969696"
                },
                fillLightSettings: {
                    intensity: 0.0,
                    position: {
                        x: 1.0,
                        y: 0.0,
                        z: -1.0
                    },
                    direction: {
                        x: -1.0,
                        y: 0.0,
                        z: 1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                backLightSettings: {
                    intensity: 0.0,
                    position: {
                        x: 0.0,
                        y: 0.0,
                        z: 1.0
                    },
                    direction: {
                        x: 0.0,
                        y: 0.0,
                        z: -1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                sideLightSettings: {
                    intensity: 0.0,
                    position: {
                        x: 0.0,
                        y: 4.0,
                        z: 0.0
                    },
                    direction: {
                        x: 0.0,
                        y: -1.0,
                        z: 0.0
                    },
                    diffuseColor: "#FFFFFF"
                }
            },
            pointLightSettings: {
                pointLight1Settings: {
                    intensity: 12.0,
                    position: {
                        x: -1.0,
                        y: 1.25,
                        z: 1.0
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight2Settings: {
                    intensity: 8.0,
                    position: {
                        x: 0.5,
                        y: 1.5,
                        z: 1.5
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight3Settings: {
                    intensity: 5.0,
                    position: {
                        x: 0.0,
                        y: 1.5,
                        z: -1.0
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight4Settings: {
                    intensity: 0.0,
                    position: {
                        x: 0.0,
                        y: 4.0,
                        z: 0.0
                    },
                    diffuseColor: "#FFFFFF"
                }
            }
        }
    },
    concreteDomeLight: {
        id: 9,
        acesEnabled: true,
        thumbnailImage: "/environment/LT_Studio_Vivid_Clo3D/Studio_Vivid.jpg",
        type: "dark",
        domeSettings: {
            domePath: "/environment/Domes/concrete_dome.glb",
            domeMaterialAlbedoColor: "",
            floorMaterialAlbedoColor: "",
            domeMaterialTexturePath: "",
            floorMaterialTexturePath: ""
        },
        skyboxSettings: {
            pathFor6Images: "/environment/LT_Studio_Vivid_Clo3D/",
            rotation: 1 * Math.PI / 2,
            intensity: 0.5,
            exposure: 0.6,
            contrast: 1.0
        },
        lightSettings: {
            type: "point",
            directionalLightSettings: {
                keyLightSettings: {
                    intensity: 1.1,
                    position: {
                        x: -1.0,
                        y: 0.0,
                        z: -1.0
                    },
                    direction: {
                        x: 1.0,
                        y: 0.0,
                        z: 1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                fillLightSettings: {
                    intensity: 0.75,
                    position: {
                        x: 1.0,
                        y: 0.0,
                        z: -1.0
                    },
                    direction: {
                        x: -1.0,
                        y: 0.0,
                        z: 1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                backLightSettings: {
                    intensity: 1.0,
                    position: {
                        x: 0.0,
                        y: 0.0,
                        z: 1.0
                    },
                    direction: {
                        x: 0.0,
                        y: 0.0,
                        z: -1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                sideLightSettings: {
                    intensity: 0.0,
                    position: {
                        x: 0.0,
                        y: 4.0,
                        z: 0.0
                    },
                    direction: {
                        x: 0.0,
                        y: -1.0,
                        z: 0.0
                    },
                    diffuseColor: "#FFFFFF"
                }
            },
            pointLightSettings: {
                pointLight1Settings: {
                    intensity: 12.0,
                    position: {
                        x: -1.0,
                        y: 1.25,
                        z: 1.0
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight2Settings: {
                    intensity: 8.0,
                    position: {
                        x: 0.5,
                        y: 1.5,
                        z: 1.5
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight3Settings: {
                    intensity: 5.0,
                    position: {
                        x: 0.0,
                        y: 1.5,
                        z: -1.0
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight4Settings: {
                    intensity: 0.0,
                    position: {
                        x: 0.0,
                        y: 4.0,
                        z: 0.0
                    },
                    diffuseColor: "#FFFFFF"
                }
            }
        }
    },
    adidasIndoorRoom: {
        id: 10,
        acesEnabled: false,
        thumbnailImage: "/environment/Adidas2_Flip/Adidas_Indoor_Room_Flip.jpg",
        type: "dark",
        domeSettings: {
            domePath: "",
            domeMaterialAlbedoColor: "",
            floorMaterialAlbedoColor: "",
            domeMaterialTexturePath: "",
            floorMaterialTexturePath: ""
        },
        skyboxSettings: {
            pathFor6Images: "/environment/Adidas2_Flip/",
            rotation: 2.2 * Math.PI / 2,
            intensity: 1.3,
            exposure: 0.6,
            contrast: 1.0
        },
        lightSettings: {
            type: "directional",
            directionalLightSettings: {
                keyLightSettings: {
                    intensity: 1.5,
                    position: {
                        x: -1.0,
                        y: 0.0,
                        z: -1.0
                    },
                    direction: {
                        x: 1.0,
                        y: 0.0,
                        z: 1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                fillLightSettings: {
                    intensity: 0.6,
                    position: {
                        x: 1.0,
                        y: 0.0,
                        z: -1.0
                    },
                    direction: {
                        x: -1.0,
                        y: 0.0,
                        z: 1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                backLightSettings: {
                    intensity: 0.6,
                    position: {
                        x: 0.0,
                        y: 0.0,
                        z: 1.0
                    },
                    direction: {
                        x: 0.0,
                        y: 0.0,
                        z: -1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                sideLightSettings: {
                    intensity: 0.0,
                    position: {
                        x: 0.0,
                        y: 4.0,
                        z: 0.0
                    },
                    direction: {
                        x: 0.0,
                        y: -1.0,
                        z: 0.0
                    },
                    diffuseColor: "#FFFFFF"
                }
            },
            pointLightSettings: {
                pointLight1Settings: {
                    intensity: 1.0,
                    position: {
                        x: -1.0,
                        y: 1.25,
                        z: 1.0
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight2Settings: {
                    intensity: 12.0,
                    position: {
                        x: 0.5,
                        y: 1.5,
                        z: 1.5
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight3Settings: {
                    intensity: 1.0,
                    position: {
                        x: 0.0,
                        y: 1.5,
                        z: -1.0
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight4Settings: {
                    intensity: 0.0,
                    position: {
                        x: 0.0,
                        y: 4.0,
                        z: 0.0
                    },
                    diffuseColor: "#FFFFFF"
                }
            }
        }
    },
    adidasMetaverse: {
        id: 11,
        acesEnabled: false,
        thumbnailImage: "/environment/Adidas1_Flip/Adidas_Metaverse_Background_Flip.jpg",
        type: "dark",
        domeSettings: {
            domePath: "",
            domeMaterialAlbedoColor: "",
            floorMaterialAlbedoColor: "",
            domeMaterialTexturePath: "",
            floorMaterialTexturePath: ""
        },
        skyboxSettings: {
            pathFor6Images: "/environment/Adidas1_Flip/",
            rotation: 3 * Math.PI / 2,
            intensity: 1.5,
            exposure: 0.6,
            contrast: 1.0
        },
        lightSettings: {
            type: "directional",
            directionalLightSettings: {
                keyLightSettings: {
                    intensity: 1.5,
                    position: {
                        x: -1.0,
                        y: 0.0,
                        z: -1.0
                    },
                    direction: {
                        x: 1.0,
                        y: 0.0,
                        z: 1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                fillLightSettings: {
                    intensity: 0.6,
                    position: {
                        x: 1.0,
                        y: 0.0,
                        z: -1.0
                    },
                    direction: {
                        x: -1.0,
                        y: 0.0,
                        z: 1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                backLightSettings: {
                    intensity: 1.1,
                    position: {
                        x: 0.0,
                        y: 0.0,
                        z: 1.0
                    },
                    direction: {
                        x: 0.0,
                        y: 0.0,
                        z: -1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                sideLightSettings: {
                    intensity: 0.0,
                    position: {
                        x: 0.0,
                        y: 4.0,
                        z: 0.0
                    },
                    direction: {
                        x: 0.0,
                        y: -1.0,
                        z: 0.0
                    },
                    diffuseColor: "#FFFFFF"
                }
            },
            pointLightSettings: {
                pointLight1Settings: {
                    intensity: 1.0,
                    position: {
                        x: -1.0,
                        y: 1.25,
                        z: 1.0
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight2Settings: {
                    intensity: 12.0,
                    position: {
                        x: 0.5,
                        y: 1.5,
                        z: 1.5
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight3Settings: {
                    intensity: 1.0,
                    position: {
                        x: 0.0,
                        y: 1.5,
                        z: -1.0
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight4Settings: {
                    intensity: 0.0,
                    position: {
                        x: 0.0,
                        y: 4.0,
                        z: 0.0
                    },
                    diffuseColor: "#FFFFFF"
                }
            }
        }
    },
    noBackground: {
        id: 12,
        acesEnabled: true,
        thumbnailImage: "",
        type: "light",
        default: {
            domeSettings: {
                domePath: "",
                domeMaterialAlbedoColor: "",
                floorMaterialAlbedoColor: "",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 0.0,
                intensity: 1.3,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "point",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 3.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight2Settings: {
                        intensity: 12.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight3Settings: {
                        intensity: 12.0,
                        position: {
                            x: 0.0,
                            y: 1.5,
                            z: -2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight4Settings: {
                        intensity: 3.5,
                        position: {
                            x: 2.0,
                            y: 1.8,
                            z: -0.2
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
        // productList: [
        //     {
        //         product_path: "AHDRG0845RG_0",
        //         domeSettings: {
        //             domePath: "",
        //             domeMaterialAlbedoColor: "",
        //             floorMaterialAlbedoColor: "",
        //             domeMaterialTexturePath: "",
        //             floorMaterialTexturePath: ""
        //         },
        //         skyboxSettings: {
        //             pathFor6Images: "",
        //             rotation: 0.0,
        //             intensity: 1.3,
        //             exposure: 0.6,
        //             contrast: 1.0
        //         },
        //         lightSettings: {
        //             type: "point",
        //             directionalLightSettings: {
        //                 keyLightSettings: {
        //                     intensity: 1.1,
        //                     position: {
        //                         x: -1.0,
        //                         y: 0.0,
        //                         z: -1.0
        //                     },
        //                     direction: {
        //                         x: 1.0,
        //                         y: 0.0,
        //                         z: 1.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 fillLightSettings: {
        //                     intensity: 4.0,
        //                     position: {
        //                         x: 1.0,
        //                         y: 0.0,
        //                         z: -1.0
        //                     },
        //                     direction: {
        //                         x: -1.0,
        //                         y: 0.0,
        //                         z: 1.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 backLightSettings: {
        //                     intensity: 2.7,
        //                     position: {
        //                         x: 0.0,
        //                         y: 0.0,
        //                         z: 1.0
        //                     },
        //                     direction: {
        //                         x: 0.0,
        //                         y: 0.0,
        //                         z: -1.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 sideLightSettings: {
        //                     intensity: 0.0,
        //                     position: {
        //                         x: 0.0,
        //                         y: 4.0,
        //                         z: 0.0
        //                     },
        //                     direction: {
        //                         x: 0.0,
        //                         y: -1.0,
        //                         z: 0.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 }
        //             },
        //             pointLightSettings: {
        //                 pointLight1Settings: {
        //                     intensity: 4.0,
        //                     position: {
        //                         x: -1.0,
        //                         y: 1.5,
        //                         z: 1.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 pointLight2Settings: {
        //                     intensity: 12.0,
        //                     position: {
        //                         x: 0.5,
        //                         y: 1.5,
        //                         z: 1.5
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 pointLight3Settings: {
        //                     intensity: 4.0,
        //                     position: {
        //                         x: 0.0,
        //                         y: 1.2,
        //                         z: -0.9
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 pointLight4Settings: {
        //                     intensity: 5.0,
        //                     position: {
        //                         x: 2.0,
        //                         y: 1.8,
        //                         z: -0.2
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 }
        //             }
        //         }
        //     }
        // ]
    },
    louisPhilippeIndoorRoom: {
        id: 13,
        acesEnabled: true,
        thumbnailImage: "/environment/abfrl1_Flip/LP_Background_Indoor_1_flip.jpg",
        type: "dark",
        domeSettings: {
            domePath: "",
            domeMaterialAlbedoColor: "",
            floorMaterialAlbedoColor: "",
            domeMaterialTexturePath: "",
            floorMaterialTexturePath: ""
        },
        skyboxSettings: {
            pathFor6Images: "/environment/abfrl1_Flip/",
            rotation: 2.2 * Math.PI / 2,
            intensity: 1.0,
            exposure: 0.6,
            contrast: 1.0
        },
        lightSettings: {
            type: "directional",
            directionalLightSettings: {
                keyLightSettings: {
                    intensity: 1.1,
                    position: {
                        x: -1.0,
                        y: 0.0,
                        z: -1.0
                    },
                    direction: {
                        x: 1.0,
                        y: 0.0,
                        z: 1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                fillLightSettings: {
                    intensity: 0.75,
                    position: {
                        x: 1.0,
                        y: 0.0,
                        z: -1.0
                    },
                    direction: {
                        x: -1.0,
                        y: 0.0,
                        z: 1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                backLightSettings: {
                    intensity: 1.0,
                    position: {
                        x: 0.0,
                        y: 0.0,
                        z: 1.0
                    },
                    direction: {
                        x: 0.0,
                        y: 0.0,
                        z: -1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                sideLightSettings: {
                    intensity: 0.0,
                    position: {
                        x: 0.0,
                        y: 4.0,
                        z: 0.0
                    },
                    direction: {
                        x: 0.0,
                        y: -1.0,
                        z: 0.0
                    },
                    diffuseColor: "#FFFFFF"
                }
            },
            pointLightSettings: {
                pointLight1Settings: {
                    intensity: 1.0,
                    position: {
                        x: -1.0,
                        y: 1.25,
                        z: 1.0
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight2Settings: {
                    intensity: 12.0,
                    position: {
                        x: 0.5,
                        y: 1.5,
                        z: 1.5
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight3Settings: {
                    intensity: 1.0,
                    position: {
                        x: 0.0,
                        y: 1.5,
                        z: -1.0
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight4Settings: {
                    intensity: 0.0,
                    position: {
                        x: 0.0,
                        y: 4.0,
                        z: 0.0
                    },
                    diffuseColor: "#FFFFFF"
                }
            }
        }
    },
    valentinoIndoorRoom: {
        id: 14,
        acesEnabled: false,
        thumbnailImage: "/environment/Valentino_Flip_Small/Valentino_Pink_4K_Flip.png",
        type: "light",
        domeSettings: {
            domePath: "/environment/Domes/concrete_dome.glb",
            domeMaterialAlbedoColor: "",
            floorMaterialAlbedoColor: "",
            domeMaterialTexturePath: "",
            floorMaterialTexturePath: ""
        },
        skyboxSettings: {
            pathFor6Images: "/environment/Valentino_Flip_Small/",
            rotation: 2.2 * Math.PI / 2,
            intensity: 1.3,
            exposure: 0.6,
            contrast: 1.0
        },
        lightSettings: {
            type: "directional",
            directionalLightSettings: {
                keyLightSettings: {
                    intensity: 1.1,
                    position: {
                        x: -1.0,
                        y: 0.0,
                        z: -1.0
                    },
                    direction: {
                        x: 1.0,
                        y: 0.0,
                        z: 1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                fillLightSettings: {
                    intensity: 0.75,
                    position: {
                        x: 1.0,
                        y: 0.0,
                        z: -1.0
                    },
                    direction: {
                        x: -1.0,
                        y: 0.0,
                        z: 1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                backLightSettings: {
                    intensity: 1.0,
                    position: {
                        x: 0.0,
                        y: 0.0,
                        z: 1.0
                    },
                    direction: {
                        x: 0.0,
                        y: 0.0,
                        z: -1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                sideLightSettings: {
                    intensity: 0.0,
                    position: {
                        x: 0.0,
                        y: 4.0,
                        z: 0.0
                    },
                    direction: {
                        x: 0.0,
                        y: -1.0,
                        z: 0.0
                    },
                    diffuseColor: "#FFFFFF"
                }
            },
            pointLightSettings: {
                pointLight1Settings: {
                    intensity: 12.0,
                    position: {
                        x: -1.0,
                        y: 1.25,
                        z: 1.0
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight2Settings: {
                    intensity: 8.0,
                    position: {
                        x: 0.5,
                        y: 1.5,
                        z: 1.5
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight3Settings: {
                    intensity: 5.0,
                    position: {
                        x: 0.0,
                        y: 1.5,
                        z: -1.0
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight4Settings: {
                    intensity: 0.0,
                    position: {
                        x: 0.0,
                        y: 4.0,
                        z: 0.0
                    },
                    diffuseColor: "#FFFFFF"
                }
            }
        }
    },
    vacationBackground: {
        id: 15,
        acesEnabled: true,
        thumbnailImage: "/environment/Vacation_Flip/Vacation_4k_Flip.jpg",
        type: "dark",
        domeSettings: {
            domePath: "",
            domeMaterialAlbedoColor: "",
            floorMaterialAlbedoColor: "",
            domeMaterialTexturePath: "",
            floorMaterialTexturePath: ""
        },
        skyboxSettings: {
            pathFor6Images: "/environment/Vacation_Flip/",
            rotation: 5.4,
            intensity: 1.0,
            exposure: 0.6,
            contrast: 1.0
        },
        lightSettings: {
            type: "directional",
            directionalLightSettings: {
                keyLightSettings: {
                    intensity: 1.1,
                    position: {
                        x: -1.0,
                        y: 0.0,
                        z: -1.0
                    },
                    direction: {
                        x: 1.0,
                        y: 0.0,
                        z: 1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                fillLightSettings: {
                    intensity: 0.75,
                    position: {
                        x: 1.0,
                        y: 0.0,
                        z: -1.0
                    },
                    direction: {
                        x: -1.0,
                        y: 0.0,
                        z: 1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                backLightSettings: {
                    intensity: 1.0,
                    position: {
                        x: 0.0,
                        y: 0.0,
                        z: 1.0
                    },
                    direction: {
                        x: 0.0,
                        y: 0.0,
                        z: -1.0
                    },
                    diffuseColor: "#FFFFFF"
                },
                sideLightSettings: {
                    intensity: 0.0,
                    position: {
                        x: 0.0,
                        y: 4.0,
                        z: 0.0
                    },
                    direction: {
                        x: 0.0,
                        y: -1.0,
                        z: 0.0
                    },
                    diffuseColor: "#FFFFFF"
                }
            },
            pointLightSettings: {
                pointLight1Settings: {
                    intensity: 1.0,
                    position: {
                        x: -1.0,
                        y: 1.25,
                        z: 1.0
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight2Settings: {
                    intensity: 12.0,
                    position: {
                        x: 0.5,
                        y: 1.5,
                        z: 1.5
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight3Settings: {
                    intensity: 1.0,
                    position: {
                        x: 0.0,
                        y: 1.5,
                        z: -1.0
                    },
                    diffuseColor: "#E8E9CB"
                },
                pointLight4Settings: {
                    intensity: 0.0,
                    position: {
                        x: 0.0,
                        y: 4.0,
                        z: 0.0
                    },
                    diffuseColor: "#FFFFFF"
                }
            }
        }
    },
    noBackgroundNicobar: {
        id: 16,
        acesEnabled: true,
        thumbnailImage: "",
        type: "light",
        default: {
            domeSettings: {
                domePath: "",
                domeMaterialAlbedoColor: "",
                floorMaterialAlbedoColor: "",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 0.0,
                intensity: 1.3,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#F5F5E8"
                    },
                    pointLight2Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#F5F5E8"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#F5F5E8"
                    },
                    pointLight4Settings: {
                        intensity: 0.35,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
        // productList: [
        //     {
        //         product_path: "AHDRG0845RG_0",
        //         domeSettings: {
        //             domePath: "",
        //             domeMaterialAlbedoColor: "",
        //             floorMaterialAlbedoColor: "",
        //             domeMaterialTexturePath: "",
        //             floorMaterialTexturePath: ""
        //         },
        //         skyboxSettings: {
        //             pathFor6Images: "",
        //             rotation: 0.0,
        //             intensity: 1.3,
        //             exposure: 0.6,
        //             contrast: 1.0
        //         },
        //         lightSettings: {
        //             type: "point",
        //             directionalLightSettings: {
        //                 keyLightSettings: {
        //                     intensity: 1.1,
        //                     position: {
        //                         x: -1.0,
        //                         y: 0.0,
        //                         z: -1.0
        //                     },
        //                     direction: {
        //                         x: 1.0,
        //                         y: 0.0,
        //                         z: 1.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 fillLightSettings: {
        //                     intensity: 4.0,
        //                     position: {
        //                         x: 1.0,
        //                         y: 0.0,
        //                         z: -1.0
        //                     },
        //                     direction: {
        //                         x: -1.0,
        //                         y: 0.0,
        //                         z: 1.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 backLightSettings: {
        //                     intensity: 2.7,
        //                     position: {
        //                         x: 0.0,
        //                         y: 0.0,
        //                         z: 1.0
        //                     },
        //                     direction: {
        //                         x: 0.0,
        //                         y: 0.0,
        //                         z: -1.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 sideLightSettings: {
        //                     intensity: 0.0,
        //                     position: {
        //                         x: 0.0,
        //                         y: 4.0,
        //                         z: 0.0
        //                     },
        //                     direction: {
        //                         x: 0.0,
        //                         y: -1.0,
        //                         z: 0.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 }
        //             },
        //             pointLightSettings: {
        //                 pointLight1Settings: {
        //                     intensity: 4.0,
        //                     position: {
        //                         x: -1.0,
        //                         y: 1.5,
        //                         z: 1.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 pointLight2Settings: {
        //                     intensity: 12.0,
        //                     position: {
        //                         x: 0.5,
        //                         y: 1.5,
        //                         z: 1.5
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 pointLight3Settings: {
        //                     intensity: 4.0,
        //                     position: {
        //                         x: 0.0,
        //                         y: 1.2,
        //                         z: -0.9
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 pointLight4Settings: {
        //                     intensity: 5.0,
        //                     position: {
        //                         x: 2.0,
        //                         y: 1.8,
        //                         z: -0.2
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 }
        //             }
        //         }
        //     }
        // ]
    },
    fabricDomeLight: {
        id: 17,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/Fabric_Dome_thumbnail.png",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/fabric_dome.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                columnMaterialEmissiveColor: "#5A5A5A",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "point",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 3.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.0
                        },
                        diffuseColor: "#E8E9CB"
                    },
                    pointLight2Settings: {
                        intensity: 12.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#E8E9CB"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.5,
                            z: -2.0
                        },
                        diffuseColor: "#E8E9CB"
                    },
                    pointLight4Settings: {
                        intensity: 3.5,
                        position: {
                            x: 2.0,
                            y: 1.8,
                            z: -0.2
                        },
                        diffuseColor: "#E8E9CB"
                    }
                }
            }
        },
        // productList: [
        //     {
        //         product_path: "AHDRG0845RG_0",
        //         domeSettings: {
        //             domePath: "/environment/Domes/fabric_dome.glb",
        //             domeMaterialAlbedoColor: "#E8E9CB",
        //             floorMaterialAlbedoColor: "#E8E9CB",
        //             domeMaterialTexturePath: "",
        //             floorMaterialTexturePath: ""
        //         },
        //         skyboxSettings: {
        //             pathFor6Images: "",
        //             rotation: 1 * Math.PI / 2,
        //             intensity: 0.5,
        //             exposure: 0.6,
        //             contrast: 1.0
        //         },
        //         lightSettings: {
        //             type: "point",
        //             directionalLightSettings: {
        //                 keyLightSettings: {
        //                     intensity: 2.5,
        //                     position: {
        //                         x: 1.0,
        //                         y: 2.0,
        //                         z: -2.5
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 fillLightSettings: {
        //                     intensity: 5.0,
        //                     position: {
        //                         x: -1.0,
        //                         y: 1.25,
        //                         z: 2.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 backLightSettings: {
        //                     intensity: 4.0,
        //                     position: {
        //                         x: 0.0,
        //                         y: 1.0,
        //                         z: -3.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 },
        //                 sideLightSettings: {
        //                     intensity: 0.0,
        //                     position: {
        //                         x: 0.0,
        //                         y: 4.0,
        //                         z: 0.0
        //                     },
        //                     diffuseColor: "#FFFFFF"
        //                 }
        //             },
        //             pointLightSettings: {
        //                 pointLight1Settings: {
        //                     intensity: 3.0,
        //                     position: {
        //                         x: -1.0,
        //                         y: 1.5,
        //                         z: 1.0
        //                     },
        //                     diffuseColor: "#E8E9CB"
        //                 },
        //                 pointLight2Settings: {
        //                     intensity: 8.0,
        //                     position: {
        //                         x: 0.5,
        //                         y: 1.5,
        //                         z: 1.5
        //                     },
        //                     diffuseColor: "#E8E9CB"
        //                 },
        //                 pointLight3Settings: {
        //                     intensity: 4.0,
        //                     position: {
        //                         x: 0.0,
        //                         y: 1.2,
        //                         z: -0.9
        //                     },
        //                     diffuseColor: "#E8E9CB"
        //                 },
        //                 pointLight4Settings: {
        //                     intensity: 2.5,
        //                     position: {
        //                         x: 2.0,
        //                         y: 1.8,
        //                         z: -0.2
        //                     },
        //                     diffuseColor: "#E8E9CB"
        //                 }
        //             }
        //         }
        //     }
        // ]
    },
    beachEnvironmentWithSkybox: {
        id: 18,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/beach_tropical_skybox.webp",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/1729452732440990.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight2Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight4Settings: {
                        intensity: 0.35,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
    },
    beachEnvironment: {
        id: 19,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/beach_tropical.webp",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/BeningHumanTropical.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight2Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight4Settings: {
                        intensity: 0.35,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
    },
    woodenEnvironment: {
        id: 20,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/wooden.webp",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/wooden.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight2Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight4Settings: {
                        intensity: 0.35,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
        productList: [
            {
                product_path: "7",
                primary: "shoes",
                domeSettings: {
                    domePath: "",
                    domeMaterialAlbedoColor: "",
                    floorMaterialAlbedoColor: "",
                    domeMaterialTexturePath: "",
                    floorMaterialTexturePath: ""
                },
                skyboxSettings: {
                    pathFor6Images: "",
                    rotation: 0.0,
                    intensity: 1.3,
                    exposure: 0.6,
                    contrast: 1.0
                },
                lightSettings: {
                    type: "env",
                    envLightSettings: {
                        path: "/environment/Domes/environment.env",
                        intensity: 1.25,
                        rotationY: 2.4,
                    },
                }
            },
        ]
    },
    noBackgroundCCC: {
        id: 21,
        acesEnabled: true,
        thumbnailImage: "",
        type: "light",
        default: {
            domeSettings: {
                domePath: "",
                domeMaterialAlbedoColor: "",
                floorMaterialAlbedoColor: "",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 0.0,
                intensity: 1.3,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#F5F5E8"
                    },
                    pointLight2Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#F5F5E8"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#F5F5E8"
                    },
                    pointLight4Settings: {
                        intensity: 0.5,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
    },
    noBackgroundNStudioEnviroment: {
        id: 22,
        acesEnabled: true,
        thumbnailImage: "",
        type: "light",
        default: {
            domeSettings: {
                domePath: "",
                domeMaterialAlbedoColor: "",
                floorMaterialAlbedoColor: "",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 0.0,
                intensity: 1.3,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "env",
                envLightSettings: {
                    path: "/environment/Domes/environment.env",
                    intensity: 1.25,
                    rotationY: 2.4,

                },
            }
        },
    },
    WoodenNStudioEnvironment: {
        id: 23,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/wooden.webp",
        type: "env",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/wooden.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "env",
                envLightSettings: {
                    path: "/environment/Domes/environment.env",
                    intensity: 1.25,
                    rotationY: 2.4,

                },
            }
        },
    },
    greyNStudioBG: {
        id: 24,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/grey.webp",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/greyBg1.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },

            lightSettings: {
                type: "env",
                envLightSettings: {
                    path: "/environment/Domes/environment.env",
                    intensity: 1.25,
                    rotationY: 2.4,

                },
            }

        },
    },
    lineTribe: {
        id: 25,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/lineTribe.webp",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/linetribe.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight2Settings: {
                        intensity: 10.0,
                        position: {
                            x: 1,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight4Settings: {
                        intensity: 0.35,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
    },
    lineTribePainting: {
        id: 26,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/lineTribePainting.webp",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/lineTribePainting.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight2Settings: {
                        intensity: 10.0,
                        position: {
                            x: 1,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight4Settings: {
                        intensity: 0.35,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
    },
    envNStudioPhilo: {
        id: 27,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/envphilo.webp",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/Envphilo.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "env",
                envLightSettings: {
                    path: "/environment/Domes/environment.env",
                    intensity: 1.25,
                    rotationY: 2.4,

                },
            }
        },
    },
    envPhilo: {
        id: 28,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/envphilo.webp",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/Envphilo.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight2Settings: {
                        intensity: 10.0,
                        position: {
                            x: 1,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight4Settings: {
                        intensity: 0.35,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
    },
    greyBG: {
        id: 29,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/grey.webp",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/greyBg1.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight2Settings: {
                        intensity: 10.0,
                        position: {
                            x: 1,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight4Settings: {
                        intensity: 0.35,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
    },
    beachEnvironments: {
        id: 30,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/beach_tropical.webp",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/BeningHumanTropicals.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight2Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight4Settings: {
                        intensity: 0.35,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
    },
    light360Environment: {
        id: 31,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/Light360.jpeg",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/light360Environment.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight2Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight4Settings: {
                        intensity: 0.35,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
    },
    virgioEnvironment: {
        id: 32,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/VirgioEnvironment.jpg",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/virgioEnvironment.glb",
                domeMaterialAlbedoColor: "",
                floorMaterialAlbedoColor: "",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight2Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight4Settings: {
                        intensity: 0.35,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
    },
    dark360Environment: {
        id: 33,
        acesEnabled: true,
        thumbnailImage: "/environment/Domes/Dark360.jpeg",
        type: "light",
        default: {
            domeSettings: {
                domePath: "/environment/Domes/dark360Environment.glb",
                domeMaterialAlbedoColor: "#E8E9CB",
                floorMaterialAlbedoColor: "#E8E9CB",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 1 * Math.PI / 2,
                intensity: 0.5,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight2Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    pointLight4Settings: {
                        intensity: 0.35,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                }
            }
        },
    },
    noBackgroundSuperkicks: {
        id: 34,
        acesEnabled: true,
        thumbnailImage: "",
        type: "light",
        default: {
            domeSettings: {
                domePath: "",
                domeMaterialAlbedoColor: "",
                floorMaterialAlbedoColor: "",
                domeMaterialTexturePath: "",
                floorMaterialTexturePath: ""
            },
            skyboxSettings: {
                pathFor6Images: "",
                rotation: 0.0,
                intensity: 1.3,
                exposure: 0.6,
                contrast: 1.0
            },
            lightSettings: {
                type: "pointAmbient",
                directionalLightSettings: {
                    keyLightSettings: {
                        intensity: 2.5,
                        position: {
                            x: 1.0,
                            y: 2.0,
                            z: -2.5
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    fillLightSettings: {
                        intensity: 5.0,
                        position: {
                            x: -1.0,
                            y: 1.25,
                            z: 2.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    backLightSettings: {
                        intensity: 4.0,
                        position: {
                            x: 0.0,
                            y: 1.0,
                            z: -3.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    sideLightSettings: {
                        intensity: 0.0,
                        position: {
                            x: 0.0,
                            y: 4.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    }
                },
                pointLightSettings: {
                    pointLight1Settings: {
                        intensity: 4.0,
                        position: {
                            x: -1.0,
                            y: 1.5,
                            z: 1.5
                        },
                        diffuseColor: "#F5F5E8"
                    },
                    pointLight2Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.5,
                            y: 1.5,
                            z: 2.0
                        },
                        diffuseColor: "#F5F5E8"
                    },
                    pointLight3Settings: {
                        intensity: 10.0,
                        position: {
                            x: 0.0,
                            y: 1.4,
                            z: -2.0
                        },
                        diffuseColor: "#F5F5E8"
                    },
                    pointLight4Settings: {
                        intensity: 0.35,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF"
                    },
                    exposure: 1,
                    contrast: 1.5,
                }
            }
        },
        productList: [
            {
                productIds: ["7"],
                primary: "shoes",
                domeSettings: {
                    domePath: "",
                    domeMaterialAlbedoColor: "",
                    floorMaterialAlbedoColor: "",
                    domeMaterialTexturePath: "",
                    floorMaterialTexturePath: ""
                },
                skyboxSettings: {
                    pathFor6Images: "",
                    rotation: 0.0,
                    intensity: 1.3,
                    exposure: 0.6,
                    contrast: 1.0
                },
                lightSettings: {
                    type: "env",
                    ambientLightSettings: {
                        intensity: 2,
                        position: {
                            x: 0.0,
                            y: 0.0,
                            z: 0.0
                        },
                        diffuseColor: "#FFFFFF",
                    },
                    envLightSettings: {
                        path: "/environment/Domes/environment.env",
                        intensity: 1,
                        rotationY: 1.597,
                        contrast: 1.5,
                        exposure: 2.4,
                    },
                }
            },
            {
                productIds: ["14"],
                primary: "shoes",
                domeSettings: {
                    domePath: "",
                    domeMaterialAlbedoColor: "",
                    floorMaterialAlbedoColor: "",
                    domeMaterialTexturePath: "",
                    floorMaterialTexturePath: ""
                },
                skyboxSettings: {
                    pathFor6Images: "",
                    rotation: 0.0,
                    intensity: 1.3,
                    exposure: 0.6,
                    contrast: 1.0
                },
                lightSettings: {
                    type: "env",
                    envLightSettings: {
                        path: "/environment/Domes/environment.env",
                        intensity: 1,
                        rotationY: 2.4,
                    },
                }
            },
        ]
    },
}
const {
    greyBG,
    fabricDomeLight,
    satinDomeLight,
    adidasIndoorRoom,
    noBackground,
    adidasMetaverse,
    columnHallDomeLight,
    pumaEnvironment,
    fabindiaEnvironmentNight,
    fabindiaEnvironment,
    vacationBackground,
    noBackgroundNicobar,
    concreteDomeLight,
    valentinoIndoorRoom,
    nicobarEnvironment,
    noBackgroundCCC,
    light360Environment,
    dark360Environment,
    virgioEnvironment,
    beachEnvironment,
    beachEnvironmentWithSkybox,
    greyNStudioBG,
    WoodenNStudioEnvironment,
    envNStudioPhilo,
    noBackgroundNStudioEnviroment,
    envPhilo,
    lineTribe,
    lineTribePainting,
    woodenEnvironment,
    noBackgroundSuperkicks,
} = SKYBOX_CONFIG;
export const skyboxConfig = {
    'adidas': [
        greyBG,
        fabricDomeLight,
        satinDomeLight,
        adidasIndoorRoom,
        noBackground,
        adidasMetaverse
    ],
    'abfrl': [
        greyBG,
        // columnHallwithHangingGarden,
        columnHallDomeLight,
        noBackground,
        fabricDomeLight,
        satinDomeLight,
        // clo3DLight
        // louisPhilippeIndoorRoom
    ],
    'puma': [
        greyBG,
        // columnHallwithHangingGarden,
        pumaEnvironment,
        fabindiaEnvironmentNight,
        // columnHallDomeLight,
        noBackground,
        // fabricDomeLight,
        // satinDomeLight,
        // clo3DLight
        // louisPhilippeIndoorRoom
    ],
    'fabindia': [
        greyBG,
        // columnHallwithHangingGarden,
        fabindiaEnvironment,
        // nicobarEnvironment,
        // columnHallDomeLight,
        noBackground,
        // fabricDomeLight,
        // satinDomeLight,
        // clo3DLight
        // louisPhilippeIndoorRoom
    ],
    'cluster1': [
        greyBG,
        fabricDomeLight,
        satinDomeLight,
        noBackground,
        vacationBackground,
    ],
    'cluster2': [
        greyBG,
        concreteDomeLight,
        noBackground,
        valentinoIndoorRoom
    ],
    'nicobar': [
        greyBG,
        nicobarEnvironment,
        noBackgroundNicobar,
    ],
    'nicobar-test': [
        greyBG,
        noBackgroundNicobar,
        nicobarEnvironment,
    ],
    'ccclub': [
        greyBG,
        noBackgroundCCC,
        light360Environment,
        dark360Environment,
        columnHallDomeLight,
    ],
    'cava': [
        greyBG,
        noBackgroundNicobar,
        light360Environment,
        dark360Environment,
    ],
    'virgio': [
        greyBG,
        virgioEnvironment,
        noBackgroundNicobar,
        light360Environment,
        dark360Environment,
    ],
    'forevernew': [
        greyBG,
        virgioEnvironment,
        noBackgroundNicobar,
        light360Environment,
        dark360Environment,
    ],
    "beinghuman": [
        greyBG,
        noBackgroundNicobar,
        beachEnvironment,
        beachEnvironmentWithSkybox,
        light360Environment,
        dark360Environment,
        greyNStudioBG,
        WoodenNStudioEnvironment,
        envNStudioPhilo,
        noBackgroundNStudioEnviroment,
    ],
    "beinghumans": [
        greyBG,
        noBackgroundNicobar,
        beachEnvironment,
        light360Environment,
        dark360Environment,
    ],
    "superkicks": [
        woodenEnvironment,
        noBackgroundSuperkicks,
        // WoodenNStudioEnvironment,
        // envNStudioPhilo,
        // noBackgroundNStudioEnviroment,
    ],
    "fef": [
        envPhilo,
        greyBG,
        lineTribe,
        lineTribePainting,
        woodenEnvironment,
        noBackgroundNicobar,
        greyNStudioBG,
        WoodenNStudioEnvironment,
        envNStudioPhilo,
        noBackgroundNStudioEnviroment,
    ],
    "burgerbae": [
        envPhilo,
        greyBG,
        lineTribe,
        lineTribePainting,
        woodenEnvironment,
        noBackgroundNicobar,
    ],
    "rarerabbit": [
        greyBG,
        woodenEnvironment,
        beachEnvironment,
        noBackgroundNicobar,
        greyNStudioBG,
        WoodenNStudioEnvironment,
        envNStudioPhilo,
        noBackgroundNStudioEnviroment,
    ],
    "rarerism": [
        woodenEnvironment,
        greyBG,
        beachEnvironment,
        noBackgroundNicobar,
        light360Environment,
        dark360Environment,
    ],
    "fashor": [
        greyBG,
        beachEnvironment,
        noBackground,
    ],
    'beyondextremes': [
        greyBG,
        noBackgroundCCC,
        light360Environment,
        dark360Environment,
        columnHallDomeLight,
    ],
    'enamor': [
        noBackgroundNicobar,
        greyBG,
        adidasIndoorRoom,
        woodenEnvironment,
        satinDomeLight,
        fabricDomeLight,
    ],
    'default': [
        greyBG,
        fabricDomeLight,
        satinDomeLight,
        adidasIndoorRoom,
        noBackgroundNicobar
    ],
};