
import Select from "containers/sandbox/Select";
import { useState } from "react";
import { ASSET_TYPE_OPTIONS, ASSET_TYPES, BRANDS } from "utils/constants";
import "./UploadAssets.scss";
import { adidasProductList, abfrlProductList, cluster1ProductList, cluster2ProductList, SANProductList, ANANTAProductList, abfrlV2ProductList, thessProductList, pumaProductList, nicobarProductList, ccclubProductList, cavaProductList, virgioProductList, forevernewProductList, beyondExtremesProductList, testProductList, beingHumansProductList, beingHumanProductListQA, rareRabbitProductList, rareismProductList, fashorProductList, superKicksProductList, fefProductList, burgerBaeProductList, enamorProductList, missChaseList, indianTerrainList} from "configs/productList";
import Button from 'components/Button';
import { userServiceAPI } from "api";
import axios from 'axios';
const PROGRESS = {
    PRESIGNED_URL: "Generating urls ...",
    TEMP_UPLOAD: "Uploading files to temporary folder...",
    OPTIMSE: "Optimising and uploading files...",
}
const productList = [
    ...adidasProductList,
    ...abfrlProductList,
    ...abfrlV2ProductList,
    ...cluster1ProductList,
    ...cluster2ProductList,
    ...SANProductList,
    ...ANANTAProductList,
    ...thessProductList,
    ...pumaProductList,
    ...nicobarProductList,
    ...ccclubProductList,
    ...cavaProductList,
    ...beyondExtremesProductList,
    ...virgioProductList,
    ...forevernewProductList,
    ...testProductList,
    ...beingHumansProductList,
    ...beingHumanProductListQA,
    ...rareRabbitProductList,
    ...rareismProductList,
    ...fashorProductList,
    ...superKicksProductList,
    ...fefProductList,
    ...burgerBaeProductList,
    ...enamorProductList,
    ...missChaseList,
    ...indianTerrainList,
];

const TEXTURE_OPTIONS = [
    {
        label: "0",
        value: 0,
    }, {
        label: "1024",
        value: 1024,
    }, {
        label: "2048",
        value: 2048,
    }, {
        label: "4096",
        value: 4096,
    }
]

const API_VERSION_OPTIONS = [
    {
        label: 'v2',
        value: 'v2',
    },
    {
        label: 'v1',
        value: 'v1',
    }];
function UploadAssets() {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [brandOption, setBrandOption] = useState(null);
    const [assetType, setAssetType] = useState(null);
    const [brandInput, setBrandInput] = useState('');
    const [assetInput, setAssetInput] = useState('');
    const [productInput, setProductInput] = useState('');
    const [productOptions, setProductOptions] = useState([]);
    const [selectedAPIVersion, setSelectedAPIVersion] = useState(API_VERSION_OPTIONS[0]);
    const [selectedTexture, setSelectedTexture] = useState(TEXTURE_OPTIONS[0]);
    const [selectedProductOption, setSelectedProductOption] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [fileDetails, setFileDetails] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");

    const onBrandsChange = (e, option) => {
        setErrorMessage("");
        setSuccessMessage("");
        setBrandOption(option);
        if (option?.value !== brandOption?.value) {
            onAssetChange(e, assetType, option);
        }
    }
    const getUniqueByKey = (array, key) => {
        return array.filter((value, index, self) =>
            index === self.findIndex((t) => t[key] === value[key])
        );
    };

    const onAssetChange = (e, option, brandOption) => {
        if (option) {
            setAssetType(option);
            let products = productList.filter(product => product.brand === brandOption?.value);
            setSelectedProductOption(null);
            switch (option?.label) {
                case "Top": {
                    setProductOptions(getUniqueByKey(products, 'product_path').map(product => {
                        return {
                            label: `${product.product_path}`,
                            value: product.product_path.split('_')[0],
                        };
                    }));
                    return;
                }
                case "Bottom": {
                    setProductOptions(getUniqueByKey(products, 'product_counter_part').map(product => {
                        return {
                            label: `${product.product_counter_part}`,
                            value: product.product_counter_part.split('_')[0],
                        };
                    }));
                    return;
                }
                case "Shoes": {
                    setProductOptions(getUniqueByKey(products, 'shoes').map(product => {
                        return {
                            label: `${product.shoes}`,
                            value: product.shoes,
                        };
                    }));
                    return;
                }
                case "Avatar": {
                    setProductOptions(getUniqueByKey(products, 'avatar').map(product => {
                        return {
                            label: `${product.gender}_avatar_${product.avatar}`,
                            value: `${product.gender}_avatar_${product.avatar}`,
                        };
                    }));
                    return;
                }
                default:
                    setProductOptions([]);
                    return;
            }
        }
        setErrorMessage("");
        setSuccessMessage("");
    }
    const onProductsChange = (e, value) => {
        setErrorMessage("");
        setSuccessMessage("");
        setSelectedProductOption(value);
    }
    const handleFileChange = (e) => {
        setErrorMessage("");
        setSuccessMessage("");
        const files = e.target.files;
        setFiles(files);
        const detailsArray = Array.from(files).map(file => ({
            name: file.name,
            type: 'model/gltf-binary',
            size: (file.size / (1024 * 1024)).toFixed(2), // Convert bytes to MB and format to 2 decimal places
        }));
        setFileDetails(detailsArray);
    };


    const handleSubmit = async (e) => {
        setErrorMessage("");
        setSuccessMessage("");
        if (selectedProductOption && brandOption && assetType && files.length > 0) {
            setLoading(true);
            try {
                setSuccessMessage(PROGRESS.PRESIGNED_URL);
                const signedUrlResponse = await userServiceAPI.getPresignedUrl({ fileDetails: fileDetails });
                const { urls, tempFolderName } = signedUrlResponse.data;
                setSuccessMessage(PROGRESS.TEMP_UPLOAD);
                // Step 2: Upload files to S3
                await Promise.all(
                    urls.map(({ url }, index) => {
                        const file = files[index];
                        return axios.put(url, file, {
                            headers: {
                                'Content-Type': 'model/gltf-binary', // Use the same content type as in the signed URL
                            },
                        });
                    })
                );
                setSuccessMessage(PROGRESS.OPTIMSE);
                // const form = new FormData();
                const payload = {
                    brand: brandOption?.value,
                    type: assetType?.value,
                    id: selectedProductOption?.value,
                    version: selectedAPIVersion?.value,
                    folder: tempFolderName,
                }
                if (selectedAPIVersion.value === "v2") {
                    payload.texture = selectedTexture?.value
                }
                const response = await userServiceAPI.uploadAssetsToS3(payload);
                setSuccessMessage(response?.data?.message || "Uploaded successfully");
                setLoading(false);
            } catch (e) {
                console.error(e);
                if (e?.response?.data?.data) {
                    const { data } = e.response.data;
                    const { file, message } = data;
                    const errorMessage = message ? `File: ${file} ${message}` : "Something went wrong";
                    setErrorMessage(errorMessage)
                } else {
                    setErrorMessage("Something went wrong")
                }
                setLoading(false);
                setSuccessMessage("");
            }
        }
    }

    const onAPIVersionChange = (e, option) => {
        setSelectedTexture(TEXTURE_OPTIONS[0])
        setSelectedAPIVersion(option);
    };

    const onTextureChange = (e, option) => {
        setSelectedTexture(option)
    }

    const isEnabled = (selectedProductOption?.label && brandOption?.label && assetType?.label && files.length > 0);
    return (
        <div className="Dopplr_UploadAssets">
            <div className="Dopplr_UploadAssets__Form">
                <h1>Upload Assets</h1>
                <Select
                    id="brand"
                    label={"Select a brand"}
                    className={'Dopplr__Sandbox__AutoComplete'}
                    options={BRANDS}
                    onChange={onBrandsChange}
                    value={brandOption}
                    onInputChange={(e, value) => setBrandInput(value)}
                    inputValue={brandInput}
                />
                <Select
                    id="assetType"
                    label={"Select AssetType"}
                    className={'Dopplr__Sandbox__AutoComplete'}
                    options={brandOption ? ASSET_TYPE_OPTIONS : []}
                    onChange={(e, option) => onAssetChange(e, option, brandOption)}
                    value={assetType}
                    onInputChange={(e, value) => setAssetInput(value)}
                    inputValue={assetInput}
                />
                <Select
                    id="assetId"
                    label={"Select id"}
                    className={'Dopplr__Sandbox__AutoComplete'}
                    options={assetType ? productOptions : []}
                    onChange={onProductsChange}
                    value={selectedProductOption}
                    onInputChange={(e, value) => {
                        setProductInput(value);
                    }
                    }
                    inputValue={productInput}
                />
                <Select
                    id="version"
                    label={"API Version"}
                    className={'Dopplr__Sandbox__AutoComplete'}
                    options={API_VERSION_OPTIONS}
                    onChange={onAPIVersionChange}
                    value={selectedAPIVersion}
                />
                {selectedAPIVersion?.value === "v2" && <Select
                    id="texture"
                    label={"Texture"}
                    className={'Dopplr__Sandbox__AutoComplete'}
                    options={TEXTURE_OPTIONS}
                    onChange={onTextureChange}
                    value={selectedTexture}
                />}
                <div style={{ paddingLeft: "1em" }}>
                    <label>Upload Files</label>
                    <div>
                        <input
                            type="file"
                            name="file"
                            onChange={handleFileChange}
                            multiple
                        />
                    </div>
                </div>
                {fileDetails.length > 0 && (
                    <div className="Dopplr_UploadAssets__UploadedFiles">
                        <h3>Uploaded Files:</h3>
                        <ul>
                            {fileDetails.map((file, index) => (
                                <li key={index}>
                                    {file.name} - {file.size} MB
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div style={{ width: '250px' }}>
                    <Button isLoading={loading} disabled={!isEnabled} type={'black-white'} onClick={handleSubmit}>Upload</Button>
                </div>
                {errorMessage && <div className="Dopplr_UploadAssets__Error">{errorMessage}</div>}
                {successMessage && <div className="Dopplr_UploadAssets__Success">{successMessage}</div>}
            </div>
        </div>
    )
}

export default UploadAssets;
